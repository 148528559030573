

import { useState } from "react";
import { sampleData2  } from "../../GeoExperiment/ChildComponents/SampleFile2";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';   // Because I imported and registered this here, It will be applied to entire Project folder, SO for others I have to set  datalabels: {   display: false    },
ChartJS.register(ChartDataLabels);






function Model_Output_MMM({}) {





    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';





    let dataChart = {};
    let optionChart = {};
    if(sampleData2.length > 0){

        dataChart = {
            labels: sampleData2.map((data) => data.Date.slice(-5)),
            datasets: [
                {
                    label: "Actual",
                    data: sampleData2.map((data) => data.Actual),
                    pointBackgroundColor: "#6e6e6e",
                    backgroundColor:"#6e6e6e",
                    borderColor: "#6e6e6e",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },

                    xAxisId: 'x-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                    yAxisId: 'y-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis

                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph

                },
                {
                    label: "Fitted",
                    data: sampleData2.map((data) => data.Fitted),
                    pointBackgroundColor: "#2899cf",
                    borderColor: "#2899cf",
                    backgroundColor:"#2899cf",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },
                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph
                },            
            ]
        }
        optionChart = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            // animation: {
            //   duration: 2000,
            //   easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
            //   animateScales: true,

                // onStart: function(animation) {
                //   console.log('Animation started');
                // },
                // onComplete: function(animation) {
                //   console.log('Animation completed');
                // },
            // },


            plugins: {
                datalabels: {
                    font: {
                        family: "Toboggan",        // To change font family of data labels on charts
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "11.8px" : "10px"
                    }
                },
                legend: {
                labels: {
                    boxWidth: screen_width === 'default-1536px' ? 12 : 10,       // For making legend square
                    font: {
                        family: "Toboggan",         // To change font family of label(label is present on top of chart)
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "12px" : "10px"
                    }
                }
                }
            
            },


            scales: {
                x: {
                    id: 'x-axis1',
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of Y-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"  
                        }
                    }
                },
                y: {
                    beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

                    ticks:{
                        callback: function(value){
                            return "₹" + value
                        },
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"
                        }
                    },

                    id: 'y-axis1',
                    grid: {
                        display: false
                    }
                }
            }
        }
    }


    const [showGraph, setShowGraph] = useState(false);

    const handleToggle = () => {
        if(showData){
            setShowData(prevState => !prevState);
        }
        setShowGraph(prevState => !prevState);
    };

    const [showData, setShowData] = useState(false);

    const handleToggle2 = () => {
        if(showGraph){
            setShowGraph(prevState => !prevState);
        }
        setShowData(prevState => !prevState);
    };













    return (
        <div  style={{height:"100%", width:"100%", display:"flex", alignItems:"flex-end" }}>
            <div  style={{width:"100%", height:"95.5%", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px", backgroundColor:""}}>
            {

                sampleData2.length>0  &&  
                    <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        
                        
                        <div  style={{width:"calc(100% - 2.5rem)", height:"12%", display:"flex", justifyContent:"space-between", alignItems:"center", borderBottom:"1px solid #E5E5E5"}}>
                            
                            <div  style={{display:"flex", alignItems:"center"}}>

                                <p  style={{color:"#0000EE", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "18px" : "15.5px"}}> MMM Experiment </p>
                                <p  className="fontF"  style={{fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "18px" : "15.5px",      color:"#171717"}}>  &nbsp; / demo_specs</p>
                                
                            </div>


                            <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "24px" : "20px"}}>

                                <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                    <button className={`toggle-container ${showGraph ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "41.8px" : "34.8px",           height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle}>
                                        <div className="toggle-button"                                        style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                    </button>
                                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Graph</p>
                                </div>

                                <div style={{ display: "flex", gap: "1px" }}>
                                    <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                    <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                </div>

                                <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                    <button className={`toggle-container ${showData ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "42.3px" : "35px",         height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle2}>
                                        <div className="toggle-button"                                       style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                    </button>
                                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Data</p>
                                </div>

                            </div>
                        </div>
                        

                        <div  style={{width:"100%", height:"88%", display:"flex", backgroundColor:""}}>

        
                                <div  style={{  width: (!showData && showGraph)    ?    "100%" 
                                                                                :    (showData && !showGraph)    ?    "0%"
                                                                                                                    :    (!showData && !showGraph) && "70%", 
                                                display: (showData && !showGraph)  ?    "none"
                                                                                :    (!showData && !showGraph) && "flex", 

                                                height:"100%", display:"flex", justifyContent:"center", alignItems:"center", transition: "width 0.2s"}}>
                                    <div  className="line-chart-responsiveness"  style={{borderRadius:"8px"}}>
                                        <div style={{width: '96%', height:"92%"}}>
                                            <Line data={dataChart} options={optionChart}/>
                                        </div>
                                    </div>
                                </div>    
        
                               
                                <div  style={{  width: (!showData && showGraph)    ?    "0%" 
                                                                                :    (showData && !showGraph)    ?    "100%" 
                                                                                                                    :    (!showData && !showGraph) && "30%", 
                                                display: (!showData && showGraph)  ?    "none"
                                                                                :    (!showData && !showGraph) && "flex", 
                                                height:"100%",  flexDirection:"column",  justifyContent:"space-between", alignItems:"flex-start", transition: "width 0.2s", backgroundColor:"", padding:"0.6rem 0 0.6rem 1.1rem"}}>
    
    
                                    <table style={{ width:"88%",      fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>
                                        <thead>
                                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px" }}>Parameter</th>
                                                <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px" }}>Value</th>
                                            </tr>
                                        </thead>
        
                                        <tbody>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>R Sq</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>94%</td>                                                                
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Mape</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>7.5%</td>
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>DW</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>1.67</td>
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>AIC</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>45367</td>                                               
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table style={{ width:"88%",      fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>
                                        <thead>
                                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px" }}>Media</th>
                                                <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px" }}>Contribution</th>
                                            </tr>
                                        </thead>
        
                                        <tbody>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>TV</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>3.23%</td>                                                                
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Print</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>1.2%</td>
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>OOH</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>0.12%</td>
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Radio</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>0.25%</td>                                               
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Meta</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>1.65%</td>                                               
                                            </tr>
                                            <tr>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Google</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>1.82%</td>                                               
                                            </tr>
                                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Total</td>
                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>8.27%</td>                                               
                                            </tr>
                                        </tbody>
                                    </table>
    
                                
                                </div>  
                                
                        </div>

                        
                    </div>
            }
            </div>
        </div>
    );
  }
  
  export default Model_Output_MMM;