
import { useState } from "react";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import Select from "react-select"; 
import { CSVLink } from "react-csv";
import { IoMdArrowBack } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { FiArrowRight } from "react-icons/fi";








const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '43px', 
        borderRadius: '4px',
        border: '1px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '16px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '12px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};


const customStyles2 = {
    control: (provided, state) => ({
        ...provided,
        height: '40px', 
        borderRadius: '4px',
        border: '1px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '10px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};








function TVData_Step3({      unique_Channels_in_TV,    mapping_Arr_TV_Step_3,    set_mapping_Arr_TV_Step_3,     non_Aggregated_Variables_TV,    set_non_Aggregated_Variables_TV,      Overall_Variables_Picked_for_Aggregation_TV,    set_Overall_Variables_Picked_for_Aggregation_TV,    after_Variables_Aggregation,    set_after_Variables_Aggregation,    headers_TV_Step3_,    set_headers_TV_Step3_,    Variable_Aggregation_Record_TV,    set_Variable_Aggregation_Record_TV,    groupingName,    set_groupingName,    uploadData_descriptionX,    setUploadData_descriptionX,    aggregate_Variables_Screen,    set_aggregate_Variables_Screen,    disable_grp_name_desc,    set_disable_grp_name_desc,     overAll_Variable_Groups,    set_overAll_Variable_Groups      }) {


    // console.log("after_Variables_Aggregation :- ", after_Variables_Aggregation);
    // console.log("unique_Channels_in_TV :- ", unique_Channels_in_TV);




    const [Aggregate_Variables_Popup, set_Aggregate_Variables_Popup] = useState(false);
    const [New_Variable_Name, set_New_Variable_Name] = useState('');






    


    const [checkedItems2, setCheckedItems2] = useState([]);
    if(unique_Channels_in_TV.length > 0    &&    checkedItems2.length===0){
        setCheckedItems2(new Array(unique_Channels_in_TV.length).fill(false));
    }
  

    const handleCheckboxChange2 = (index) => {

        const selected_Item = unique_Channels_in_TV[index];
        set_Overall_Variables_Picked_for_Aggregation_TV((prev_Items) => {
            if (prev_Items.includes(selected_Item)) {    return prev_Items.filter(state => state !== selected_Item);    }
            else {                                       return [...prev_Items, selected_Item];                         }
        });


        const updatedCheckedItems = checkedItems2.map((checked, i) =>  i === index   ?   !checked   :   checked);
        // console.log("updatedCheckedItems:-", updatedCheckedItems);
        setCheckedItems2(updatedCheckedItems);

    };

    useEffect(() => {
        if(checkedItems2.includes(true)){
            const res = unique_Channels_in_TV.filter((item, index) => checkedItems2[index])
            console.log(`Variables selected Currently :- `, res);
        }
    }, [checkedItems2]);
















    const[mappingArr_is_Updated, set_mappingArr_is_Updated] = useState(false);    // If i am coming from step 2 to step 3, I do not want all this processing to happen if nothing is changed, so this state variable acts as a flag


    useEffect(()=>{

        if(Aggregate_Variables_Popup    &&    mapping_Arr_TV_Step_3.length>0){
            console.log("Before deletion :- ", after_Variables_Aggregation);

            const updatedArray = after_Variables_Aggregation.map(obj => {
                const newObj = { ...obj };
                const keysToDelete = Object.keys(newObj).filter(key => key.includes(`TV_CH_${groupingName}_Impr_`));
                keysToDelete.forEach(key => delete newObj[key]);
                return newObj;
            });
              
            set_after_Variables_Aggregation(updatedArray);              
        }



        if(!Aggregate_Variables_Popup    &&    New_Variable_Name    &&    !space_Found_in_MC_Name    &&    !duplicate_Variable_name){

            set_disable_grp_name_desc(true);
            set_mappingArr_is_Updated(true);

            
            set_non_Aggregated_Variables_TV(unique_Channels_in_TV.filter(item => !Overall_Variables_Picked_for_Aggregation_TV.includes(item)))
            const res = unique_Channels_in_TV.filter((item, index) => checkedItems2[index]);
            console.log("res :- ", res);

            
            if(res.length === 0){
                const updatedItems = mapping_Arr_TV_Step_3.filter(obj => obj.MergedColumnName  !==  New_Variable_Name);
                set_mapping_Arr_TV_Step_3(updatedItems);
                set_New_Variable_Name('');
                return;
            }


            if(    mapping_Arr_TV_Step_3.some(obj => obj.MergedColumnName  ===  New_Variable_Name)    ){                       // If MergedColumnName already exists, then override it with newly selected Agg_Variables
                const existingIndex = mapping_Arr_TV_Step_3.findIndex(obj => obj.MergedColumnName === New_Variable_Name);
                const updatedArr = mapping_Arr_TV_Step_3.map(    (obj, index) => index === existingIndex ? { ...obj, Agg_Variables: res } : obj    );
                set_mapping_Arr_TV_Step_3(updatedArr)
            }
            else{
                // console.log("Entry registered")
                const newEntry = {   MergedColumnName: New_Variable_Name,    Agg_Variables: res   };
                set_mapping_Arr_TV_Step_3([...mapping_Arr_TV_Step_3, newEntry]);
            }


            set_New_Variable_Name('');
            setCheckedItems2(new Array(unique_Channels_in_TV.length).fill(false));
        }

    },[Aggregate_Variables_Popup]); 



    useEffect(()=>{
        if(mapping_Arr_TV_Step_3.length>0){
            console.log("mapping_Arr_TV_Step_3 :- ", mapping_Arr_TV_Step_3);
        }
    },[mapping_Arr_TV_Step_3]);


    useEffect(()=>{
        if(after_Variables_Aggregation.length > 0){
            console.log("after_Variables_Aggregation :- ", after_Variables_Aggregation);
        }
    }, [after_Variables_Aggregation])












    const [disable_input_field, set_disable_input_field] = useState(false);

    useEffect(()=>{                          // Suppose if Agg_Variables have already been selected for new column, but user wants to edit the Agg_Variables(Add new Agg_Variables and remove previous Agg_Variables)
        if(New_Variable_Name.length > 0    &&    edit_Flag    &&    mapping_Arr_TV_Step_3.some(obj => obj.MergedColumnName === New_Variable_Name)){
     
            set_disable_input_field(true);

            if(checkedItems2.includes(true)){     // Suppose I selected few elements, but did not give any name and close the pop-up and then i open existing key-values pair, then problem will arise, that's why This if statement is here
                const res = unique_Channels_in_TV.filter((item, index) => checkedItems2[index])
                console.log("Elements to be removed from Overall_Variables_Picked_for_Aggregation_TV :- ", res);

                set_Overall_Variables_Picked_for_Aggregation_TV(prevState => 
                    prevState.filter(item => !res.includes(item))
                );
            }


            const index = mapping_Arr_TV_Step_3.findIndex(obj => obj.MergedColumnName === New_Variable_Name);
            console.log("mapping_Arr_TV_Step_3[index] :- ", mapping_Arr_TV_Step_3[index].Agg_Variables);

            const filteredArray  =  unique_Channels_in_TV.map(item => mapping_Arr_TV_Step_3[index].Agg_Variables.includes(item));
            console.log("filteredArray:-", filteredArray);
            setCheckedItems2(filteredArray);

            set_edit_Flag(false);
        }
    },[New_Variable_Name]);









    


    const[after_Mapping_arr_TV_Step3_is_Updated, set_after_Mapping_arr_TV_Step3_is_Updated] = useState(false);

    useEffect(()=>{

        if(!Aggregate_Variables_Popup    &&    mapping_Arr_TV_Step_3.length===0    &&    mappingArr_is_Updated){
            set_after_Variables_Aggregation([]);
            set_mappingArr_is_Updated(false);   
        }

        if(!Aggregate_Variables_Popup    &&    mapping_Arr_TV_Step_3.length>0    &&    mappingArr_is_Updated){ 
            

            const variable_To_MergedVariable_Map = {};
            mapping_Arr_TV_Step_3.forEach(({MergedColumnName, Agg_Variables}) => {
                Agg_Variables.forEach(position => {
                    const formattedPosition = position.replace(/\s+/g, '_'); // Replace spaces with underscores (even though it will already have _ instead of spaces)
                    variable_To_MergedVariable_Map[formattedPosition] = `TV_CH_${groupingName}_Impr_`+MergedColumnName;
                });
            });
            console.log("variable_To_MergedVariable_Map :- ", variable_To_MergedVariable_Map);







            const prefix = 'TV_CH_Impr_';

            Object.keys(variable_To_MergedVariable_Map).forEach(key => {
                const value = variable_To_MergedVariable_Map[key];    // Step 1: Get the value of the original key.           
                delete variable_To_MergedVariable_Map[key];           // Step 2: Delete the old key (since we cannot change the key itself, therefore Remove the original key)
                variable_To_MergedVariable_Map[prefix + key] = value; // Step 3: Add the new key with the prefix.
            });

            console.log("variable_To_MergedVariable_Map :- ", variable_To_MergedVariable_Map);


            



            const output = after_Variables_Aggregation.map(item => {

                const aggregated = {};
                let stateTotals = {};

                
                for (const key in item) {
                    if (variable_To_MergedVariable_Map[key]) {
                        const state = variable_To_MergedVariable_Map[key];

                        if (!stateTotals[state]) {
                            stateTotals[state] = 0;        // Initialize the state in stateTotals if not already done
                        }

                        stateTotals[state] += item[key];
                    }
                    
                    aggregated[key] = item[key];
                }


                for (const key in item) {
                    if (!variable_To_MergedVariable_Map[key]) {
                        const modifiedKey = key.replace(/(TV_CH_)(Impr)/, `$1${groupingName}_$2`);
                        aggregated[modifiedKey] = item[key];    // Use the modified key
                    }
                }


                for (const [state, total] of Object.entries(stateTotals)) {
                    aggregated[state] = total;
                }
                

                return aggregated;
            });
            console.log("output :- ", output);




            

            const newObject = {
                Name: groupingName,          
                Description: uploadData_descriptionX,   
                Dimension: Dimension_Val.value,
                MappingArr: mapping_Arr_TV_Step_3
            };
            console.log("newObject :- ", newObject);          


            //set_Variable_Aggregation_Record_TV(prevState => [  ...prevState,  newObject  ]);


            set_Variable_Aggregation_Record_TV(prevState => {
                const existingIndex = prevState.findIndex(item => item.Name === newObject.Name);    // Check if the object with the same Name already exists in the array
            
                if (existingIndex !== -1) {                                                         // If the object exists, replace it with the newObject (basically suppose user create 1 new merged variable already, then user edits or create another 2nd merged variable, in that case we are updating, basically over-riding it)
                    return prevState.map((item, index) =>
                        index === existingIndex ? newObject : item
                    );
                } 
                else {                                                                              // If the object doesn't exist, append the newObject
                    return [...prevState, newObject];
                }
            });



            if(!overAll_Variable_Groups.includes(groupingName)){
                set_overAll_Variable_Groups([...overAll_Variable_Groups, groupingName])
            }
            





            set_after_Mapping_arr_TV_Step3_is_Updated(true);
            set_after_Variables_Aggregation(output);
            set_mappingArr_is_Updated(false);
        }

    },[mappingArr_is_Updated]);

    useEffect(()=>{
        if(Variable_Aggregation_Record_TV.length > 0){
            console.log("Variable_Aggregation_Record_TV :- ", Variable_Aggregation_Record_TV);
        }
    },[Variable_Aggregation_Record_TV])

    useEffect(()=>{
        if(overAll_Variable_Groups.length > 0){
            console.log("overAll_Variable_Groups :- ", overAll_Variable_Groups);
        }
    },[overAll_Variable_Groups])



    useEffect(()=>{
        if(after_Variables_Aggregation.length === 0){
            console.log("Yes it is empty now : after_Variables_Aggregation");
        }
        if(after_Variables_Aggregation.length > 0    &&    after_Mapping_arr_TV_Step3_is_Updated){
            const formattedData = Object.keys(after_Variables_Aggregation[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            set_headers_TV_Step3_(formattedData);
            set_after_Mapping_arr_TV_Step3_is_Updated(false);
        }
    },[after_Variables_Aggregation]);





    const [space_Found_in_MC_Name, set_space_Found_in_MC_Name] = useState(false);
    const [edit_Flag, set_edit_Flag] = useState(false);
    const [duplicate_Variable_name, set_duplicate_Variable_name] = useState(false);

    useEffect(()=>{
        if(New_Variable_Name.length > 0    &&    !edit_Flag){

            if(New_Variable_Name.includes(' ')){    set_space_Found_in_MC_Name(true);    }
            else{                                   set_space_Found_in_MC_Name(false);   }

            if (mapping_Arr_TV_Step_3.some(obj => obj.MergedColumnName === New_Variable_Name)){    set_duplicate_Variable_name(true);    }
            else{                                                                                  set_duplicate_Variable_name(false);    }
            
        }
    },[New_Variable_Name])







    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)  screen_width = '1200_1400';














    const heightX =  Variable_Aggregation_Record_TV.length===1  ?  "32.8%"  :  Variable_Aggregation_Record_TV.length===2  ?  "49.6%"  :  Variable_Aggregation_Record_TV.length===3  ?  "66.4%"  :  Variable_Aggregation_Record_TV.length===4  ?  "83.2%"  :  "100%"


    
    const [Dimension_Selection_Options, set_Dimension_Selection_Options] = useState([{ value: 'Channel', label: 'Channel' },  { value: 'Genre', label: 'Genre' },  { value: 'Language', label: 'Language' }]);
    const [Dimension_Val, set_Dimension_Val] = useState({ value: 'Channel', label: 'Channel' });

    function set_Dimension_Val_Func(X){
        set_Dimension_Val(X);
    }


    const [group_name_Popup, set_group_name_Popup] = useState(false);
    const [selected_Group_name, set_selected_Group_name] = useState('');
    const [description_Popup, setDescription_Popup] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');

    const [groupingName_is_Unique, set_groupingName_is_Unique] = useState(false);







    const handleChange = (valueX) => {
        set_groupingName(valueX);
    };

    function handleChange2(valueX) {   
        setUploadData_descriptionX(valueX);
    }


    useEffect(()=>{
        if(overAll_Variable_Groups.includes(groupingName)){
            set_groupingName_is_Unique(false);
        }
        else{
            set_groupingName_is_Unique(true);
        }
    },[groupingName]);




    const [existing_Group_Opened, set_existing_Group_Opened] = useState(false);
    useEffect(()=>{
        if(existing_Group_Opened    &&    groupingName.length>0){
            set_existing_Group_Opened(false);



            const foundObject = Variable_Aggregation_Record_TV.find(item => item.Name === groupingName);
            console.log("foundObject :- ", foundObject.MappingArr);


            set_mapping_Arr_TV_Step_3(foundObject.MappingArr);


            const Overall_Variables_Picked_for_this_grouping = [];
            foundObject.MappingArr.forEach(item => {
                Overall_Variables_Picked_for_this_grouping.push(...item.Agg_Variables); // Flatten the arrays into one
            });
            console.log("Overall_Variables_Picked_for_this_grouping :- ", Overall_Variables_Picked_for_this_grouping);
            set_Overall_Variables_Picked_for_Aggregation_TV(Overall_Variables_Picked_for_this_grouping);
            set_non_Aggregated_Variables_TV(unique_Channels_in_TV.filter(item => !Overall_Variables_Picked_for_this_grouping.includes(item)));


            set_aggregate_Variables_Screen(true);
        }
    },[existing_Group_Opened, groupingName])

    















    



    return (
        <div  style={{width:"100%",  height:"100%"}}>



        {
            aggregate_Variables_Screen    
                ?

                <div    style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"flex-start", gap:"0.8rem", backgroundColor:""}}>

                    <div    style={{width:"100%", height:"10.5%", display:"flex", justifyContent:"space-between", alignItems:"center", backgroundColor:"", gap:"1.6rem"}}>

                        <div    style={{width:"58%", height:"100%",  display:"flex", backgroundColor:"", justifyContent:"space-between", alignItems:"center"}}>
                            <div  style={{width:"30%", position:"relative"}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-10px", left:"10px", color:"#404040"}}> Name <span style={{ color: "red", fontSize: "14px", marginLeft: "0px" }}>*</span>  </h2>
                                <input    type="text"  value={groupingName}    onChange={(e) => {handleChange(e.target.value)}}      className="input-field"   placeholder="Dimension Name"  style={{fontWeight:"500",    opacity:disable_grp_name_desc ? "0.6" : "1"}}    disabled={disable_grp_name_desc}/>
                            </div>



                            <div style={{width:"60%",  position:"relative",  display:"flex", flexDirection:"column", justifyContent:"space-between", backgroundColor:""}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-10px", left:"10px", color:"#404040"}}> Description <span style={{ color: "red", fontSize: "14px", marginLeft: "0px" }}>*</span>  </h2>
                                <input    type="text"  value={uploadData_descriptionX}    onChange={(e) => {handleChange2(e.target.value)}}      className="input-field"   placeholder="Description"  style={{fontWeight:"500",    opacity:disable_grp_name_desc ? "0.6" : "1"}}    disabled={disable_grp_name_desc}/>
                            </div>    
                        </div>     


                        <div  style={{width:"42%", height:"100%", backgroundColor:"", display:"flex", justifyContent:"flex-end", alignItems:"center"   }}>
                            <button    onClick={()=>{set_Aggregate_Variables_Popup(true)}}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600",    opacity:(groupingName.length===0  ||  uploadData_descriptionX.length===0  ||  !groupingName_is_Unique) ? "0.6" : "1" }}      disabled={(groupingName.length===0  ||  uploadData_descriptionX.length===0  ||  !groupingName_is_Unique)}  >Create Group</button> 
                        </div>
                        
                    </div>

                    <div  style={{width:"100%", height:"89.5%", display:"flex", gap:"1.6rem", backgroundColor:""}}>
                        
                        <div  style={{width:"58%", overflowY:"scroll", height:"330px", backgroundColor:"", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                            <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%", height:mapping_Arr_TV_Step_3.length===0 ? "100%" : "",     backgroundColor: "", color:"#171717" }}>
                                
                                <thead  style={{width:"100%"}}>
                                        <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                            <th    style={{width:"5%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                            <th    style={{width:"30%",    padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>Group Name</th>
                                            <th    style={{width:"5%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start"}}></th>
                                            <th    style={{width:"60%",    padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>Mapped</th>
                                        </tr>
                                </thead>

                                <tbody  style={{width:"100%"}}>
                                    {
                                        mapping_Arr_TV_Step_3.length>0
                                        ?
                                            mapping_Arr_TV_Step_3.map((obj, index) => {
                                                return(
                                                    <tr key={index}  style={{width:"100%"}}>

                                                        <td      style={{width:"5%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd", borderLeft:"0px solid #ddd"}}></td>
                                                        <td      style={{width:"30%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd", borderRight:"1px solid #ddd", verticalAlign:"top"}}>    {obj.MergedColumnName}    </td>
                                                        <td      style={{width:"5%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd"}}></td>

                                                        <td    onClick={()=>{  set_Aggregate_Variables_Popup(true);    set_New_Variable_Name(obj.MergedColumnName);    set_edit_Flag(true)  }}   style={{ width:"60%", borderBottom:"1px solid #ddd", borderRight:"0px solid #ddd",  backgroundColor:"",  padding:"6px 0 10px 0", cursor:"pointer"    }}>
                                                            {
                                                                obj.Agg_Variables.map((region, index) => (
                                                                    <div key={index}  style={{paddingTop:"4px"}}>{region}</div>
                                                                ))
                                                            }
                                                        </td>
                                                                        
                                                    </tr>
                                                );
                                            })
                                        :
                                            <tr>
                                                <td colSpan="4" style={{ height: "100%", backgroundColor:"", width: "100%" }}>
                                                    <div  style={{width:"100%", height:"100%",  display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1rem", backgroundColor:""}}>
                                                        <svg    style={{width:screen_width === 'default-1536px' ? "160px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                                                            <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                                                            <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                                                            <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                                                            <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                                                        </svg>

                                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"2px", backgroundColor:""}}>
                                                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "22px" : "24.5px", color:"#111827", fontWeight:"600"}}>No Variables aggregated</p>

                                                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "16px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Start Aggregating Variables</p>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>

                            </table>
                        </div>

                        

                        <div  style={{width:"42%", overflowY:"scroll", height:"330px", backgroundColor:"", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                            <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",     backgroundColor: "", color:"#171717" }}>
                                
                                <thead  style={{width:"100%"}}>
                                        <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                            <th    style={{width:"7%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                            <th    style={{width:"93%",    padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>UnMapped ({non_Aggregated_Variables_TV.length})</th>
                                        </tr>
                                </thead>

                                <tbody  style={{width:"100%"}}>
                                    {                           
                                        non_Aggregated_Variables_TV.map((item, index) => (
                                            
                                                <tr key={index}  style={{width:"100%", backgroundColor:""}}>
                                                    <td      style={{width:"7%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"0px solid #ddd", borderLeft:"0px solid #ddd"}}></td>

                                                    <td  style={{ width:"93%", borderBottom:"0px solid #ddd", borderRight:"0px solid #ddd",  backgroundColor:""}}>    
                                                        <p  style={{width:"100%", backgroundColor:"", textAlign:"start",   padding:screen_width === 'default-1536px' ? "5px 5px 5px 0" : "10.5px 10.5px 10.5px 0"}}> {item} </p>    
                                                    </td>                                                                
                                                </tr>
                                            
                                        ))
                                    }
                                </tbody>


                            </table>

                        </div>
                        
                    </div>

                </div>

                :

                <div    style={{width:"100%", height:"100%", backgroundColor:"", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"flex-start", backgroundColor:"", gap:"0.8rem"}}>
                    
                    <div    style={{width:"100%", height:"10.5%", display:"flex", justifyContent:"space-between", alignItems:"center", backgroundColor:""}}>

                        <div  style={{width:"15%", height:"100%", position:"relative", backgroundColor:""}}>
                            <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Dimension</h2>
                            <Select  options={Dimension_Selection_Options}  value={Dimension_Val}  onChange={set_Dimension_Val_Func}    styles={screen_width === 'default-1536px' ? customStyles : customStyles2}/>
                        </div>

                        <button    onClick={()=>{    set_aggregate_Variables_Screen(true);    set_Overall_Variables_Picked_for_Aggregation_TV([]);    set_mapping_Arr_TV_Step_3([]);    set_non_Aggregated_Variables_TV(unique_Channels_in_TV)    }}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}>Create Dimensions</button>

                    </div>
                   

                    <div  style={{width:"100%", height:"89.5%", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                        
                        <div    style={{width:"100%",  height:"87%", backgroundColor:""}}>
                            <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",  height:heightX,    backgroundColor: "", color:"#171717", borderRadius:"8px" }}>
                                <thead  style={{width:"100%"}}>
                                        <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                            <th    style={{width:"3%",       padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                            <th    style={{width:"26%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Dimension Name</th>
                                            <th    style={{width:"50%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Description</th>
                                            <th    style={{width:"21%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}></th>
                                        </tr>
                                </thead>

                                <tbody  style={{width:"100%"}}>
                                    {
                                        Variable_Aggregation_Record_TV.length > 0 
                                        ? 
                                            Variable_Aggregation_Record_TV.map((obj, index) => {
                                                    return(
                                                        <tr key={index}  style={{width:"100%"}}>
                                                            <td      style={{width:"3%" , borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"0px solid #ddd"}}></td>
                                                            <td      style={{width:"26%", borderBottom:"1px solid #ddd", textAlign:"start"}}>
                                                                {
                                                                    screen_width === 'default-1536px'   ?
                                                                                                            obj.Name.length > 18       ?   <div  style={{display:"flex"}}>
                                                                                                                                                    <p>{obj.Name.slice(0,16)}</p>
                                                                                                                                                    <button    onClick={()=>{set_group_name_Popup(true);  set_selected_Group_name(obj.Name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                </div>  
                                                                                                                                            :   obj.Name
                                                                                                        :
                                                                                                            obj.Name.length > 14       ?   <div  style={{display:"flex"}}>
                                                                                                                                                    <p>{obj.Name.slice(0,14)}</p>
                                                                                                                                                    <button    onClick={()=>{set_group_name_Popup(true);  set_selected_Group_name(obj.Name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                </div>  
                                                                                                                                            :   obj.Name
                                                                }
                                                            </td>
                                                            <td      style={{width:"50%", borderBottom:"1px solid #ddd", textAlign:"start"}}>    
                                                                {
                                                                    obj.Description.length > 50     ?    <div  style={{display:"flex"}}>
                                                                                                            <p>{obj.Description.slice(0,48)}</p>
                                                                                                            <button    onClick={()=>{setDescription_Popup(true);  setSelectedDescription(obj.Description)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                        </div>  
                                                                                                    :    obj.Description
                                                                }
                                                                
                                                            </td>
                                                            <td      style={{width:"21%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"0px solid #ddd"}}>
                                                                <button      onClick={()=>{    set_groupingName(obj.Name);    setUploadData_descriptionX(obj.Description);    set_existing_Group_Opened(true);    set_disable_grp_name_desc(true)    }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Edit</button>
                                                            </td>
                                                        </tr>
                                                    );
                                            })
                                        :

                                        <tr>
                                            <td colSpan="4" style={{ height: "100%", backgroundColor:"", width: "100%" }}>
                                                <div  style={{width:"100%", height:"100%",  display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1rem"}}>
                                                    <svg    style={{width:screen_width === 'default-1536px' ? "160px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                                                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                                                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                                                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                                                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                                                    </svg>

                                                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"2px", backgroundColor:""}}>
                                                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "22px" : "24.5px", color:"#111827", fontWeight:"600"}}>No Variable Groups Available</p>

                                                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "16px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Create Variable Groups</p>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>

                                    }
                                </tbody>
                            </table>
                        </div>

                        <div    style={{width:"100%", height:"13%", backgroundColor:"", display:"flex", justifyContent:"flex-end", alignItems:"center", paddingRight:"0.5rem", borderTop:"1px solid #f1f1f1",    }}>
                            
                        </div>

                        
                    </div>

                </div>

        }



                <div>
                    {   
                        after_Variables_Aggregation.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={after_Variables_Aggregation}    headers={headers_TV_Step3_}    filename={"Grouped"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>
                        </div>
                    }
                </div>







        {    
            Aggregate_Variables_Popup  &&  ( 
                <div   className={`mmm-modal-delete-rows`}  style={{width:"620px", height:"520px", background:"#FFFFFF", color:"#171717", padding:"20px 35px", gap:"2rem", zIndex:"3"}}>

                    <div  style={{height:"7%", width:"100%", borderBottom:"1px solid white",   display:"flex", alignItems:"center", justifyContent:"space-between", backgroundColor:""}}>
                        <p  style={{fontSize:"26px", fontWeight:"500", color:"#474747"}}>Create Group</p>
                        <button  onClick={()=>{set_Aggregate_Variables_Popup(false);    set_disable_input_field(false)}}>    <RxCross2  fontSize={32}/>    </button>
                    </div>


                    <div style={{width:"100%", height:"10%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <div  style={{width:"50%", position:"relative"}}>
                            <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1", fontSize:screen_width === 'default-1536px' ? "12px" : "11px", top:screen_width === 'default-1536px' ? "-9px" : "-8px", left:"10px", padding:"0 4px", color:"#404040"}}>Group Name</h2>
                            <input    type="text"    value={New_Variable_Name}    onChange={(e) => {set_New_Variable_Name(e.target.value)}}      className="mmm-input-field2"   placeholder="Group Name"    style={{opacity:disable_input_field ? "0.6" : "1"}}    disabled={disable_input_field}/>
                        </div>
                        <div  style={{width:"40%"}}>
                            {
                                space_Found_in_MC_Name    ?    <p style={{color:"red"}}>Space not allowed</p>
                                                        :    duplicate_Variable_name    &&    <p style={{color:"red"}}>Name already exists</p>
                            }
                        </div>
                    </div>


                    <div style={{height:"74%", width:"100%", padding:"0", overflowY:"scroll", backgroundColor:""}}>
                        
                        <ul  style={{display:"flex", flexDirection:"column", gap:"0.8rem"}}>

                            {
                                checkedItems2.map((item, index) => (
                                    item &&
                                        <li key={index}  style={{display:"flex", gap:"0.6rem", fontSize:"17px", color:"#5C5C5C", fontWeight:"500"}}>
                                            <label style={{ display: "flex", gap: "0.6rem", cursor: "pointer" }}>
                                                <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                <p>{unique_Channels_in_TV[index]}</p>
                                            </label>
                                        </li>
                                ))
                            }

                            {
                                unique_Channels_in_TV.map((item, index) => (
                                    !Overall_Variables_Picked_for_Aggregation_TV.includes(item)    &&
                                        <li key={index}  style={{display:"flex", gap:"0.6rem", fontSize:"17px", color:"#5C5C5C", fontWeight:"500"}}>
                                            <label style={{ display: "flex", gap: "0.6rem", cursor: "pointer" }}>
                                                <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                <p>{item}</p>
                                            </label>
                                        </li>
                                ))
                            }

                        </ul>

                    </div>
            
                </div>
            )                     
        }

        {
            Aggregate_Variables_Popup    &&    (<div   className={` mmm-overlay-delete-rows `}  style={{ backdropFilter:"blur(10px)", zIndex:"2"}}></div>)  
        }









        {            
            group_name_Popup  &&  ( 
                <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>File Name</p>
                        <button  onClick={()=>{set_group_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selected_Group_name}    </p>    
                
            
                </div>
            )
                                    
        }

        {
            group_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }







        {
            description_Popup  &&  ( 
                <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Description</p>
                        <button  onClick={()=>{setDescription_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedDescription}    </p>    
            
            
                </div>
            )
                                    
        }

        {
            description_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }







               
        </div>
    );
  }
  
  export default TVData_Step3;
