import { useState } from "react";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function PrintDataStep5({  handleActive,    isActiveArr,    after_Missing_Dates_and_Positions_Treatment,    after_flipping_the_Positions_Horizontally,    setAfter_flipping_the_Positions_Horizontally  }) {










    useEffect(()=>{
        if(after_Missing_Dates_and_Positions_Treatment.length    >    0){


            const outputMap = new Map();
            const keys = Object.keys(after_Missing_Dates_and_Positions_Treatment[0]);

            
            after_Missing_Dates_and_Positions_Treatment.forEach(item => {
                const { Date, State, Position, Cost } = item;
                const key = `${Date}|${State}`; // Create a unique key for each Date and State


                // Replace spaces in Position with underscores
                const formattedPosition = Position.replace(/\s+/g, '_');

                
                if (!outputMap.has(key)) {
                    const newEntry = { Date, State };
                    
                    keys.forEach(k => {
                        if (k !== 'Date' && k !== 'State' && k !== 'Position' && k !== 'Cost') {
                            newEntry[k] = 0;
                        }
                    });
                    
                    outputMap.set(key, newEntry);
                }

                outputMap.get(key)[formattedPosition] = Cost; // Use formattedPosition here
            });


            const output = Array.from(outputMap.values());
            console.log("output :- ", output);
            setAfter_flipping_the_Positions_Horizontally(output);


        }
    },[after_Missing_Dates_and_Positions_Treatment])








    const [headers1, setHeaders1] = useState([]);
    useEffect(()=>{
        if(after_flipping_the_Positions_Horizontally.length > 0){
            const formattedData = Object.keys(after_flipping_the_Positions_Horizontally[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            setHeaders1(formattedData);
        }
    },[after_flipping_the_Positions_Horizontally]);








    const [nextBtnClicked, setNextBtnClicked] = useState(false);
    useEffect(()=>{
        if(!isActiveArr.includes(5)    &&    nextBtnClicked){
            if(!isActiveArr.includes(6))  handleActive(6);
            setNextBtnClicked(false);
        }
    }, [isActiveArr, nextBtnClicked]);








    return (
        <div>
                <div>
                    {   
                        after_flipping_the_Positions_Horizontally.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between", gap:"4rem"}}>
                            <CSVLink  data={after_flipping_the_Positions_Horizontally}    headers={headers1}    filename={"P_Step5_after_flipping_the_Positions_Horizontally"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>

                            <button  onClick={()=>{handleActive(5);  setNextBtnClicked(true);}}  style={{backgroundColor:"green", fontSize:"13px", borderRadius:"5px", padding:"0 1rem"}}  >Next</button>
                        </div>
                    }
                </div>
        </div>
    );
}


export default PrintDataStep5;