import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FiArrowRight } from "react-icons/fi";
import MMM_Merged_data from "./MMM_Merged_data";




function TransformDataMMM({     after_Variables_Aggregation,      setActiveTab,    set_selected_Tab,    Sales_states,    Sales_Dates,    data_tv,    data_pr,    data_rd,    data_dg,    data_sl,        final_merged_Dataset,
                                overAll_Variable_Groups,    set_overAll_Variable_Groups,    selected_dummies,    set_selected_dummies,        selected_Transform_Action,    set_selected_Transform_Action,
                                mergedDataset_Card_Selected,    set_mergedDataset_Card_Selected
      }) {






    const navigate = useNavigate(); 















    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';



    const [isHovered_on_Card, setIsHovered_on_Card] = useState('');






    if (data_sl.length===0    ||    (data_tv.length===0  &&  data_pr.length===0  &&  data_rd.length===0  &&  data_dg.length===0)) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>


                <div  style={{width:"100%", height:"100%",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1",  borderBottomLeftRadius:"8px", borderBottomRightRadius:"8px",   display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>
                    <svg    style={{width:screen_width === 'default-1536px' ? "290px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </svg>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "28px" : "24.5px", color:"#111827", fontWeight:"600"}}>Upload Required Data</p>

                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Uploading sales data and at least one channel's data is mandatory</p>
                    </div>
                    
                    <div      onClick={()=>{   set_selected_Tab("upload_data")   }}       style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontSize:screen_width === 'default-1536px' ? "18px" : "15px", display:"flex", alignItems:"center", gap:"0.4rem", cursor:"pointer"}}     className="footer-button" >
                        Go to Upload Data Page
                        <FiArrowRight  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                    </div>

                </div>

            </div>
        )
    }



    if((data_tv.length > 0    &&    after_Variables_Aggregation.length===0)    
    //     ||    
    //    (data_pr.length > 0    &&    processed_print_data.length===0)    ||    
    //    (data_rd.length > 0    &&    processed_radio_data.length===0)    ||    
    //    (data_dg.length > 0    &&    processed_digital_data.length===0)
    ){
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>


                <div  style={{width:"100%", height:"100%",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1",  borderBottomLeftRadius:"8px", borderBottomRightRadius:"8px",   display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>
                    <svg    style={{width:screen_width === 'default-1536px' ? "290px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </svg>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "28px" : "24.5px", color:"#111827", fontWeight:"600"}}>Process the Uploaded Data</p>

                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Processing the uploaded files is mandatory</p>
                    </div>
                    
                    <div      onClick={()=>{   set_selected_Tab("process_data")   }}       style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontSize:screen_width === 'default-1536px' ? "18px" : "15px", display:"flex", alignItems:"center", gap:"0.4rem", cursor:"pointer"}}     className="footer-button" >
                        Go to Process Data Page
                        <FiArrowRight  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                    </div>

                </div>

            </div>
        )
    }



    if( final_merged_Dataset.length === 0 ){
            return (                          
                <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>
    
    
                    <div  style={{width:"100%", height:"100%",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderBottomLeftRadius:"8px", borderBottomRightRadius:"8px",   display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>
                        <svg    style={{width:screen_width === 'default-1536px' ? "290px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                            <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                            <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                            <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                            <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                        </svg>
    
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "28px" : "24.5px", color:"#111827", fontWeight:"600"}}>Merge the Processed Data</p>
    
                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Merging the processed data is mandatory</p>
                        </div>
                        
                        <div      onClick={()=>{   set_selected_Tab("merge_data")   }}       style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontSize:screen_width === 'default-1536px' ? "18px" : "15px", display:"flex", alignItems:"center", gap:"0.4rem", cursor:"pointer"}}     className="footer-button" >
                            Go to Merge Data Page
                            <FiArrowRight  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                        </div>
    
                    </div>
    
                </div>
            )
    }











    return (
        <div  style={{width:"100%", height:"100%",    padding:mergedDataset_Card_Selected === ''  ?  screen_width === 'default-1536px' ? "2rem" : "1.6rem"
                                                                                                  : '1.8rem 0 0 0',    borderBottomLeftRadius:"8px",  borderBottomRightRadius:"8px",   boxShadow:mergedDataset_Card_Selected === ''  ?  "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1"  :  "", display:"flex", flexDirection:"column", justifyContent:"flex-start",  gap: screen_width === 'default-1536px' ? "2rem" : "1.6rem", backgroundColor:""}}>
            {/* <p  className="fontF"  style={{fontWeight:"600",      fontSize: screen_width === 'default-1536px' ? "18px" : "16px",      color:"#171717"}}>Select any one to continue the process (Sales Mandatory)</p> */}
            
            <div  style={{width:"100%", height:"100%", backgroundColor:"", display:"flex", justifyContent:"flex-start", gap: screen_width === 'default-1536px' ? "2rem" : "1.6rem"}}>

                
            {


                mergedDataset_Card_Selected === '' 
                ?
                    <>
                    {
                        final_merged_Dataset.length>0  &&
                        <div    onClick={()=>{  setActiveTab('/mmm-data');    set_mergedDataset_Card_Selected('Transform')    }}    style={{width:"19%",      height:screen_width === 'default-1536px' ? "140px" : "120px",     backgroundColor:"#FFFFFF",  borderRadius:"8px",        transform: isHovered_on_Card === 'merged' ? "scale(0.97)" : "scale(1)",            boxShadow: isHovered_on_Card === 'merged' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",         transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',      padding:screen_width === 'default-1536px' ? "12px" : "20px",      display:"flex", alignItems:"center", justifyContent:"center", position:"relative", cursor:"pointer"}}    onMouseEnter={() => setIsHovered_on_Card('merged')}     onMouseLeave={() => setIsHovered_on_Card('')}>
                            <div  style={{display:"flex", flexDirection:"column",    gap:screen_width === 'default-1536px' ? "5px" : "8.33px",    alignItems:"center", justifyContent:"center"}}>
                                <svg      style={{width:screen_width === 'default-1536px' ? "68px" : "60px",    height:screen_width === 'default-1536px' ? "68px" : "60px"}}   viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M64.5 72.1667H21.5C18.6498 72.1638 15.9171 71.0303 13.9017 69.0149C11.8863 66.9995 10.7528 64.2669 10.75 61.4167V32.75C10.7528 29.8998 11.8863 27.1671 13.9017 25.1517C15.9171 23.1363 18.6498 22.0028 21.5 22H64.5C67.3502 22.0028 70.0829 23.1363 72.0983 25.1517C74.1137 27.1671 75.2472 29.8998 75.25 32.75V61.4167C75.2472 64.2669 74.1137 66.9995 72.0983 69.0149C70.0829 71.0303 67.3502 72.1638 64.5 72.1667Z" fill="#C2E2F5"/>
                                    <path d="M35.8297 21.9936H44.1037L38.9365 13.0353C38.4579 12.2189 37.6759 11.6251 36.7611 11.3832C35.8463 11.1413 34.873 11.2711 34.0535 11.7443C33.234 12.2174 32.635 12.9955 32.3871 13.9086C32.1391 14.8218 32.2625 15.796 32.7302 16.6186L35.8297 21.9936ZM21.5 72.1603V75.7436C21.5 76.6939 21.8775 77.6054 22.5495 78.2774C23.2215 78.9494 24.133 79.3269 25.0833 79.3269C26.0337 79.3269 26.9451 78.9494 27.6171 78.2774C28.2891 77.6054 28.6667 76.6939 28.6667 75.7436V72.1603H21.5Z" fill="#1A7AAF"/>
                                    <path d="M51.6493 22.0019L59.8659 13.7853C60.2081 13.4547 60.4811 13.0593 60.6689 12.6222C60.8567 12.185 60.9556 11.7148 60.9597 11.239C60.9638 10.7632 60.8732 10.2913 60.693 9.85096C60.5128 9.41058 60.2467 9.01049 59.9103 8.67405C59.5738 8.3376 59.1737 8.07152 58.7334 7.89135C58.293 7.71118 57.8211 7.62051 57.3454 7.62465C56.8696 7.62878 56.3994 7.72763 55.9622 7.91543C55.525 8.10323 55.1296 8.37622 54.799 8.71846L41.5156 22.0019H51.6493ZM57.3325 75.7519C57.3325 76.7022 57.71 77.6137 58.382 78.2857C59.054 78.9577 59.9654 79.3352 60.9158 79.3352C61.8661 79.3352 62.7776 78.9577 63.4496 78.2857C64.1216 77.6137 64.4991 76.7022 64.4991 75.7519V72.1685H57.3325V75.7519Z" fill="#1A7AAF"/>
                                </svg>

                                <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Merged Data</p>
                            </div>
                        </div>       
                    }  
                    </>   
                :
                    <>
                    {
                        mergedDataset_Card_Selected === 'Transform'    &&    <MMM_Merged_data      final_merged_Dataset={final_merged_Dataset}    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}    selected_dummies={selected_dummies}    set_selected_dummies={set_selected_dummies}        selected_Transform_Action={selected_Transform_Action}    set_selected_Transform_Action={set_selected_Transform_Action}    set_mergedDataset_Card_Selected={set_mergedDataset_Card_Selected}/>
                    }
                    </>
            }
    
            </div>
        </div>
    );
  }
  
  export default TransformDataMMM;