import { useEffect, useState } from "react";
import Loader from "../../../assets/Images/loading.gif";
// import { getShortFormContent} from "../../../Utils";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';   // Because I imported and registered this here, It will be applied to entire Project folder, SO for others I have to set  datalabels: {   display: false    },
ChartJS.register(ChartDataLabels);









const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
  
    return (
        <>
            <div    ref={innerRef}    {...innerProps}
                        data-tooltip-content={data.value} // Tooltip content
                        data-tooltip-id="select-tooltip"
                        style={{ display: "flex",   alignItems: "center",    padding: "10px",    cursor: "pointer",    textWrap:"nowrap",    backgroundColor: props.isSelected ? "#4FB2E1": props.isFocused ? "#E4F1FA" : "#fff"  }}
            >
                {data.label}
            </div>

            <Tooltip    id="select-tooltip"    place="left"    positionStrategy="fixed"    style={{background:"#fff", color:"black", boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05)"}}    border="1px solid #ddd"/>
        </>
    );
};



          






function CompareResults({exp_Name,    fileObjs}) {




    const navigate = useNavigate();

    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400){
        screen_width = '1200_1400';
    }



    
    const [chart2, setChart2] = useState([]);
    const [chart3, setChart3] = useState([]);
    const [lift2, setLift2] = useState([]);
    const [lift3, setLift3] = useState([]);
    const [stddev2, setStddev2] = useState([]);
    const [stddev3, setStddev3] = useState([]);

    const [bottomSectionFile_for_Comparisons, setBottomSectionFile_for_Comparisons] = useState(null);
    const [filenameOptions2, setFilenameOptions2] = useState([]);
    const [topSectionFile_for_Comparisons, setTopSectionFile_for_Comparisons] = useState(null);
    const [filenameOptions, setFilenameOptions] = useState([]);




    

    useEffect(() => {
      if(topSectionFile_for_Comparisons){

        const fileNAme = topSectionFile_for_Comparisons.value;
        const matchingFileObj = fileObjs.find(obj => obj.file_name === fileNAme);

        setChart2(matchingFileObj.result.graph_data);



        if(matchingFileObj.result.lift){                // If experiment is freshly created
            setLift2(matchingFileObj.result.lift);
        }
        else{                                         // If experiment is loaded from DB
            setLift2([matchingFileObj.result.lift_value, matchingFileObj.result.lift_percentage]);
        }



        setStddev2(matchingFileObj.result.stddev);

        const sessionFileNames = fileObjs.filter(obj => obj.file_name !== fileNAme).map(obj => obj.file_name);    //  Purpose of these 3 lines - The Subset which is already selected from the dropdown of top section should not appear in bottom section's select options
        const formattedData = sessionFileNames.map(file_name => ({value:file_name,  label:file_name}));
        setFilenameOptions2(formattedData);

      }
    }, [topSectionFile_for_Comparisons]);

    function setTopSectionFile_for_Comparisons_Func(X){    
      setTopSectionFile_for_Comparisons(X);   
      const selectedObj = fileObjs.find(file => file.file_name === X.value);
      setSelectedObjID2(selectedObj.objId);
    }



    useEffect(() => {
      if(bottomSectionFile_for_Comparisons){

        const fileNAme = bottomSectionFile_for_Comparisons.value;
        const matchingFileObj = fileObjs.find(obj => obj.file_name === fileNAme);
        
        setChart3(matchingFileObj.result.graph_data);

        
        if(matchingFileObj.result.lift){                // If experiment is freshly created
            setLift3(matchingFileObj.result.lift);
        }
        else{                                         // If experiment is loaded from DB
            setLift3([matchingFileObj.result.lift_value, matchingFileObj.result.lift_percentage]);
        }


        setStddev3(matchingFileObj.result.stddev);

        const sessionFileNames = fileObjs.filter(obj => obj.file_name !== fileNAme).map(obj => obj.file_name);    //  Purpose of these 3 lines - The Subset which is already selected from the dropdown of bottom section should not appear in top section's select options
        const formattedData = sessionFileNames.map(file_name => ({value:file_name,  label:file_name}));
        setFilenameOptions(formattedData);
      }
    }, [bottomSectionFile_for_Comparisons]);

    function setBottomSectionFile_for_Comparisons_Func(X){    
      setBottomSectionFile_for_Comparisons(X);  
      const selectedObj = fileObjs.find(file => file.file_name === X.value);
      setSelectedObjID3(selectedObj.objId);
    }











    const [selectedObjID2, setSelectedObjID2] = useState(null);
    const [selectedObjID3, setSelectedObjID3] = useState(null);


    useEffect(() => {
        const t_file_name = fileObjs[fileObjs.length-2].file_name;
        const b_file_name = fileObjs[fileObjs.length-1].file_name;

        setSelectedObjID2(fileObjs[fileObjs.length-2].objId);
        setSelectedObjID3(fileObjs[fileObjs.length-1].objId);

        setTopSectionFile_for_Comparisons({value:t_file_name,  label:t_file_name});
        setBottomSectionFile_for_Comparisons({value:b_file_name,  label:b_file_name});

    }, []);  




    let dataChart2 = {};
    let optionChart2 = {};
    if(chart2.length > 0    &&    selectedObjID2!==null){

        const maxActual = Math.max(...chart2.map(data => data.actual_metric));
        const maxSynthetic = Math.max(...chart2.map(data => data.synthetic_metric));
        const maxYValue = Math.max(maxActual, maxSynthetic);
        const datesObj_ = fileObjs.find(obj => obj.objId === selectedObjID2).datesObj;



        let lastDatebeforeGap = null;                          // this code snippet will act when pre period and campaign period are not connected
        let lastBiggestMetricBeforeGap = null;
        let firstDateAfterGap = null;                          
        let FirstBiggestMetricAfterGap = null;

        for(let i=0; i<chart2.length; i++){
            let flag = false;

            if(chart2[i].actual_metric === null    &&    chart2[i].synthetic_metric === null){
                flag = true;

                lastDatebeforeGap = chart2[i-1].date.slice(-5);
                lastBiggestMetricBeforeGap = Math.max(chart2[i-1].actual_metric, chart2[i-1].synthetic_metric);

                for(let j=i+1; j<chart2.length; j++){
                    if(chart2[j].actual_metric !== null    &&    chart2[j].synthetic_metric !== null){
                        firstDateAfterGap = chart2[j].date.slice(-5);
                        FirstBiggestMetricAfterGap = Math.max(chart2[j].actual_metric, chart2[j].synthetic_metric);
                        break;
                    }
                }
            }

            if(flag) break;
        }



        dataChart2 = {
            labels: chart2.map((data) => data.date.slice(-5)),
            datasets: [
                {
                    label: "Actual",
                    data: chart2.map((data) => data.actual_metric),
                    pointBackgroundColor: "#6e6e6e",
                    backgroundColor:"#6e6e6e",
                    borderColor: "#6e6e6e",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },

                    xAxisId: 'x-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                    yAxisId: 'y-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis

                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph

                },
                {
                    label: "Synthetic",
                    data: chart2.map((data) => data.synthetic_metric),
                    pointBackgroundColor: "#2899cf",
                    borderColor: "#2899cf",
                    backgroundColor:"#2899cf",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },
                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph
                },
                {
                    type: 'line',
                    label: '',
                    data: [
                        // { x: date_Starting_Campaign, y: 0 },
                        { x: datesObj_.Date_Starting_Campaign, y: maxYValue },
                        { x: datesObj_.Date_middle_Campaign, y: maxYValue },
                        { x: datesObj_.Date_Ending_Campaign, y: maxYValue },
                        
                        // { x: date_Ending_Campaign, y: 0 }
                    ],
                    fill: true,
                    backgroundColor: '#EEEEEE',
                    borderColor: 'white',
                    tension: 0,
                    borderWidth: 0.3,
                    pointRadius: 0,
                    
                    datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 0,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Campaign Period";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                        else {                             return '';                    }
                    }
                    }
                },

                {
                    type: 'line',
                    label: '',
                    data: [
                        { x: lastDatebeforeGap, y: 0 },
                        { x: lastDatebeforeGap, y: lastBiggestMetricBeforeGap },
                    ],
                    fill: true,
                    borderColor: 'gray', 
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            
                {
                    type: 'line',
                    label: '',
                    data: [
                    { x: firstDateAfterGap, y: 0 },
                    { x: firstDateAfterGap, y: FirstBiggestMetricAfterGap },
                    ],
                    fill: true,
                    borderColor: 'gray',
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            ]
        }
        optionChart2 = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            // animation: {
            //   duration: 2000,
            //   easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
            //   animateScales: true,

                // onStart: function(animation) {
                //   console.log('Animation started');
                // },
                // onComplete: function(animation) {
                //   console.log('Animation completed');
                // },
            // },


            plugins: {
                datalabels: {
                    font: {
                        family: "Toboggan",        // To change font family of data labels on charts
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "11.8px" : "10px"
                    }
                },
                legend: {
                labels: {
                    boxWidth: screen_width === 'default-1536px' ? 12 : 10,       // For making legend square
                    font: {
                        family: "Toboggan",         // To change font family of label(label is present on top of chart)
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "12px" : "10px"
                    }
                }
                }
            
            },


            scales: {
                x: {
                    id: 'x-axis1',
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of Y-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"  
                        }
                    }
                },
                y: {
                    beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

                    ticks:{
                        callback: function(value){
                            return "₹" + value
                        },
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"
                        }
                    },

                    id: 'y-axis1',
                    grid: {
                        display: false
                    }
                }
            }
        }
    }

 
    let dataChart3 = {};
    let optionChart3 = {};
    if(chart3.length > 0    &&    selectedObjID3!==null){

        const maxActual = Math.max(...chart3.map(data => data.actual_metric));
        const maxSynthetic = Math.max(...chart3.map(data => data.synthetic_metric));
        const maxYValue = Math.max(maxActual, maxSynthetic);
        const datesObj_ = fileObjs.find(obj => obj.objId === selectedObjID3).datesObj;



        let lastDatebeforeGap = null;                          // this code snippet will act when pre period and campaign period are not connected
        let lastBiggestMetricBeforeGap = null;
        let firstDateAfterGap = null;                          
        let FirstBiggestMetricAfterGap = null;

        for(let i=0; i<chart3.length; i++){
            let flag = false;

            if(chart3[i].actual_metric === null    &&    chart3[i].synthetic_metric === null){
                flag = true;

                lastDatebeforeGap = chart3[i-1].date.slice(-5);
                lastBiggestMetricBeforeGap = Math.max(chart3[i-1].actual_metric, chart3[i-1].synthetic_metric);

                for(let j=i+1; j<chart3.length; j++){
                    if(chart3[j].actual_metric !== null    &&    chart3[j].synthetic_metric !== null){
                        firstDateAfterGap = chart3[j].date.slice(-5);
                        FirstBiggestMetricAfterGap = Math.max(chart3[j].actual_metric, chart3[j].synthetic_metric);
                        break;
                    }
                }
            }

            if(flag) break;
        }



        dataChart3 = {
            labels: chart3.map((data) => data.date.slice(-5)),
            datasets: [
                {
                    label: "Actual",
                    data: chart3.map((data) => data.actual_metric),
                    pointBackgroundColor: "#6e6e6e",
                    backgroundColor:"#6e6e6e",
                    borderColor: "#6e6e6e",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },

                    xAxisId: 'x-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                    yAxisId: 'y-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis

                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph

                },
                {
                    label: "Synthetic",
                    data: chart3.map((data) => data.synthetic_metric),
                    pointBackgroundColor: "#2899cf",
                    borderColor: "#2899cf",
                    backgroundColor:"#2899cf",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },
                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph
                },
                {
                    type: 'line',
                    label: '',
                    data: [
                        // { x: date_Starting_Campaign, y: 0 },
                        { x: datesObj_.Date_Starting_Campaign, y: maxYValue },
                        { x: datesObj_.Date_middle_Campaign, y: maxYValue },
                        { x: datesObj_.Date_Ending_Campaign, y: maxYValue },
                        
                        // { x: date_Ending_Campaign, y: 0 }
                    ],
                    fill: true,
                    backgroundColor: '#EEEEEE',
                    borderColor: 'white',
                    tension: 0,
                    borderWidth: 0.3,
                    pointRadius: 0,
                    
                    datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 0,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Campaign Period";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                        else {                             return '';                    }
                    }
                    }
                },

                {
                    type: 'line',
                    label: '',
                    data: [
                        { x: lastDatebeforeGap, y: 0 },
                        { x: lastDatebeforeGap, y: lastBiggestMetricBeforeGap },
                    ],
                    fill: true,
                    borderColor: 'gray', 
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            
                {
                    type: 'line',
                    label: '',
                    data: [
                    { x: firstDateAfterGap, y: 0 },
                    { x: firstDateAfterGap, y: FirstBiggestMetricAfterGap },
                    ],
                    fill: true,
                    borderColor: 'gray',
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            ]
        }
        optionChart3 = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            // animation: {
            //   duration: 2000,
            //   easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
            //   animateScales: true,

                // onStart: function(animation) {
                //   console.log('Animation started');
                // },
                // onComplete: function(animation) {
                //   console.log('Animation completed');
                // },
            // },


            plugins: {
                datalabels: {
                    font: {
                        family: "Toboggan",        // To change font family of data labels on charts
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "11.8px" : "10px"
                    }
                },
                legend: {
                labels: {
                    boxWidth: screen_width === 'default-1536px' ? 12 : 10,       // For making legend square
                    font: {
                        family: "Toboggan",         // To change font family of label(label is present on top of chart)
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "12px" : "10px"
                    }
                }
                }
            
            },


            scales: {
                x: {
                    id: 'x-axis1',
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of Y-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"  
                        }
                    }
                },
                y: {
                    beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

                    ticks:{
                        callback: function(value){
                            return "₹" + value
                        },
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"
                        }
                    },

                    id: 'y-axis1',
                    grid: {
                        display: false
                    }
                }
            }
        }
    }
  










    const [showGraph, setShowGraph] = useState(false);
    const [showData, setShowData] = useState(false);


    const handleToggle = () => {
        if(showData){
            setShowData(prevState => !prevState);
        }
        setShowGraph(prevState => !prevState);
    };


    const handleToggle2 = () => {
        if(showGraph){
            setShowGraph(prevState => !prevState);
        }
        setShowData(prevState => !prevState);
    };







    function getShortFormContent(content,maxLen=15){
        return content.length > maxLen ? `${content.slice(0,maxLen+1)}...` : content
    }










    return (
        <div  style={{width:"100%", height:"100%",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1",  borderRadius:"8px"}}>


            {
              (chart2.length>0  &&  chart3.length>0)  ?


                    <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>

                        <div  style={{width:"calc(100% - 3rem)", height:"8%", display:"flex", justifyContent:"space-between", alignItems:"center", borderBottom:"1px solid #E5E5E5"}}>
                            
                            <div  style={{display:"flex", alignItems:"center"}}>
                                <button  onClick={()=>{    navigate('/experiment-and-data')    }}    style={{display:"flex",      gap:screen_width === 'default-1536px' ? "6px" : "5px",      alignItems:"center"}}>
                                    <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 26 : 22.5}  color="#0000EE"/>
                                    <p  style={{color:"#0000EE", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "22px" : "20px", paddingBottom:screen_width === 'default-1536px' ? "3px" : "2px"}}>{exp_Name} </p>
                                </button>                        
                            </div>


                            <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "24px" : "20px"}}>

                                <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                    <button className={`toggle-container ${showGraph ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "41.8px" : "34.8px",       height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle}>
                                        <div className="toggle-button"                                        style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                    </button>
                                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Graph</p>
                                </div>

                                <div style={{ display: "flex", gap: "1px" }}>
                                    <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                    <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                </div>

                                <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                    <button className={`toggle-container ${showData ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "42.3px" : "35px",         height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle2}>
                                        <div className="toggle-button"                                       style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                    </button>
                                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Data</p>
                                </div>

                            </div>
                        </div>


                        <div  style={{height:"46%", width:"100%", display:"flex", borderBottom:"3px solid #f1f1f1", position:"relative"}}>

                            <div  style={{  width: (!showData && showGraph)    ?    "100%" 
                                                                                :    (showData && !showGraph)    ?    "0%"
                                                                                                                :    (!showData && !showGraph) && "77.5%", 
                                            display: (showData && !showGraph)  ?    "none"
                                                                                :    (!showData && !showGraph) && "flex", 

                                            height:"100%", display:"flex", justifyContent:"center", alignItems:"center", transition: "width 0.2s", position:"absolute", top:"0", left:"0"}}>
                                <div  className="line-chart-responsiveness">
                                    <div style={{width: '96%', height:"96%"}}>
                                        <Line data={dataChart2} options={optionChart2}/>
                                    </div>
                                </div>
                            </div>  

                            
                            {
                                (lift2  &&  stddev2)  &&
                                    <div  style={{  width: (!showData && showGraph)    ?    "0%" 
                                                                                        :    (showData && !showGraph)    ?    "100%" 
                                                                                                                        :    (!showData && !showGraph) && "22.5%", 
                                                    display: (!showData && showGraph)  ?    "none"
                                                                                        :    (!showData && !showGraph) && "flex", 
                                                    height:"100%", flexDirection:"column",  alignItems:"center",  justifyContent:"start", transition: "width 0.2s",  gap:"1.7rem", position:"absolute", top:"0", right:"0", paddingTop:"1.6rem"}}>
        
                                        <div  style={{width:"90%"}}>

                                            <Select
                                                // classNamePrefix="react-select"
                                                options={filenameOptions.map((element) => ({...element, label:getShortFormContent(element.label, screen_width >= "default-1536px"  ?  22  :  18),    }))}    
                                                value={topSectionFile_for_Comparisons}
                                                onChange={setTopSectionFile_for_Comparisons_Func}
                                                components={{Option: CustomOption}}
                                            />
                                        </div>
        
                                        <table style={{ width:"90%",      fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>
                                            <thead>
                                                <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                    <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Metric</th>
                                                    <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Value</th>
                                                </tr>
                                            </thead>
            
                                            <tbody>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift Value</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{lift2[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift Percentage</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{lift2[1]}</td>
                                                </tr>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Std Deviation</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{stddev2[0]}</td>
                                                </tr>
                                            </tbody>
                                        </table>
        
                                    
                                    </div>  
                            }

                        </div>


                        <div  style={{height:"46%", width:"100%", display:"flex", position:"relative"}}>

                            <div  style={{  width: (!showData && showGraph)    ?    "100%" 
                                                                                :    (showData && !showGraph)    ?    "0%"
                                                                                                                :    (!showData && !showGraph) && "77.5%", 
                                            display: (showData && !showGraph)  ?    "none"
                                                                                :    (!showData && !showGraph) && "flex", 

                                            height:"100%", display:"flex", justifyContent:"center", alignItems:"center", transition: "width 0.2s", position:"absolute", top:"0", left:"0"}}>
                                <div  className="line-chart-responsiveness"  style={{ borderRadius:"8px"}}>
                                    <div style={{width: '96%', height:"96%"}}>
                                        <Line data={dataChart3} options={optionChart3}/>
                                    </div>
                                </div>
                            </div>    

                            
                            {
                                (lift3  &&  stddev3)  &&
                                <div  style={{  width: (!showData && showGraph)    ?    "0%" 
                                                                            :    (showData && !showGraph)    ?    "100%" 
                                                                                                            :    (!showData && !showGraph) && "22.5%", 
                                                display: (!showData && showGraph)  ?    "none"
                                                                                    :    (!showData && !showGraph) && "flex", 
                                                height:"100%", flexDirection:"column",  alignItems:"center",  justifyContent:"start", transition: "width 0.2s", gap:"1.7rem", position:"absolute", top:"0", right:"0", paddingTop:"1.6rem", backgroundColor:""}}>

                                        <div  style={{width:"90%"}}>
                                            
                                            <Select
                                                // classNamePrefix="react-select"
                                                options={filenameOptions2.map((element) => ({...element, label:getShortFormContent(element.label, screen_width >= "default-1536px"  ?  22  :  18),    }))}    
                                                value={bottomSectionFile_for_Comparisons}
                                                onChange={setBottomSectionFile_for_Comparisons_Func}
                                                components={{Option: CustomOption}}
                                            />
                                        </div>

                                        <table style={{ width:"90%",      fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>
                                            <thead>
                                                <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                    <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Metric</th>
                                                    <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Value</th>
                                                </tr>
                                            </thead>
            
                                            <tbody>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift Value</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{lift3[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift Percentage</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{lift3[1]}</td>
                                                </tr>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Std Deviation</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{stddev3[0]}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    
                                    </div>  
                            }

                        </div>
                  </div>

              :

                  <div   style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img src={Loader} width="180" height="180"/>
                  </div>

            }



            
             
            


        </div>
    );
  }
  
  export default CompareResults;
