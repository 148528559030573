import { useState, useEffect } from "react";



function TD_Step_2({  final_merged_Dataset,     overAll_Variable_Groups,    set_overAll_Variable_Groups,    selected_dummies,    selected_Transform_Action,    set_selected_Transform_Action    }) {


    

    // const [keys_, setKeys_] = useState([]);

    // if (final_merged_Dataset.length > 0    &&    keys_.length === 0) {
    //     const firstElementKeys = Object.keys(final_merged_Dataset[0]);
    //     const filteredKeys = firstElementKeys.filter(key => key !== 'Date' && key !== 'State');

    //     console.log("All Variable Options :- ", filteredKeys);
    //     setKeys_(filteredKeys);
    // }
    



    const [selected_Variable_Groups, set_selected_Variable_Groups] = useState([]);
    const [selected_Variable_Groups_Updated, set_selected_Variable_Groups_Updated] = useState([]);
    const [isHovered_on_button, set_isHovered_on_button] = useState('');


    
    

    const handleChange_Group = (X) => {

        set_selected_Variable_Groups((prevSelected) => {
            if (prevSelected.includes(X)) {
                return prevSelected.filter((item) => item !== X);    // Remove from array if already selected
            } 
            else { 
                return [...prevSelected, X];                         // Add to array if not selected
            }
        });

        set_selected_Variable_Groups_Updated(true);
    };

    
    useEffect(() => {
        if(selected_Variable_Groups.length>0    &&    selected_Variable_Groups_Updated){
            console.log("selected Variable Groups :- ", selected_Variable_Groups);
        }
    }, [selected_Variable_Groups]);








    
    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"", gap:"1.6rem"}}>


            <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>Action</div>

                <div style={{width:"100%", height:"87%", backgroundColor:"", padding:"1.6rem 2rem 2.2rem 2rem", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>

                    <button        onClick={()=>{  set_selected_Transform_Action("log");     set_selected_Variable_Groups([])  }}       className="create-exp-button"   style={{ display: "flex", justifyContent: "center", alignItems: "center",     backgroundColor: selected_Transform_Action === "log"  ? "#97DDFF"  :  "#FFFFFF" ,     color: "#171717", borderRadius: "37px", fontWeight: "500",     transform: isHovered_on_button === 'log' ? "scale(0.97)" : "scale(1)",             boxShadow: isHovered_on_button === 'log' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",     transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out'}}    onMouseEnter={() => set_isHovered_on_button("log")}      onMouseLeave={() => set_isHovered_on_button('')}>Log</button>
                    <button        onClick={()=>{  set_selected_Transform_Action("lag1");    set_selected_Variable_Groups([])  }}       className="create-exp-button"   style={{ display: "flex", justifyContent: "center", alignItems: "center",     backgroundColor: selected_Transform_Action === "lag1" ? "#97DDFF"  :  "#FFFFFF" ,     color: "#171717", borderRadius: "37px", fontWeight: "500",     transform: isHovered_on_button === 'lag1' ? "scale(0.97)" : "scale(1)",            boxShadow: isHovered_on_button === 'lag1' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",    transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out'}}    onMouseEnter={() => set_isHovered_on_button("lag1")}     onMouseLeave={() => set_isHovered_on_button('')}>1 day Lag</button>
                    <button        onClick={()=>{  set_selected_Transform_Action("lag2");    set_selected_Variable_Groups([])  }}       className="create-exp-button"   style={{ display: "flex", justifyContent: "center", alignItems: "center",     backgroundColor: selected_Transform_Action === "lag2" ? "#97DDFF"  :  "#FFFFFF" ,     color: "#171717", borderRadius: "37px", fontWeight: "500",     transform: isHovered_on_button === 'lag2' ? "scale(0.97)" : "scale(1)",            boxShadow: isHovered_on_button === 'lag2' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",    transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out'}}    onMouseEnter={() => set_isHovered_on_button("lag2")}     onMouseLeave={() => set_isHovered_on_button('')}>2 day Lag</button>
                    
                </div>
            </div>


            <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>Select Variables</div>
                
                <div    style={{width:"100%", height:"87%", backgroundColor:"", padding:"1.6rem 2rem 2.2rem 2rem"}}>

                
                    <ul  style={{width:"100%", height:"100%", overflowY:"scroll",  display:"flex", flexDirection:"column", gap:"0.4rem", fontSize:"16.2px", color:"#474747", fontWeight:"500"}}>
                        {
                            selected_Transform_Action !== "log"
                            ?
                                overAll_Variable_Groups.map((item, index) => (
                                    <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                        <label style={{ display: "flex", gap: "0.6rem", cursor: "pointer" }}>
                                            <input   type="checkbox"   checked={selected_Variable_Groups.includes(item)}     onChange={()=>{handleChange_Group(item)}}/>
                                            <p>{item}</p>
                                        </label>
                                    </li>
                                ))
                            :
                                overAll_Variable_Groups.filter(item => !selected_dummies.includes(item))
                                    .map((item, index) => (
                                    <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                        <label style={{ display: "flex", gap: "0.6rem", cursor: "pointer" }}>
                                            <input   type="checkbox"   checked={selected_Variable_Groups.includes(item)}     onChange={()=>{handleChange_Group(item)}}/>
                                            <p>{item}</p>
                                        </label>
                                    </li>
                                ))
                        }
                    </ul>


                </div>

            </div>

    
        </div>
    );
  }
  
  export default TD_Step_2;