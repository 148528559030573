import React, { useState } from "react";
import AccordionTabs from "./AccordionTabs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';




function DataConversion({dimensionsOptions,    data2,    aggregatedData,    setAggregatedData,    states,    checkedItems_TV,    setCheckedItems_TV,    selected_TV,    setSelected_TV,        afterRegionalMapping_arr,    setAfterRegionalMapping_arr,     wideFormatData,    setWideFormatData,    data,    data3,    data4}) {



    const navigate = useNavigate();  
    

    const data_ = [{    id:1,  TabTitle:"Step 1 - Selection of  Dimensions to group and metrics to be aggregated"},
                  {    id:2,  TabTitle:"Step 2 - Regional Mapping"           },
                  {    id:3,  TabTitle:"Step 3 - Geo Stacking" }];

    const [isActiveArr, setIsActiveArr] = useState(Array(0));              
    const handleActive = (id) => {                                         
    setIsActiveArr(    !isActiveArr.includes(id)                         
                        ?    isActiveArr.concat([id])                      
                        :    isActiveArr.filter((e) => e !== id)    );
    }



    return (
        <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:"2rem", paddingTop:"5rem", position:"relative"}}>

            <div  style={{width:"65%", fontSize:"24px", fontWeight:"bold", display:"flex", justifyContent:"space-between"}}>
                <button        onClick={()=>{  navigate("/data-upload")  }}        ><IoMdArrowRoundBack /></button>
                <p>TV Data</p>
                <p></p>
            </div>

            <div  style={{width:"65%", backgroundColor:"#f1f1f1"}}>
                {
                  data_.map((section, index) => (
                    <AccordionTabs    key={index}    section={section}    isActiveArr={isActiveArr}    handleActive={handleActive}
                                      dimensionsOptions={dimensionsOptions}    data2={data2}    checkedItems_TV={checkedItems_TV}    setCheckedItems_TV={setCheckedItems_TV}    selected_TV={selected_TV}    setSelected_TV={setSelected_TV}    aggregatedData={aggregatedData}    setAggregatedData={setAggregatedData}    states={states}    afterRegionalMapping_arr={afterRegionalMapping_arr}    setAfterRegionalMapping_arr={setAfterRegionalMapping_arr}    wideFormatData={wideFormatData}    setWideFormatData={setWideFormatData}/>
                  ))
                }
            </div>  

            {            
                wideFormatData.length>0    &&
                (
                    data4.length>0     ?    <button  onClick={()=>{navigate("/data-conversion-steps-print");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>    //Take to Print data Transformation
                                       :    data3.length>0    ?    <button  onClick={()=>{navigate("/data-conversion-steps-radio");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>//Take to Radio data Transformation
                                                              :    data.length>0    &&    <button  onClick={()=>{navigate("/data-conversion-steps-merging");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>
                )                                                                            
            }

        </div>
    );
}


export default DataConversion;