import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Loader from "../../../assets/Images/loading.gif";




const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SelectionStatus = () => {
  const query = useQuery();
  const [GADs_accountIds, setGADsAccountIds] = useState([]);
  const [GAnalytics_accountIds, setAnalyticsAccountIds] = useState([]);
  const [FBADs_accountIds, setFBADsAccountIds] = useState([]);
  
  useEffect(() => {
    const id = 'data-id'; 
    const GADs_ids = query.getAll('GADsclient_id');
    setGADsAccountIds(GADs_ids);
    const GAnalytics_ids = query.getAll('GAnalyticsclient_id');
    setAnalyticsAccountIds(GAnalytics_ids);
    const FBADs_ids = query.getAll('FBAdsclient_id');
    setFBADsAccountIds(FBADs_ids);

    console.log("GAnalytics/GADs accountIds/FB Ad Ids", GAnalytics_accountIds, GADs_accountIds,FBADs_accountIds);

    if(FBADs_ids.length > 0) {
      console.log("FB Account found", FBADs_ids);
      window.location.href = `https://integration.truelift.ai/receive-data/${id}?FBADsclient_id=${FBADs_accountIds}&processed=true`;
    } 
    else if (GADs_ids.length > 0 || GAnalytics_ids.length > 0){
      console.log("Google Account found");
      window.location.href = `https://integration.truelift.ai/receive-data/${id}?GADsclient_id=${GADs_accountIds}&GAnalytics_accountIds=${GAnalytics_accountIds}&processed=true`;
    }}, [query]);



//   const renderMessage = () => {
//     if (GADs_accountIds.length > 0) {
//       return (<h1>Client Ads Account IDs</h1>)
//     } 
//     else if (GAnalytics_accountIds.length > 0) {
//       return (<h1>Client Analytics Account IDs</h1>);
//     } 
//     else if (FBADs_accountIds.length > 0) {
//       return (<h1>FB Client Ads Account IDs</h1>)
//     }

//     return <p>No Client Accounts to save</p>;
//   };



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';




  return (

    <div  style={{width:"100%",  height:"100%", display: "flex", flexDirection: "column", justifyContent:"space-between", gap:"22.4px"}}>

        <div  style={{ width: "100%", height: "9%", background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px",      padding:screen_width === 'default-1536px' ? "16px 24px" : "13.3px 20px",      boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <p className="fontF" style={{ fontSize:screen_width === 'default-1536px' ? "24px" : "20px",      fontWeight: "600", lineHeight: "36px" }}>Data Integrations</p>
            <div className="fontF">DP(Name and Role)</div>
        </div>



        <div  style={{width:"100%", flex:"1", padding:"1.6rem", borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:"1.6rem"}}>
            
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={Loader} width="180" height="180"/>
            </div>
          
        </div>


    </div>
  );
};

export default SelectionStatus;