

import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function TVData_Step2({    selected_metrics_TV,    statesPicked_Overall_TV,    set_statesPicked_Overall_TV,    All_metrics_Headers_TV,    mappingArr,    setMappingArr,    headers_TV_Step2,    set_headers_TV_Step2,    unMappedRegions_TV,    set_unMappedRegions_TV,    set_unique_Channels_in_TV,    selected_metrics_TV_raw,    States_Names_Used,    set_States_Names_Used,    aggregated_Data_TV,    Sales_states,       after_Regional_Mapping_arr_TV,    set_After_Regional_Mapping_arr_TV,        headers_TV_Step3,    set_headers_TV_Step3,    wide_Format_Data_TV,    set_Wide_Format_Data_TV    }) {


    // console.log("aggregated_Data_TV :- ", aggregated_Data_TV);




    const [mapRegionsPopup, setMapRegionsPopup] = useState(false);
    const [selectedState, setSelectedState] = useState('');








    const [checkedItems2, setCheckedItems2] = useState([]);
    if(All_metrics_Headers_TV.length > 0    &&    checkedItems2.length===0){
        setCheckedItems2(new Array(All_metrics_Headers_TV.length).fill(false));
    }
    

    const handleCheckboxChange2 = (index) => {

        const selectedRegion = All_metrics_Headers_TV[index];
        set_statesPicked_Overall_TV((prevStates) => {
            if (prevStates.includes(selectedRegion)) {    return prevStates.filter(state => state !== selectedRegion);    }
            else {                                        return [...prevStates, selectedRegion];                         }
        });


        const updatedCheckedItems = checkedItems2.map((checked, i) =>  i === index   ?   !checked   :   checked);
        // console.log("updatedCheckedItems:-", updatedCheckedItems);
        setCheckedItems2(updatedCheckedItems);

    };

    useEffect(() => {
        if(checkedItems2.includes(true)){
            const res = All_metrics_Headers_TV.filter((item, index) => checkedItems2[index])
            console.log(`Regions selected for ${selectedState} :- `, res);
        }
    }, [checkedItems2]);















    const[mappingArr_is_Updated, set_mappingArr_is_Updated] = useState(false);    // If i am coming from step 3 to step 2, I do not want all this processing to happen if nothing is changed, so this state variable acts as a flag


    useEffect(()=>{

        if(!mapRegionsPopup    &&    selectedState){

            set_mappingArr_is_Updated(true);
            set_unMappedRegions_TV(All_metrics_Headers_TV.filter(item => !statesPicked_Overall_TV.includes(item)));
            const res = All_metrics_Headers_TV.filter((item, index) => checkedItems2[index]);
            //console.log("res :- ", res);

            
            if(res.length === 0){
                const updatedItems = mappingArr.filter(obj => obj.state !== selectedState);
                setMappingArr(updatedItems);
                return;
            }


            if(    mappingArr.some(obj => obj.state === selectedState)    ){                       // If state already exists, then override it with newly selected regions
                const existingIndex = mappingArr.findIndex(obj => obj.state === selectedState);
                const updatedArr = mappingArr.map(    (obj, index) => index === existingIndex ? { ...obj, regions: res } : obj    );
                setMappingArr(updatedArr)
            }
            else{
                const newEntry = {   state: selectedState,    regions: res   };
                setMappingArr([...mappingArr, newEntry]);
            }


            setSelectedState('');
            setCheckedItems2(new Array(All_metrics_Headers_TV.length).fill(false));
        }

    },[mapRegionsPopup]); 


    useEffect(()=>{
        if(mappingArr.length>0){
            console.log("mappingArr :- ", mappingArr);
        }
    },[mappingArr]);
















    useEffect(()=>{                          // Suppose if regions have already been selected for some state, but user wants to edit the regions(Add new regions and remove previous regions)
        if(selectedState.length > 0){
            
            if(    mappingArr.some(obj => obj.state === selectedState)    ){
                const index = mappingArr.findIndex(obj => obj.state === selectedState);
                console.log("mappingArr[index] :- ", mappingArr[index].regions);

                const filteredArray  =  All_metrics_Headers_TV.map(item => mappingArr[index].regions.includes(item));
                console.log("filteredArray:-", filteredArray);
                setCheckedItems2(filteredArray);
            }

        }
    },[selectedState]);









    const[after_Regional_Mapping_arr_TV_is_Updated, set_after_Regional_Mapping_arr_TV_is_Updated] = useState(false);

    useEffect(()=>{

        if(!mapRegionsPopup    &&    mappingArr.length===0    &&    mappingArr_is_Updated){  
            set_After_Regional_Mapping_arr_TV([]);
            set_Wide_Format_Data_TV([]);
            set_mappingArr_is_Updated(false);   
        }

        if(!mapRegionsPopup    &&    mappingArr.length>0    &&    mappingArr_is_Updated){     // mappingArr.length===states.length means that all the states are mandatory to mapped
            

            const regionToStateMap = {};

            mappingArr.forEach(({ state, regions }) => {
                regions.forEach(region => {
                    
                    selected_metrics_TV_raw.forEach(metric => {
                        regionToStateMap[region + metric] = `${state}_${metric.slice(0, 4)}`;
                    });

                });
            });

            console.log("regionToStateMap :- ", regionToStateMap);

            // Create a Set to store unique values
            const uniqueValues = new Set(Object.values(regionToStateMap));
            console.log("uniqueValues :- ", Array.from(uniqueValues));
            set_States_Names_Used(Array.from(uniqueValues));    

        
            
            const output = aggregated_Data_TV.map(item => {
                
                const aggregated = {};
                let stateTotals = {};
                

                // aggregated.Channel = item.Channel;
                // aggregated.Genre = item.Genre;          

                
                
                for (const key in item) {

                    if (regionToStateMap[key]) {
                        const state = regionToStateMap[key];

                        if (!stateTotals[state]) {
                            stateTotals[state] = 0;        // Initialize the state in stateTotals if not already done
                        }

                        stateTotals[state] += item[key];   // Add the value to the corresponding state's total
                    }
                    else {                            // If not found, then keep it as it is
                        aggregated[key] = item[key];
                    }
                }



                for (const [state, total] of Object.entries(stateTotals)) {
                    aggregated[state] = total;
                }




                return aggregated;
            });
            console.log("output :- ", output);




            const AllMetricsWithSuffixes = new Set(
                All_metrics_Headers_TV.flatMap(header => 
                    selected_metrics_TV_raw.map(suffix => header + suffix) 
                )
            );
            console.log("AllMetricsWithSuffixes :-", AllMetricsWithSuffixes);




            const filteredOutput = output.map(item => {
                const filteredItem = {};

                for (const key in item) {
                    if (!AllMetricsWithSuffixes.has(key)) {
                        filteredItem[key] = item[key];
                    }
                }

                return filteredItem;
            });
            console.log("filteredOutput :- ", filteredOutput);


            set_after_Regional_Mapping_arr_TV_is_Updated(true);
            set_After_Regional_Mapping_arr_TV(filteredOutput);
            set_mappingArr_is_Updated(false);     //  

        }

    },[mappingArr_is_Updated]);








    const[after_wide_Format_Data_TV_isUpdated, set_after_wide_Format_Data_TV_isUpdated] = useState(false);

    useEffect(()=>{
        if(after_Regional_Mapping_arr_TV.length > 0    &&    after_Regional_Mapping_arr_TV_is_Updated){
            const formattedData = Object.keys(after_Regional_Mapping_arr_TV[0]).map(X => ({label:X,  key:X}));
            console.log("formattedData :- ", formattedData);
            set_headers_TV_Step2(formattedData);










            const uniqueChannels = [...new Set(      after_Regional_Mapping_arr_TV.map(item => `${item.Channel.replace(/ /g, '_')}`)      )].sort();
            // console.log("uniqueChannels :- ", uniqueChannels);
            set_unique_Channels_in_TV(uniqueChannels);



            const resultMap = {};


            after_Regional_Mapping_arr_TV.forEach(item => {
                const { Date, Channel } = item;

                
                States_Names_Used.forEach(state => {
                    const value = item[state];
                    const resultKey = `${Channel.replace(/ /g, '_')}`;  // we are replacing " " in Channel


                    if (!resultMap[Date]) {
                        resultMap[Date] = {};
                    }

                    if (!resultMap[Date][state]) {
                        resultMap[Date][state] = { Date, State: state };
                    }

                    resultMap[Date][state][resultKey] = value;
                });
            });
            console.log("resultMap :- ", resultMap);


            
            const res = [];

            Object.entries(resultMap).forEach(([date, states]) => {
                Object.entries(states).forEach(([state, data]) => {
                    
                    uniqueChannels.forEach(combination => {
                        if (!data[combination]) {
                            data[combination] = 0; // Assign 0 if not present
                        }
                    });

                    // Create the final object in the desired order
                    const orderedResult = { Date: data.Date, State: data.State };
                    uniqueChannels.forEach(combination => {
                        orderedResult[combination] = data[combination];
                    });


                    res.push(orderedResult);
                });
            });


            console.log(res);
            console.log(res[0].State);
            console.log(typeof(res[0]['Star_Suvarna_Plus...Kar_GEC']));
            console.log(typeof(res[0]['Star_Suvarna...Kar_GEC']));





            const arr_3 = res.reduce((result, entry) => {
                const { Date, State, ...metrics } = entry;
                const stateName = State.split('_')[0];  // Get state name without suffix (e.g., "Delhi", "Mumbai")
                const suffix = State.split('_')[1];    // Get the suffix (e.g., "Imp", "rat.")
              
                // Find the object for the current date and state in the result array
                let dateStateObj = result.find(item => item.Date === Date && item.State === stateName);
                
                // If not found, create a new object for this Date and State
                if (!dateStateObj) {
                    dateStateObj = { Date, State: stateName };
                    result.push(dateStateObj);
                }
              
                // Add the metrics with the suffix to the dateStateObj
                Object.keys(metrics).forEach(key => {
                    const newKey = `TV_CH_${suffix}_${key}`;
                    dateStateObj[newKey] = metrics[key];
                });
              
                return result;
            }, []);
            console.log("arr_3 :- ", arr_3);      // Channel Impr, Channel NGRP
 









            const resX = aggregate_By_Genres(after_Regional_Mapping_arr_TV);
            console.log("resX :-", resX);

            const uniqueCombinations2 = [...new Set(      resX.map(item => `${item.Genre.replace(/ /g, '_')}`)      )].sort();






            const resultMap2 = {};


            resX.forEach(item => {
                const { Date, Genre } = item;

                
                States_Names_Used.forEach(state => {
                    const value = item[state];
                    const resultKey = `${Genre.replace(/ /g, '_')}`;
                    // console.log("resultKey:-", resultKey);


                    if (!resultMap2[Date]) {
                        resultMap2[Date] = {};
                    }

                    if (!resultMap2[Date][state]) {
                        resultMap2[Date][state] = { Date, State: state };
                    }

                    resultMap2[Date][state][resultKey] = value;
                });
            });
            console.log("resultMap2 :- ", resultMap2);


            
            const res2 = [];

            Object.entries(resultMap2).forEach(([date, states]) => {
                Object.entries(states).forEach(([state, data]) => {
                    
                    uniqueCombinations2.forEach(combination => {
                        if (!data[combination]) {
                            data[combination] = 0; // Assign 0 if not present
                        }
                    });

                    // Create the final object in the desired order
                    const orderedResult = { Date: data.Date, State: data.State };
                    uniqueCombinations2.forEach(combination => {
                        orderedResult[combination] = data[combination];
                    });


                    res2.push(orderedResult);
                });
            });


            console.log(res2);





            const arr_3X = res2.reduce((result, entry) => {
                const { Date, State, ...metrics } = entry;
                const stateName = State.split('_')[0];  // Get state name without suffix (e.g., "Delhi", "Mumbai")
                const suffix = State.split('_')[1];    // Get the suffix (e.g., "Imp", "rat.")
              
                // Find the object for the current date and state in the result array
                let dateStateObj = result.find(item => item.Date === Date && item.State === stateName);
                
                // If not found, create a new object for this Date and State
                if (!dateStateObj) {
                    dateStateObj = { Date, State: stateName };
                    result.push(dateStateObj);
                }
              
                // Add the metrics with the suffix to the dateStateObj
                Object.keys(metrics).forEach(key => {
                    const newKey = `TV_GN_${suffix}_${key}`;
                    dateStateObj[newKey] = metrics[key];
                });
              
                return result;
            }, []);
            console.log("arr_3X :- ", arr_3X);










            let Impression_Total_;
            if(selected_metrics_TV.includes("Impressions")){
                Impression_Total_ = arr_3.map((obj) => {
                    // Extract the State and Date values
                    const state = obj.State;  // Assuming the key is 'State'
                    const date = obj.Date;    // Assuming the key is 'Date'
                
                    // Calculate the sum of the '_Impr_' keys
                    const totalImpressions = Object.entries(obj)
                        .filter(([key]) => key.includes("_Impr_"))  // Filter only '_Impr_' keys
                        .reduce((sum, [, value]) => sum + (parseFloat(value) || 0), 0);  // Sum the values
                
                    // Return an object with State, Date, and the calculated Impressions Total
                    return {
                        Date: date,
                        State: state,
                        Impressions_Total: totalImpressions
                    };
                });

                console.log("Impression_Total_ :- ", Impression_Total_);
            }          


            let Rating_Total_;
            if(selected_metrics_TV.includes("rat")){
                Rating_Total_ = arr_3.map((obj) => {
                    // Extract the State and Date values
                    const state = obj.State;  // Assuming the key is 'State'
                    const date = obj.Date;    // Assuming the key is 'Date'
                
                    // Calculate the sum of the '_Impr_' keys
                    const totalratings = Object.entries(obj)
                        .filter(([key]) => key.includes("_rat._"))  // Filter only '_rat._' keys
                        .reduce((sum, [, value]) => sum + (parseFloat(value) || 0), 0);  // Sum the values
                
                    // Return an object with State, Date, and the calculated Impressions Total
                    return {
                        Date: date,
                        State: state,
                        Ratings_Total: totalratings
                    };
                });

                console.log("Rating_Total_ :- ", Rating_Total_);
            }

            
            let NGRP_Total_;
            if(selected_metrics_TV.includes("NGRP")){
                NGRP_Total_ = arr_3.map((obj) => {
                    // Extract the State and Date values
                    const state = obj.State;  // Assuming the key is 'State'
                    const date = obj.Date;    // Assuming the key is 'Date'
                
                    // Calculate the sum of the '_Impr_' keys
                    const totalNGRP = Object.entries(obj)
                        .filter(([key]) => key.includes("_NGRP_"))  // Filter only '_rat._' keys
                        .reduce((sum, [, value]) => sum + (parseFloat(value) || 0), 0);  // Sum the values
                
                    // Return an object with State, Date, and the calculated Impressions Total
                    return {
                        Date: date,
                        State: state,
                        NGRP_Total: totalNGRP
                    };
                });
                
                console.log("NGRP_Total_ :- ", NGRP_Total_);
            }









            // FIRST WAY TO MERGE - Using find() : Not so efficient in time, efficient in space

            // Merge arr_3 and arr_3X based on Date and State
            // const arr = arr_3.map(item => {
            //     // Find the corresponding object in arr_3X with the same Date and State
            //     const match = arr_3X.find(x => x.Date === item.Date && x.State === item.State);
                
            //     // Merge the two objects and return the result
            //     return { ...item, ...match };
            // });




            // SECOND WAY TO MERGE - Using lookupMap, efficient in time, not efficient in space

            // Create a lookup object for arr_3X using Date and State as the key
            const lookupMap = arr_3X.reduce((acc, curr) => {
                const key = `${curr.Date}-${curr.State}`;  // Combine Date and State as a unique key
                acc[key] = curr;  // Store the object in the lookup map
                return acc;
            }, {});
            
            // Merge arr_3 and arr_3X using the lookup map
            const arr = arr_3.map(item => {
                const key = `${item.Date}-${item.State}`;  // Create the same key to lookup in the map
                const match = lookupMap[key];  // Directly access the corresponding object from arr_3X
                return { ...item, ...match };  // Merge the two objects
            });


            
            console.log("After merging Genres and Channels arrays", arr);










            if(selected_metrics_TV.includes("Impressions")){
                arr.forEach(item => {
                    const matchingImpression = Impression_Total_.find(impression => 
                        impression.Date === item.Date && impression.State === item.State
                    );
                    if (matchingImpression) {
                      item.Impressions_Total = matchingImpression.Impressions_Total;
                    }
                });

                console.log("After appending Impressions_Total :- ", arr);
            }


            if(selected_metrics_TV.includes("rat")){
                arr.forEach(item => {   
                    const matchingRating = Rating_Total_.find(Rating => 
                        Rating.Date === item.Date && Rating.State === item.State
                    );  
                    if (matchingRating) {
                        item.Ratings_Total = matchingRating.Ratings_Total;
                    }
                });

                console.log("After appending Ratings_Total :- ", arr);
            }
            
            
            if(selected_metrics_TV.includes("NGRP")){
                arr.forEach(item => {   
                    const matchingNGRP = NGRP_Total_.find(NGRP => 
                        NGRP.Date === item.Date && NGRP.State === item.State
                    );  
                    if (matchingNGRP) {
                        item.NGRP_Total = matchingNGRP.NGRP_Total;
                    }
                });

                console.log("After appending NGRP_Total :- ", arr);
            }





            console.log(arr);
            set_Wide_Format_Data_TV(arr);

            set_after_Regional_Mapping_arr_TV_is_Updated(false);
            set_after_wide_Format_Data_TV_isUpdated(true);
        }
    },[after_Regional_Mapping_arr_TV]);



    function aggregate_By_Genres(data) {
        const resultMap = new Map();

      

        data.forEach(item => {
            const key = `${item.Genre}-${item.Date}`; 
            
            if (!resultMap.has(key)) {                
                const parsedItem = { ...item };                           

                for (const [key, value] of Object.entries(parsedItem)) {
                    if (States_Names_Used.includes(key)) {  
                        parsedItem[key] = parseFloat(value) || 0;
                    }
                }
                resultMap.set(key, parsedItem);       // First occurence of channel's name from that specific genre will be saved, anyhow it does not matter because later on we will remove the channel name
            } 
            else {
                const existing = resultMap.get(key); 
                for (const [key, value] of Object.entries(item)) {
                    if (States_Names_Used.includes(key)) { 
                        existing[key] = (parseFloat(existing[key]) || 0) + (parseFloat(value) || 0);
                    }
                }
            }
        });

        console.log("resultMap :- ", resultMap);
      


        
        // The entire code below convert the map "resultMap" to an array of Objects  AND also It retains only columns which are present in res[] & States_Names_Used[] (So basically Channel column is removed here)
        const res = ["Genre", "Date"];

        return Array.from(resultMap.values()).map(item => ({

            ...res.reduce((acc, key) => {
                if (key in item) {
                  acc[key] = item[key];
                }
                return acc;
            }, {}),



            ...Object.fromEntries(
                Object.entries(item).filter(([key]) => 
                    States_Names_Used.some(substring => key.includes(substring))  // Check if key includes any of these substrings
                )
              )
        }));

    }




    useEffect(()=>{
        if(wide_Format_Data_TV.length === 0){
            console.log("Yes it is empty now : wide_Format_Data_TV");
        }
        if(wide_Format_Data_TV.length > 0    &&    after_wide_Format_Data_TV_isUpdated){
            const formattedData = Object.keys(wide_Format_Data_TV[0]).map(X => ({label:X,  key:X}));
            console.log("formattedData :- ", formattedData);
            set_headers_TV_Step3(formattedData);
            set_after_wide_Format_Data_TV_isUpdated(false);
        }
    },[wide_Format_Data_TV]);




    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)  screen_width = '1200_1400';

    








    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", backgroundColor:"", gap:"1.6rem"}}>


            <div  style={{width:"58%", overflowY:"scroll", height:"100%", backgroundColor:"", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",     backgroundColor: "", color:"#171717" }}>
                    
                    <thead  style={{width:"100%"}}>
                            <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                <th    style={{width:"5%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                <th    style={{width:"20%",    padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>Geo</th>
                                <th    style={{width:"5%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start"}}></th>
                                <th    style={{width:"70%",    padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>Regions</th>
                            </tr>
                    </thead>

                    <tbody  style={{width:"100%"}}>
                        {
                            Sales_states.map((state, index) => {
                                return(
                                    <tr key={index}  style={{width:"100%"}}>

                                        <td      style={{width:"5%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd", borderLeft:"0px solid #ddd"}}></td>
                                        <td      style={{width:"20%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd", borderRight:"1px solid #ddd", verticalAlign:"top"}}>    {state}    </td>
                                        <td      style={{width:"5%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd"}}></td>

                                        {
                                            (mappingArr.some(obj => obj.state === state))  ?    <td    onClick={()=>{  setMapRegionsPopup(true);    setSelectedState(state)  }}   style={{ width:"70%", borderBottom:"1px solid #ddd", borderRight:"0px solid #ddd",  backgroundColor:"",  padding:"6px 0 10px 0", cursor:"pointer"    }}>
                                                                                                    {
                                                                                                        mappingArr[mappingArr.findIndex(obj => obj.state === state)].regions
                                                                                                        .map((region, index) => (
                                                                                                            <div key={index}  style={{paddingTop:"4px"}}>{region}</div>
                                                                                                        ))
                                                                                                    }
                                                                                                </td>
                                                                                            :   <td  style={{ width:"70%", borderBottom:"1px solid #ddd", borderRight:"0px solid #ddd",  backgroundColor:""}}>    
                                                                                                    <button  onClick={()=>{  setMapRegionsPopup(true);    setSelectedState(state)  }}  style={{width:"100%", backgroundColor:"", textAlign:"start",  color:"#0000EE",  padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0"}}> Click here</button>    
                                                                                                </td>
                                        }
                                                        
                                    </tr>
                                );
                            })
                        }
                    </tbody>

                </table>

            </div>

            <div  style={{width:"42%", overflowY:"scroll", height:"100%", backgroundColor:"", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px",}}>
                <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",     backgroundColor: "", color:"#171717" }}>
                    
                    <thead  style={{width:"100%"}}>
                            <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                <th    style={{width:"7%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",     borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                <th    style={{width:"93%",    padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",  backgroundColor:"",    borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>Unmapped Regions ({unMappedRegions_TV.length})</th>
                            </tr>
                    </thead>

                    <tbody  style={{width:"100%"}}>
                        {                           
                            unMappedRegions_TV.map((item, index) => (
                                
                                    <tr key={index}  style={{width:"100%", backgroundColor:""}}>

                                        <td      style={{width:"7%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0",  backgroundColor:"",    borderBottom:"0px solid #ddd", borderLeft:"0px solid #ddd"}}></td>

                                        <td  style={{ width:"93%", borderBottom:"0px solid #ddd", borderRight:"0px solid #ddd",  backgroundColor:""}}>    
                                            <p  style={{width:"100%", backgroundColor:"", textAlign:"start",   padding:screen_width === 'default-1536px' ? "5px 5px 5px 0" : "10.5px 10.5px 10.5px 0"}}> {item} </p>    
                                        </td>
                                        
                                                        
                                    </tr>
                                
                            ))
                        }
                    </tbody>


                </table>

            </div>




                <div>
                    {   
                        after_Regional_Mapping_arr_TV.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={after_Regional_Mapping_arr_TV}    headers={headers_TV_Step2}    filename={"After_Regional_Mapping_file"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>

                        </div>
                    }
                </div>

                <div>
                    {   
                        wide_Format_Data_TV.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={wide_Format_Data_TV}    headers={headers_TV_Step3}    filename={"TV_Processed_File_Default"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>
                        </div>
                    }
                </div>

            {    
                mapRegionsPopup  &&  ( 
                    <div   className={`mmm-modal-delete-rows`}  style={{width:"620px", height:"520px", background:"#FFFFFF", color:"#171717", padding:"20px 35px", gap:"2rem"}}>

                        <div  style={{height:"7%", width:"100%", borderBottom:"1px solid white",   display:"flex", alignItems:"center", justifyContent:"space-between", backgroundColor:""}}>
                            <p  style={{fontSize:"26px", fontWeight:"500", color:"#474747"}}>Map regions with {selectedState}</p>
                            <button  onClick={()=>{setMapRegionsPopup(false);}}>    <RxCross2  fontSize={32}/>    </button>
                        </div>


                        <div style={{height:"84%", width:"100%", padding:"0", overflowY:"scroll", backgroundColor:""}}>
                            
                            <ul  style={{display:"flex", flexDirection:"column", gap:"0.8rem"}}>

                                {
                                    checkedItems2.map((item, index) => (
                                        item &&
                                            <li key={index}  style={{display:"flex", gap:"0.6rem", fontSize:"17px", color:"#5C5C5C", fontWeight:"500"}}>
                                                <label style={{ display: "flex", gap: "0.6rem", cursor: "pointer" }}>
                                                    <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                    <p>{All_metrics_Headers_TV[index]}</p>
                                                </label>
                                            </li>
                                    ))
                                }

                                {
                                    All_metrics_Headers_TV.map((item, index) => (
                                        !statesPicked_Overall_TV.includes(item)    &&
                                            <li key={index}  style={{display:"flex", gap:"0.6rem", fontSize:"17px", color:"#5C5C5C", fontWeight:"500"}}>
                                                <label style={{ display: "flex", gap: "0.6rem", cursor: "pointer" }}>
                                                    <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                    <p>{item}</p>
                                                </label>
                                            </li>
                                    ))
                                }

                            </ul>

                        </div>
                
                    </div>
                )                     
            }

            {
                mapRegionsPopup    &&    (<div   className={` mmm-overlay-delete-rows `}  style={{ backdropFilter:"blur(10px)"}}></div>)  
            }
    
        </div>
    );
  }
  
  export default TVData_Step2;
