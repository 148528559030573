import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function TVDataStep2({aggregatedData, states, handleActive, isActiveArr,     afterRegionalMapping_arr,    setAfterRegionalMapping_arr}) {

    // console.log("aggregatedData :- ", aggregatedData);


    const [select_RegionalMapping_Popup, setSelect_RegionalMapping_Popup] = useState(false);

    const [mapRegionsPopup, setMapRegionsPopup] = useState(false);
    const [selectedState, setSelectedState] = useState('');
    const [statesPicked_Overall, set_statesPicked_Overall] = useState([]);


    function mapStatewithRegionsFunc(state){
        setMapRegionsPopup(true);  
        setSelectedState(state);
        setSelect_RegionalMapping_Popup(false);
    }




    const [metricsHeaders, setMetricsHeaders] = useState([]);
    if(aggregatedData.length > 0    &&    metricsHeaders.length === 0){
        const arr = Object.keys(aggregatedData[0]).filter(key => key.includes('Impressions'));
        console.log("arr :- ", arr);
        setMetricsHeaders(arr);
    }

    const [checkedItems2, setCheckedItems2] = useState([]);
    if(metricsHeaders.length > 0    &&    checkedItems2.length===0){
        setCheckedItems2(new Array(metricsHeaders.length).fill(false));
    }
  

    const handleCheckboxChange2 = (index) => {

        const selectedRegion = metricsHeaders[index];
        set_statesPicked_Overall((prevStates) => {
            if (prevStates.includes(selectedRegion)) {    return prevStates.filter(state => state !== selectedRegion);    }
            else {                                        return [...prevStates, selectedRegion];                         }
        });


        const updatedCheckedItems = checkedItems2.map((checked, i) =>  i === index   ?   !checked   :   checked);
        setCheckedItems2(updatedCheckedItems);

    };

    useEffect(() => {
        if(checkedItems2.includes(true)){
            const res = metricsHeaders.filter((item, index) => checkedItems2[index])
            console.log(`Regions selected for ${selectedState} :- `, res);
        }
    }, [checkedItems2]);















    const [mappingArr, setMappingArr] = useState([]);
    useEffect(()=>{

        if(!mapRegionsPopup    &&    selectedState){
            const res = metricsHeaders.filter((item, index) => checkedItems2[index]);
            
            if(res.length === 0){
                const updatedItems = mappingArr.filter(obj => obj.state !== selectedState);
                setMappingArr(updatedItems);
                return;
            }


            if(    mappingArr.some(obj => obj.state === selectedState)    ){                       // If state already exists, then override it with newly selected regions
                const existingIndex = mappingArr.findIndex(obj => obj.state === selectedState);
                const updatedArr = mappingArr.map(    (obj, index) => index === existingIndex ? { ...obj, regions: res } : obj    );
                setMappingArr(updatedArr)
            }
            else{
                const newEntry = {   state: selectedState,    regions: res   };
                setMappingArr([...mappingArr, newEntry]);
            }


            setSelectedState('');
            setCheckedItems2(new Array(metricsHeaders.length).fill(false));
        }

    },[mapRegionsPopup]); 

    useEffect(()=>{
        if(mappingArr.length>0){
            console.log("mappingArr :- ", mappingArr);
        }
    },[mappingArr]);













    useEffect(()=>{                          // Suppose if regions have already been selected for some state, but user wants to edit the regions(Add new regions and remove previous regions)
        if(selectedState.length > 0){
            
            if(    mappingArr.some(obj => obj.state === selectedState)    ){
                const index = mappingArr.findIndex(obj => obj.state === selectedState);
                console.log("mappingArr[index] :- ", mappingArr[index].regions);

                const filteredArray  =  metricsHeaders.map(item => mappingArr[index].regions.includes(item));
                console.log("filteredArray:-", filteredArray);
                setCheckedItems2(filteredArray);
            }

        }
    },[selectedState]);










    useEffect(()=>{

        if(!select_RegionalMapping_Popup    &&    !mapRegionsPopup    &&    mappingArr.length===states.length){     // mappingArr.length===states.length means that all the states are required to mapped
            

            const regionToStateMap = {};
            mappingArr.forEach(({state, regions}) => {
                regions.forEach(region => {
                    regionToStateMap[region] = state;
                });
            });
            console.log("regionToStateMap :- ", regionToStateMap);



            

            
            const output = aggregatedData.map(item => {
                const aggregated = {};
                
                // aggregated.Channel = item.Channel;
                // aggregated.Genre = item.Genre;
                
                let stateTotals = mappingArr.reduce((acc, {state}) => {
                    acc[state] = 0;
                    return acc;
                }, {});
                
                for (const key in item) {
                    if (regionToStateMap[key]) {
                        const state = regionToStateMap[key];
                        stateTotals[state] += item[key];
                    } 
                    else {
                        aggregated[key] = item[key];
                    }
                }

                for (const [state, total] of Object.entries(stateTotals)) {
                    aggregated[state] = total;
                }

                return aggregated;
            });
            console.log("output :- ", output);


            const filteredOutput = output.map(item => {
                const filteredItem = {};
                for (const key in item) {
                  if (!metricsHeaders.includes(key)) {
                    filteredItem[key] = item[key];
                  }
                }
                return filteredItem;
            });
            console.log("filteredOutput :- ", filteredOutput);

            setAfterRegionalMapping_arr(filteredOutput);

            
        }

    },[select_RegionalMapping_Popup]);

    const [headers2, setHeaders2] = useState([]);
    useEffect(()=>{
        if(afterRegionalMapping_arr.length > 0){
            const formattedData = Object.keys(afterRegionalMapping_arr[0]).map(X => ({label:X,  key:X}));
            console.log("formattedData :- ", formattedData);
            setHeaders2(formattedData);
        }
    },[afterRegionalMapping_arr]);
    




    const [nextBtnClicked2, setNextBtnClicked2] = useState(false);
    useEffect(()=>{
        if(!isActiveArr.includes(2)    &&    nextBtnClicked2){
            if(!isActiveArr.includes(3)) handleActive(3);
            setNextBtnClicked2(false);
        }
    }, [isActiveArr, nextBtnClicked2]);







    return (
        <div>

            <div  style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                <button  onClick={()=>{setSelect_RegionalMapping_Popup(true)}}  style={{backgroundColor:"gray", padding:"5px 20px", borderRadius:"5px", color:"white"}}>Regional Mapping with States</button>

                <div>
                    {   
                        afterRegionalMapping_arr.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={afterRegionalMapping_arr}    headers={headers2}    filename={"After_Regional_Mapping_file"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>

                            <button  onClick={()=>{handleActive(2);  setNextBtnClicked2(true);}}  style={{backgroundColor:"green", fontSize:"13px", borderRadius:"5px", padding:"0 1rem"}}  >Next</button>
                        </div>
                    }
                </div>
            </div>


            {    
                select_RegionalMapping_Popup  &&  ( 
                    <div   className={`mmm-modal-delete-rows`}  style={{width:"75%", height:"90%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>

                        <div  style={{height:"10%", width:"100%", borderBottom:"1px solid white", paddingBottom:"1rem",   display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p  style={{fontSize:"26px"}}>Regional Mapping with States</p>
                            <button  onClick={()=>{setSelect_RegionalMapping_Popup(false)}}>    <RxCross2  fontSize={32}/>    </button>
                        </div>



                        <div  style={{width:"100%", height:"80%"}}>

                            <div style={{width:"100%", height:"9%", backgroundColor: "gray", display:"flex", alignItems:"flex-end"}}>
                                        <p className="mmm-cell3"  style={{width:"200px"}}>  State     </p>
                                        <p className="mmm-cell3"  style={{flex:"1"}}>  Region         </p>      {/* Because of flex:1, it took remaining width */}
                            </div>

                            <div  style={{width:"100%", height:"91%", overflowY:"scroll",}}>

                                <table style={{ width:"100%", height:"100%", fontSize:"16.35px"}}>

                                    <tbody>
                                        {
                                            states.map((state, index) => (
                                                    <tr key={index}>
                                                        <td  className="mmm-cell3"  style={{width:"200px"}}>        {state}      </td>
                                                        {
                                                            (mappingArr.some(obj => obj.state === state))  ?  <td  className="mmm-cell3"  style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"flex-start", padding:"1rem 0 1rem 12rem", gap:"0.25rem"}}>
                                                                                                                {
                                                                                                                    mappingArr[mappingArr.findIndex(obj => obj.state === state)].regions
                                                                                                                    .map((region, index) => (
                                                                                                                        <div key={index}>{region}</div>
                                                                                                                    ))
                                                                                                                }

                                                                                                                <button  onClick={()=>{mapStatewithRegionsFunc(state)}}  style={{backgroundColor:"green", padding:"0px 20px", borderRadius:"5px", marginTop:"0.5rem"}}>Edit</button>
                                                                                                              </td>
                                                                                                           :  <td  className="mmm-cell3"  style={{flex:"1"}}>    <button  onClick={()=>{mapStatewithRegionsFunc(state)}}  style={{width:"100%"}}>Select Regions</button>    </td>
                                                        }
                                                    </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                        </div>


                        <div  style={{height:"6%", width:"100%",   display:"flex", justifyContent:"space-between"}}>
                            {
                                mappingArr.length === states.length 
                                  ?
                                    <>
                                        <p></p>
                                        <button  onClick={()=>{setSelect_RegionalMapping_Popup(false)}}  style={{width:"16%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px"}}>Save</button>
                                    </>
                                  :
                                    <>
                                        <p  style={{color:"orange", fontSize:"18px"}}>*It is mandatory to map all the states with the regions</p>
                                        <button  disabled={true}  style={{opacity:"0.6", width:"16%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px"}}>Save</button>
                                    </>
                            }
                        </div>
                    
                    </div>
                )                     
            }

            {
                select_RegionalMapping_Popup    &&    (<div   className={` mmm-overlay-delete-rows `}></div>)
            }







            {    
                mapRegionsPopup  &&  ( 
                    <div   className={`mmm-modal-delete-rows`}  style={{width:"600px", height:"600px"}}>

                        <div  style={{height:"10%", width:"100%", borderBottom:"1px solid white", paddingBottom:"1rem",   display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p  style={{fontSize:"26px"}}>Map regions with {selectedState}</p>
                            <button  onClick={()=>{setMapRegionsPopup(false);    setSelect_RegionalMapping_Popup(true);}}>    <RxCross2  fontSize={32}/>    </button>
                        </div>


                        <div style={{height:"78%", width:"100%", padding:"2rem", overflowY:"scroll"}}>
                            
                            <ul  style={{display:"flex", flexDirection:"column", gap:"0.2rem"}}>

                                {
                                    checkedItems2.map((item, index) => (
                                        item &&
                                            <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                                <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                <p>{metricsHeaders[index]}</p>
                                            </li>
                                    ))
                                }

                                {
                                    metricsHeaders.map((item, index) => (
                                        !statesPicked_Overall.includes(item)    &&
                                            <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                                <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                <p>{item}</p>
                                            </li>
                                    ))
                                }

                            </ul>

                        </div>


                        <div  style={{height:"6%", width:"100%",   display:"flex", justifyContent:"center"}}>
                            <button  onClick={()=>{setMapRegionsPopup(false);    setSelect_RegionalMapping_Popup(true);}}  style={{width:"100%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px"}}>Save</button>
                        </div>
                
                    </div>
                )                     
            }

            {
                mapRegionsPopup    &&    (<div   className={` mmm-overlay-delete-rows `}  style={{ backdropFilter:"blur(10px)"}}></div>)  
            }

        </div>
    );
}

export default TVDataStep2;