import { useState } from "react";
import Loader from "../../../assets/Images/loading.gif";
import { useNavigate } from 'react-router-dom';
import { IoMdAdd } from "react-icons/io";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { MdOutlineFileDownload, MdDeleteOutline } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io";
import { useOutsideClick } from "../../../Hooks/useOutsideClick";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Papa from "papaparse";
import { insert_file_data_func2, save_raw_csv_file_func, save_geo_data_func, generate_exp_id_func, experiment_table_entry_func, results_table_entry_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';   // Because I imported and registered this here, It will be applied to entire Project folder, SO for others I have to set  datalabels: {   display: false    },
ChartJS.register(ChartDataLabels);




function ExperimentInSession({exp_Name,    setExp_Name,    fileObjs,    setFileObjs,    setCurrentExperimentWindow,    loading2,    setLoading2,    loadingCS,    setCreateSubset,    selectedObjID_forSubset,    count2,    setCount2,    count3,    setCount3,    currentExperiment_IsLoaded_FromDB,    setCurrentExperiment_IsLoaded_FromDB}) {





    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    let itemsPerPage = 5;



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400){
        screen_width = '1200_1400';
        itemsPerPage = 4;
    }



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = fileObjs.slice(indexOfFirstItem, indexOfLastItem);   // Items to display on current page


    const totalPages = Math.ceil(fileObjs.length / itemsPerPage);


    const startItem = indexOfFirstItem + 1;                             // For displaying 1-8 of 18
    const endItem = Math.min(indexOfLastItem, fileObjs.length);         // For displaying 1-8 of 18





    const heightX = itemsPerPage === 5    ?    currentItems.length===1 ? "33.7%" : currentItems.length===2 ? "50.3%" : currentItems.length===3 ? "67%" : currentItems.length===4 ? "83.5%"  :  "100%"
                                          :    currentItems.length===1 ? "39.5%" : currentItems.length===2 ? "59.68%" : currentItems.length===3 ? "79.85%" : "100%"



    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };









    const [file_name_Popup, setFile_name_Popup] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState('');
    const [subset_of_Popup, setSubset_of_Popup] = useState(false);
    const [selectedSubsetof, setSelectedSubsetof] = useState('');
    const [description_Popup, setDescription_Popup] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');

    const [runModel, setRunModel] = useState(false);













    const [chart1, setChart1] = useState([]);
    const [lift1, setLift1] = useState([]);
    const [stddev1, setStddev1] = useState([]);
    const [selectedObjID, setSelectedObjID] = useState(null);

    function run_model_func(objID){
        const matchedObject = fileObjs.find(obj => obj.objId === objID);
        console.log("matchedObject :- ", matchedObject);
  
  
        setChart1(matchedObject.result.graph_data);   

        if(matchedObject.result.lift){                // If experiment is freshly created
            setLift1(matchedObject.result.lift);
        }
        else{                                         // If experiment is loaded from DB
            setLift1([matchedObject.result.lift_value, matchedObject.result.lift_percentage]);
        }
        
        setStddev1(matchedObject.result.stddev);
  
  
        setSelectedObjID(objID);
    }
      
    let dataChart = {};
    let optionChart = {};
    if(chart1.length > 0    &&    selectedObjID!==null){

        const maxActual = Math.max(...chart1.map(data => data.actual_metric));
        const maxSynthetic = Math.max(...chart1.map(data => data.synthetic_metric));
        const maxYValue = Math.max(maxActual, maxSynthetic);
        const datesObj_ = fileObjs.find(obj => obj.objId === selectedObjID).datesObj;



        let lastDatebeforeGap = null;                          // this code snippet will act when pre period and campaign period are not connected
        let lastBiggestMetricBeforeGap = null;
        let firstDateAfterGap = null;                          
        let FirstBiggestMetricAfterGap = null;

        for(let i=0; i<chart1.length; i++){
            let flag = false;

            if(chart1[i].actual_metric === null    &&    chart1[i].synthetic_metric === null){
                flag = true;

                lastDatebeforeGap = chart1[i-1].date.slice(-5);
                lastBiggestMetricBeforeGap = Math.max(chart1[i-1].actual_metric, chart1[i-1].synthetic_metric);

                for(let j=i+1; j<chart1.length; j++){
                    if(chart1[j].actual_metric !== null    &&    chart1[j].synthetic_metric !== null){
                        firstDateAfterGap = chart1[j].date.slice(-5);
                        FirstBiggestMetricAfterGap = Math.max(chart1[j].actual_metric, chart1[j].synthetic_metric);
                        break;
                    }
                }
            }

            if(flag) break;
        }



        dataChart = {
            labels: chart1.map((data) => data.date.slice(-5)),
            datasets: [
                {
                    label: "Actual",
                    data: chart1.map((data) => data.actual_metric),
                    pointBackgroundColor: "#6e6e6e",
                    backgroundColor:"#6e6e6e",
                    borderColor: "#6e6e6e",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },

                    xAxisId: 'x-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                    yAxisId: 'y-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis

                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph

                },
                {
                    label: "Synthetic",
                    data: chart1.map((data) => data.synthetic_metric),
                    pointBackgroundColor: "#2899cf",
                    borderColor: "#2899cf",
                    backgroundColor:"#2899cf",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },
                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph
                },
                {
                    type: 'line',
                    label: '',
                    data: [
                        // { x: date_Starting_Campaign, y: 0 },
                        { x: datesObj_.Date_Starting_Campaign, y: maxYValue },
                        { x: datesObj_.Date_middle_Campaign, y: maxYValue },
                        { x: datesObj_.Date_Ending_Campaign, y: maxYValue },
                        
                        // { x: date_Ending_Campaign, y: 0 }
                    ],
                    fill: true,
                    backgroundColor: '#EEEEEE',
                    borderColor: 'white',
                    tension: 0,
                    borderWidth: 0.3,
                    pointRadius: 0,
                    
                    datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 0,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Campaign Period";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                        else {                             return '';                    }
                    }
                    }
                },

                {
                    type: 'line',
                    label: '',
                    data: [
                        { x: lastDatebeforeGap, y: 0 },
                        { x: lastDatebeforeGap, y: lastBiggestMetricBeforeGap },
                    ],
                    fill: true,
                    borderColor: 'gray', 
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            
                {
                    type: 'line',
                    label: '',
                    data: [
                    { x: firstDateAfterGap, y: 0 },
                    { x: firstDateAfterGap, y: FirstBiggestMetricAfterGap },
                    ],
                    fill: true,
                    borderColor: 'gray',
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            ]
        }
        optionChart = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            // animation: {
            //   duration: 2000,
            //   easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
            //   animateScales: true,

                // onStart: function(animation) {
                //   console.log('Animation started');
                // },
                // onComplete: function(animation) {
                //   console.log('Animation completed');
                // },
            // },


            plugins: {
                datalabels: {
                    font: {
                        family: "Toboggan",        // To change font family of data labels on charts
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "11.8px" : "10px"
                    }
                },
                legend: {
                labels: {
                    boxWidth: screen_width === 'default-1536px' ? 12 : 10,       // For making legend square
                    font: {
                        family: "Toboggan",         // To change font family of label(label is present on top of chart)
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "12px" : "10px"
                    }
                }
                }
            
            },


            scales: {
                x: {
                    id: 'x-axis1',
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of Y-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"  
                        }
                    }
                },
                y: {
                    beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

                    ticks:{
                        callback: function(value){
                            return "₹" + value
                        },
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"
                        }
                    },

                    id: 'y-axis1',
                    grid: {
                        display: false
                    }
                }
            }
        }
    }







    const [showGraph, setShowGraph] = useState(false);

    const handleToggle = () => {
        if(showData){
            setShowData(prevState => !prevState);
        }
        setShowGraph(prevState => !prevState);
    };

    const [showData, setShowData] = useState(false);

    const handleToggle2 = () => {
        if(showGraph){
            setShowGraph(prevState => !prevState);
        }
        setShowData(prevState => !prevState);
    };








    const [index_Opened, set_Index_Opened] = useState(null);
    const { drop_Down_Ref } = useOutsideClick(set_Index_Opened, index_Opened);

    const toggleDropdown = (index) => {
        if(index_Opened === index)  set_Index_Opened(null);
        else                        set_Index_Opened(index);
    };








    const [delete_Dataset_FromExp, set_Delete_Dataset_FromExp] = useState(false);
    const [id_Delete_Dataset, set_Id_Delete_Dataset] = useState(null);
    function delete_dataset_fromExp_func(){
        if(id_Delete_Dataset === selectedObjID_forSubset){          
            setCreateSubset(false);
        }    

        const updatedFileObjs = fileObjs.filter(obj => obj.objId !== id_Delete_Dataset);
        
        setChart1([]);       // The purpose of this setter here is - If i click on run model for a dataset, and then I delete the same dataset, then error will come without this setter here
        
        setFileObjs(updatedFileObjs);

        set_Id_Delete_Dataset(null);
        set_Delete_Dataset_FromExp(false);
    }







    const headers = [ {label:'date', key:'date'},            // The key should be same with field's name in object
                      {label:'geo', key:'geo'},
                      {label:'metric', key:'metric'},
                      {label:'testgeo', key:'testgeo'},
                      {label:'campaign', key:'campaign'} ];




    const [discardExperiment, set_DiscardExperiment] = useState(false);
    const [save_Experiment, set_Save_Experiment] = useState(false);
    















    const arrayToCSV = (arr) => {               // Function to convert array of objects to CSV string
        const csv = Papa.unparse(arr); 
        return csv;
    };
    const csvToBlob = (csv) => {                // Function to convert CSV string to Blob
        const blob = new Blob([csv], { type: 'text/csv' });
        return blob;
    };
        const blobToFile = (blob, fileName) => {    // Function to convert Blob to File
        const file = new File([blob], fileName, { type: 'text/csv' });
        return file;
    };





    async function save_exp_func(){
        setLoading2(true);

        
        const exp_id = await generate_exp_id_func();
        console.log("Response of generate_exp_id_func:- ", exp_id);


        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();


        for(let i=0; i<fileObjs.length; i++){       // The files which are already present on DB (which are not subsets in current experiment)
            if(fileObjs[i].subsetOf === ""){    
                const resZ = await results_table_entry_func(fileObjs[i].file_id, fileObjs[i].result);
                console.log("Response of results_table_entry_func:- ", resZ);

                const resP = await experiment_table_entry_func(exp_id.data, exp_Name, fileObjs[i].file_id, fileObjs[i].subsetOf);
                // console.log("Response of experiment_table_entry_func:- ", resP);
            }
        }



        for (let i=0; i<fileObjs.length; i++) {   
            if(fileObjs[i].subsetOf !== ""){ 


                const obj_file_data = {
                    file_name: fileObjs[i].file_name,
                    business: fileObjs[i].businessTagsObj.business,
                    category: fileObjs[i].businessTagsObj.category,
                    product: fileObjs[i].businessTagsObj.product,
                    upload_timestamp: formattedTime,
                    description: fileObjs[i].description,
                    Non_Test_Geos: fileObjs[i].Non_Test_Geos
                };
                
                await insert_file_data(exp_id.data, obj_file_data,  fileObjs[i]);

            }
        }



        console.log("This must execute in the last");

        setFileObjs([]);
        setExp_Name('');
        setChart1([]);
        set_Save_Experiment(false);
        setCurrentExperimentWindow(false);

        setCount2(count2 + 1);
        setCount3(count3 + 1);


        setLoading2(false);
    }

    const insert_file_data = async (exp_id, obj_file_data, obj) => {   
        

        const res = await insert_file_data_func2(obj_file_data);          
        // console.log("Response of insert_file_data_func2:- ", res);


        if(res.data.status === 200){           
            const formData = new FormData();


            const csvString = arrayToCSV(obj.file_data);
            const csvBlob = csvToBlob(csvString);
            const csvFile = blobToFile(csvBlob, obj.file_name);



            formData.append('file', csvFile);
            formData.append('file_id_', res.data.data.file_id);
            formData.append('file_name_', obj.file_name);
            await save_raw_csv_file(exp_id, formData, obj);
        }
    }

    const save_raw_csv_file = async (exp_id, formData, obj) => {   
        const res = await save_raw_csv_file_func(formData);          
        // console.log("Response of save_raw_csv_file_func:- ", res); 
        
        if(res.data.status === 200){
            const formData2 = new FormData();

            const csvString = arrayToCSV(obj.file_data);
            const csvBlob = csvToBlob(csvString);
            const csvFile = blobToFile(csvBlob, obj.file_name);

            formData2.append('file', csvFile);
            formData2.append('file_id_', res.data.data);
            formData2.append('flagForBE_about_DateFormat', false);
            await save_geo_data(exp_id, formData2, obj);
        }

    }

    const save_geo_data = async (exp_id, formData2, obj) => {   
        const res = await save_geo_data_func(formData2);          
        console.log("Response of save_geo_data_func:- ", res);      
        
        if(res.data.status === 200){
            const resZ = await results_table_entry_func(res.data.file_id, obj.result);
            console.log("Response of results_table_entry_func:- ", resZ);

            if(resZ.data.status === 200){
                const resP = await experiment_table_entry_func(exp_id, exp_Name, res.data.file_id, obj.subsetOf);
                console.log("Response of experiment_table_entry_func:- ", resP);
            }
        }
    }






    if (loading2) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={Loader} width="180" height="180"/>
            </div>
        )
    }







    return (
        <div  style={{width:"100%", height:"100%", display:"flex", alignItems:"flex-end"}}>

            {
                !runModel
                ?
                    <div  style={{width:"100%", height:"95.5%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                        

                        <div  style={{width:"100%", height:screen_width === 'default-1536px' ? "85.3%" : "84.2%",      padding:screen_width === 'default-1536px' ? "1.6rem 1.6rem 0 1.6rem" :  "1.33rem 1.33rem 0 1.33rem",      boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                            <div  style={{width:"100%", height:"10%", display:"flex", justifyContent:"space-between", alignItems:"flex-start"}}>
                                <p  className="fontF"  style={{fontWeight:"600",      fontSize:screen_width === 'default-1536px' ? "22px" : "18.5px",      color:"#171717"}}>{exp_Name}</p>
                                {/* <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4px 12px 4px 6px" : "3.2px 10px 3.2px 5px",      border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600", display:"flex", alignItems:"center", gap:"3px",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >
                                    <IoMdAdd fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                                    Upload Dataset
                                </button> */}

                                {
                                    (fileObjs.length >= 2   &&  !loadingCS)   &&
                                                                <button      onClick={()=>{     navigate('/compare-results')     }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4px 12px 4px 12px" : "3.2px 10px 3.2px 10px",      border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600", display:"flex", alignItems:"center", gap:"3px",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >
                                                                    Compare Results
                                                                </button>
                                }
                            </div>

                            <div  style={{width:"100%", height:"3.75%"}}></div>

                            <div  style={{ width: "100%", height:"86.25%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>

                                <div    style={{width:"100%", height:screen_width === 'default-1536px' ? "84%" : "82%"}}>
                                    <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13.5px",    width: "100%", height: heightX, backgroundColor: "white", color:"#171717" }}>
                                        <thead  style={{width:"100%"}}>
                                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                                    <th   style={{width:"3%",  padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",    borderBottom:"1px solid #ddd", textAlign:"start",    borderTopLeftRadius:"8px"}}></th>
                                                    <th   style={{width:"15%", padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",    borderBottom:"1px solid #ddd", textAlign:"start",}}>File Name</th>
                                                    <th   style={{width:"42%", padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",    borderBottom:"1px solid #ddd", textAlign:"start",}}>Description</th>
                                                    <th   style={{width:"15%", padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",    borderBottom:"1px solid #ddd", textAlign:"start",}}>Subset of</th>
                                                    <th   style={{width:"15%", padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",    borderBottom:"1px solid #ddd", textAlign:"start",}}></th>
                                                    <th   style={{width:"10%", padding:screen_width === 'default-1536px' ? "15px" : "12.5px",                                borderBottom:"1px solid #ddd", textAlign:"center",   borderTopRightRadius:"8px"}}>Action</th>
                                                </tr>
                                        </thead>

                                        <tbody  style={{width:"100%"}}>
                                            {
                                                currentItems.map((obj, index) => {

                                                        return(
                                                            <tr key={index}  style={{width:"100%"}}>
                                                                <td      style={{width:"3%",  borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}></td>
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    {
                                                                        screen_width === 'default-1536px'   ?
                                                                                                                obj.file_name.length > 18       ?   <div  style={{display:"flex"}}>
                                                                                                                                                        <p>{obj.file_name.slice(0,16)}</p>
                                                                                                                                                        <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                    </div>  
                                                                                                                                                :   obj.file_name
                                                                                                            :
                                                                                                                obj.file_name.length > 14       ?   <div  style={{display:"flex"}}>
                                                                                                                                                        <p>{obj.file_name.slice(0,14)}</p>
                                                                                                                                                        <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                    </div>  
                                                                                                                                                :   obj.file_name
                                                                    }
                                                                </td>
                                                                <td      style={{width:"42%", borderBottom:"1px solid #ddd", textAlign:"start",}}>    
                                                                    {
                                                                        obj.description.length > 50     ?    <div  style={{display:"flex"}}>
                                                                                                                <p>{obj.description.slice(0,48)}</p>
                                                                                                                <button    onClick={()=>{setDescription_Popup(true);  setSelectedDescription(obj.description)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                            </div>  
                                                                                                        :    obj.description
                                                                    }
                                                                    
                                                                </td>
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    {
                                                                        obj.subsetOf === null ||  obj.subsetOf === ""  ?    "NA" 
                                                                                                :    screen_width === 'default-1536px'  ?
                                                                                                                                            obj.subsetOf.length > 20       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                    <p>{obj.subsetOf.slice(0,18)}</p>
                                                                                                                                                                                    <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subsetOf)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                                </div>  
                                                                                                                                                                            :   obj.subsetOf
                                                                                                                                        :
                                                                                                                                            obj.subsetOf.length > 16       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                    <p>{obj.subsetOf.slice(0,15)}</p>
                                                                                                                                                                                    <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subsetOf)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                                </div>  
                                                                                                                                                                            :   obj.subsetOf
                                                                    }
                                                                </td>
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{     run_model_func(obj.objId);    setRunModel(true);     }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Run Model</button>
                                                                </td>
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}    onMouseDown={(e)=>{e.stopPropagation()}}      onClick={()=>{toggleDropdown(index)}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                            <HiOutlineDotsVertical />

                                                                            <div    ref={drop_Down_Ref}    className={`absolute left-[50%] top-[0%] z-[1000] flex translate-x-[-50%] translate-y-[3em] flex-col rounded-lg bg-white text-richblack-900 transition-all duration-150          ${ index_Opened === index ? 'visible translate-y-[1.65em] opacity-100' : 'invisible opacity-0'}`}      style={{ boxShadow: '0 0 12px rgba(0, 0, 0, 0.2)',      width:screen_width === 'default-1536px' ? "160px" : "134px",      padding:screen_width === 'default-1536px' ? "10px" : "8px"}}>

                                                                                <div className="absolute left-[50%] top-[0%] -z-10 translate-x-[-50%] translate-y-[-30%] rotate-45 select-none rounded bg-white"        style={{height:screen_width === 'default-1536px' ? "24px" : "20px",      width:screen_width === 'default-1536px' ? "24px" : "20px"}}></div>    {/*  This div is for white triangle which is right below the DownArrow icon  */}
                                                                            

                                                                                <CSVLink  data={obj.file_data}    headers={headers}    filename={obj.file_name}    onClick={(e)=>{set_Index_Opened(null)}}    onMouseDown={(e)=>{e.stopPropagation()}}>
                                                                                    <button    style={{width:"100%",      fontSize:screen_width === 'default-1536px' ? "14px" : "12px",      color:"rgb(64,64,64)", textAlign:"start",      padding:screen_width === 'default-1536px' ? "8px 0 8px 16px" : "6px 0 6px 14px",      display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6px"}}    className="rounded-lg bg-transparent hover:bg-slate-200">
                                                                                        <MdOutlineFileDownload    fontSize={screen_width === 'default-1536px' ? 20 : 16.6}/>
                                                                                        Download
                                                                                    </button>
                                                                                </CSVLink>

                                                                                
                                                                                <button    onMouseDown={(e)=>{e.stopPropagation()}}    onClick={()=>{set_Id_Delete_Dataset(obj.objId);  set_Delete_Dataset_FromExp(true);    set_Index_Opened(null)}}    style={{fontSize:screen_width === 'default-1536px' ? "14px" : "12px",      color:"rgb(64,64,64)", textAlign:"start",      padding:screen_width === 'default-1536px' ? "8px 0 8px 16px" : "6px 0 6px 14px",       display:"flex", alignItems:"",      gap:screen_width === 'default-1536px' ? "8px" : "6px"}}    className="rounded-lg bg-transparent hover:bg-slate-200">
                                                                                    <MdDeleteOutline    fontSize={screen_width === 'default-1536px' ? 20 : 16.6}/>
                                                                                    Delete
                                                                                </button>
                                                                            </div>
                                                                    </div>

                                                                </td>
                                                            
                                                            </tr>
                                                        );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>


                                <div style={{ width: "100%",      height:screen_width === 'default-1536px' ? "16%" : "18%",      backgroundColor: "white", display: "flex", justifyContent: "flex-end", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                                    <div style={{ display: "flex", gap: "2rem",      paddingRight:screen_width === 'default-1536px' ? "2rem" : "1.75rem"}}>
                                        
                                        <p style={{ display: "flex", alignItems: "center",    fontSize:screen_width === 'default-1536px' ? "16px" : "14px" }}>
                                            {`${startItem}-${endItem} of ${fileObjs.length}`}
                                        </p>

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <button onClick={handlePrevPage}  disabled={currentPage === 1}            style={{opacity : currentPage===1           ?  "0.3" : "1"}}>         <GoChevronLeft  fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                            <button onClick={handleNextPage}  disabled={currentPage === totalPages}   style={{opacity : currentPage===totalPages  ?  "0.3" : "1"}}>         <GoChevronRight fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        

                        <div  style={{width:"100%", height:"10.5%",      padding:screen_width === 'default-1536px' ? "12px 24px" : "10px 24px",      borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <div>
                                {/* { 
                                    exp_Name.length === 0  ?  <p  style={{color:"#DC2626"}}>Please enter experiment name</p>
                                                        :  !checkedItems.includes(true)  &&  <p  style={{color:"#DC2626"}}>Select atleast 1 dataset to create Experiment</p>
                                } */}
                            </div>

                            <div  style={{display:"flex", gap:"1rem"}}>
                                <button      onClick={()=>{     set_DiscardExperiment(true);    setCurrentExperiment_IsLoaded_FromDB(false)     }}      className="footer-button"      style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}  >Discard</button>
                               
                               {
                                currentExperiment_IsLoaded_FromDB ?  <button      onClick={()=>{          }}                                 className="footer-button"      style={{borderRadius:"7px", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600", opacity:"0.6"}}     >Save Experiment</button>
                                                                  :  <button      onClick={()=>{     set_Save_Experiment(true)       }}      className="footer-button"      style={{borderRadius:"7px", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600"}}     >Save Experiment</button>
                               } 
                            </div>
                        </div>


                    </div>
                :
                    <div  style={{width:"100%", height:"95.5%", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                        {
                
                            chart1.length>0  &&  
                                <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                                    
                                    
                                    <div  style={{width:"calc(100% - 2.5rem)", height:"14%", display:"flex", justifyContent:"space-between", alignItems:"center", borderBottom:"1px solid #E5E5E5"}}>
                                        
                                        <div  style={{display:"flex", alignItems:"center"}}>
                                            <button  onClick={()=>{    setRunModel(false);    }}    style={{display:"flex",      gap:screen_width === 'default-1536px' ? "6px" : "5px",      alignItems:"center"}}>
                                                <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                                                <p  style={{color:"#0000EE", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "18px" : "15.5px"}}>{exp_Name} </p>
                                            </button>

                                            {
                                                selectedObjID!==null  &&  <p  className="fontF"  style={{fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "18px" : "15.5px",      color:"#171717"}}>  &nbsp; / {fileObjs.find(obj => obj.objId === selectedObjID).file_name}</p>
                                            }
                                            
                                        </div>


                                        <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "24px" : "20px"}}>

                                            <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                                <button className={`toggle-container ${showGraph ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "41.8px" : "34.8px",           height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle}>
                                                    <div className="toggle-button"                                        style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                                </button>
                                                <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Graph</p>
                                            </div>

                                            <div style={{ display: "flex", gap: "1px" }}>
                                                <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                                <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                            </div>

                                            <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                                <button className={`toggle-container ${showData ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "42.3px" : "35px",         height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle2}>
                                                    <div className="toggle-button"                                       style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                                </button>
                                                <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Data</p>
                                            </div>

                                        </div>
                                    </div>
                                    

                                    <div  style={{width:"100%", height:"86%", display:"flex", position:"relative"}}>    {/* Position absolute was required to remove jerk when we turn off show only graph */}
                
                    
                                            <div  style={{  width: (!showData && showGraph)    ?    "100%" 
                                                                                               :    (showData && !showGraph)    ?    "0%"
                                                                                                                                :    (!showData && !showGraph) && "70%", 
                                                            display: (showData && !showGraph)  ?    "none"
                                                                                               :    (!showData && !showGraph) && "flex", 

                                                            height:"100%", display:"flex", justifyContent:"center", alignItems:"center", transition: "width 0.2s", position:"absolute", top:"0", left:"0"   }}>
                                                <div  className="line-chart-responsiveness"  style={{borderRadius:"8px"}}>
                                                    <div style={{width: '96%', height:"92%"}}>
                                                        <Line data={dataChart} options={optionChart}/>
                                                    </div>
                                                </div>
                                            </div>    
                    
                                            {
                                            (lift1  &&  stddev1)  &&
                                                <div  style={{  width: (!showData && showGraph)    ?    "0%" 
                                                                                                   :    (showData && !showGraph)    ?    "100%" 
                                                                                                                                    :    (!showData && !showGraph) && "30%", 
                                                                display: (!showData && showGraph)  ?    "none"
                                                                                                   :    (!showData && !showGraph) && "flex", 
                                                                height:"100%",  justifyContent:"flex-start", alignItems:"center", transition: "width 0.2s", paddingLeft:"1.1rem", position:"absolute", top:"0", right:"0"}}>
                    
                    
                                                    <table style={{ width:"88%",      fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>
                                                        <thead>
                                                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                                <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Metric</th>
                                                                <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Value</th>
                                                            </tr>
                                                        </thead>
                        
                                                        <tbody>
                                                            <tr>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift Value</td>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{lift1[0]}</td>                                                                
                                                            </tr>
                                                            <tr>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift Percentage</td>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{(lift1[1] * 100).toFixed(2)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Std Deviation</td>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{(stddev1[0]*100).toFixed(2)}%</td>
                                                            </tr>
                                                            <tr>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift% / Std dev</td>
                                                                <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{((lift1[1] * 100).toFixed(2) / (stddev1[0]*100).toFixed(2)).toFixed(3)}</td>
                                                                
                                                                {
                                                                    ((lift1[1] * 100).toFixed(2) / (stddev1[0]*100).toFixed(2)).toFixed(3) > 2.58    ?    <td  style={{padding:"10px"}}> <div style={{width:"20px", height:"20px", backgroundColor:"green", borderRadius:"5px"}}></div> </td>
                                                                                                                                                     :    (((lift1[1] * 100).toFixed(2) / (stddev1[0]*100).toFixed(2)).toFixed(3) > 1.96     &&     ((lift1[1] * 100).toFixed(2) / (stddev1[0]*100).toFixed(2)).toFixed(3) <= 2.58)    ?    <td  style={{padding:"10px"}}> <div style={{width:"20px", height:"20px", backgroundColor:"#39e75f", borderRadius:"5px"}}></div> </td>
                                                                                                                                                                                                                                                                                                                                       :    (((lift1[1] * 100).toFixed(2) / (stddev1[0]*100).toFixed(2)).toFixed(3) > 1.5     &&     ((lift1[1] * 100).toFixed(2) / (stddev1[0]*100).toFixed(2)).toFixed(3) <= 1.96)    ?    <td  style={{padding:"10px"}}> <div style={{width:"20px", height:"20px", backgroundColor:"orange", borderRadius:"5px"}}></div> </td>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        :    ((lift1[1] * 100).toFixed(2) / (stddev1[0]*100).toFixed(2)).toFixed(3) <= 1.5    &&    <td  style={{padding:"10px"}}> <div style={{width:"20px", height:"20px", backgroundColor:"red", borderRadius:"5px"}}></div> </td>
                                                                }
                                                                
                                                            </tr>
                                                        </tbody>
                                                    </table>
                    
                                                
                                                </div>  
                                            }
                                            
                
                                    </div>

                                    
                                </div>
                        }
                    </div>
            }



            {                                          
                delete_Dataset_FromExp  &&  ( 
                    <div   className={` modal-delete-rows`}   style={{width: screen_width === '1200_1400' && "320px"}}>

                        <div style={{display:"flex", flexDirection:"column", gap:"0.5rem"}}>
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "24px" : "20px"}}>     Are you sure?    </p>    
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "15px" : "13px"}}>     The Selected Dataset will be deleted from the Experiment    </p>    
                        </div>

                        <div    className="discard-business-buttons-container"    style={{display:"flex", justifyContent:"flex-start"}}>
                            <button  onClick={() => { delete_dataset_fromExp_func()}}             className="discard-business-button"     style={{backgroundColor:"red", borderRadius:"5px", color:"white"}}>Delete</button>
                            <button  onClick={() => { set_Delete_Dataset_FromExp(false) }}        className="discard-business-button"     style={{backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                delete_Dataset_FromExp    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {
                
                file_name_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>File Name</p>
                            <button  onClick={()=>{setFile_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedFilename}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                file_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {
                subset_of_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Subset Of</p>
                            <button  onClick={()=>{setSubset_of_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedSubsetof}    </p>    
                
                    </div>
                )
                                        
            }

            {
                subset_of_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }

                   

            {
                description_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}   style={{width: screen_width === '1200_1400' && "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize: screen_width === 'default-1536px' ? "22px" : "19px", fontWeight:"600"}}>Description</p>
                            <button  onClick={()=>{setDescription_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}       color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize: screen_width === 'default-1536px' ? "16px" : "14px",      margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedDescription}    </p>    
                
                
                    </div>
                )
                                        
            }

            {
                description_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {                                          
                discardExperiment  &&  ( 
                    <div   className={` modal-delete-rows`}   style={{width: screen_width === '1200_1400' && "310px"}}>

                        <div style={{display:"flex", flexDirection:"column", gap:"0.5rem"}}>
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "24px" : "20px"}}>     Are you sure?    </p>    
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "15px" : "13px"}}>     The Existing Experiment will be discarded, all the progress you made will be lost  </p>    
                        </div>

                        <div    className="discard-business-buttons-container"    style={{display:"flex", justifyContent:"flex-start"}}>
                            <button  onClick={() => {    setFileObjs([]);    setExp_Name('');    setCurrentExperimentWindow(false);    set_DiscardExperiment(false);    setCreateSubset(false);    }}        className="discard-business-button"      style={{backgroundColor:"red", borderRadius:"5px", color:"white"}}>Discard</button>
                            <button  onClick={() => {    set_DiscardExperiment(false)    }}                                                                                                                  className="discard-business-button"      style={{backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                discardExperiment    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {                                          
                save_Experiment  &&  ( 
                    <div   className={` modal-delete-rows`}   style={{width: screen_width === '1200_1400' && "320px"}}>

                        <div style={{display:"flex", flexDirection:"column", gap:"0.5rem"}}>
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "24px" : "20px"}}>     Are you sure?    </p>    
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "15px" : "13px"}}>     You want to save Experiment named "{exp_Name}" containing {fileObjs.length} files to the DB  </p>    
                        </div>

                        <div     className="discard-business-buttons-container"     style={{display:"flex", justifyContent:"flex-start"}}>
                            <button  onClick={() => {   save_exp_func()               }}     className="discard-business-button"     style={{backgroundColor:"green", borderRadius:"5px", color:"white"}}>Save</button>
                            <button  onClick={() => {   set_Save_Experiment(false);   }}     className="discard-business-button"     style={{backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                save_Experiment    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



        </div>
    );
  }
  
  export default ExperimentInSession;
