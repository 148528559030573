import React, { useState } from "react";
import AccordionTabs3 from "./AccordionTabs3";
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";




function DataConversion3({dimensionsOptions_R,      data3,    states,      salesData_Dates,    checkedItems_RD,    setCheckedItems_RD,    selected_RD,    setSelected_RD,        data_After_Discarding_ExtraData_R,    setdata_After_Discarding_ExtraData_R,    aggregatedData_R,    setAggregatedData_R,    afterRegionalMapping_arr_R,    setAfterRegionalMapping_arr_R,    after_Missing_Dates_and_States_Treatment_R,    setAfter_Missing_Dates_and_States_Treatment_R,    data4,    data2}) {



    const navigate = useNavigate();  


    const data_ = [{    id:1,  TabTitle:"Step 1 - Dataset after retaining the dates corresponding to Sales data"},
                  {    id:2,  TabTitle:"Step 2 - Selection of  Dimensions to group and metrics to be aggregated"},
                  {    id:3,  TabTitle:"Step 3 - Regional Mapping"           },
                  {    id:4,  TabTitle:"Step 4 - Missing Dates and States Treatment" }];

    const [isActiveArr, setIsActiveArr] = useState(Array(0));              
    const handleActive = (id) => {                                         
    setIsActiveArr(    !isActiveArr.includes(id)                         
                ?    isActiveArr.concat([id])                      
                :    isActiveArr.filter((e) => e !== id)    );
    }



    return (
        <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:"2rem", paddingTop:"5rem", position:"relative"}}>


            <div  style={{width:"65%", fontSize:"24px", fontWeight:"bold", display:"flex", justifyContent:"space-between"}}>
                <button        onClick={()=>{  data4.length > 0  ?  navigate("/data-conversion-steps-print")
                                                                 :  data2.length>0  ?  navigate("/data-conversion-steps-tv")  :  navigate("/data-upload")
                  }}        ><IoMdArrowRoundBack /></button>
                <p>Radio Data</p>
                <p></p>
            </div>


            <div  style={{width:"65%", backgroundColor:"#f1f1f1"}}>
                {
                data_.map((section, index) => (
                    <AccordionTabs3    key={index}    section={section}    isActiveArr={isActiveArr}    handleActive={handleActive}
                                       dimensionsOptions_R={dimensionsOptions_R}    data3={data3}    states={states}    salesData_Dates={salesData_Dates}    checkedItems_RD={checkedItems_RD}    setCheckedItems_RD={setCheckedItems_RD}    selected_RD={selected_RD}    setSelected_RD={setSelected_RD}          data_After_Discarding_ExtraData_R={data_After_Discarding_ExtraData_R}    setdata_After_Discarding_ExtraData_R={setdata_After_Discarding_ExtraData_R}    aggregatedData_R={aggregatedData_R}    setAggregatedData_R={setAggregatedData_R}    afterRegionalMapping_arr_R={afterRegionalMapping_arr_R}    setAfterRegionalMapping_arr_R={setAfterRegionalMapping_arr_R}    after_Missing_Dates_and_States_Treatment_R={after_Missing_Dates_and_States_Treatment_R}    setAfter_Missing_Dates_and_States_Treatment_R={setAfter_Missing_Dates_and_States_Treatment_R}/>
                ))
                }
            </div>


            {            
                after_Missing_Dates_and_States_Treatment_R.length>0    &&    <button  onClick={()=>{navigate("/data-conversion-steps-merging");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>                                                                         
            }  


        </div>
    );
}


export default DataConversion3;