
import { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";






function MergeDataMMM({    set_selected_Tab,    data_tv,    data_pr,    data_rd,    data_dg,    data_sl,    setActiveTab,    after_Variables_Aggregation,    formatted_SalesData,    final_merged_Dataset,    set_final_merged_Dataset,    items_to_be_merged_arr,    set_items_to_be_merged_arr }) {







    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';



    const [isHovered_on_Card, setIsHovered_on_Card] = useState('');



    useEffect(() => {
        if(items_to_be_merged_arr.length >= 2){


            const lookup2 = new Map();
            const defaultKeys = Object.keys(after_Variables_Aggregation[0]).filter(key => key !== 'Date' && key !== 'State');

            after_Variables_Aggregation.forEach(item => {
                const key = `${item.Date}|${item.State}`;                
                const { Date, State, ...rest } = item;                   // Exclude Date and State from the object
                lookup2.set(key, rest);
            });

            let output2 = formatted_SalesData.map(item => {                         // Merge the two arrays
                const key = `${item.Date}|${item.State}`;
                console.log("lookup2.has(key) :- ", lookup2.has(key));   // This cannot be false if 100% States are mapped with regions, if it is false then there is something wrong with the logics
                const salesData = lookup2.get(key) || Object.fromEntries(defaultKeys.map(k => [k, 0]));
                return { ...item, ...salesData };
            });

            console.log("final merged Dataset :- ", output2);


            set_final_merged_Dataset(output2);
        }
    },[items_to_be_merged_arr])









    if (data_sl.length===0    ||    (data_tv.length===0  &&  data_pr.length===0  &&  data_rd.length===0  &&  data_dg.length===0)) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>


                <div  style={{width:"100%", height:"100%",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderBottomLeftRadius:"8px", borderBottomRightRadius:"8px",   display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>
                    <svg    style={{width:screen_width === 'default-1536px' ? "290px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </svg>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "28px" : "24.5px", color:"#111827", fontWeight:"600"}}>Upload Required Data</p>

                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Uploading sales data and at least one channel's data is mandatory</p>
                    </div>
                    
                    <div      onClick={()=>{   set_selected_Tab("upload_data")   }}       style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontSize:screen_width === 'default-1536px' ? "18px" : "15px", display:"flex", alignItems:"center", gap:"0.4rem", cursor:"pointer"}}     className="footer-button" >
                        Go to Upload Data Page
                        <FiArrowRight  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                    </div>

                </div>

            </div>
        )
    }




    if((data_tv.length > 0    &&    after_Variables_Aggregation.length===0)    
        //     ||    
        //    (data_pr.length > 0    &&    processed_print_data.length===0)    ||    
        //    (data_rd.length > 0    &&    processed_radio_data.length===0)    ||    
        //    (data_dg.length > 0    &&    processed_digital_data.length===0)
        ){
            return (                          
                <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>
    
    
                    <div  style={{width:"100%", height:"100%",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px",   display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>
                        <svg    style={{width:screen_width === 'default-1536px' ? "290px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                            <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                            <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                            <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                            <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                        </svg>
    
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "28px" : "24.5px", color:"#111827", fontWeight:"600"}}>Process the Uploaded Data</p>
    
                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Processing the uploaded files is mandatory</p>
                        </div>
                        
                        <div      onClick={()=>{   set_selected_Tab("process_data")   }}       style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontSize:screen_width === 'default-1536px' ? "18px" : "15px", display:"flex", alignItems:"center", gap:"0.4rem", cursor:"pointer"}}     className="footer-button" >
                            Go to Process Data Page
                            <FiArrowRight  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                        </div>
    
                    </div>
    
                </div>
            )
    }





    return (
        <div  style={{width:"100%", height:"100%",     padding: screen_width === 'default-1536px' ? "2rem" : "1.6rem",    borderBottomLeftRadius:"8px", borderBottomRightRadius:"8px",   boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", flexDirection:"column", justifyContent:"flex-start",  gap: screen_width === 'default-1536px' ? "2rem" : "1.6rem"}}>
            {/* <p  className="fontF"  style={{fontWeight:"600",      fontSize: screen_width === 'default-1536px' ? "18px" : "16px",      color:"#171717"}}>Select any one to continue the process (Sales Mandatory)</p> */}
            
            <div  style={{width:"100%", height:"auto", backgroundColor:"", display:"flex", justifyContent:"flex-start", gap: screen_width === 'default-1536px' ? "2rem" : "1.6rem"}}>

            
            {
                data_tv.length>0  &&
                <div    onClick={()=>{    setActiveTab('/mmm-data');      set_items_to_be_merged_arr(prevArr => {    if (prevArr.includes('tv')){ return prevArr.filter(item => item !== 'tv'); }    else { return [...prevArr, 'tv']; }    });    }}                     style={{width:"19%",      height:screen_width === 'default-1536px' ? "140px" : "120px",     backgroundColor: items_to_be_merged_arr.includes("tv")  ?  "#E4F1FA"  :  "#FFFFFF",  borderRadius:"8px",        transform: isHovered_on_Card === 'tv' ? "scale(0.97)" : "scale(1)",            boxShadow: isHovered_on_Card === 'tv' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",         transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',      padding:screen_width === 'default-1536px' ? "12px" : "20px",      display:"flex", alignItems:"center", justifyContent:"center", position:"relative", cursor:"pointer"}}    onMouseEnter={() => setIsHovered_on_Card('tv')}     onMouseLeave={() => setIsHovered_on_Card('')}>
                    <div  style={{display:"flex", flexDirection:"column", gap:screen_width === 'default-1536px' ? "5px" : "8.33px",    alignItems:"center", justifyContent:"center"}}>
                        <svg      style={{width:screen_width === 'default-1536px' ? "68px" : "60px",    height:screen_width === 'default-1536px' ? "68px" : "60px"}}   viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M64.5 72.1667H21.5C18.6498 72.1638 15.9171 71.0303 13.9017 69.0149C11.8863 66.9995 10.7528 64.2669 10.75 61.4167V32.75C10.7528 29.8998 11.8863 27.1671 13.9017 25.1517C15.9171 23.1363 18.6498 22.0028 21.5 22H64.5C67.3502 22.0028 70.0829 23.1363 72.0983 25.1517C74.1137 27.1671 75.2472 29.8998 75.25 32.75V61.4167C75.2472 64.2669 74.1137 66.9995 72.0983 69.0149C70.0829 71.0303 67.3502 72.1638 64.5 72.1667Z" fill="#C2E2F5"/>
                            <path d="M35.8297 21.9936H44.1037L38.9365 13.0353C38.4579 12.2189 37.6759 11.6251 36.7611 11.3832C35.8463 11.1413 34.873 11.2711 34.0535 11.7443C33.234 12.2174 32.635 12.9955 32.3871 13.9086C32.1391 14.8218 32.2625 15.796 32.7302 16.6186L35.8297 21.9936ZM21.5 72.1603V75.7436C21.5 76.6939 21.8775 77.6054 22.5495 78.2774C23.2215 78.9494 24.133 79.3269 25.0833 79.3269C26.0337 79.3269 26.9451 78.9494 27.6171 78.2774C28.2891 77.6054 28.6667 76.6939 28.6667 75.7436V72.1603H21.5Z" fill="#1A7AAF"/>
                            <path d="M51.6493 22.0019L59.8659 13.7853C60.2081 13.4547 60.4811 13.0593 60.6689 12.6222C60.8567 12.185 60.9556 11.7148 60.9597 11.239C60.9638 10.7632 60.8732 10.2913 60.693 9.85096C60.5128 9.41058 60.2467 9.01049 59.9103 8.67405C59.5738 8.3376 59.1737 8.07152 58.7334 7.89135C58.293 7.71118 57.8211 7.62051 57.3454 7.62465C56.8696 7.62878 56.3994 7.72763 55.9622 7.91543C55.525 8.10323 55.1296 8.37622 54.799 8.71846L41.5156 22.0019H51.6493ZM57.3325 75.7519C57.3325 76.7022 57.71 77.6137 58.382 78.2857C59.054 78.9577 59.9654 79.3352 60.9158 79.3352C61.8661 79.3352 62.7776 78.9577 63.4496 78.2857C64.1216 77.6137 64.4991 76.7022 64.4991 75.7519V72.1685H57.3325V75.7519Z" fill="#1A7AAF"/>
                        </svg>

                        <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Television</p>
                    </div>
                </div>       
            }                                                                                                                                                                                       

            {
                data_dg.length>0  &&
                <div    onClick={()=>{    setActiveTab('/mmm-data');      set_items_to_be_merged_arr(prevArr => {    if (prevArr.includes('digital')){ return prevArr.filter(item => item !== 'digital'); }    else { return [...prevArr, 'digital']; }    });    }}      style={{width:"19%",      height:screen_width === 'default-1536px' ? "140px" : "120px",     backgroundColor:"#FFFFFF",  borderRadius:"8px",        transform: isHovered_on_Card === 'digital' ? "scale(0.97)" : "scale(1)",       boxShadow: isHovered_on_Card === 'digital' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",    transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',      padding:screen_width === 'default-1536px' ? "12px" : "20px",      display:"flex", alignItems:"center", justifyContent:"center", position:"relative", cursor:"pointer"}}    onMouseEnter={() => setIsHovered_on_Card('digital')}     onMouseLeave={() => setIsHovered_on_Card('')}>
                    <div  style={{display:"flex", flexDirection:"column", gap:screen_width === 'default-1536px' ? "5px" : "8.33px", alignItems:"center", justifyContent:"center"}}>
                        <svg       style={{width:screen_width === 'default-1536px' ? "68px" : "60px",    height:screen_width === 'default-1536px' ? "68px" : "60px"}} viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M42.4687 43.664H24.6719C24.1406 43.664 23.7422 43.2657 23.7422 42.7343C23.7422 42.2031 24.1406 41.672 24.6719 41.672H42.4687C43.0001 41.672 43.5312 42.2031 43.5312 42.7343C43.5312 43.2657 43.0001 43.664 42.4687 43.664ZM25.8672 71.5546H19.625C19.0937 71.5546 18.6953 71.1563 18.6953 70.6251C18.6953 70.0937 19.0937 69.5625 19.625 69.5625H25.4687L30.7813 63.0546C31.0469 62.7891 31.3125 62.6563 31.5781 62.6563H42.4687C43.0001 62.6563 43.5312 63.0546 43.5312 63.586C43.5312 64.1171 43.0001 64.6485 42.4687 64.6485H32.1094L26.6641 71.2891C26.5313 71.422 26.2656 71.5546 25.8672 71.5546ZM42.4687 22.8125H31.5781C31.3125 22.8125 31.0469 22.6797 30.7813 22.4141L25.4687 15.7734H19.625C19.0937 15.7734 18.6953 15.375 18.6953 14.7109C18.6953 14.1797 19.0937 13.7812 19.625 13.7812H25.8672C26.1328 13.7812 26.5313 13.9141 26.6641 14.1797L32.1094 20.8203H42.4687C43.0001 20.8203 43.5312 21.3516 43.5312 21.8828C43.5312 22.4141 43.0001 22.8125 42.4687 22.8125Z" fill="#E4F1FA"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.9609 49.5077C15.1094 49.5077 12.0547 46.4531 12.0547 42.7343C12.0547 39.0157 15.1094 35.9609 18.9609 35.9609C22.6797 35.9609 25.7344 39.0157 25.7344 42.7343C25.7344 46.4531 22.5469 49.5077 18.9609 49.5077ZM14.8437 20.6875C11.6563 20.6875 9 18.0313 9 14.8437C9 11.6563 11.6563 9 14.8437 9C18.0313 9 20.5547 11.6563 20.5547 14.8437C20.5547 18.0313 18.0313 20.6875 14.8437 20.6875ZM14.8437 76.4688C11.6563 76.4688 9 73.8126 9 70.6251C9 67.4374 11.6563 64.7812 14.8437 64.7812C18.0313 64.7812 20.5547 67.4374 20.5547 70.6251C20.5547 73.8126 18.0313 76.4688 14.8437 76.4688Z" fill="#C2E2F5"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M46.9845 77H43.5313H42.4688C41.9553 77 41.5391 76.5838 41.5391 76.0703V75.9374V75.4063C41.5391 53.6251 41.5391 31.8437 41.5391 10.0625C41.5391 9.53125 41.8048 9.13281 42.4688 9.13281H46.9845C47.5156 9.13281 48.047 9.53125 48.047 10.0625V16.5676C48.047 17.5014 48.6959 18.3027 49.5913 18.5679C51.4064 19.1054 53.1494 19.8465 54.7527 20.7575C55.5816 21.2285 56.6337 21.1319 57.3079 20.4578L61.8523 15.9134C61.9453 15.8203 62.0227 15.7101 62.1262 15.6289C62.4395 15.3829 62.978 15.4311 63.3205 15.7734L69.8282 22.2813C69.961 22.4141 70.0939 22.6797 70.0939 22.9453C70.0939 23.2109 69.961 23.4766 69.8282 23.6094L65.1414 28.1897C64.4667 28.8491 64.3526 29.8864 64.7993 30.7174C65.6999 32.3932 66.3924 34.1683 66.9115 36.0075C67.1651 36.9062 67.9674 37.5546 68.9012 37.5546H75.4065C76.0705 37.5546 76.4688 37.9531 76.4688 38.4843V47.6486C76.4688 48.1797 76.0705 48.578 75.4065 48.578H68.9013C67.9674 48.578 67.1651 49.2265 66.9115 50.1252C66.3907 51.9706 65.6954 53.7514 64.7903 55.4321C64.3471 56.2552 64.4539 57.282 65.1149 57.943L69.6953 62.5234C70.0939 62.922 70.0939 63.4531 69.6953 63.8514L63.3205 70.2266C62.9219 70.6251 62.2579 70.6251 61.8596 70.2266L57.279 65.6461C56.6179 64.9851 55.5908 64.8772 54.773 65.33C53.0899 66.2619 51.3065 67.0187 49.4584 67.5652C48.563 67.83 47.9142 68.6314 47.9142 69.5652V76.0703C47.9142 76.4688 47.5156 77 46.9845 77ZM43.5313 56.8043C43.5313 57.9595 44.5127 58.8861 45.6449 58.6564C52.8729 57.1902 58.4065 50.7591 58.4065 43C58.4065 35.2409 52.8729 28.8098 45.6449 27.3436C44.5127 27.1139 43.5313 28.0405 43.5313 29.1957V56.8043Z" fill="#1A7AAF"/>
                        </svg>

                        <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Digital</p>
                    </div>
                </div>
            }

            {
                data_pr.length>0  &&
                <div    onClick={()=>{    setActiveTab('/mmm-data');      set_items_to_be_merged_arr(prevArr => {    if (prevArr.includes('print')){ return prevArr.filter(item => item !== 'print'); }    else { return [...prevArr, 'print']; }    });    }}            style={{width:"19%",      height:screen_width === 'default-1536px' ? "140px" : "120px",     backgroundColor:"#FFFFFF", borderRadius:"8px",         transform: isHovered_on_Card === 'print' ? "scale(0.97)" : "scale(1)",         boxShadow: isHovered_on_Card === 'print' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",      transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',      padding:screen_width === 'default-1536px' ? "12px" : "20px",      display:"flex", alignItems:"center", justifyContent:"center", position:"relative", cursor:"pointer"}}    onMouseEnter={() => setIsHovered_on_Card('print')}     onMouseLeave={() => setIsHovered_on_Card('')}>
                    <div  style={{display:"flex", flexDirection:"column", gap:screen_width === 'default-1536px' ? "5px" : "8.33px", alignItems:"center", justifyContent:"center"}}>
                        <svg         style={{width:screen_width === 'default-1536px' ? "68px" : "60px",    height:screen_width === 'default-1536px' ? "68px" : "60px"}}   viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.9238 45.6484L23.9886 76.6011C23.9899 76.9876 24.0673 77.37 24.2164 77.7265C24.3654 78.083 24.5833 78.4067 24.8575 78.679C25.1316 78.9514 25.4568 79.167 25.8143 79.3137C26.1718 79.4604 26.5547 79.5352 26.9412 79.5339L74.8365 79.3727C75.6169 79.3699 76.3643 79.0574 76.9142 78.5037C77.4641 77.9499 77.7716 77.2005 77.769 76.4201L77.6752 48.5812C77.6725 47.8008 77.3599 47.0535 76.8062 46.5035C76.2525 45.9536 75.503 45.6461 74.7226 45.6487L51.5956 45.7266L23.9238 45.6484Z" fill="#C2E2F5"/>
                        <path d="M39.0331 45.6905L31.3633 48.792L32.3946 52.2529L34.3213 53.7645L46.1504 62.8736C47.4957 63.9036 49.1443 64.4591 50.8387 64.4532C52.533 64.4473 54.1777 63.8804 55.5159 62.841L76.6099 46.3738L76.6397 46.3497C76.1054 45.8938 75.4252 45.6449 74.7229 45.6485L51.596 45.7264L39.0331 45.691" fill="#1A7AAF"/>
                        <path d="M33.7543 52.364C35.3373 56.6149 38.3188 62.6888 43.8081 69.063C44.1665 69.4823 44.4052 69.9904 44.499 70.5339C44.5928 71.0775 44.5383 71.6362 44.3412 72.1514C43.4841 74.4075 40.4737 74.8526 39.0162 72.9292C33.7514 65.9834 27.862 60.5583 23.7083 57.1352L22.0703 55.8483L27.8874 50.2476L33.1354 50.6574L33.7543 52.364Z" fill="#8ECDED"/>
                        <path d="M44.3828 27.9666L57.2452 16.7222" stroke="#1A7AAF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M51.0586 36.9354L62.9682 34.335" stroke="#1A7AAF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M36.5547 17.1309L40.5756 7" stroke="#1A7AAF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M27.4809 55.5157C36.4168 49.9324 46.0942 49.0066 50.9147 48.9352C51.1983 48.9304 51.4752 48.8475 51.7148 48.6957C51.9545 48.5438 52.1477 48.3289 52.2732 48.0745C52.3988 47.8201 52.4518 47.536 52.4265 47.2535C52.4012 46.9709 52.2985 46.7008 52.1298 46.4727L27.9201 13.7155C27.7452 13.4786 27.5063 13.2967 27.2314 13.1911C26.9566 13.0855 26.6573 13.0607 26.3688 13.1196C26.0803 13.1785 25.8147 13.3186 25.6032 13.5235C25.3918 13.7284 25.2433 13.9895 25.1753 14.2759C23.9765 19.3409 20.7553 29.0874 12.7263 36.6815C10.6645 38.6433 9.36746 41.2743 9.06703 44.1044C8.76661 46.9344 9.48235 49.7791 11.0863 52.13C14.7381 57.5044 21.9708 58.9586 27.4809 55.5157Z" fill="#C2E2F5"/>
                        <path d="M22.4316 22.522C23.5836 19.8582 24.5008 17.0989 25.1729 14.2756C25.2409 13.9893 25.3893 13.7283 25.6007 13.5235C25.8121 13.3187 26.0776 13.1786 26.3659 13.1197C26.6543 13.0607 26.9535 13.0854 27.2283 13.1908C27.503 13.2962 27.742 13.478 27.9169 13.7146L52.1276 46.472C52.2963 46.7001 52.3989 46.9702 52.4242 47.2528C52.4494 47.5353 52.3963 47.8194 52.2708 48.0738C52.1452 48.3282 51.952 48.543 51.7123 48.6948C51.4726 48.8466 51.1958 48.9294 50.9121 48.9342C48.1148 48.9806 45.3264 49.2631 42.5765 49.7785L22.4316 22.522Z" fill="#1A7AAF"/>
                        </svg>

                        <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Print</p>
                    </div>
                </div>
            }

            {
                data_rd.length>0  &&
                <div    onClick={()=>{    setActiveTab('/mmm-data');      set_items_to_be_merged_arr(prevArr => {    if (prevArr.includes('radio')){ return prevArr.filter(item => item !== 'radio'); }    else { return [...prevArr, 'radio']; }    });    }}            style={{width:"19%",      height:screen_width === 'default-1536px' ? "140px" : "120px",     backgroundColor:"#FFFFFF",  borderRadius:"8px",        transform: isHovered_on_Card === 'radio' ? "scale(0.97)" : "scale(1)",         boxShadow: isHovered_on_Card === 'radio' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",      transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',      padding:screen_width === 'default-1536px' ? "12px" : "20px",      display:"flex", alignItems:"center", justifyContent:"center", position:"relative", cursor:"pointer"}}    onMouseEnter={() => setIsHovered_on_Card('radio')}     onMouseLeave={() => setIsHovered_on_Card('')}>
                    <div  style={{display:"flex", flexDirection:"column", gap:screen_width === 'default-1536px' ? "5px" : "8.33px", alignItems:"center", justifyContent:"center"}}>
                        <svg         style={{width:screen_width === 'default-1536px' ? "68px" : "60px",    height:screen_width === 'default-1536px' ? "68px" : "60px"}}  viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9145 27.7667C17.2908 27.756 16.6903 27.5286 16.2159 27.1236C15.7416 26.7186 15.4228 26.1611 15.3144 25.5469C15.2059 24.9326 15.3145 24.2998 15.6215 23.7568C15.9286 23.2139 16.4149 22.7946 16.9971 22.5709L56.4138 8.23757C57.0838 7.99428 57.823 8.02711 58.4688 8.32884C59.1146 8.63057 59.6141 9.17648 59.8574 9.84649C60.1007 10.5165 60.0679 11.2557 59.7661 11.9015C59.4644 12.5473 58.9185 13.0468 58.2485 13.2901L18.8318 27.6234C18.5364 27.7231 18.2262 27.7716 17.9145 27.7667Z" fill="#1A7AAF"/>
                        <path d="M68.0846 22.3955H17.918C10.4966 22.3955 4.48047 28.4117 4.48047 35.833V64.4997C4.48047 71.921 10.4966 77.9372 17.918 77.9372H68.0846C75.506 77.9372 81.5221 71.921 81.5221 64.4997V35.833C81.5221 28.4117 75.506 22.3955 68.0846 22.3955Z" fill="#C2E2F5"/>
                        <path d="M68.0846 45.687H50.168C49.4552 45.687 48.7716 45.4039 48.2676 44.8999C47.7636 44.3959 47.4805 43.7123 47.4805 42.9995C47.4805 42.2867 47.7636 41.6032 48.2676 41.0992C48.7716 40.5952 49.4552 40.312 50.168 40.312H68.0846C68.7974 40.312 69.481 40.5952 69.985 41.0992C70.489 41.6032 70.7721 42.2867 70.7721 42.9995C70.7721 43.7123 70.489 44.3959 69.985 44.8999C69.481 45.4039 68.7974 45.687 68.0846 45.687ZM60.918 60.0203H50.168C49.4552 60.0203 48.7716 59.7372 48.2676 59.2332C47.7636 58.7292 47.4805 58.0456 47.4805 57.3328C47.4805 56.6201 47.7636 55.9365 48.2676 55.4325C48.7716 54.9285 49.4552 54.6453 50.168 54.6453H60.918C61.6307 54.6453 62.3143 54.9285 62.8183 55.4325C63.3223 55.9365 63.6055 56.6201 63.6055 57.3328C63.6055 58.0456 63.3223 58.7292 62.8183 59.2332C62.3143 59.7372 61.6307 60.0203 60.918 60.0203Z" fill="#1A7AAF"/>
                        <path d="M28.668 63.604C36.0893 63.604 42.1055 57.5878 42.1055 50.1665C42.1055 42.7452 36.0893 36.729 28.668 36.729C21.2466 36.729 15.2305 42.7452 15.2305 50.1665C15.2305 57.5878 21.2466 63.604 28.668 63.604Z" fill="#1A7AAF"/>
                        <path d="M68.0846 77.9372H17.918C14.3556 77.9324 10.9405 76.5152 8.42146 73.9962C5.90246 71.4772 4.48521 68.0621 4.48047 64.4997V35.833C4.48521 32.2706 5.90246 28.8555 8.42146 26.3365C10.9405 23.8175 14.3556 22.4002 17.918 22.3955H68.0846C71.647 22.4002 75.0622 23.8175 77.5811 26.3365C80.1001 28.8555 81.5174 32.2706 81.5221 35.833V64.4997C81.5174 68.0621 80.1001 71.4772 77.5811 73.9962C75.0622 76.5152 71.647 77.9324 68.0846 77.9372ZM17.918 27.7705C15.7805 27.7734 13.7315 28.6237 12.2201 30.1351C10.7087 31.6465 9.85831 33.6956 9.85547 35.833V64.4997C9.85831 66.6371 10.7087 68.6862 12.2201 70.1976C13.7315 71.709 15.7805 72.5593 17.918 72.5622H68.0846C70.2221 72.5593 72.2711 71.709 73.7825 70.1976C75.2939 68.6862 76.1443 66.6371 76.1471 64.4997V35.833C76.1443 33.6956 75.2939 31.6465 73.7825 30.1351C72.2711 28.6237 70.2221 27.7734 68.0846 27.7705H17.918Z" fill="#1A7AAF"/>
                        </svg>

                        <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Radio</p>
                    </div>
                </div>
            }
                

            </div>

            <div  style={{width:"100%", height:"auto", backgroundColor:"", display:"flex", justifyContent:"space-between"}}>
            {   
                data_sl.length>0  &&
                <div    onClick={()=>{    setActiveTab('/mmm-data');      set_items_to_be_merged_arr(prevArr => {    if (prevArr.includes('sales')){ return prevArr.filter(item => item !== 'sales'); }    else { return [...prevArr, 'sales']; }    });    }}           style={{width:"19%",      height:screen_width === 'default-1536px' ? "140px" : "120px",     backgroundColor: items_to_be_merged_arr.includes("sales")  ?  "#E4F1FA"  :  "#FFFFFF",  borderRadius:"8px",        transform: isHovered_on_Card === 'sales' ? "scale(0.97)" : "scale(1)",         boxShadow: isHovered_on_Card === 'sales' ? "-8px -8px 12px 0px #AEAEC080, 8px 8px 12px 0px #AEAEC080" : "-6px -6px 20px 0px #21438426, 6px 6px 20px 0px #21438426",      transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',      padding:screen_width === 'default-1536px' ? "12px" : "20px",      display:"flex", alignItems:"center", justifyContent:"center", position:"relative", cursor:"pointer"}}    onMouseEnter={() => setIsHovered_on_Card('sales')}     onMouseLeave={() => setIsHovered_on_Card('')}>
                    <div  style={{display:"flex", flexDirection:"column", gap:screen_width === 'default-1536px' ? "5px" : "8.33px", alignItems:"center", justifyContent:"center"}}>
                        <svg        style={{width:screen_width === 'default-1536px' ? "68px" : "60px",    height:screen_width === 'default-1536px' ? "68px" : "60px"}}  viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.1562 14.7812H68.5312V55.0938H20.1562V14.7812Z" fill="#C2E2F5"/>
                        <path d="M33.6348 14.7814V11.4062C33.6348 10.3017 34.5302 9.40625 35.6348 9.40625H53.1751C54.2796 9.40625 55.1751 10.3017 55.1751 11.4062V14.7814" fill="#C2E2F5"/>
                        <path d="M33.6348 14.7814V11.4062C33.6348 10.3017 34.5302 9.40625 35.6348 9.40625H53.1751C54.2796 9.40625 55.1751 10.3017 55.1751 11.4062V14.7814" stroke="#1A7AAF" strokeWidth="2.6875"/>
                        <path d="M20.1562 14.7812H68.5312V55.0938H20.1562V14.7812Z" stroke="#1A7AAF" strokeWidth="2.6875"/>
                        <path d="M10.75 14.7812H77.9375M10.75 55.0938H77.9375M44.3438 56.4375V76.5721M44.3438 68.4935L37.625 75.25M44.3196 68.4935L51.0625 75.25M53.9919 24.2006C54.1168 27.2367 53.5536 30.2618 52.3445 33.0495C51.1354 35.8372 49.3117 38.3156 47.0098 40.299C44.7079 42.2824 41.9874 43.7195 39.0517 44.5032C36.116 45.2869 33.0411 45.3969 30.057 44.8247" stroke="#1A7AAF" strokeWidth="2.6875"/>
                        <path d="M49.9055 26.7782L54.0308 22.8438L57.9653 26.9688M38.7389 24.3968L31.1387 31.9971M38.7389 31.9971L31.1387 24.3968" stroke="#1A7AAF" strokeWidth="2.6875"/>
                        <path d="M59.125 49.7188C61.3514 49.7188 63.1562 47.9139 63.1562 45.6875C63.1562 43.4611 61.3514 41.6562 59.125 41.6562C56.8986 41.6562 55.0938 43.4611 55.0938 45.6875C55.0938 47.9139 56.8986 49.7188 59.125 49.7188Z" fill="#C2E2F5" stroke="#1A7AAF" strokeWidth="2.6875"/>
                        </svg>

                        <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Sales</p>
                    </div>
                </div>
            }

            </div>



        </div>
    );
  }
  
  export default MergeDataMMM;