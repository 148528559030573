import { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { MdOutlineDelete } from "react-icons/md";
import { send_Business_Setup_Data_to_DB } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import "../../../CSS/ComponentsStyling.css";





function AddNew({setAddNew_btn_Clicked,    setIsHovered_on_Add_New,    business_setup_arr,    count,    setCount,    setLoading}) {




    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';




    useEffect(()=>{
        Create_EmptyRow_Func();
    },[])


    const[arr, setArr] = useState([]);
    function CreateArrayOf_EmptyObject() {      
        return {
          business: "",
          category: "",
          product: ""
        };
    }    
    
    function Create_EmptyRow_Func() {
        const newObj = CreateArrayOf_EmptyObject(); // Create a single empty object
        setArr(prevState => [...prevState, newObj]); // Add the empty object to the state array
    }



    const handleCellEdit = (newValue, rowIndex, fieldName) => {   // Function to handle cell edit
        setArr(prevState =>
            prevState.map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, [fieldName]: newValue };
                }
                return row;
            })
        );
    };


    const [isHovered_on_del_row, setIsHovered_on_del_row] = useState(-1);
    function deleteRow(index){
        const demoArr = arr.filter((obj, indx) => indx !== index);
        setArr(demoArr);
    }




    const [emptyCells, setEmptyCells] = useState(true);
    const [atleastOneCell_isNonEmpty, setAtleastOneCell_isNonEmpty] = useState(true);
    useEffect(()=>{
        if(arr.length>0){
            // console.log("arr :- ", arr);

            const temp = arr.some((row) =>  Object.values(row).some((value) => value === "")   );
            setEmptyCells(temp);

            const anyValuePresent = arr.some(item  =>  item.business || item.category || item.product);
            setAtleastOneCell_isNonEmpty(anyValuePresent);
        }
    },[arr]);





    
    function saveNewBusinesses_func(){
        const mergedArray = [...business_setup_arr, ...arr];
        console.log("mergedArray :- ", mergedArray);
        upload_Business_Setup_Data_to_DB(mergedArray)
    }

    const upload_Business_Setup_Data_to_DB = async (mergedArray) => {     
        setLoading(true);   
        try {
          const res = await send_Business_Setup_Data_to_DB(mergedArray);          
          console.log("Response of send_Business_Setup_Date_to_DB:- ", res);
          setCount(count+1);
          setAddNew_btn_Clicked(false);  
          setArr([]);  
          setIsHovered_on_Add_New(false);
        } 
        catch (error) {
          console.log("Could not Upload data to DB");
        }
        setLoading(false);
    }





    const [discardBusinesses_Confirmation, setDiscardBusinesses_Confirmation] = useState(false);
    
    function discard_businesses_func(){
        setAddNew_btn_Clicked(false);  
        setArr([]);  
        setIsHovered_on_Add_New(false);
    }





    return (
        <>

            <div    className="header-container"    style={{ width: "100%", height: "9%",    padding: screen_width === 'default-1536px' ? "0 24px" : "0 20px",    background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p    className="header-title" style={{ fontWeight: "600" }}>Business Setup</p>
                <div  className="fontF">DP(Name and Role)</div>
            </div>


            <div  style={{width:"100%", height:"10%", display:"flex", alignItems:"center"}}>
                <button    className="back-navigation-business-setup"    onClick={()=>{    if (!atleastOneCell_isNonEmpty){setAddNew_btn_Clicked(false);  setArr([]);  setIsHovered_on_Add_New(false);} else {setDiscardBusinesses_Confirmation(true);}  }}     style={{color:"#6E6E6E", display:"flex"}}>
                    <IoMdArrowBack   fontSize={screen_width === 'default-1536px' ? 22 : 18.5}   color="#0000EE"/>
                    <span  style={{color:"#0000EE", fontWeight:"400"}}>Business Setup</span>
                </button>

                <p  className="add-new-text"  style={{color:"#6E6E6E"}}> &nbsp; / &nbsp; Add New</p>
            </div>



            <div    className="gap-between-containers"    style={{width:"100%", height:"72.75%", display:"flex", flexDirection:"column",   overflowY: screen_width === 'default-1536px'   ?   arr.length>3 ? "auto" : "none"
                                                                                                                                                                                       :   arr.length>2 ? "auto" : "none",    paddingBottom: screen_width === 'default-1536px'    ?    arr.length>3 ? "2rem" : "0"
                                                                                                                                                                                                                                                                                  :    arr.length>2 ? "2rem" : "0"}}>
                {
                    arr.map((obj, index) => (
                        <div   className="add-new-business-input-container"   key={index} style={{display:"flex", flexDirection:"column", borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1"}}>
                            
                            
                            <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                                <div  style={{width:"30%", position:"relative"}}>
                                    <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Business</h2>
                                    <input    type="text"    value={arr[index].business}    onChange={(e) => handleCellEdit(e.target.value, index, 'business')}    
                                                                                            className="input-field2"
                                                                                            placeholder="Business"/>
                                </div>

                                <div  style={{width:"30%", position:"relative"}}>
                                    <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Category</h2>
                                    <input    type="text"    value={arr[index].category}    onChange={(e) => handleCellEdit(e.target.value, index, 'category')}   
                                                                                            className="input-field2" 
                                                                                            placeholder="Category"/>
                                </div>

                                <div  style={{width:"30%", position:"relative"}}>
                                    <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Product</h2>
                                    <input    type="text"    value={arr[index].product}     onChange={(e) => handleCellEdit(e.target.value, index, 'product')} 
                                                                                            className="input-field2"    
                                                                                            placeholder="Product"/>
                                </div>
                            </div>


                            <div  style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                                <div     onClick={()=> { deleteRow(index) }}     style={{display:"flex", alignItems:"center", gap:"0.4rem", padding:"0.4rem 0.7rem", borderRadius:"5px", cursor:"pointer", boxShadow: isHovered_on_del_row === index? "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1" : "none", transition: 'box-shadow 0.2s ease-in-out'}}    onMouseEnter={() => setIsHovered_on_del_row(index)}     onMouseLeave={() => setIsHovered_on_del_row(-1)}>
                                    
                                    <div  style={{display:"flex", alignItems:"center"}}>    
                                        <MdOutlineDelete color="#DC2626" fontSize={20}/>    
                                    </div>  

                                    <p  className="delete-business"  style={{color:"#DC2626"}}>Delete</p>

                                </div>
                            </div>  


                        </div>
                    ))
                }

                <button  onClick={()=>{Create_EmptyRow_Func()}}  style={{width:"100%", height:"10%", borderRadius:"8px", color:"#1A7AAF", fontWeight:"600", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1"}}    className="add-new-button" >Add New</button>
            </div>



            <div  className="cancel-save-container"   style={{width:"100%", height:"8.25%", borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                
                <div>
                    {
                        emptyCells  &&  <p    className="error-texts"  style={{color:"#DC2626"}}>Empty cells detected, Please enter the value in empty cells in order to save it</p>
                    }
                </div>

                <div  style={{display:"flex", gap:"1rem"}}>
                    <button      onClick={()=>{   if (!atleastOneCell_isNonEmpty){setAddNew_btn_Clicked(false);  setArr([]);  setIsHovered_on_Add_New(false);} else {setDiscardBusinesses_Confirmation(true);}  }}     style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF"}}    className="footer-button" >Cancel</button>
                    <button      onClick={()=>{saveNewBusinesses_func()}}                  disabled={emptyCells}                                                                                                       style={{borderRadius:"7px", backgroundColor:"#1A7AAF", color:"white", opacity: emptyCells ? "0.6" : "1"}}    className="footer-button" >Save</button>
                </div>
            </div>







            {
                                          
                discardBusinesses_Confirmation  &&  ( 
                    <div   className={` modal-delete-rows`}  style={{width: screen_width === '1200_1400' && "300px"}}>

                        <div style={{display:"flex", flexDirection:"column", gap:"0.5rem"}}>
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "24px" : "20px"}}>     Are you sure?    </p>    
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "15px" : "12px"}}>     The Created businesses will be discarded    </p>    
                        </div>

                        <div  className="discard-business-buttons-container"  style={{display:"flex", justifyContent:"flex-start"}}>
                            <button  onClick={() => { discard_businesses_func()}}                    className="discard-business-button"      style={{backgroundColor:"red", borderRadius:"5px", color:"white"}}>Discard</button>
                            <button  onClick={() => { setDiscardBusinesses_Confirmation(false) }}    className="discard-business-button"      style={{backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                discardBusinesses_Confirmation    &&    (<div   className={` overlay-delete-rows `}></div>)
            }




        </>
    );
}

export default AddNew;