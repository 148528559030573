
export const sampleData2 = [
    {Date: '2024-01-01', Actual: '7379.830791', Fitted: '7232.234175'},
{Date: '2024-01-02', Actual: '7297.548525', Fitted: '7005.646584'},
{Date: '2024-01-03', Actual: '4563.802383', Fitted: '4472.526335'},
{Date: '2024-01-04', Actual: '7277.635746', Fitted: '7495.964819'},
{Date: '2024-01-05', Actual: '7105.487886', Fitted: '6963.378128'},
{Date: '2024-01-06', Actual: '6051.527035', Fitted: '5991.011765'},
{Date: '2024-01-07', Actual: '5597.327955', Fitted: '5653.301235'},
{Date: '2024-01-08', Actual: '7517.544209', Fitted: '7367.193325'},
{Date: '2024-01-09', Actual: '7857.096325', Fitted: '8249.951141'},
{Date: '2024-01-10', Actual: '7326.498354', Fitted: '7399.763337'},
{Date: '2024-01-11', Actual: '7106.753644', Fitted: '7319.956253'},
{Date: '2024-01-12', Actual: '7280.330024', Fitted: '6843.510223'},
{Date: '2024-01-13', Actual: '6673.206005', Fitted: '6673.206005'},
{Date: '2024-01-14', Actual: '6033.816488', Fitted: '6516.521807'},
{Date: '2024-01-15', Actual: '8124.65403', Fitted: '7393.435167'},
{Date: '2024-01-16', Actual: '8211.425926', Fitted: '7882.968889'},
{Date: '2024-01-17', Actual: '7847.654238', Fitted: '8161.560407'},
{Date: '2024-01-18', Actual: '7661.730012', Fitted: '7968.199213'},
{Date: '2024-01-19', Actual: '4464.474482', Fitted: '4776.987696'},
{Date: '2024-01-20', Actual: '6490.844311', Fitted: '6685.56964'},
{Date: '2024-01-21', Actual: '6116.393554', Fitted: '5932.901747'},
{Date: '2024-01-22', Actual: '8238.781888', Fitted: '8733.108801'},
{Date: '2024-01-23', Actual: '8285.940098', Fitted: '8700.237103'},
{Date: '2024-01-24', Actual: '7920.104875', Fitted: '7444.898583'},
{Date: '2024-01-25', Actual: '7472.476907', Fitted: '7024.128293'},
{Date: '2024-01-26', Actual: '7664.125041', Fitted: '8277.255044'},
{Date: '2024-01-27', Actual: '6946.340904', Fitted: '6251.706814'},
{Date: '2024-01-28', Actual: '6223.229757', Fitted: '6160.99746'},
{Date: '2024-01-29', Actual: '8144.820226', Fitted: '7411.786406'},
{Date: '2024-01-30', Actual: '5255.115607', Fitted: '5202.564451'},
{Date: '2024-01-31', Actual: '7779.044825', Fitted: '7779.044825'},
{Date: '2024-02-01', Actual: '7880.849898', Fitted: '7802.041399'},
{Date: '2024-02-02', Actual: '7746.685551', Fitted: '8211.486684'},
{Date: '2024-02-03', Actual: '5714.080882', Fitted: '5656.940073'},
{Date: '2024-02-04', Actual: '5139.628382', Fitted: '5499.402369'},
{Date: '2024-02-05', Actual: '6969.63726', Fitted: '6551.459024'},
{Date: '2024-02-06', Actual: '6998.311785', Fitted: '6438.446842'},
{Date: '2024-02-07', Actual: '6590.911365', Fitted: '6327.27491'},
{Date: '2024-02-08', Actual: '6301.440526', Fitted: '6427.469337'},
{Date: '2024-02-09', Actual: '6149.469349', Fitted: '5534.522414'},
{Date: '2024-02-10', Actual: '5127.965166', Fitted: '4717.727953'},
{Date: '2024-02-11', Actual: '4699.830982', Fitted: '4229.847883'},
{Date: '2024-02-12', Actual: '6639.768148', Fitted: '6241.38206'},
{Date: '2024-02-13', Actual: '6750.907846', Fitted: '7425.998631'},
{Date: '2024-02-14', Actual: '6109.073603', Fitted: '5986.892131'},
{Date: '2024-02-15', Actual: '6132.69974', Fitted: '5764.737756'},
{Date: '2024-02-16', Actual: '6042.151446', Fitted: '5558.77933'},
{Date: '2024-02-17', Actual: '5049.390489', Fitted: '4695.933155'},
{Date: '2024-02-18', Actual: '4641.091349', Fitted: '4548.269522'},
{Date: '2024-02-19', Actual: '6595.73256', Fitted: '6925.519188'},
{Date: '2024-02-20', Actual: '6936.206595', Fitted: '7283.016925'},
{Date: '2024-02-21', Actual: '6725.525688', Fitted: '6120.228376'},
{Date: '2024-02-22', Actual: '6361.547621', Fitted: '6234.316669'},
{Date: '2024-02-23', Actual: '6324.46182', Fitted: '6640.684911'},
{Date: '2024-02-24', Actual: '5994.016708', Fitted: '6413.597878'},
{Date: '2024-02-25', Actual: '5473.597132', Fitted: '5911.484903'},
{Date: '2024-02-26', Actual: '7406.126221', Fitted: '6813.636123'},
{Date: '2024-02-27', Actual: '7361.083429', Fitted: '7066.640092'},
{Date: '2024-02-28', Actual: '6985.472202', Fitted: '6356.779703'},
{Date: '2024-02-29', Actual: '6699.799837', Fitted: '6565.803841'},
{Date: '2024-03-01', Actual: '6438.140255', Fitted: '7081.954281'},
{Date: '2024-03-02', Actual: '5833.760259', Fitted: '5600.409848'},
{Date: '2024-03-03', Actual: '5506.559138', Fitted: '5010.968816'},
{Date: '2024-03-04', Actual: '7244.315139', Fitted: '6519.883625'},
{Date: '2024-03-05', Actual: '7020.116563', Fitted: '6318.104907'},
{Date: '2024-03-06', Actual: '6648.649761', Fitted: '7047.568747'},
{Date: '2024-03-07', Actual: '6414.739964', Fitted: '6350.592564'},
{Date: '2024-03-08', Actual: '6262.500312', Fitted: '6199.875309'},
{Date: '2024-03-09', Actual: '5648.386562', Fitted: '5761.354293'},
{Date: '2024-03-10', Actual: '5270.033516', Fitted: '5006.53184'},
{Date: '2024-03-11', Actual: '7389.748675', Fitted: '6946.363754'},
{Date: '2024-03-12', Actual: '6983.867818', Fitted: '6774.351784'},
{Date: '2024-03-13', Actual: '6442.945646', Fitted: '6378.51619'},
{Date: '2024-03-14', Actual: '6072.625057', Fitted: '5768.993804'},
{Date: '2024-03-15', Actual: '5861.867293', Fitted: '5627.392601'},
{Date: '2024-03-16', Actual: '5092.759165', Fitted: '4685.338432'},
{Date: '2024-03-17', Actual: '4661.366986', Fitted: '5034.276345'},
{Date: '2024-03-18', Actual: '6579.605152', Fitted: '6711.197255'},
{Date: '2024-03-19', Actual: '6613.011935', Fitted: '6017.840861'},
{Date: '2024-03-20', Actual: '6432.715253', Fitted: '6239.733795'},
{Date: '2024-03-21', Actual: '6407.601164', Fitted: '6984.285269'},
{Date: '2024-03-22', Actual: '6232.038938', Fitted: '6169.718549'},
{Date: '2024-03-23', Actual: '5575.544285', Fitted: '5519.788842'},
{Date: '2024-03-24', Actual: '5259.192902', Fitted: '4996.233257'},
{Date: '2024-03-25', Actual: '7200.326732', Fitted: '7488.339801'},
{Date: '2024-03-26', Actual: '7085.762776', Fitted: '7156.620403'},
{Date: '2024-03-27', Actual: '6759.013538', Fitted: '6961.783945'},
{Date: '2024-03-28', Actual: '6626.180163', Fitted: '6294.871155'},
{Date: '2024-03-29', Actual: '6388.96467', Fitted: '6900.081844'},
{Date: '2024-03-30', Actual: '5667.316372', Fitted: '6120.701682'},
{Date: '2024-03-31', Actual: '5215.169992', Fitted: '5632.383591'},
{Date: '2024-04-01', Actual: '7172.361463', Fitted: '7602.703151'},
{Date: '2024-04-02', Actual: '6820.82741', Fitted: '7366.493603'},
{Date: '2024-04-03', Actual: '6320.665339', Fitted: '5878.218766'},
{Date: '2024-04-04', Actual: '6065.855898', Fitted: '5519.928867'},
{Date: '2024-04-05', Actual: '5878.025692', Fitted: '5349.00338'},
{Date: '2024-04-06', Actual: '5126.113233', Fitted: '5433.680027'},
{Date: '2024-04-07', Actual: '4707.617803', Fitted: '4942.998693'},
{Date: '2024-04-08', Actual: '6635.528534', Fitted: '6303.752107'},
{Date: '2024-04-09', Actual: '6439.43798', Fitted: '6890.198639'},
{Date: '2024-04-10', Actual: '6055.858536', Fitted: '5934.741365'},
{Date: '2024-04-11', Actual: '5803.54499', Fitted: '6267.828589'},
{Date: '2024-04-12', Actual: '5681.292511', Fitted: '6192.608837'},
{Date: '2024-04-13', Actual: '4978.563347', Fitted: '4679.849546'},
{Date: '2024-04-14', Actual: '4596.95539', Fitted: '4137.259851'},
{Date: '2024-04-15', Actual: '6552.531723', Fitted: '6814.632992'},
{Date: '2024-04-16', Actual: '6377.190372', Fitted: '6504.73418'},
{Date: '2024-04-17', Actual: '6009.17283', Fitted: '5708.714189'},
{Date: '2024-04-18', Actual: '5768.530711', Fitted: '5883.901325'},
{Date: '2024-04-19', Actual: '5655.031802', Fitted: '5881.233074'},
{Date: '2024-04-20', Actual: '4958.867815', Fitted: '4760.513103'},
{Date: '2024-04-21', Actual: '4582.183741', Fitted: '4811.292928'},
{Date: '2024-04-22', Actual: '6541.452986', Fitted: '6410.623926'},
{Date: '2024-04-23', Actual: '6368.88132', Fitted: '6496.258946'},
{Date: '2024-04-24', Actual: '6002.94104', Fitted: '6423.146913'},
{Date: '2024-04-25', Actual: '5763.856869', Fitted: '5533.302594'},
{Date: '2024-04-26', Actual: '5651.526421', Fitted: '5481.980628'},
{Date: '2024-04-27', Actual: '4956.238779', Fitted: '4757.989228'},
{Date: '2024-04-28', Actual: '4580.211964', Fitted: '4763.420442'},
{Date: '2024-04-29', Actual: '6539.974154', Fitted: '7193.971569'},
{Date: '2024-04-30', Actual: '6367.772195', Fitted: '5730.994975'}
];
