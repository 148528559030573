import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";
import { IoMdArrowRoundBack } from "react-icons/io";
import { sendDataTo_R_API_MMM } from "../../Upload_Retrieve_DB_Data/ProfileAPI";



function DataMerging({salesData_Dates,    states,    data,        wideFormatData,    after_flipping_the_Positions_Horizontally,    after_merging_the_Positions,    after_Missing_Dates_and_States_Treatment_R,    data2,    data3,    data4}) {



    // console.log("data :- ", data);
    // console.log("wideFormatData :- ", wideFormatData);
    // console.log("after_flipping_the_Positions_Horizontally :- ", after_flipping_the_Positions_Horizontally);
    // console.log("after_merging_the_Positions :- ", after_merging_the_Positions);
    // console.log("after_Missing_Dates_and_States_Treatment_R :- ", after_Missing_Dates_and_States_Treatment_R);





    const [formatted_SalesData, set_formatted_SalesData] = useState([]);

    useEffect(()=>{
        if(data.length    >    0){
            const sortedStates = [...states].sort();     // We are assuming that Dates will be sorted, so we are not sorting them now, if required in future then we will also sort them


            const lookup = new Map();
            data.forEach(item => {
                const key = `${item.Date}|${item.State}`;
                lookup.set(key, parseFloat(item.Sales) || 0);
            });
            // console.log("lookup :- ", lookup);


            const output = [];
            salesData_Dates.forEach(date => {
                sortedStates.forEach(state => {
                        
                    const key = `${date}|${state}`;
                    output.push({  Date: date,    State: state,    Sales: lookup.get(key) || 0  });
                      
                });
            });


            console.log("output :- ", output);
            set_formatted_SalesData(output);
        }
    },[data]);


    useEffect(()=>{
        if(formatted_SalesData.length > 0){
            // console.log("formatted_SalesData :- ", formatted_SalesData);
        }
    },[formatted_SalesData]);






    const [final_merged_Dataset, set_final_merged_Dataset] = useState([]);

    useEffect(()=>{
        if(formatted_SalesData.length > 0){


            const lookup = new Map();                        // Merging TV Data with Sales Data

            wideFormatData.forEach(item => {
                const key = `${item.Date}|${item.State}`;                
                const { Date, State, ...rest } = item;                       // Exclude Date and State from the object
                lookup.set(key, rest);
            });

            const output = formatted_SalesData.map(item => {                 // Merge the two arrays
                const key = `${item.Date}|${item.State}`;
                console.log("lookup.has(key) :- ", lookup.has(key));         // This cannot be false, if it is false then there is something wrong with the logics
                const salesData = lookup.get(key) || {};
                return { ...item, ...salesData };
            });

            console.log("output :- ", output);






            let output2;
            if(after_merging_the_Positions.length === 0){    // Merging Print Data with already merged TV Data+Sales Data
                const lookup2 = new Map();

                after_flipping_the_Positions_Horizontally.forEach(item => {
                    const key = `${item.Date}|${item.State}`;                
                    const { Date, State, ...rest } = item;                   // Exclude Date and State from the object
                    lookup2.set(key, rest);
                });

                output2 = output.map(item => {                         // Merge the two arrays
                    const key = `${item.Date}|${item.State}`;
                    console.log("lookup2.has(key) :- ", lookup2.has(key));   // This cannot be false, if it is false then there is something wrong with the logics
                    const salesData = lookup2.get(key) || {};
                    return { ...item, ...salesData };
                });

                console.log("output2 :- ", output2);
            }
            else{
                const lookup2 = new Map();

                after_merging_the_Positions.forEach(item => {
                    const key = `${item.Date}|${item.State}`;                
                    const { Date, State, ...rest } = item;                   // Exclude Date and State from the object
                    lookup2.set(key, rest);
                });

                output2 = output.map(item => {                         // Merge the two arrays
                    const key = `${item.Date}|${item.State}`;
                    console.log("lookup2.has(key) :- ", lookup2.has(key));   // This cannot be false, if it is false then there is something wrong with the logics
                    const salesData = lookup2.get(key) || {};
                    return { ...item, ...salesData };
                });

                console.log("output2 :- ", output2);
            }







            const lookup3 = new Map();                        // Merging Radio Data with already merged TV+Sales+Print Data

            after_Missing_Dates_and_States_Treatment_R.forEach(item => {
                const key = `${item.Date}|${item.State}`;                
                const { Date, State, ...rest } = item;                       // Exclude Date and State from the object
                lookup3.set(key, rest);
            });

            const output3 = output2.map(item => {                            // Merge the two arrays
                const key = `${item.Date}|${item.State}`;
                console.log("lookup3.has(key) :- ", lookup3.has(key));       // This cannot be false, if it is false then there is something wrong with the logics
                const salesData = lookup3.get(key) || {};
                return { ...item, ...salesData };
            });

            console.log("output3 :- ", output3);
            set_final_merged_Dataset(output3);

        }
    },[formatted_SalesData]);








    const navigate = useNavigate();  








    const [headers2, setHeaders2] = useState([]);
    useEffect(()=>{
        if(final_merged_Dataset.length > 0){
            const formattedData = Object.keys(final_merged_Dataset[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            setHeaders2(formattedData);
        }
    },[final_merged_Dataset]);
















    const [R_output_, set_R_output_] = useState(null);
    const arrayToCSV = (arr) => {               // Function to convert array of objects to CSV string
        const csv = Papa.unparse(arr); 
        return csv;
    };
    const csvToBlob = (csv) => {                // Function to convert CSV string to Blob
        const blob = new Blob([csv], { type: 'text/csv' });
        return blob;
    };
    const blobToFile = (blob, fileName) => {    // Function to convert Blob to File
        const file = new File([blob], fileName, { type: 'text/csv' });
        return file;
    };



    async function run_model_func(){


            const csvString = arrayToCSV(final_merged_Dataset);
            const csvBlob = csvToBlob(csvString);
            const csvFile = blobToFile(csvBlob, "final_merged_Dataset");

            const formData = new FormData();
            formData.append('file', csvFile);

  
            const R_output = await sendDataTo_R_API_MMM(formData);
            
            if(R_output){
                console.log("R_output :- ", R_output);
                set_R_output_(R_output);               
            }
        
  
    }








    return (

        <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:"2rem", paddingTop:"5rem", position:"relative", overflowY:"scroll"}}>


            <div  style={{width:"65%", fontSize:"24px", fontWeight:"bold", display:"flex", justifyContent:"space-between"}}>
                <button        onClick={()=>{  data3.length > 0  ?  navigate("/data-conversion-steps-radio")
                                                                 :  data4.length > 0  ?  navigate("/data-conversion-steps-print")
                                                                                      :  data2.length > 0  ?  navigate("/data-conversion-steps-tv")
                                                                                                           :  navigate("/")
                  }}        ><IoMdArrowRoundBack /></button>
                <p>Merged Data</p>
                <p></p>
            </div>


            <div>
                {   
                    final_merged_Dataset.length > 0  &&
                    
                    <div  style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"400px", border:"2px solid #f1f1f1", padding:"1rem", borderRadius:"5px"}}>
                        <CSVLink  data={final_merged_Dataset}    headers={headers2}    filename={"final_merged_Dataset"}>
                            <MdOutlineFileDownload  color="black" fontSize={30}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                        </CSVLink>

                        <button    onClick={()=>{run_model_func()}}    style={{backgroundColor:"teal", padding:"5px 20px", borderRadius:"5px", fontWeight:"600", color:"white", fontSize:"18px"}}>Run R code for MMM</button>
                    </div>
                }
            </div>


{
    R_output_ !== null &&

            <table  className="table-font"  style={{ width: "80%", backgroundColor: "white", color:"#171717"  }}>
                <thead  style={{width:"100%"}}>
                        <tr style={{ backgroundColor: "#E4F1FA", width:"100%"}}>
                            <th className="cell2X"   style={{width:"15.5%", padding:"0 0 0 40px"}}>Date</th>
                            <th className="cell2X"   style={{width:"15.5%"}}>Geo</th>
                            <th className="cell2X"   style={{width:"15.5%"}}>Sales</th>
                            <th className="cell2X"   style={{width:"15.5%"}}>Fitted Values</th>
                        </tr>
                </thead>

                <tbody  style={{width:"100%"}}>

                    {
                            R_output_.fitted_values.map((obj, index) => {

                                return(
                        
                                    <tr key={index}  style={{width:"100%"}}>
                                        <td className="cell2"     style={{width:"15.5%", padding:"10px 0 10px 40px"}}> {obj.model_period} </td>
                                        <td className="cell2"     style={{width:"15.5%"}}> {obj.geo} </td>
                                        <td className="cell2"     style={{width:"15.5%"}}> {obj.Sales} </td>
                                        <td className="cell2"     style={{width:"15.5%"}}> {obj.fitted_values} </td>
                                    </tr>

                                );
                            })
                    }

                </tbody>
            </table>
}

        </div>
    );
}


export default DataMerging;
