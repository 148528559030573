import "./App.css";
import Navigation from "./Components/Navigation";
import useWindowDimensions from './useWindowDimensions';          // Using it for responsiveness in terms of height
import { Route, Routes } from 'react-router-dom';
import { useState, useEffect } from "react";

import Papa from "papaparse";
import Dashboard from "./Components/Dashboard";
import ExperimentAndData from "./Components/GeoExperiment/ExperimentAndData";
import DataSubset from "./Components/GeoExperiment/DataSubset";
import PlaceboAnalysis from "./Components/GeoExperiment/PlaceboAnalysis";
import PowerOfTest from "./Components/GeoExperiment/PowerOfTest";
import ProductA from "./Components/Attribution/ProductA";
import ProductB from "./Components/Attribution/ProductB";
import ProductC from "./Components/Attribution/ProductC";
import UserManagement from "./Components/ClientOnboarding/UserManagement";
import DataIntegrations from "./Components/ClientOnboarding/DataIntegrations";
import BusinessSetup from "./Components/ClientOnboarding/BusinessSetup";
import SelectIdPage from "./Components/ClientOnboarding/ChildComponents/SelectIdPage";
import SelectionStatus from "./Components/ClientOnboarding/ChildComponents/SelectionStatus";

import { getDataFrom_Business_Setup_Table  } from "./Upload_Retrieve_DB_Data/ProfileAPI";
import { getDataFrom_File_Data_Table } from "./Upload_Retrieve_DB_Data/ProfileAPI";
import { getDataFrom_Experiment_Table  } from "./Upload_Retrieve_DB_Data/ProfileAPI";
import { sendDataTo_R_API } from "./Upload_Retrieve_DB_Data/ProfileAPI";
import DBstat from "./Components/ClientOnboarding/ChildComponents/DBstat";



import UploadData from "./Components/MMM/UploadData";
import DataConversion from "./Components/MMM/DataConversion";
import DataConversion2 from "./Components/MMM/DataConversion2";
import DataConversion3 from "./Components/MMM/DataConversion3";
import DataMerging from "./Components/MMM/DataMerging";
import Model from "./Components/MMM/Model";
import Validation from "./Components/MMM/Validation";
import MMMdata from "./Components/MMM/MMMdata";
import MMMExperiment from "./Components/MMM/MMMExperiment";


import MMM_TV_data_Upload from "./Components/MMM/MMM_data_Components/Upload_Different_Channels_data/MMM_TV_data_Upload";
import MMM_PR_data_Upload from "./Components/MMM/MMM_data_Components/Upload_Different_Channels_data/MMM_PR_data_Upload";
import MMM_RD_data_Upload from "./Components/MMM/MMM_data_Components/Upload_Different_Channels_data/MMM_RD_data_Upload";
import MMM_DG_data_Upload from "./Components/MMM/MMM_data_Components/Upload_Different_Channels_data/MMM_DG_data_Upload";
import MMM_SL_data_Upload from "./Components/MMM/MMM_data_Components/Upload_Different_Channels_data/MMM_SL_data_Upload";

import MMM_Process_TV_data from "./Components/MMM/MMM_data_Components/Process_Different_Channels_data/MMM_Process_TV_data";
import MMM_Process_PR_data from "./Components/MMM/MMM_data_Components/Process_Different_Channels_data/MMM_Process_PR_data";
import MMM_Process_RD_data from "./Components/MMM/MMM_data_Components/Process_Different_Channels_data/MMM_Process_RD_data";
import MMM_Process_DG_data from "./Components/MMM/MMM_data_Components/Process_Different_Channels_data/MMM_Process_DG_data";
import MMM_Process_SL_data from "./Components/MMM/MMM_data_Components/Process_Different_Channels_data/MMM_Process_SL_data";

import MMM_Merged_data from "./Components/MMM/MMM_data_Components/MMM_Merged_data";


import CompareResults from "./Components/GeoExperiment/ChildComponents/CompareResults";
import { apiConnector } from "./Upload_Retrieve_DB_Data/apiConnector";
import { Navigate } from 'react-router-dom';
import Login from "./Components/Login";

const ProtectedRoute = ({ element, condition, redirectTo = "/" }) => {
  return condition ? element : <Navigate to={redirectTo} />;
};

function App() {


  const { width, height } = useWindowDimensions();
  const [isUserAllowed, setIsUserAllowed] = useState(false); // Example condition

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [business_setup_arr, setBusiness_setup_arr] = useState([]);

  const fetch_Business_Setup_Data = async () => {    
    setLoading(true);       
    try {
      const res = await getDataFrom_Business_Setup_Table();          
      console.log("Data From Business Setup Table:- ", res);   
      setBusiness_setup_arr(res);  
    } 
    catch (error) {
      console.log("Could not fetch Business Setup Data")
    }
    setLoading(false);
  }

  useEffect(() => {                                               // useEffect is a react hook, it executes automatically when the component renders
    fetch_Business_Setup_Data();
  }, [count]);                                                                    // It should depend on - When user updates Business Setup and Saves and also when user deletes

  const [file_data_table_arr, setFile_data_table_arr] = useState([]);
  const [count2, setCount2] = useState(0);
  const [loading2, setLoading2] = useState(false);
  const [currentExperiment_IsLoaded_FromDB, setCurrentExperiment_IsLoaded_FromDB] = useState(false);


  const fetch_file_Data = async () => {    
      setLoading2(true);       
      try {
        const res = await getDataFrom_File_Data_Table();          
        console.log("Data From File Data Table:- ", res);   
        setFile_data_table_arr(res);
      } 
      catch (error) {
        console.log("Could not fetch File Data")
      }
      setLoading2(false);
  }


  useEffect(() => {             
    fetch_file_Data();
  }, [count2]);                                // It should depend on - When user adds new CSV Submission to the DB or deletes CSV file from DB


  const [filenamesArray, setFilenamesArray] = useState([]);    // While creating subset - We want to ensure that subset name given by user is unique in DB, in that case filenamesArray[] helps
  useEffect(() => {
      if(file_data_table_arr.length>0){
          const fileNames = file_data_table_arr.map(item => item.file_name);
          setFilenamesArray(fileNames);
      }
  }, [file_data_table_arr]);




  const [experiment_table_arr, setExperiment_table_arr] = useState([]);
  const [count3, setCount3] = useState(0);
  const fetch_experiment_Data = async () => {    
      setLoading2(true);       
      try {
        const res = await getDataFrom_Experiment_Table();          
        console.log("Data From Experiment Table:- ", res);   
        setExperiment_table_arr(res);
      } 
      catch (error) {
        console.log("Could not fetch File Data")
      }
      setLoading2(false);
  }


  useEffect(() => {             
    fetch_experiment_Data();
  }, [count3]);                // It should update - When user Saves a new Experiment, saves an existing experiment, Deletes an experiment






  const [experiment_table_arr_modified, setExperiment_table_arr_modified] = useState([]);
  useEffect(() => {             
      if(file_data_table_arr.length > 0  &&  experiment_table_arr.length > 0){
          
          const fileLookup = file_data_table_arr.reduce((acc, file) => {    
              acc[file.file_id] = file;   
              return acc;   
          }, {});
          // console.log("fileLookup :-", fileLookup);
            
          const result = experiment_table_arr.map(exp => {
              const fileDetails = fileLookup[exp.file_id] || {};
              //console.log("fileDetails :- ", fileDetails);
              
              return {
                ...exp,
                file_name: fileDetails.file_name || '',
                description: fileDetails.description || '',
                business: fileDetails.business || '',
                category: fileDetails.category || '',
                product: fileDetails.product || '',
                num_control_group: fileDetails.num_control_group || ''

              };
          });
          // console.log("result :-", result);

          const groupedData = result.reduce((acc, item) => {
              const { experiment_name } = item;
              if (!acc[experiment_name]) {
                acc[experiment_name] = [];
              }
              acc[experiment_name].push(item);
              return acc;
          }, {});
          // console.log("groupedData :- ", groupedData);

          setExperiment_table_arr_modified(groupedData);
      }
  }, [file_data_table_arr, experiment_table_arr]);








  const [experimentnamesArray, setExperimentnamesArray] = useState([]);
  const [uniqueFileIDS_from_Experiments, setUniqueFileIDS_from_Experiments] = useState([]);
  const [fileId_Mapping_With_Exps, setFileId_Mapping_With_Exps] = useState([]);
  useEffect(() => {
      if(experiment_table_arr.length>0){
          const experimentNames = Array.from(new Set(experiment_table_arr.map(item => item.experiment_name)));
          setExperimentnamesArray(experimentNames);

          
          const fileIds = experiment_table_arr.map(item => item.file_id);
          const uniqueFileIdsSet = Array.from(new Set(fileIds));
          setUniqueFileIDS_from_Experiments(uniqueFileIdsSet);


          const map = experiment_table_arr.reduce((acc, { experiment_name, file_id }) => {
            if (!acc[file_id]) {
              acc[file_id] = { file_id, experiment_name: [] };
            }
            if (!acc[file_id].experiment_name.includes(experiment_name)) {
              acc[file_id].experiment_name.push(experiment_name);
            }
            return acc;
          }, {});
          const res = Object.values(map);
          setFileId_Mapping_With_Exps(res);
      }
  }, [experiment_table_arr]);













  const [fileObjs, setFileObjs] = useState([]);
  const [runR_ForStartingDatasets, set_runR_ForStartingDatasets] = useState(0);
  const [runR_For_CreatedSubset, set_runR_For_CreatedSubset] = useState(0);
  const [initial_Datasets_fetched, set_initial_Datasets_fetched] = useState(false);
  const [subset_Created, set_subset_Created] = useState(false);
  const [selectedObjID_forSubset, setSelectedObjID_forSubset] = useState(null);
  const [createExperiment_btn_Clicked, setCreateExperiment_btn_Clicked] = useState(false);
  const [loadingCE, setLoadingCE] = useState(false);
  const [loadingCS, setLoadingCS] = useState(false);
  const [currentExperimentWindow, setCurrentExperimentWindow] = useState(false);
  const [createSubset, setCreateSubset] = useState(false);
  const [exp_Name, setExp_Name] = useState('');


  let arrTempX = [];
  useEffect(()=>{
    if(fileObjs.length > 0) console.log("fileObjs :- ", fileObjs);
  }, [fileObjs])

  function fillDateGaps(arr) {
    const result = [];
    let currentDate = new Date(arr[0].date);
    const endDate = new Date(arr[arr.length - 1].date);
  
    while (currentDate <= endDate) {
      const dateString = currentDate.toISOString().split('T')[0];
      const foundItem = arr.find(item => item.date === dateString);
      result.push({
        date: dateString,
        actual_metric: foundItem ? foundItem.actual_metric : null,
        synthetic_metric: foundItem ? foundItem.synthetic_metric : null
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return result;
  }

  async function run_model_func(Obj){

          const csvString = arrayToCSV(Obj.file_data);
          const csvBlob = csvToBlob(csvString);
          const csvFile = blobToFile(csvBlob, Obj.file_name);

          const formData = new FormData();
          formData.append('file', csvFile);


          const R_output = await sendDataTo_R_API(formData);
          
          if(R_output){
              // console.log("R_output :- ", R_output);
              // console.log("fileObjs :- ", fileObjs);


              const CurrectObject = Obj;


              const formattedData = R_output.graph_data[0].reduce((acc, curr) => {                   
                  const existingEntry = acc.find(item => item.date === curr.date);
                  if (existingEntry) {
                    existingEntry[`${curr.data_type}_metric`] = curr.metric;
                  } else {
                    acc.push({
                      date: curr.date,
                      actual_metric: curr.data_type === 'actual' ? curr.metric : null,
                      synthetic_metric: curr.data_type === 'synthetic' ? curr.metric : null,
                    });
                  }
                  return acc;
              }, []);             

              // console.log("formattedData :- ", formattedData);
              const filledArray = fillDateGaps(formattedData);
              // console.log("filledArray :- ", filledArray);

              CurrectObject.result = {
                  graph_data: filledArray,
                  lift: R_output.lift,
                  stddev: R_output.stddev
              };
              // console.log("CurrectObject :- ", CurrectObject);

               if(CurrectObject.presentonDB){   // For experiment Creation (For Starting files of an Experiment)
                arrTempX = [...arrTempX,    CurrectObject];
              }
              else{                            // for subsets (1 dataset at a time)
                const updatedFileObjs = [...fileObjs.slice(0, fileObjs.length - 1),       CurrectObject];
                setFileObjs(updatedFileObjs);
              }              
          }
  }

  const arrayToCSV = (arr) => {               // Function to convert array of objects to CSV string
      const csv = Papa.unparse(arr); 
      return csv;
  };
  const csvToBlob = (csv) => {                // Function to convert CSV string to Blob
      const blob = new Blob([csv], { type: 'text/csv' });
      return blob;
  };
  const blobToFile = (blob, fileName) => {    // Function to convert Blob to File
      const file = new File([blob], fileName, { type: 'text/csv' });
      return file;
  };


  useEffect(() => {     

    if(runR_ForStartingDatasets > 0){
      functionZXC();

      async function functionZXC(){

        console.log("R Function Started");

        for(let i=0; i<fileObjs.length;i++){
          await run_model_func(fileObjs[i])
        }

        console.log("R Function Stopped");

        // console.log("arrTempX:-", arrTempX);
        setFileObjs(arrTempX);
        setLoadingCE(false)
        setCurrentExperimentWindow(true);
        setCreateExperiment_btn_Clicked(false);
      }

    }    

  }, [runR_ForStartingDatasets]);


  useEffect(()=>{
    if(runR_For_CreatedSubset > 0){
      functionZXC();

      async function functionZXC(){      
        console.log("R Function Started");
        await run_model_func(fileObjs[fileObjs.length-1])
        console.log("R Function Stopped");

        setLoadingCS(false);
        setCreateSubset(false);
      }
    }
  },[runR_For_CreatedSubset]);

  const [activeTab, setActiveTab] = useState('/');

  // ____________________________________________________FOR MMM_________________________________________________________________________________________________________


  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [states, setStates] = useState([]);
  const [salesData_Dates, setSalesData_Dates] = useState([]);

  const [data2, setData2] = useState([]);   
  const [file2, setFile2] = useState(null);
  const [dimensionsOptions, setDimensionsOptions] = useState([]);
  const [checkedItems_TV, setCheckedItems_TV] = useState([]);
  const [selected_TV, setSelected_TV] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [afterRegionalMapping_arr, setAfterRegionalMapping_arr] = useState([]);
  const [wideFormatData, setWideFormatData] = useState([]);       // To be merged in final dataset
  
  useEffect(()=>{
    if(dimensionsOptions.length > 0    &&    checkedItems_TV.length === 0){
      console.log("It must print only once for TV");
      setCheckedItems_TV(new Array(dimensionsOptions.length).fill(false));
    }
  },[dimensionsOptions])

  const [data4, setData4] = useState([]);
  const [file4, setFile4] = useState(null);
  const [dimensionsOptions_P, setDimensionsOptions_P] = useState([]);
  const [checkedItems_PR, setCheckedItems_PR] = useState([]);
  const [selected_PR, setSelected_PR] = useState([]);
  const [uniquePositions_in_Print, setuniquePositions_in_Print] = useState([]);
  const [data_After_Discarding_ExtraData, setdata_After_Discarding_ExtraData] = useState([]);    // Basically If Sales data has Nov month's data only, and PrintData has Oct-Nov-Dec month's data, in that case we will discard Print Data of Oct and Dec month
  const [aggregatedData_P, setAggregatedData_P] = useState([]);
  const [afterRegionalMapping_arr_P, setAfterRegionalMapping_arr_P] = useState([]);
  const [after_Missing_Dates_and_Positions_Treatment, setAfter_Missing_Dates_and_Positions_Treatment] = useState([]);
  const [after_flipping_the_Positions_Horizontally, setAfter_flipping_the_Positions_Horizontally] = useState([]);     // To be merged in final dataset
  const [after_merging_the_Positions, setAfter_merging_the_Positions] = useState([]);                                 // To be merged in final dataset

  useEffect(()=>{
    if(dimensionsOptions_P.length > 0    &&    checkedItems_PR.length === 0){
      console.log("It must print only once for PR");
      setCheckedItems_PR(new Array(dimensionsOptions_P.length).fill(false));
    }
  },[dimensionsOptions_P])

  const [data3, setData3] = useState([]);
  const [file3, setFile3] = useState(null);
  const [dimensionsOptions_R, setDimensionsOptions_R] = useState([]);
  const [checkedItems_RD, setCheckedItems_RD] = useState([]);
  const [selected_RD, setSelected_RD] = useState([]);
  const [data_After_Discarding_ExtraData_R, setdata_After_Discarding_ExtraData_R] = useState([]);    // Basically If Sales data has Nov month's data only, and RadioData has Oct-Nov-Dec month's data, in that case we will discard Radio Data of Oct and Dec month
  const [aggregatedData_R, setAggregatedData_R] = useState([]);
  const [afterRegionalMapping_arr_R, setAfterRegionalMapping_arr_R] = useState([]);
  const [after_Missing_Dates_and_States_Treatment_R, setAfter_Missing_Dates_and_States_Treatment_R] = useState([]);     // To be merged in final dataset

  useEffect(()=>{
    if(dimensionsOptions_R.length > 0    &&    checkedItems_RD.length === 0){
      console.log("It must print only once for RD");
      setCheckedItems_RD(new Array(dimensionsOptions_R.length).fill(false));
    }
  },[dimensionsOptions_R])

  // ____________________________________________________FOR MMM_________________________________________________________________________________________________________

  // ____________________________________________________FOR NEW MMM UI_________________________________________________________________________________________________________



    const [selected_Tab, set_selected_Tab] = useState('upload_data');
    const [formatted_SalesData, set_formatted_SalesData] = useState([]);

    const [data_tv, setData_tv] = useState([]);
    const [file_tv, setFile_tv] = useState(null);
    const [fileName_tv, setFileName_tv] = useState(null);

    const [dimensionsOptions_TV, setDimensionsOptions_TV] = useState([]);
    const [selected_Dimensions_TV, set_selected_Dimensions_TV] = useState([]);    
    const [selected_metrics_TV, set_selected_metrics_TV] = useState([]);
    const [selected_metrics_TV_raw, set_selected_metrics_TV_raw] = useState([]);

    const [dimsCols_TV, setDimsCols_TV] = useState([]);
    const [metricsCols_TV, setMetricsCols_TV] = useState([]);
    const [headers_TV, setHeaders_TV] = useState([]);

    const [statesPicked_Overall_TV, set_statesPicked_Overall_TV] = useState([]);
    const [All_metrics_Headers_TV, set_All_metrics_Headers_TV] = useState([]);
    const [mappingArr, setMappingArr] = useState([]);
    const [headers_TV_Step2, set_headers_TV_Step2] = useState([]);
    const [States_Names_Used, set_States_Names_Used] = useState([]);
    const [unMappedRegions_TV, set_unMappedRegions_TV] = useState([]);
    const [headers_TV_Step3, set_headers_TV_Step3] = useState([]);
    const [unique_Channels_in_TV, set_unique_Channels_in_TV] = useState([]); 
    const [mapping_Arr_TV_Step_3, set_mapping_Arr_TV_Step_3] = useState([]);
    const [non_Aggregated_Variables_TV, set_non_Aggregated_Variables_TV] = useState([]);
    const [Overall_Variables_Picked_for_Aggregation_TV, set_Overall_Variables_Picked_for_Aggregation_TV] = useState([]);
    const [headers_TV_Step3_, set_headers_TV_Step3_] = useState([]);

    const [aggregated_Data_TV, set_Aggregated_Data_TV] = useState([]);
    const [after_Regional_Mapping_arr_TV, set_After_Regional_Mapping_arr_TV] = useState([]);
    const [wide_Format_Data_TV, set_Wide_Format_Data_TV] = useState([]);
    const [after_Variables_Aggregation, set_after_Variables_Aggregation] = useState([]);

    const [Variable_Aggregation_Record_TV, set_Variable_Aggregation_Record_TV] = useState([]);
    const [overAll_Variable_Groups, set_overAll_Variable_Groups] = useState(["TV Channels", "TV Genres", "Impressions Total"]);
    const [selected_dummies, set_selected_dummies] = useState([]);
    const [selected_Transform_Action, set_selected_Transform_Action] = useState("log");


    useEffect(()=>{
      if(All_metrics_Headers_TV.length>0){
        set_unMappedRegions_TV(All_metrics_Headers_TV.filter(item => !statesPicked_Overall_TV.includes(item)))
      }
    },[All_metrics_Headers_TV]);

    useEffect(()=>{
      if(unique_Channels_in_TV.length>0){
        set_non_Aggregated_Variables_TV(unique_Channels_in_TV.filter(item => !Overall_Variables_Picked_for_Aggregation_TV.includes(item)))
      }
    },[unique_Channels_in_TV]);

    useEffect(()=>{
      if(dimensionsOptions_TV.length > 0    &&    selected_Dimensions_TV.length === 0){
        console.log("It must print only once for TV");
        set_selected_Dimensions_TV(new Array(dimensionsOptions_TV.length).fill(false));
      }
    },[dimensionsOptions_TV])

    useEffect(()=>{
      if(wide_Format_Data_TV.length>0){
          console.log("wide_Format_Data_TV :- ", wide_Format_Data_TV);
          set_after_Variables_Aggregation(wide_Format_Data_TV);
      }
    },[wide_Format_Data_TV]);

    const [data_pr, setData_pr] = useState([]);
    const [file_pr, setFile_pr] = useState(null);
    const [fileName_pr, setFileName_pr] = useState(null);

    const [dimensionsOptions_PR, setDimensionsOptions_PR] = useState([]);
    const [selected_Dimensions_PR, set_selected_Dimensions_PR] = useState([]);    
    const [selected_metrics_PR, set_selected_metrics_PR] = useState([]);

    const [uniquePositions_in_PR, set_uniquePositions_in_PR] = useState([]);
    const [data_After_Discarding_ExtraData_PR, setdata_After_Discarding_ExtraData_PR] = useState([]);    // Basically If Sales data has Nov month's data only, and PrintData has Oct-Nov-Dec month's data, in that case we will discard Print Data of Oct and Dec month
    const [aggregatedData_PR, setAggregatedData_PR] = useState([]);
    const [afterRegionalMapping_arr_PR, setAfterRegionalMapping_arr_PR] = useState([]);
    const [after_Missing_Dates_and_Positions_Treatment_PR, setAfter_Missing_Dates_and_Positions_Treatment_PR] = useState([]);
    const [after_flipping_the_Positions_Horizontally_PR, setAfter_flipping_the_Positions_Horizontally_PR] = useState([]);     // To be merged in final dataset
    const [after_merging_the_Positions_PR, setAfter_merging_the_Positions_PR] = useState([]);    

    useEffect(()=>{
      if(dimensionsOptions_PR.length > 0    &&    selected_Dimensions_PR.length === 0){
        console.log("It must print only once for TV");
        set_selected_Dimensions_PR(new Array(dimensionsOptions_PR.length).fill(false));
      }
    },[dimensionsOptions_PR])

    const [data_rd, setData_rd] = useState([]);
    const [file_rd, setFile_rd] = useState(null);
    const [fileName_rd, setFileName_rd] = useState(null);
    const [dimensionsOptions_RD, setDimensionsOptions_RD] = useState([]);

    const [data_dg, setData_dg] = useState([]);
    const [file_dg, setFile_dg] = useState(null);
    const [fileName_dg, setFileName_dg] = useState(null);

    const [data_sl, setData_sl] = useState([]);
    const [file_sl, setFile_sl] = useState(null);
    const [fileName_sl, setFileName_sl] = useState(null);
    const [Sales_states, set_Sales_States] = useState([]);
    const [Sales_Dates, set_Sales_Dates] = useState([]);

    const [items_to_be_merged_arr, set_items_to_be_merged_arr] = useState([]);
    const [final_merged_Dataset, set_final_merged_Dataset] = useState([]);

    const [model_selected_Tab, set_model_selected_Tab] = useState('select_variables');

  // ____________________________________________________________________________________________________________________________________________________________________

  return (
    <div style={{width:"100%", height:height-1, display:"flex", justifyContent:"center"}}>
      <div  style={{width:"1536px", height:"100%", maxHeight:"729px",   display:"flex", padding:"1.4rem", gap:"1.4rem"}}>


        <div  style={{width:"19%", height:"100%", borderRadius:"24px", padding:"24px 0", boxShadow:"8px 8px 20px 0px #AEAEC080, -1px -1px 20px 0px #f1f1f1" }}>
          <Navigation    activeTab={activeTab}    setActiveTab={setActiveTab}/>
        </div>


        {/* <div  style={{width:"81%", height:"100%", borderRadius:"24px", padding:"24px 0", boxShadow:"8px 8px 20px 0px #AEAEC080, -1px -1px 20px 0px #f1f1f1" }}> */}
        <div  style={{width:"81%", height:"100%"}}>

          <Routes>
            <Route path="/"                      element={<Login    setIsUserAllowed={setIsUserAllowed}    setActiveTab={setActiveTab}     />}     />
            

            <Route path="/dashboard"                 element={<ProtectedRoute   element={<Dashboard/>}    condition={isUserAllowed}   />}     />
            <Route path="/experiment-and-data"       element={<ProtectedRoute   element={<ExperimentAndData     file_data_table_arr={file_data_table_arr}    uniqueFileIDS_from_Experiments={uniqueFileIDS_from_Experiments}    count2={count2}    setCount2={setCount2}    count3={count3}    setCount3={setCount3}    business_setup_arr={business_setup_arr}    loading={loading}    fileId_Mapping_With_Exps={fileId_Mapping_With_Exps}    loading2={loading2}    setLoading2={setLoading2}    loadingCS={loadingCS}        fileObjs={fileObjs}    setFileObjs={setFileObjs}        runR_ForStartingDatasets={runR_ForStartingDatasets}    set_runR_ForStartingDatasets={set_runR_ForStartingDatasets}    createExperiment_btn_Clicked={createExperiment_btn_Clicked}    setCreateExperiment_btn_Clicked={setCreateExperiment_btn_Clicked}    currentExperimentWindow={currentExperimentWindow}    setCurrentExperimentWindow={setCurrentExperimentWindow}    exp_Name={exp_Name}    setExp_Name={setExp_Name}    experimentnamesArray={experimentnamesArray}     initial_Datasets_fetched={initial_Datasets_fetched}    set_initial_Datasets_fetched={set_initial_Datasets_fetched}    loadingCE={loadingCE}    setLoadingCE={setLoadingCE}    setCreateSubset={setCreateSubset}    selectedObjID_forSubset={selectedObjID_forSubset}    experiment_table_arr_modified={experiment_table_arr_modified}    experiment_table_arr={experiment_table_arr}    currentExperiment_IsLoaded_FromDB={currentExperiment_IsLoaded_FromDB}    setCurrentExperiment_IsLoaded_FromDB={setCurrentExperiment_IsLoaded_FromDB}/>}    condition={isUserAllowed}   />}    />

            <Route path="/data-subset"               element={<ProtectedRoute   element={<DataSubset           exp_Name={exp_Name}    fileObjs={fileObjs}    setFileObjs={setFileObjs}        runR_For_CreatedSubset={runR_For_CreatedSubset}    set_runR_For_CreatedSubset={set_runR_For_CreatedSubset}    subset_Created={subset_Created}    set_subset_Created={set_subset_Created}        loadingCS={loadingCS}    setLoadingCS={setLoadingCS}    createSubset={createSubset}    setCreateSubset={setCreateSubset}    filenamesArray={filenamesArray}    selectedObjID_forSubset={selectedObjID_forSubset}    setSelectedObjID_forSubset={setSelectedObjID_forSubset}    activeTab={activeTab}    setActiveTab={setActiveTab}/>}    condition={isUserAllowed}   />}          />
            <Route path="/placebo-analysis"          element={<ProtectedRoute   element={<PlaceboAnalysis      exp_Name={exp_Name}    fileObjs={fileObjs}    setActiveTab={setActiveTab}    />}    condition={isUserAllowed}   />}     />
            <Route path="/compare-results"           element={<ProtectedRoute   element={<CompareResults       exp_Name={exp_Name}    fileObjs={fileObjs}/>}    condition={isUserAllowed}   />}     />
            <Route path="/power-of-test"             element={<ProtectedRoute   element={<PowerOfTest/>}    condition={isUserAllowed}   />}         />
            <Route path="/product-a"                 element={<ProtectedRoute   element={<ProductA/>}    condition={isUserAllowed}   />}     />
            <Route path="/product-b"                 element={<ProtectedRoute   element={<ProductB/>}    condition={isUserAllowed}   />}     />
            <Route path="/product-c"                 element={<ProtectedRoute   element={<ProductC/>}    condition={isUserAllowed}   />}     />
            <Route path="/user-management"           element={<ProtectedRoute   element={<UserManagement/>}    condition={isUserAllowed}   />}       />
            <Route path="/data-integrations"         element={<ProtectedRoute   element={<DataIntegrations/>}    condition={isUserAllowed}   />}     />
            <Route path="/select-id"                 element={<ProtectedRoute   element={<SelectIdPage />}    condition={isUserAllowed}   />}        />
            <Route path="/selection-status"          element={<ProtectedRoute   element={<SelectionStatus />}    condition={isUserAllowed}   />}     />
            <Route path="/DBstat"                    element={<ProtectedRoute   element={<DBstat />}    condition={isUserAllowed}   />} />
            <Route path="/business-setup"            element={<ProtectedRoute   element={<BusinessSetup        business_setup_arr={business_setup_arr}    count={count}    setCount={setCount}    loading={loading}    setLoading={setLoading}/>}    condition={isUserAllowed}   />}        />

                
            
            <Route path="/data-upload"                       element={<ProtectedRoute   element={<UploadData         data={data}    setData={setData}    data2={data2}    setData2={setData2}    data3={data3}    setData3={setData3}    data4={data4}    setData4={setData4}    file={file}    setFile={setFile}    file2={file2}    setFile2={setFile2}    file3={file3}    setFile3={setFile3}    file4={file4}    setFile4={setFile4}      setDimensionsOptions={setDimensionsOptions}    setDimensionsOptions_P={setDimensionsOptions_P}    setDimensionsOptions_R={setDimensionsOptions_R}        setStates={setStates}    setSalesData_Dates={setSalesData_Dates}/>}    condition={isUserAllowed}   />}     />    
            <Route path="/data-conversion-steps-tv"          element={<ProtectedRoute   element={<DataConversion     dimensionsOptions={dimensionsOptions}          data2={data2}    states={states}      checkedItems_TV={checkedItems_TV}    setCheckedItems_TV={setCheckedItems_TV}    selected_TV={selected_TV}    setSelected_TV={setSelected_TV}      aggregatedData={aggregatedData}    setAggregatedData={setAggregatedData}    afterRegionalMapping_arr={afterRegionalMapping_arr}    setAfterRegionalMapping_arr={setAfterRegionalMapping_arr}    wideFormatData={wideFormatData}    setWideFormatData={setWideFormatData}    data={data}    data3={data3}    data4={data4}    />}    condition={isUserAllowed}   />}        />
            <Route path="/data-conversion-steps-print"       element={<ProtectedRoute   element={<DataConversion2    dimensionsOptions_P={dimensionsOptions_P}      data4={data4}    states={states}      salesData_Dates={salesData_Dates}        checkedItems_PR={checkedItems_PR}    setCheckedItems_PR={setCheckedItems_PR}    selected_PR={selected_PR}    setSelected_PR={setSelected_PR}      uniquePositions_in_Print={uniquePositions_in_Print}    setuniquePositions_in_Print={setuniquePositions_in_Print}        data_After_Discarding_ExtraData={data_After_Discarding_ExtraData}    setdata_After_Discarding_ExtraData={setdata_After_Discarding_ExtraData}    aggregatedData_P={aggregatedData_P}    setAggregatedData_P={setAggregatedData_P}    afterRegionalMapping_arr_P={afterRegionalMapping_arr_P}    setAfterRegionalMapping_arr_P={setAfterRegionalMapping_arr_P}    after_Missing_Dates_and_Positions_Treatment={after_Missing_Dates_and_Positions_Treatment}    setAfter_Missing_Dates_and_Positions_Treatment={setAfter_Missing_Dates_and_Positions_Treatment}    after_flipping_the_Positions_Horizontally={after_flipping_the_Positions_Horizontally}    setAfter_flipping_the_Positions_Horizontally={setAfter_flipping_the_Positions_Horizontally}    after_merging_the_Positions={after_merging_the_Positions}    setAfter_merging_the_Positions={setAfter_merging_the_Positions}    data={data}    data2={data2}    data3={data3}/>}    condition={isUserAllowed}   />}        />
            <Route path="/data-conversion-steps-radio"       element={<ProtectedRoute   element={<DataConversion3    dimensionsOptions_R={dimensionsOptions_R}      data3={data3}    states={states}      salesData_Dates={salesData_Dates}        checkedItems_RD={checkedItems_RD}    setCheckedItems_RD={setCheckedItems_RD}    selected_RD={selected_RD}    setSelected_RD={setSelected_RD}      data_After_Discarding_ExtraData_R={data_After_Discarding_ExtraData_R}    setdata_After_Discarding_ExtraData_R={setdata_After_Discarding_ExtraData_R}    aggregatedData_R={aggregatedData_R}    setAggregatedData_R={setAggregatedData_R}    afterRegionalMapping_arr_R={afterRegionalMapping_arr_R}    setAfterRegionalMapping_arr_R={setAfterRegionalMapping_arr_R}    after_Missing_Dates_and_States_Treatment_R={after_Missing_Dates_and_States_Treatment_R}    setAfter_Missing_Dates_and_States_Treatment_R={setAfter_Missing_Dates_and_States_Treatment_R}    data4={data4}    data2={data2}/>}    condition={isUserAllowed}   />}        />
            <Route path="/data-conversion-steps-merging"     element={<ProtectedRoute   element={<DataMerging    salesData_Dates={salesData_Dates}    states={states}    data={data}                wideFormatData={wideFormatData}    after_flipping_the_Positions_Horizontally={after_flipping_the_Positions_Horizontally}    after_merging_the_Positions={after_merging_the_Positions}    after_Missing_Dates_and_States_Treatment_R={after_Missing_Dates_and_States_Treatment_R}    data2={data2}    data3={data3}    data4={data4}/>}    condition={isUserAllowed}   />}        />

            

            <Route path="/mmm-experiment"    element={<ProtectedRoute   element={<MMMExperiment    setActiveTab={setActiveTab}    set_model_selected_Tab={set_model_selected_Tab}/>}    condition={isUserAllowed}   />}  />
            <Route path="/mmm-data"          element={<ProtectedRoute   element={<MMMdata    setActiveTab={setActiveTab}    selected_Tab={selected_Tab}    set_selected_Tab={set_selected_Tab}    Sales_states={Sales_states}    Sales_Dates={Sales_Dates}         data_tv={data_tv}    data_pr={data_pr}    data_rd={data_rd}    data_dg={data_dg}    data_sl={data_sl}    wide_Format_Data_TV={wide_Format_Data_TV}    formatted_SalesData={formatted_SalesData}    set_formatted_SalesData={set_formatted_SalesData}    after_Variables_Aggregation={after_Variables_Aggregation}    final_merged_Dataset={final_merged_Dataset}    set_final_merged_Dataset={set_final_merged_Dataset}    items_to_be_merged_arr={items_to_be_merged_arr}    set_items_to_be_merged_arr={set_items_to_be_merged_arr}
            
                                                                  dimensionsOptions_TV={dimensionsOptions_TV}        selected_Dimensions_TV={selected_Dimensions_TV}    set_selected_Dimensions_TV={set_selected_Dimensions_TV}    selected_metrics_TV={selected_metrics_TV}    set_selected_metrics_TV={set_selected_metrics_TV}    dimsCols_TV={dimsCols_TV}    setDimsCols_TV={setDimsCols_TV}    metricsCols_TV={metricsCols_TV}    setMetricsCols_TV={setMetricsCols_TV}    headers_TV={headers_TV}    setHeaders_TV={setHeaders_TV}    statesPicked_Overall_TV={statesPicked_Overall_TV}    set_statesPicked_Overall_TV={set_statesPicked_Overall_TV}    All_metrics_Headers_TV={All_metrics_Headers_TV}    set_All_metrics_Headers_TV={set_All_metrics_Headers_TV}    mappingArr={mappingArr}    setMappingArr={setMappingArr}    headers_TV_Step2={headers_TV_Step2}    set_headers_TV_Step2={set_headers_TV_Step2}    unMappedRegions_TV={unMappedRegions_TV}    set_unMappedRegions_TV={set_unMappedRegions_TV}    headers_TV_Step3={headers_TV_Step3}    set_headers_TV_Step3={set_headers_TV_Step3}    unique_Channels_in_TV={unique_Channels_in_TV}    set_unique_Channels_in_TV={set_unique_Channels_in_TV}     
                                                                  mapping_Arr_TV_Step_3={mapping_Arr_TV_Step_3}    set_mapping_Arr_TV_Step_3={set_mapping_Arr_TV_Step_3}    non_Aggregated_Variables_TV={non_Aggregated_Variables_TV}    set_non_Aggregated_Variables_TV={set_non_Aggregated_Variables_TV}    Overall_Variables_Picked_for_Aggregation_TV={Overall_Variables_Picked_for_Aggregation_TV}    set_Overall_Variables_Picked_for_Aggregation_TV={set_Overall_Variables_Picked_for_Aggregation_TV}    set_after_Variables_Aggregation={set_after_Variables_Aggregation}    headers_TV_Step3_={headers_TV_Step3_}    set_headers_TV_Step3_={set_headers_TV_Step3_}    setData_tv={setData_tv}    selected_metrics_TV_raw={selected_metrics_TV_raw}    set_selected_metrics_TV_raw={set_selected_metrics_TV_raw}    States_Names_Used={States_Names_Used}    set_States_Names_Used={set_States_Names_Used}    aggregated_Data_TV={aggregated_Data_TV}    set_Aggregated_Data_TV={set_Aggregated_Data_TV}    after_Regional_Mapping_arr_TV={after_Regional_Mapping_arr_TV}    set_After_Regional_Mapping_arr_TV={set_After_Regional_Mapping_arr_TV}    set_Wide_Format_Data_TV={set_Wide_Format_Data_TV}
                                                                  Variable_Aggregation_Record_TV={Variable_Aggregation_Record_TV}    set_Variable_Aggregation_Record_TV={set_Variable_Aggregation_Record_TV}    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}
                                                                  
                                                                  business_setup_arr={business_setup_arr}        file_tv={file_tv}    setFile_tv={setFile_tv}    fileName_tv={fileName_tv}    setFileName_tv={setFileName_tv}        setDimensionsOptions_TV={setDimensionsOptions_TV}
                                                                  setData_pr={setData_pr}    file_pr={file_pr}    setFile_pr={setFile_pr}    fileName_pr={fileName_pr}    setFileName_pr={setFileName_pr}        setDimensionsOptions_PR={setDimensionsOptions_PR}
                                                                  setData_rd={setData_rd}    file_rd={file_rd}    setFile_rd={setFile_rd}    fileName_rd={fileName_rd}    setFileName_rd={setFileName_rd}        setDimensionsOptions_RD={setDimensionsOptions_RD}
                                                                  setData_dg={setData_dg}    file_dg={file_dg}    setFile_dg={setFile_dg}    fileName_dg={fileName_dg}    setFileName_dg={setFileName_dg}
                                                                  setData_sl={setData_sl}    file_sl={file_sl}    setFile_sl={setFile_sl}    fileName_sl={fileName_sl}    setFileName_sl={setFileName_sl}        set_Sales_States={set_Sales_States}    set_Sales_Dates={set_Sales_Dates}
                                                                  
                                                                  selected_dummies={selected_dummies}    set_selected_dummies={set_selected_dummies}        selected_Transform_Action={selected_Transform_Action}    set_selected_Transform_Action={set_selected_Transform_Action}/>}    condition={isUserAllowed}   />}    />
                                                          
            <Route path="/model"             element={<ProtectedRoute   element={<Model        model_selected_Tab={model_selected_Tab}    set_model_selected_Tab={set_model_selected_Tab}/>}    condition={isUserAllowed}   />}          />
            <Route path="/validation"        element={<ProtectedRoute   element={<Validation/>}    condition={isUserAllowed}   />}     />

            <Route path="/mmm-tv-data-upload"            element={<ProtectedRoute   element={<MMM_TV_data_Upload      business_setup_arr={business_setup_arr}        data_tv={data_tv}    setData_tv={setData_tv}    file_tv={file_tv}    setFile_tv={setFile_tv}    fileName_tv={fileName_tv}    setFileName_tv={setFileName_tv}        setDimensionsOptions_TV={setDimensionsOptions_TV}/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-print-data-upload"         element={<ProtectedRoute   element={<MMM_PR_data_Upload      business_setup_arr={business_setup_arr}        data_pr={data_pr}    setData_pr={setData_pr}    file_pr={file_pr}    setFile_pr={setFile_pr}    fileName_pr={fileName_pr}    setFileName_pr={setFileName_pr}        setDimensionsOptions_PR={setDimensionsOptions_PR}/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-radio-data-upload"         element={<ProtectedRoute   element={<MMM_RD_data_Upload      business_setup_arr={business_setup_arr}        data_rd={data_rd}    setData_rd={setData_rd}    file_rd={file_rd}    setFile_rd={setFile_rd}    fileName_rd={fileName_rd}    setFileName_rd={setFileName_rd}        setDimensionsOptions_RD={setDimensionsOptions_RD}/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-digital-data-upload"       element={<ProtectedRoute   element={<MMM_DG_data_Upload      business_setup_arr={business_setup_arr}        data_dg={data_dg}    setData_dg={setData_dg}    file_dg={file_dg}    setFile_dg={setFile_dg}    fileName_dg={fileName_dg}    setFileName_dg={setFileName_dg}/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-sales-data-upload"         element={<ProtectedRoute   element={<MMM_SL_data_Upload      business_setup_arr={business_setup_arr}        data_sl={data_sl}    setData_sl={setData_sl}    file_sl={file_sl}    setFile_sl={setFile_sl}    fileName_sl={fileName_sl}    setFileName_sl={setFileName_sl}        set_Sales_States={set_Sales_States}    set_Sales_Dates={set_Sales_Dates}/>}    condition={isUserAllowed}   />}     />  

            <Route path="/mmm-process-tv-data"            element={<ProtectedRoute   element={<MMM_Process_TV_data    dimensionsOptions_TV={dimensionsOptions_TV}    data_tv={data_tv}    Sales_states={Sales_states}    Sales_Dates={Sales_Dates}    selected_Dimensions_TV={selected_Dimensions_TV}    set_selected_Dimensions_TV={set_selected_Dimensions_TV}    selected_metrics_TV={selected_metrics_TV}    set_selected_metrics_TV={set_selected_metrics_TV}    dimsCols_TV={dimsCols_TV}    setDimsCols_TV={setDimsCols_TV}    metricsCols_TV={metricsCols_TV}    setMetricsCols_TV={setMetricsCols_TV}    headers_TV={headers_TV}    setHeaders_TV={setHeaders_TV}    statesPicked_Overall_TV={statesPicked_Overall_TV}    set_statesPicked_Overall_TV={set_statesPicked_Overall_TV}    All_metrics_Headers_TV={All_metrics_Headers_TV}    set_All_metrics_Headers_TV={set_All_metrics_Headers_TV}    mappingArr={mappingArr}    setMappingArr={setMappingArr}    headers_TV_Step2={headers_TV_Step2}    set_headers_TV_Step2={set_headers_TV_Step2}    unMappedRegions_TV={unMappedRegions_TV}    set_unMappedRegions_TV={set_unMappedRegions_TV}    headers_TV_Step3={headers_TV_Step3}    set_headers_TV_Step3={set_headers_TV_Step3}    unique_Channels_in_TV={unique_Channels_in_TV}    set_unique_Channels_in_TV={set_unique_Channels_in_TV}     
                                                          mapping_Arr_TV_Step_3={mapping_Arr_TV_Step_3}    set_mapping_Arr_TV_Step_3={set_mapping_Arr_TV_Step_3}    non_Aggregated_Variables_TV={non_Aggregated_Variables_TV}    set_non_Aggregated_Variables_TV={set_non_Aggregated_Variables_TV}    Overall_Variables_Picked_for_Aggregation_TV={Overall_Variables_Picked_for_Aggregation_TV}    set_Overall_Variables_Picked_for_Aggregation_TV={set_Overall_Variables_Picked_for_Aggregation_TV}    after_Variables_Aggregation={after_Variables_Aggregation}    set_after_Variables_Aggregation={set_after_Variables_Aggregation}    headers_TV_Step3_={headers_TV_Step3_}    set_headers_TV_Step3_={set_headers_TV_Step3_}    setData_tv={setData_tv}    selected_metrics_TV_raw={selected_metrics_TV_raw}    set_selected_metrics_TV_raw={set_selected_metrics_TV_raw}    States_Names_Used={States_Names_Used}    set_States_Names_Used={set_States_Names_Used}    aggregated_Data_TV={aggregated_Data_TV}    set_Aggregated_Data_TV={set_Aggregated_Data_TV}    after_Regional_Mapping_arr_TV={after_Regional_Mapping_arr_TV}    set_After_Regional_Mapping_arr_TV={set_After_Regional_Mapping_arr_TV}    wide_Format_Data_TV={wide_Format_Data_TV}    set_Wide_Format_Data_TV={set_Wide_Format_Data_TV}
                                                          Variable_Aggregation_Record_TV={Variable_Aggregation_Record_TV}    set_Variable_Aggregation_Record_TV={set_Variable_Aggregation_Record_TV}    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-process-print-data"         element={<ProtectedRoute   element={<MMM_Process_PR_data    dimensionsOptions_PR={dimensionsOptions_PR}    data_pr={data_pr}    Sales_states={Sales_states}    Sales_Dates={Sales_Dates}    selected_Dimensions_PR={selected_Dimensions_PR}    set_selected_Dimensions_PR={set_selected_Dimensions_PR}    selected_metrics_PR={selected_metrics_PR}    set_selected_metrics_PR={set_selected_metrics_PR}    uniquePositions_in_PR={uniquePositions_in_PR}    set_uniquePositions_in_PR={set_uniquePositions_in_PR}    data_After_Discarding_ExtraData_PR={data_After_Discarding_ExtraData_PR}    setdata_After_Discarding_ExtraData_PR={setdata_After_Discarding_ExtraData_PR}    aggregatedData_PR={aggregatedData_PR}    setAggregatedData_PR={setAggregatedData_PR}    afterRegionalMapping_arr_PR={afterRegionalMapping_arr_PR}    setAfterRegionalMapping_arr_PR={setAfterRegionalMapping_arr_PR}    after_Missing_Dates_and_Positions_Treatment_PR={after_Missing_Dates_and_Positions_Treatment_PR}    setAfter_Missing_Dates_and_Positions_Treatment_PR={setAfter_Missing_Dates_and_Positions_Treatment_PR}    after_flipping_the_Positions_Horizontally_PR={after_flipping_the_Positions_Horizontally_PR}    setAfter_flipping_the_Positions_Horizontally_PR={setAfter_flipping_the_Positions_Horizontally_PR}    after_merging_the_Positions_PR={after_merging_the_Positions_PR}    setAfter_merging_the_Positions_PR={setAfter_merging_the_Positions_PR}/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-process-radio-data"         element={<ProtectedRoute   element={<MMM_Process_RD_data/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-process-digital-data"       element={<ProtectedRoute   element={<MMM_Process_DG_data/>}    condition={isUserAllowed}   />}     />
            <Route path="/mmm-process-sales-data"         element={<ProtectedRoute   element={<MMM_Process_SL_data/>}    condition={isUserAllowed}   />}     />



            <Route path="/mmm-merged-data"                element={<ProtectedRoute   element={<MMM_Merged_data      final_merged_Dataset={final_merged_Dataset}    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}    selected_dummies={selected_dummies}    set_selected_dummies={set_selected_dummies}        selected_Transform_Action={selected_Transform_Action}    set_selected_Transform_Action={set_selected_Transform_Action}/>}    condition={isUserAllowed}   />}     />                


            
            {/* <Route path="*"        element={<NotFound/>} />                  means any path other than defined paths                   */}
          </Routes>

        </div>


      </div>
    </div>
  );
}

export default App;
