import { useState, useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import SubsetByDate from "./SubsetByDate";
import SubsetByGeo from "./SubsetByGeo";
import Loader from "../../../assets/Images/loading.gif";




function DataSubset_Creation({selectedObjID_forSubset,    exp_Name,    fileObjs,    setFileObjs,    setCreateSubset,    runR_For_CreatedSubset,    set_runR_For_CreatedSubset,    subset_Created,    set_subset_Created,     loadingCS,    setLoadingCS,    filenamesArray}) {





    const [uploadData_descriptionX, setUploadData_descriptionX] = useState('');
    function description_ChangeX(event) {   
        if(event.target.value.length <= 200) setUploadData_descriptionX(event.target.value); 
    }

    const [subsetBy_Date_or_Geo, setSubsetBy_Date_or_Geo] = useState('date');


    


    const [newStartDate, setNewStartDate] = useState(null);
    const [newEndDate_Pre, setNewEndDate_Pre] = useState(null);
    const [newStartDate_Camp, setNewStartDate_Camp] = useState(null);
    const [newEndDate, setNewEndDate] = useState(null);

    const [newStartDate_fix, setNewStartDate_fix] = useState(null);
    const [newEndDate_Pre_fix, setNewEndDate_Pre_fix] = useState(null);
    const [newStartDate_Camp_fix, setNewStartDate_Camp_fix] = useState(null);
    const [newEndDate_fix, setNewEndDate_fix] = useState(null);








    const entireObj = fileObjs.find(obj => obj.objId === selectedObjID_forSubset);
    const uniqueGeos = [...new Set(entireObj.file_data.map(item => item.geo))];
    const [selectedGeos, setSelectedGeos] = useState(uniqueGeos);
    
    const [geos_were_Dropped, setGeos_were_Dropped] = useState(false);
    const [subsetNamePopUp, set_SubsetNamePopUp] = useState(false);
    
    const [subsetbyDate_Array, setSubsetbyDate_Array] = useState([]);



    
    useEffect(()=>{
        if(subset_Created){
            set_subset_Created(false);
            set_runR_For_CreatedSubset(runR_For_CreatedSubset + 1);
        }
    },[fileObjs]);



    function create_Subset_Function_Pre(){
        if(selectedGeos.length  !==  uniqueGeos.length){
            setGeos_were_Dropped(true);
        }
        else{
            set_SubsetNamePopUp(true);
        }
    }



    function create_Subset_Function(){
       setLoadingCS(true);
       set_subset_Created(true);

       

       
        let array;

        if(subsetbyDate_Array.length > 0){                // if Subset by Date actually took place
            if(selectedGeos.length  !==  uniqueGeos.length){                                      // if Subset by Date took place and also Some geos were dropped
                array = subsetbyDate_Array.filter(item => selectedGeos.includes(item.geo));
            }
            else{                                                                                 // If no geo was dropped
                array = subsetbyDate_Array;      
            }
        }
        else{                                             // if only Subset by Geo took place
            array = entireObj.file_data.filter(item => selectedGeos.includes(item.geo));
        }

        // console.log("Final array before storing it as an object :- ", array);





        const f_Data = array.filter(item => item.testgeo === '0');   // Step 1: Filter the array to get objects where testgeo is '0'
        const geoValues = f_Data.map(item => item.geo);              // Step 2: Map to get the geo values
        const number_of_Non_Test_Geos_ = new Set(geoValues).size;    // Step 3: Create a Set to get unique geo values and get the count



        const location = array[0].geo;
        const datesObj = findCampaignDates(array, location);
        const businessTagsObj = {   business:entireObj.businessTagsObj.business,    category:entireObj.businessTagsObj.category,    product:entireObj.businessTagsObj.product   };


        let newFileObj = [...fileObjs,      {    objId: fileObjs[fileObjs.length-1].objId+1,    file_data:array,    file_name:subset_Name + ".csv",    result:"",    description:uploadData_descriptionX,    datesObj:datesObj,    presentonDB:false,    businessTagsObj:businessTagsObj,    subsetOf:entireObj.file_name,    file_id:"",    Non_Test_Geos:number_of_Non_Test_Geos_    }];
        setFileObjs(newFileObj);
    }




    const findCampaignDates = (data, location) => {    // Calculating for only 1 geo, because this file must have passed through validations and thus campaign value for each date will be same across all geos
      
        const filteredData = data.filter(item => item.geo === location && item.campaign === '1');
  
        const Date_Starting_Campaign = filteredData[0].date.slice(0,5);   
        const middleIndex = Math.floor(filteredData.length / 2);
        const Date_middle_Campaign = filteredData.length % 2 === 0   ?   filteredData[middleIndex - 1].date.slice(0,5)   :   filteredData[middleIndex].date.slice(0,5);
        const Date_Ending_Campaign = filteredData[filteredData.length - 1].date.slice(0,5);
  
        const Date_Starting_Campaign_default = filteredData[0].date;
        const Date_Ending_Campaign_default = filteredData[filteredData.length - 1].date;
  
  
  
  
        const filteredData2 = data.filter(item => item.geo === location && item.campaign === '0');
        
        const Date_Starting_Pre = filteredData2[0].date.slice(0,5);
        const middleIndex_ = Math.floor(filteredData2.length / 2);
        const Date_middle_Pre = filteredData2.length % 2 === 0  ?  filteredData2[middleIndex_ - 1].date.slice(0,5)  :  filteredData2[middleIndex_].date.slice(0,5);
        const Date_Ending_Pre = Date_Starting_Campaign;
  
        const Date_Starting_Pre_default = filteredData2[0].date;
        const Date_Ending_Pre_default = filteredData2[filteredData2.length - 1].date;
  
  
  

        const OriginalDates = data.filter(item => item.geo === location).map(item => item.date.slice(0, 5));




        return {
            Date_Starting_Campaign,
            Date_middle_Campaign,
            Date_Ending_Campaign,
            Date_Starting_Campaign_default,
            Date_Ending_Campaign_default,
            Date_Starting_Pre,
            Date_middle_Pre,
            Date_Ending_Pre,
            Date_Starting_Pre_default,
            Date_Ending_Pre_default,
            OriginalDates
        };
    };





    const [subset_Name, setSubset_Name] = useState('');
    const [subsetName_not_Unique, setSubsetName_not_Unique] = useState(false);
    const [subsetName_not_Unique2, setSubsetName_not_Unique2] = useState(false);

    useEffect(()=>{
        if(filenamesArray.includes(subset_Name + ".csv")){
            setSubsetName_not_Unique(true);
        }
        else{
            setSubsetName_not_Unique(false);
        }
    }, [subset_Name]);

    useEffect(()=>{
        if(fileObjs.some(file => file.file_name === subset_Name + ".csv")){
            setSubsetName_not_Unique2(true);
        }
        else{
            setSubsetName_not_Unique2(false);
        }
    }, [subset_Name]);





    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';






    if (loadingCS) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={Loader} width="180" height="180"/>
            </div>
        )
    }







    return (
        <div    style={{width:"100%", height:"91%"}}>
                        
                        
            <div  style={{width:"100%", height:"10%", display:"flex", alignItems:"center"}}>
                <button     className="back-navigation-business-setup"    onClick={()=>{    setCreateSubset(false);    }}     style={{color:"#6E6E6E", display:"flex"}}>
                    <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                    <span  style={{color:"#0000EE", fontWeight:"400"}}>{exp_Name}</span>
                </button>

                {
                    selectedObjID_forSubset!==null  &&  <p    className="add-new-text"   style={{color:"#6E6E6E"}}>  &nbsp; / &nbsp; {fileObjs.find(obj => obj.objId === selectedObjID_forSubset).file_name}</p>
                }
            </div>


            <div  style={{width:"100%", height:"90%", display:"flex", flexDirection:"column", gap:"1.4rem"}}>


        
                <div  style={{width:"100%", height:"90%", display:"flex", flexDirection:"column",      gap:screen_width === 'default-1536px' ? "1.4rem" : "1.2rem",      padding:screen_width === 'default-1536px' ? "1.6rem 1.6rem 1rem 1.6rem" : "1.3rem 1.3rem 0.8rem 1.3rem",      borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1"}}>

                    <div  style={{width:"100%", height:"9%", display:"flex", justifyContent:"flex-start", alignItems:"flex-end"}}>
                        <button   onClick={()=>{setSubsetBy_Date_or_Geo("date")}}    className="fontF"  style={{padding:screen_width === 'default-1536px' ? "10px 16px" : "8.5px 14px",     fontSize:screen_width === 'default-1536px' ? "18px" : "15px",      color: subsetBy_Date_or_Geo === "date" ? "#1A7AAF" : "#171717",    fontWeight: subsetBy_Date_or_Geo === "date" ? "600" : "400",    borderBottom: subsetBy_Date_or_Geo === "date" ? "2px solid #1A7AAF" : "2px solid #E5E5E5"}}>Subset by Date</button>
                        <button   onClick={()=>{setSubsetBy_Date_or_Geo("geo")}}     className="fontF"  style={{padding:screen_width === 'default-1536px' ? "10px 16px" : "8.5px 14px",     fontSize:screen_width === 'default-1536px' ? "18px" : "15px",      color: subsetBy_Date_or_Geo === "geo"  ? "#1A7AAF" : "#171717",    fontWeight: subsetBy_Date_or_Geo === "geo"  ? "600" : "400",    borderBottom: subsetBy_Date_or_Geo === "geo"  ? "2px solid #1A7AAF" : "2px solid #E5E5E5"}}>Subset by Geo</button>
                        <div  style={{flex:"1", borderBottom:"1px solid #E5E5E5"}}></div>
                    </div>

                    <div  style={{width:"100%",      height:screen_width === 'default-1536px' ? "73%" : "70%"}}>
                        {
                            subsetBy_Date_or_Geo === "date"   ?                                             <SubsetByDate    fileObjs={fileObjs}    selectedObjID_forSubset={selectedObjID_forSubset}    setSubsetbyDate_Array={setSubsetbyDate_Array}                     newStartDate={newStartDate}    setNewStartDate={setNewStartDate}    newEndDate_Pre={newEndDate_Pre}    setNewEndDate_Pre={setNewEndDate_Pre}    newStartDate_Camp={newStartDate_Camp}    setNewStartDate_Camp={setNewStartDate_Camp}    newEndDate={newEndDate}    setNewEndDate={setNewEndDate}    newStartDate_fix={newStartDate_fix}    setNewStartDate_fix={setNewStartDate_fix}    newEndDate_Pre_fix={newEndDate_Pre_fix}    setNewEndDate_Pre_fix={setNewEndDate_Pre_fix}    newStartDate_Camp_fix={newStartDate_Camp_fix}    setNewStartDate_Camp_fix={setNewStartDate_Camp_fix}    newEndDate_fix={newEndDate_fix}    setNewEndDate_fix={setNewEndDate_fix}/>
                                                              :   subsetBy_Date_or_Geo === "geo"    &&      <SubsetByGeo     fileObjs={fileObjs}    selectedObjID_forSubset={selectedObjID_forSubset}    selectedGeos={selectedGeos}    setSelectedGeos={setSelectedGeos}/> 
                        }
                    </div>

                    <div style={{width:"100%", height:screen_width === 'default-1536px' ? "18%" : "21%",      position:"relative", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                        <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1",      fontSize:screen_width === 'default-1536px' ? "12px" : "11px",      top:screen_width === 'default-1536px' ? "-9px" : "-8px",      left:"10px", padding:"0 4px", color:"#404040"}}>Description</h2>
                        <textarea   onChange={description_ChangeX}  value={uploadData_descriptionX} 
                                    className="fontF" style={{width:"100%",  height:"77%",     fontSize:screen_width === 'default-1536px' ? '15px' : '13.5px',     fontWeight:"500",     padding:screen_width === 'default-1536px' ? '6px 12px' : '5px 10px',     border:'1px solid #D1D5DB',  borderRadius:"4px", color:"#171717", outline:"none"}}/>
                        <div  style={{width:"100%", height:"21%", display:"flex", justifyContent:"flex-end"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "12px" : "11px"}}>{uploadData_descriptionX.length}/200</p>
                        </div>
                    </div>

                </div>

                <div  style={{width:"100%", height:"10%",      padding:screen_width === 'default-1536px' ? "12px 24px" : "10px 20px",      borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <div>
                        { 
                            uploadData_descriptionX===''      &&    !( (newStartDate===newStartDate_fix  &&  newEndDate_Pre===newEndDate_Pre_fix  &&  newStartDate_Camp===newStartDate_Camp_fix  &&  newEndDate===newEndDate_fix)    &&    (selectedGeos.length === uniqueGeos.length) )          &&          <p     className="error-texts"    style={{color:"#DC2626"}}>Please provide description for the new subset</p>
                        }
                    </div>

                    <div  style={{display:"flex", gap:"1rem"}}>
                        <button      onClick={()=>{ setCreateSubset(false)   }}         className="footer-button"      style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}    >Cancel</button>
                        <button      onClick={()=>{ create_Subset_Function_Pre() }}     className="footer-button"      style={{borderRadius:"7px", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600", opacity:(  ( (newStartDate === newStartDate_fix    &&    newEndDate_Pre === newEndDate_Pre_fix    &&    newStartDate_Camp === newStartDate_Camp_fix    &&    newEndDate === newEndDate_fix)            &&            (selectedGeos.length === uniqueGeos.length) )                ||                uploadData_descriptionX==='')    ?  "0.6"  :  "1"}}       disabled={(  ( (newStartDate === newStartDate_fix    &&    newEndDate_Pre === newEndDate_Pre_fix    &&    newStartDate_Camp === newStartDate_Camp_fix    &&    newEndDate === newEndDate_fix)            &&            (selectedGeos.length === uniqueGeos.length) )                ||                uploadData_descriptionX==='')}>Create Subset</button>
                    </div>
                </div>
            </div>



            {                                          
                geos_were_Dropped  &&  ( 
                    <div   className={` modal-delete-rows`}      style={{width:screen_width === 'default-1536px' ? "420px" : "350px",      gap:screen_width === 'default-1536px' ? "1.5rem" : "1rem",      padding:screen_width === 'default-1536px' ? "20px 25px" : "16px 20px"}}>

                        <div style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                            <p  style={{fontSize:screen_width === 'default-1536px' ? "24px" : "20px"}}>     Are you sure?    </p>    
                            <p  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>     The following Geos will be dropped from the Dataset:    </p>    
                        </div>

                        <div  style={{width:"100%", marginTop:"0.6rem"}}>
                            <ul style={{listStyleType: "disc", paddingLeft: "1.5rem"}}>
                                {
                                    uniqueGeos.filter(geo => !selectedGeos.includes(geo)).map((key, index) => ( 
                                        <li  className="fontF"  key={index}  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",    fontWeight:"400", color:"#404040", margin:"0 0 0.3rem 0rem"}}>    {key}    </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div    className="discard-business-buttons-container"    style={{display:"flex", justifyContent:"flex-start"}}>
                            <button  onClick={() => { setGeos_were_Dropped(false);  set_SubsetNamePopUp(true) }}         className="discard-business-button"     style={{backgroundColor:"red", borderRadius:"5px", color:"white"}}>Yes, I confirm</button>
                            <button  onClick={() => { setGeos_were_Dropped(false);  setSubsetBy_Date_or_Geo('geo')}}     className="discard-business-button"     style={{backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                geos_were_Dropped    &&    (<div   className={` overlay-delete-rows `}></div>)
            }






            {                                          
                subsetNamePopUp  &&  ( 
                    <div   className={` modal-delete-rows`}  style={{width:screen_width === 'default-1536px' ? "380px" : "320px",    display:"flex",      gap:screen_width === 'default-1536px' ? "1.5rem" : "1rem",      padding:screen_width === 'default-1536px' ? "20px 25px" : "16px 20px"}}>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "17px",      fontWeight:"600"}}>Please give subset name</p>

                        

                        <p>
                            {
                                (subsetName_not_Unique && subsetName_not_Unique2)    ?    <p  className="fontF"  style={{color:"#DC2626", fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}> The Dataset with same name exists in the DB, name should be unique </p>
                                                                                     :    subsetName_not_Unique    ?    <p  className="fontF"  style={{color:"#DC2626", fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}> The Dataset with same name exists in the DB, name should be unique </p>
                                                                                                                   :    subsetName_not_Unique2 && <p  className="fontF"  style={{color:"#DC2626", fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}> The Dataset with same name exists in the session, name should be unique </p>
                            }
                        </p>

                       
                        <div  style={{width:"100%", position:"relative", marginTop:"1rem"}}>
                            <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1", fontSize:screen_width === 'default-1536px' ? "12px" : "11px", top:screen_width === 'default-1536px' ? "-9px" : "-8px", left:"10px", padding:"0 4px", color:"#404040"}}>Subset Name</h2>
                            <input    type="text"  value={subset_Name}    onChange={(e) => {setSubset_Name(e.target.value)}}      className="input-field"   placeholder="Subset Name"/>
                        </div>

                      

                        <div    className="discard-business-buttons-container"    style={{display:"flex", justifyContent:"flex-start"}}>
                            <button  onClick={() => { create_Subset_Function() }}         className="discard-business-button"        style={{width:"50%",  backgroundColor:"green", borderRadius:"5px", color:"white",    opacity:(subset_Name === ''    ||    subsetName_not_Unique    ||    subsetName_not_Unique2) ? "0.6" : "1"}}    disabled={(subset_Name === ''    ||    subsetName_not_Unique    ||    subsetName_not_Unique2)}>Create Subset</button>
                            <button  onClick={() => { set_SubsetNamePopUp(false) }}       className="discard-business-button"        style={{width:"50%",  backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                subsetNamePopUp    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



        </div>
    );
  }
  
  export default DataSubset_Creation;