

import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";





function TVData_Step1({     dimensionsOptions_TV,    data_tv,       selected_Dimensions_TV,    set_selected_Dimensions_TV,    selected_metrics_TV,    set_selected_metrics_TV,    dimsCols_TV,    setDimsCols_TV,    metricsCols_TV,    setMetricsCols_TV,    headers_TV,    setHeaders_TV,    set_All_metrics_Headers_TV,    set_selected_metrics_TV_raw,        aggregated_Data_TV,    set_Aggregated_Data_TV    }) {


    





    const [dimensions_Updated, set_dimensions_Updated] = useState(false);
    const [metrics_Updated, set_metrics_Updated] = useState(false);



    const handleCheckboxChange = (index) => {
        const updated_selected_Dimensions_TV = selected_Dimensions_TV.map((checked, i) =>  i === index   ?   !checked   :   checked);
        set_selected_Dimensions_TV(updated_selected_Dimensions_TV);
        set_dimensions_Updated(true);
    };

    
    useEffect(() => {
        if(selected_Dimensions_TV.includes(true)    &&    dimensions_Updated){
            const res = dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index])
            console.log("Dimensions Selected :- ", res);
        }
    }, [selected_Dimensions_TV]);








    
    
    const handleChange = (event) => {
        const value = event.target.value;

        set_selected_metrics_TV((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((item) => item !== value);    // Remove from array if already selected
            } 
            else { 
                return [...prevSelected, value];                         // Add to array if not selected
            }
        });

        set_metrics_Updated(true);
    };

    
    useEffect(() => {
        if(selected_metrics_TV.length>0    &&    metrics_Updated){
            console.log("Selected Metrics :- ", selected_metrics_TV);
        }
    }, [selected_metrics_TV]);









    useEffect(()=>{
        if(selected_Dimensions_TV.includes(true)    &&    dimensions_Updated){
            const res = dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]);
            
            const filteredData = data_tv.map(obj => {
                return Object.keys(obj).reduce((acc, key) => {
                if (res.includes(key)) {
                    acc[key] = obj[key];
                }
                return acc;
                }, {});
            });
            
            console.log("filteredData :- ", filteredData);
            setDimsCols_TV(filteredData);
        }
    },[selected_Dimensions_TV]);    
 


    useEffect(()=>{
        if(selected_metrics_TV.length>0    &&    metrics_Updated){

            const filteredData = data_tv.map(obj => {
                return Object.keys(obj).reduce((acc, key) => {
                    if (selected_metrics_TV.some(substring => key.includes(substring))) {
                        acc[key] = obj[key];
                    }
                    return acc;
                }, {});
            });
            
            console.log("filteredData :- ", filteredData);
            setMetricsCols_TV(filteredData);
        }
    },[selected_metrics_TV]);











    

    
    useEffect(()=>{

        // console.log("dimsCols :- ", dimsCols);
        // console.log("metricsCols :- ", metricsCols);

        if(selected_Dimensions_TV.includes(true)    &&    selected_metrics_TV.length>0    &&    (dimensions_Updated  ||  metrics_Updated)){
            const mergedArray = dimsCols_TV.map((obj, index) => {      return { ...obj, ...metricsCols_TV[index] };      });
            console.log("mergedArray :- ", mergedArray);


            const out = aggregateData(mergedArray);
            console.log("out :-", out);
            set_Aggregated_Data_TV(out);



            const substrings = ['Impressions', 'rat', 'NGRP'];
            const uniquePrefixes = new Set();
            const uniqueMetrics = new Set();

            Object.keys(out[0]).forEach(key => {
                const match = substrings.find(sub => key.includes(sub));
                if (match) {
                    const prefix = key.split(match)[0];
                    const entension = key.slice(prefix.length);  // Everything after the prefix
                   
                    uniquePrefixes.add(prefix);
                    uniqueMetrics.add(entension);
                }
            });
            const uniquePrefixArray = [...uniquePrefixes];
            const uniqueMetricsArray = [...uniqueMetrics];

            console.log("Unique prefixes of merged dataset:", uniquePrefixArray);
            console.log("uniqueMetricsArray :- ", uniqueMetricsArray);
            set_All_metrics_Headers_TV(uniquePrefixArray);
            set_selected_metrics_TV_raw(uniqueMetricsArray);
            


            const formattedData = Object.keys(out[0]).map(X => ({label:X,  key:X}));
            console.log("formattedData :- ", formattedData);
            setHeaders_TV(formattedData);
        }

    },[dimsCols_TV, metricsCols_TV]);



    function aggregateData(data) {
        const resultMap = new Map();
      

        // Iterate over the data array
        data.forEach(item => {
            const key = `${item.Channel}-${item.Date}`; // Unique key for grouping
            
            if (!resultMap.has(key)) {                  // Initialize the aggregation object
                const parsedItem = { ...item };

                for (const [key, value] of Object.entries(parsedItem)) {
                    if (key.includes("Impressions")    ||    key.includes("rat")    ||    key.includes("NGRP")) {
                        parsedItem[key] = parseFloat(value) || 0;
                    }
                }

                parsedItem.AAAAAAAA = 'newValue_A';    // I have inserted these 4 columns or 4 key-value pairs to show that these will be removed when this Map "resultMap" will be converted into Array of Objects
                parsedItem.AAAAAABB = 'newValue_A';  
                parsedItem.AAAAAACC = 'newValue_A';  
                parsedItem.AAAAAADD = 'newValue_A';  
                resultMap.set(key, parsedItem);
            } 
            else {
                const existing = resultMap.get(key);      // Aggregate the "Impressions" values
                for (const [key, value] of Object.entries(item)) {
                    if (key.includes("Impressions")    ||    key.includes("rat")    ||    key.includes("NGRP")) {
                        existing[key] = (parseFloat(existing[key]) || 0) + (parseFloat(value) || 0);
                    }
                }
            }
        });

        console.log("resultMap :- ", resultMap);
      


        
        // The entire code below convert the map "resultMap" to an array of Objects  AND also It retains only columns which are present in res[] & columns containing "Impressions", "NGRP", "rat",    So basically columns AAAAAAAA, AAAAAABB, AAAAAACC, AAAAAADD will be removed
        const res = dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]);
        console.log("res :-", res);

        return Array.from(resultMap.values()).map(item => ({

            ...res.reduce((acc, key) => {
                if (key in item) {
                  acc[key] = item[key];
                }
                return acc;
            }, {}),



            ...Object.fromEntries(
                Object.entries(item).filter(([key]) => 
                  ["Impressions", "NGRP", "rat"].some(substring => key.includes(substring))  // Check if key includes any of these substrings
                )
              )
        }));

    }







    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"", gap:"1.6rem"}}>

            
            <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>Select Dimensions</div>
                
                <div    style={{width:"100%", height:"87%", backgroundColor:"", padding:"1.6rem 2rem 2.2rem 2rem"}}>
                    <ul  style={{width:"100%", height:"100%", overflowY:"scroll",  display:"flex", flexDirection:"column", gap:"0.4rem", fontSize:"16.2px", color:"#474747", fontWeight:"500"}}>
                        {
                            dimensionsOptions_TV.map((item, index) => (
                                <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                    <label style={{ display: "flex", gap: "0.6rem", cursor: "pointer" }}>
                                        <input   type="checkbox"   checked={selected_Dimensions_TV[index]}     onChange={() => handleCheckboxChange(index)}/>
                                        <p>{item}</p>
                                    </label>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>


            <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>Select Metrics</div>

                <div style={{width:"100%", height:"87%", backgroundColor:"", padding:"1.6rem 2rem 2.2rem 2rem"}}>
                    

                    <div    style={{width:"100%", height:"100%", overflowY:"scroll",  display:"flex", flexDirection:"column", gap:"0.4rem", fontSize:"16.2px", color:"#474747", fontWeight:"500"}}>
                        <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                            <input    type="checkbox"    value="Impressions"    checked={selected_metrics_TV.includes("Impressions")}     onChange={handleChange}/>
                            <p>Impressions</p>
                        </label>

                        <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                            <input    type="checkbox"    value="rat"            checked={selected_metrics_TV.includes("rat")}             onChange={handleChange}/>
                            <p>rat</p>
                        </label>

                        <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                            <input    type="checkbox"    value="NGRP"           checked={selected_metrics_TV.includes("NGRP")}            onChange={handleChange}/>
                            <p>NGRP</p>
                        </label>
                    </div>


                </div>
            </div>


            <div  style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                
                <div>
                    {   
                        (selected_Dimensions_TV.includes(true)    &&    selected_metrics_TV.length>0)  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={aggregated_Data_TV}    headers={headers_TV}    filename={"Aggregated_file"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>
                        </div>
                    }
                </div>

            </div>
    
        </div>
    );
  }
  
  export default TVData_Step1;
