

import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import TVData_Step1 from "../Process_Different_Channels_data/TVData_Steps/TVData_Step1";
import TVData_Step2 from "../Process_Different_Channels_data/TVData_Steps/TVData_Step2";
import TVData_Step3 from "../Process_Different_Channels_data/TVData_Steps/TVData_Step3";




function MMM_Process_TV_data({    dimensionsOptions_TV,    data_tv,    Sales_states,    Sales_Dates,    selected_Dimensions_TV,    set_selected_Dimensions_TV,    selected_metrics_TV,    set_selected_metrics_TV,    dimsCols_TV,    setDimsCols_TV,    metricsCols_TV,    setMetricsCols_TV,    headers_TV,    setHeaders_TV,    statesPicked_Overall_TV,    set_statesPicked_Overall_TV,    All_metrics_Headers_TV,    set_All_metrics_Headers_TV,    mappingArr,    setMappingArr,    headers_TV_Step2,    set_headers_TV_Step2,    unMappedRegions_TV,    set_unMappedRegions_TV,    headers_TV_Step3,    set_headers_TV_Step3,    unique_Channels_in_TV,    set_unique_Channels_in_TV,     mapping_Arr_TV_Step_3,    set_mapping_Arr_TV_Step_3,    non_Aggregated_Variables_TV,    set_non_Aggregated_Variables_TV,    Overall_Variables_Picked_for_Aggregation_TV,    set_Overall_Variables_Picked_for_Aggregation_TV,    after_Variables_Aggregation,    set_after_Variables_Aggregation,    headers_TV_Step3_,    set_headers_TV_Step3_,    setData_tv,    selected_metrics_TV_raw,    set_selected_metrics_TV_raw,    States_Names_Used,    set_States_Names_Used,    aggregated_Data_TV,    set_Aggregated_Data_TV,    after_Regional_Mapping_arr_TV,    set_After_Regional_Mapping_arr_TV,    wide_Format_Data_TV,    set_Wide_Format_Data_TV,    Variable_Aggregation_Record_TV,    set_Variable_Aggregation_Record_TV,    overAll_Variable_Groups,    set_overAll_Variable_Groups,    set_process_Card_Selected


    }) {




    const navigate = useNavigate();







    useEffect(()=>{
        if(Sales_Dates.length > 0    &&    data_tv.length > 0){  // If user deleted the existing Print file and re-uploads another one, for that scenario we have to build some logics
            const filteredData = data_tv.filter(item => Sales_Dates.includes(item.Date));
            console.log("Data after discarding additional dates of TV File :- ", filteredData);
            setData_tv(filteredData);
        }
    },[Sales_Dates]);







    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';



    const [TV_Process_Step_Number, set_TV_Process_Step_Number] = useState(1);

    const [groupingName, set_groupingName] = useState('');
    const [uploadData_descriptionX, setUploadData_descriptionX] = useState('');
    const [aggregate_Variables_Screen, set_aggregate_Variables_Screen] = useState(false);
    const [disable_grp_name_desc, set_disable_grp_name_desc] = useState(false);






    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column", backgroundColor:"", }}>
    


                <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", position:"", backgroundColor:""}}>
                    <div  style={{width:"100%", height:"86%", display:"flex", flexDirection:"column", alignItems:"center",      gap:screen_width === 'default-1536px' ? "1.8rem" : "1rem", backgroundColor:""}}>
                    

                        <div  style={{width:"100%", height:"40px", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"", position:"relative"}}>


                            <div  style={{width:"100%", display:"flex", alignItems:"center", backgroundColor:"", position:"absolute", top:"", left:""}}>
                                <button    className="back-navigation-business-setup"   onClick={()=>{    set_process_Card_Selected('')    }}   style={{color:"#6E6E6E", display:"flex", gap:"0.3rem", backgroundColor:"", alignItems:""}}>
                                    <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                                    <span  style={{color:"#0000EE", fontWeight:"400", fontSize:"17px"}}>Television</span>
                                </button>

                                {/* <p  className="add-new-text"  style={{color:"#6E6E6E", fontSize:"15.5px"}}>  &nbsp; </p> */}
                            </div>

                            
                            <div style={{display:"flex", gap:"8px", alignItems:"center"}}>
                                

                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"38px", height:"38px", borderRadius:"50%", backgroundColor:"#16618E", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:"#FFFFFF", fontSize:"16px", fontWeight:"600"}}>1</span>    </div>
                                    <p    className='fontF'  style={{color:"#3D3D3D", fontSize:"16px", fontWeight:"600"}}>Select Variables</p>      {/* for text */}
                                </div>                                
                                <div  style={{width:"64px", height:"0", border:TV_Process_Step_Number === 1 ? "1px dashed gray" : "1px dashed #16618E"}}></div>


                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"38px", height:"38px", border:TV_Process_Step_Number === 1 ? "2px solid gray" : "none",    borderRadius:"50%", backgroundColor:TV_Process_Step_Number === 1 ? "#f1f1f1" : "#16618E", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:TV_Process_Step_Number === 1 ? "#3D3D3D" : "#FFFFFF", fontSize:"16px", fontWeight:"600"}}>2</span>    </div>
                                    <p    className='fontF'  style={{color:"#3D3D3D", fontSize:"16px", fontWeight:"600"}}>Map Geos</p>      {/* for text */}
                                </div>
                                <div  style={{width:"64px", height:"0", border:TV_Process_Step_Number === 3 ? "1px dashed #16618E" : "1px dashed gray"}}></div>


                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"38px", height:"38px", border:TV_Process_Step_Number === 3 ? "none" : "2px solid gray",    borderRadius:"50%", backgroundColor:TV_Process_Step_Number === 3 ? "#16618E" : "#f1f1f1", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:TV_Process_Step_Number === 3 ? "#FFFFFF" : "#3D3D3D", fontSize:"16px", fontWeight:"600"}}>3</span>    </div>
                                    <p    className='fontF'  style={{color:"#3D3D3D", fontSize:"16px", fontWeight:"600"}}>Define Dimensions</p>      {/* for text */}
                                </div>


                            </div>

                        </div>

                        <div  style={{width:"100%", height:"383px", backgroundColor:""}}>  
                            {
                                TV_Process_Step_Number === 1    ?   <TVData_Step1      dimensionsOptions_TV={dimensionsOptions_TV}    data_tv={data_tv}       selected_Dimensions_TV={selected_Dimensions_TV}    set_selected_Dimensions_TV={set_selected_Dimensions_TV}    selected_metrics_TV={selected_metrics_TV}    set_selected_metrics_TV={set_selected_metrics_TV}    dimsCols_TV={dimsCols_TV}    setDimsCols_TV={setDimsCols_TV}    metricsCols_TV={metricsCols_TV}    setMetricsCols_TV={setMetricsCols_TV}    headers_TV={headers_TV}    setHeaders_TV={setHeaders_TV}      set_All_metrics_Headers_TV={set_All_metrics_Headers_TV}    set_selected_metrics_TV_raw={set_selected_metrics_TV_raw}        aggregated_Data_TV={aggregated_Data_TV}    set_Aggregated_Data_TV={set_Aggregated_Data_TV}/>
                                                                :   TV_Process_Step_Number === 2    ?    <TVData_Step2      selected_metrics_TV={selected_metrics_TV}    statesPicked_Overall_TV={statesPicked_Overall_TV}    set_statesPicked_Overall_TV={set_statesPicked_Overall_TV}    All_metrics_Headers_TV={All_metrics_Headers_TV}    mappingArr={mappingArr}    setMappingArr={setMappingArr}    headers_TV_Step2={headers_TV_Step2}    set_headers_TV_Step2={set_headers_TV_Step2}    unMappedRegions_TV={unMappedRegions_TV}    set_unMappedRegions_TV={set_unMappedRegions_TV}    set_unique_Channels_in_TV={set_unique_Channels_in_TV}    selected_metrics_TV_raw={selected_metrics_TV_raw}    States_Names_Used={States_Names_Used}    set_States_Names_Used={set_States_Names_Used}    aggregated_Data_TV={aggregated_Data_TV}    Sales_states={Sales_states}       after_Regional_Mapping_arr_TV={after_Regional_Mapping_arr_TV}    set_After_Regional_Mapping_arr_TV={set_After_Regional_Mapping_arr_TV}      headers_TV_Step3={headers_TV_Step3}    set_headers_TV_Step3={set_headers_TV_Step3}    wide_Format_Data_TV={wide_Format_Data_TV}    set_Wide_Format_Data_TV={set_Wide_Format_Data_TV}/>
                                                                                                    :    TV_Process_Step_Number === 3    &&    <TVData_Step3    unique_Channels_in_TV={unique_Channels_in_TV}    mapping_Arr_TV_Step_3={mapping_Arr_TV_Step_3}    set_mapping_Arr_TV_Step_3={set_mapping_Arr_TV_Step_3}    non_Aggregated_Variables_TV={non_Aggregated_Variables_TV}    set_non_Aggregated_Variables_TV={set_non_Aggregated_Variables_TV}    Overall_Variables_Picked_for_Aggregation_TV={Overall_Variables_Picked_for_Aggregation_TV}    set_Overall_Variables_Picked_for_Aggregation_TV={set_Overall_Variables_Picked_for_Aggregation_TV}    after_Variables_Aggregation={after_Variables_Aggregation}    set_after_Variables_Aggregation={set_after_Variables_Aggregation}    headers_TV_Step3_={headers_TV_Step3_}    set_headers_TV_Step3_={set_headers_TV_Step3_}    Variable_Aggregation_Record_TV={Variable_Aggregation_Record_TV}    set_Variable_Aggregation_Record_TV={set_Variable_Aggregation_Record_TV}    groupingName={groupingName}    set_groupingName={set_groupingName}    uploadData_descriptionX={uploadData_descriptionX}    setUploadData_descriptionX={setUploadData_descriptionX}    aggregate_Variables_Screen={aggregate_Variables_Screen}    set_aggregate_Variables_Screen={set_aggregate_Variables_Screen}    disable_grp_name_desc={disable_grp_name_desc}    set_disable_grp_name_desc={set_disable_grp_name_desc}    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}/>
                            }
                        </div>                  


                    </div>



                    <div  style={{width:"100%", height:"10%",    padding:screen_width === 'default-1536px' ? "12px 24px" : "10px 24px",    borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                        <div>
                            {/* { 
                                ASD    ?    <p    className="error-texts"    style={{color:"#DC2626"}}>CSV file contains Errors, Click on "Show Errors" for more details</p>
                                        :    !(showBtn  &&  business_Val  &&  category_Val  &&  product_Val   &&  !duplicateFileNameError  &&  file  &&  uploadData_descriptionX)  &&  <p    className="error-texts"    style={{color:"#DC2626"}}>All fields must be filled in before saving</p>
                            } */}
{/* 
                            {
                                TV_Process_Step_Number === 3    &&    <p    className="error-texts"    style={{color:"darkblue", fontWeight:"500"}}>Variable aggregation is an optional step. You can proceed by clicking the Save button.</p>
                            } */}
                            
                        </div>

                        <div  style={{display:"flex", gap:"1rem"}}>
                            {
                                TV_Process_Step_Number === 2  &&  <button      onClick={()=>{ set_TV_Process_Step_Number(TV_Process_Step_Number-1) }}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}   >Previous</button>
                            }


                            {
                                (TV_Process_Step_Number === 3  &&  aggregate_Variables_Screen)    &&    <button      onClick={()=>{    set_aggregate_Variables_Screen(false);    set_groupingName('');    setUploadData_descriptionX('');    set_disable_grp_name_desc(false)    }}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}   >Previous</button>
                            }

                            {
                                (TV_Process_Step_Number === 3  &&  !aggregate_Variables_Screen)    &&    <button      onClick={()=>{ set_TV_Process_Step_Number(TV_Process_Step_Number-1) }}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}   >Previous</button>
                            }

                            
                            {
                                TV_Process_Step_Number !== 3  &&
                                <button      onClick={()=>{ TV_Process_Step_Number !== 3  &&  set_TV_Process_Step_Number(TV_Process_Step_Number+1) }}    className="footer-button"    style={{borderRadius:"7px",  border:"2px solid #1A7AAF", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600",
                                                                                                opacity: ((TV_Process_Step_Number === 1    &&    !(dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]).includes("Channel")    &&    dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]).includes("Genre")    &&    dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]).includes("Date")    &&    (selected_metrics_TV.includes("Impressions")    ||    selected_metrics_TV.includes("rat")    ||    selected_metrics_TV.includes("NGRP"))  ))    ||    (TV_Process_Step_Number === 2          &&          mappingArr.length === 0))    ?    "0.6"    :    "1"
                                                            }}        

                                                                                                disabled={                 (TV_Process_Step_Number === 1          &&          !(dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]).includes("Channel")    &&    dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]).includes("Genre")    &&    dimensionsOptions_TV.filter((item, index) => selected_Dimensions_TV[index]).includes("Date")    &&    (selected_metrics_TV.includes("Impressions")    ||    selected_metrics_TV.includes("rat")    ||    selected_metrics_TV.includes("NGRP")) ))
                                                                                                                    ||   (TV_Process_Step_Number === 2          &&          mappingArr.length === 0)        }

                                                                >Next</button>
                            }

                            {
                                TV_Process_Step_Number === 3  &&
                                <button      onClick={()=>{  set_process_Card_Selected('')  }}    className="footer-button"    style={{  borderRadius:"7px",  border:"2px solid #1A7AAF", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600"  }}>Skip</button>
                            }
                            
                        </div>
                    </div>
                
                </div>

    
        </div>
    );
  }
  
  export default MMM_Process_TV_data;