function MMM_Process_SL_data() {


    return (
        <div  style={{width:"100%",  height:"100%",  display:"flex", justifyContent:"center", alignItems:"center", fontSize:"24px"}}>
    
    MMM_Process_SL_data
    
        </div>
    );
  }
  
  export default MMM_Process_SL_data;