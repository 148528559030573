import Papa from "papaparse";
import { useEffect, useState } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { FiArrowRight } from "react-icons/fi";
import { IoMdArrowBack } from "react-icons/io";
import { sendDataTo_R_API_Placebo } from "../../Upload_Retrieve_DB_Data/ProfileAPI";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';   // Because I imported and registered this here, It will be applied to entire Project folder, SO for others I have to set  datalabels: {   display: false    },
ChartJS.register(ChartDataLabels);

function PlaceboAnalysis({exp_Name,      fileObjs,      setActiveTab}) {





    const [currentPage, setCurrentPage] = useState(1);
    let itemsPerPage = 7;



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400){
        screen_width = '1200_1400';
        itemsPerPage = 6;
    }



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = fileObjs.slice(indexOfFirstItem, indexOfLastItem);   // Items to display on current page


    const totalPages = Math.ceil(fileObjs.length / itemsPerPage);


    const startItem = indexOfFirstItem + 1;                             // For displaying 1-8 of 18
    const endItem = Math.min(indexOfLastItem, fileObjs.length);      // For displaying 1-8 of 18




    const heightX = itemsPerPage === 7    ?    currentItems.length===1 ? "24.3%" : currentItems.length===2 ? "36.95%" : currentItems.length===3 ? "49.55%" : currentItems.length===4 ? "62.15%" : currentItems.length===5 ? "74.8%"  :  currentItems.length===6 ? "87.4%" : "100%"
                                          :    currentItems.length===1 ? "27.54%" : currentItems.length===2 ? "42.025%" : currentItems.length===3 ? "56.5%" :  currentItems.length===4 ? "71.025%" : currentItems.length===5 ? "85.5%"  :  "100%"





    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };







    const [file_name_Popup, setFile_name_Popup] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState('');
    const [subset_of_Popup, setSubset_of_Popup] = useState(false);
    const [selectedSubsetof, setSelectedSubsetof] = useState('');
    const [description_Popup, setDescription_Popup] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');






    const [placebo_Details_popup, set_placebo_Details_popup] = useState(false);
    const [selected_Dataset_for_Placebo, set_selected_Dataset_for_Placebo] = useState([]);
    const [selected_objID_for_Placebo, set_selected_objID_for_Placebo] = useState(-1);
    const [total_controls_for_Selected_Dataset, set_total_controls_for_Selected_Dataset] = useState(0);
    
    const [error_Controls, set_error_Controls] = useState(false);
    const [error_Iterations, set_error_Iterations] = useState(false);
    const [controls, set_Controls] = useState('');
    const [iterations, set_Iterations] = useState('');


    const handleChange = (valueX) => {

        if(valueX === ""){
            set_Controls(valueX);
            set_error_Controls(false);
        } 
        else{
            const numericValue = Number(valueX);

            if (isNaN(numericValue)  ||  numericValue < 5  ||  numericValue > total_controls_for_Selected_Dataset) {
                console.log("Error: Input must be a numeric value between 5 and " + total_controls_for_Selected_Dataset);
                set_error_Controls(true);
                set_Controls(valueX);
            } 
            else {
                set_Controls(numericValue);
                set_error_Controls(false);
            }
        }

    };

    const handleChange2 = (valueX) => {
        if(valueX === ""){
            set_Iterations(valueX);
            set_error_Iterations(false);
        } 
        else{
            const numericValue = Number(valueX);

            if (isNaN(numericValue)  ||  numericValue < 10  ||  numericValue > 500) {
                console.log("Error: Input must be a numeric value between 10 and 500");
                set_error_Iterations(true);
                set_Iterations(valueX);
            } 
            else {
                set_Iterations(numericValue);
                set_error_Iterations(false);
            }
        }
    };








    function fillDateGaps(arr) {
        const result = [];
        let currentDate = new Date(arr[0].date);
        const endDate = new Date(arr[arr.length - 1].date);
      
        while (currentDate <= endDate) {
            const dateString = currentDate.toISOString().split('T')[0];
            const foundItem = arr.find(item => item.date === dateString);
            result.push({
                date: dateString,
                actual_metric: foundItem ? foundItem.actual_metric : null,
                synthetic_metric: foundItem ? foundItem.synthetic_metric : null
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }
      
        return result;
    }

    const [placebo_output, set_placebo_output] = useState(null);
    const [placebo_Loader, set_placebo_Loader] = useState(false);

    const arrayToCSV = (arr) => {               // Function to convert array of objects to CSV string
        const csv = Papa.unparse(arr); 
        return csv;
    };
    const csvToBlob = (csv) => {                // Function to convert CSV string to Blob
        const blob = new Blob([csv], { type: 'text/csv' });
        return blob;
    };
    const blobToFile = (blob, fileName) => {    // Function to convert Blob to File
        const file = new File([blob], fileName, { type: 'text/csv' });
        return file;
    };

    async function run_placebo_R(){

            set_placebo_Loader(true);


            const csvString = arrayToCSV(selected_Dataset_for_Placebo);
            const csvBlob = csvToBlob(csvString);
            const csvFile = blobToFile(csvBlob, "Obj.file_name");

            const formData = new FormData();
            formData.append('file', csvFile);


            const R_output = await sendDataTo_R_API_Placebo( formData, Number(controls), Number(iterations));
            
            if(R_output){
                console.log("R_output :- ", R_output);             
                
                // const formattedData = R_output.synctrl_result.graph_data[0].reduce((acc, curr) => {   
                const formattedData = R_output.placebo_result.mean_graph_data.reduce((acc, curr) => {                   
                    const existingEntry = acc.find(item => item.date === curr.date);
                    if (existingEntry) {
                        existingEntry[`${curr.data_type}_metric`] = curr.metric;
                    } 
                    else {
                        acc.push({
                            date: curr.date,
                            actual_metric: curr.data_type === 'actual' ? curr.metric : null,
                            synthetic_metric: curr.data_type === 'synthetic' ? curr.metric : null,
                        });
                    }
                    return acc;
                }, []);

                

                // console.log("formattedData :- ", formattedData);
                const filledArray = fillDateGaps(formattedData);
                // console.log("filledArray :- ", filledArray);



                const object_res = {
                    graph_data: filledArray,
                    // lift: R_output.placebo_result
                    lift: R_output.placebo_result.mean_lift
                };

                set_placebo_output(object_res);
            }
  
    }
    
    const [chart1, setChart1] = useState([]);
    const [lift1, setLift1] = useState([]);
    const [selectedObjID, setSelectedObjID] = useState(null);
    const [show_Placebo_Results, set_show_Placebo_Results] = useState(false);
    

    function run_model_func(){
  
        setChart1(placebo_output.graph_data);
        setLift1(placebo_output.lift);
        setSelectedObjID(selected_objID_for_Placebo);

        set_placebo_Loader(false);
        set_placebo_Details_popup(false)
        set_show_Placebo_Results(true);
    }

    useEffect(()=>{
        if(placebo_output !== null){
            run_model_func()
        }
    },[placebo_output]);
      



    let dataChart = {};
    let optionChart = {};
    if(chart1.length > 0    &&    selectedObjID!==null){

        const maxActual = Math.max(...chart1.map(data => data.actual_metric));
        const maxSynthetic = Math.max(...chart1.map(data => data.synthetic_metric));
        const maxYValue = Math.max(maxActual, maxSynthetic);
        const datesObj_ = fileObjs.find(obj => obj.objId === selectedObjID).datesObj;



        let lastDatebeforeGap = null;                          // this code snippet will act when pre period and campaign period are not connected
        let lastBiggestMetricBeforeGap = null;
        let firstDateAfterGap = null;                          
        let FirstBiggestMetricAfterGap = null;

        for(let i=0; i<chart1.length; i++){
            let flag = false;

            if(chart1[i].actual_metric === null    &&    chart1[i].synthetic_metric === null){
                flag = true;

                lastDatebeforeGap = chart1[i-1].date.slice(-5);
                lastBiggestMetricBeforeGap = Math.max(chart1[i-1].actual_metric, chart1[i-1].synthetic_metric);

                for(let j=i+1; j<chart1.length; j++){
                    if(chart1[j].actual_metric !== null    &&    chart1[j].synthetic_metric !== null){
                        firstDateAfterGap = chart1[j].date.slice(-5);
                        FirstBiggestMetricAfterGap = Math.max(chart1[j].actual_metric, chart1[j].synthetic_metric);
                        break;
                    }
                }
            }

            if(flag) break;
        }



        dataChart = {
            labels: chart1.map((data) => data.date.slice(-5)),
            datasets: [
                {
                    label: "Actual",
                    data: chart1.map((data) => data.actual_metric),
                    pointBackgroundColor: "#6e6e6e",
                    backgroundColor:"#6e6e6e",
                    borderColor: "#6e6e6e",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },

                    xAxisId: 'x-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                    yAxisId: 'y-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis

                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph

                },
                {
                    label: "Synthetic",
                    data: chart1.map((data) => data.synthetic_metric),
                    pointBackgroundColor: "#2899cf",
                    borderColor: "#2899cf",
                    backgroundColor:"#2899cf",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },
                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph
                },
                {
                    type: 'line',
                    label: '',
                    data: [
                        // { x: date_Starting_Campaign, y: 0 },
                        { x: datesObj_.Date_Starting_Campaign, y: maxYValue },
                        { x: datesObj_.Date_middle_Campaign, y: maxYValue },
                        { x: datesObj_.Date_Ending_Campaign, y: maxYValue },
                        
                        // { x: date_Ending_Campaign, y: 0 }
                    ],
                    fill: true,
                    backgroundColor: '#EEEEEE',
                    borderColor: 'white',
                    tension: 0,
                    borderWidth: 0.3,
                    pointRadius: 0,
                    
                    datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 0,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Campaign Period";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                        else {                             return '';                    }
                    }
                    }
                },

                {
                    type: 'line',
                    label: '',
                    data: [
                        { x: lastDatebeforeGap, y: 0 },
                        { x: lastDatebeforeGap, y: lastBiggestMetricBeforeGap },
                    ],
                    fill: true,
                    borderColor: 'gray', 
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            
                {
                    type: 'line',
                    label: '',
                    data: [
                    { x: firstDateAfterGap, y: 0 },
                    { x: firstDateAfterGap, y: FirstBiggestMetricAfterGap },
                    ],
                    fill: true,
                    borderColor: 'gray',
                    tension: 0,
                    borderWidth: 0.5,
                    pointRadius: 0,
                    
                    datalabels: {    display: false    }
                },
            ]
        }
        optionChart = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            // animation: {
            //   duration: 2000,
            //   easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
            //   animateScales: true,

                // onStart: function(animation) {
                //   console.log('Animation started');
                // },
                // onComplete: function(animation) {
                //   console.log('Animation completed');
                // },
            // },


            plugins: {
                datalabels: {
                    font: {
                        family: "Toboggan",        // To change font family of data labels on charts
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "11.8px" : "10px"
                    }
                },
                legend: {
                labels: {
                    boxWidth: screen_width === 'default-1536px' ? 12 : 10,       // For making legend square
                    font: {
                        family: "Toboggan",         // To change font family of label(label is present on top of chart)
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "12px" : "10px"
                    }
                }
                }
            
            },


            scales: {
                x: {
                    id: 'x-axis1',
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of Y-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"  
                        }
                    }
                },
                y: {
                    beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

                    ticks:{
                        callback: function(value){
                            return "₹" + value
                        },
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"
                        }
                    },

                    id: 'y-axis1',
                    grid: {
                        display: false
                    }
                }
            }
        }
    }







    const [showGraph, setShowGraph] = useState(false);

    const handleToggle = () => {
        if(showData){
            setShowData(prevState => !prevState);
        }
        setShowGraph(prevState => !prevState);
    };

    const [showData, setShowData] = useState(false);

    const handleToggle2 = () => {
        if(showGraph){
            setShowGraph(prevState => !prevState);
        }
        setShowData(prevState => !prevState);
    };







    









    if (fileObjs.length === 0) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>

                <div    style={{ width: "100%", height: "9%",     padding:screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p    className="header-title" style={{ fontWeight: "600" }}>Placebo Analysis</p>
                    <div  className="fontF">DP(Name and Role)</div>
                </div>

                <div  style={{width:"100%", flex:"1",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px", marginTop:screen_width === 'default-1536px' ? "1.4rem" : "1.2rem",    display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>
                    <svg    style={{width:screen_width === 'default-1536px' ? "290px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </svg>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "28px" : "24.5px", color:"#111827", fontWeight:"600"}}>No Experiment in the session</p>

                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Either Create or Load an Experiment in session to start Placebo Analysis</p>
                    </div>
                    
                    <NavLink      onClick={()=>{setActiveTab("/experiment-and-data")}}    to={"/experiment-and-data"}    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontSize:screen_width === 'default-1536px' ? "18px" : "15px", display:"flex", alignItems:"center", gap:"0.4rem"}}     className="footer-button" >
                        Go to Experiment Page
                        <FiArrowRight  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                    </NavLink>

                </div>

            </div>
        )
    }






    return (
        <div  style={{width:"100%",  height:"100%", display: "flex", flexDirection: "column", position:"relative"}}>


        <div    style={{ width: "100%", height: "9%",     padding:screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <p    className="header-title" style={{ fontWeight: "600" }}>Placebo Analysis</p>
            <div  className="fontF">DP(Name and Role)</div>
        </div>


        {
            !show_Placebo_Results 
            ?
                <div  style={{width:"100%", flex:"1",      padding:screen_width === 'default-1536px' ? "1.6rem 1.6rem 0 1.6rem" : "1.33rem 1.33rem 0 1.33rem",      boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px", marginTop:screen_width === 'default-1536px' ? "1.4rem" : "1.2rem"}}>
                    
                    <div  style={{width:"100%", height:"10%", display:"flex", justifyContent:"flex-start", alignItems:"flex-start"}}>
                        <p  className="fontF"  style={{fontWeight:"600",      fontSize:screen_width === 'default-1536px' ? "22px" : "18.5px",      color:"#171717"}}>{exp_Name}</p>
                    </div>



                    
                    <div  style={{ width: "100%", height:"90%", display:"flex", flexDirection: "column", justifyContent: "flex-start"}}>

                        <div    style={{ width:"100%", height:screen_width === 'default-1536px' ? "88%" : "86%"}}>
                            <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",  height:heightX,    backgroundColor: "white", color:"#171717" }}>
                                <thead  style={{width:"100%"}}>
                                        <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                            <th    style={{width:"5%",       padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                            <th    style={{width:"19%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>File Name</th>
                                            <th    style={{width:"19%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Description</th>
                                            <th    style={{width:"19%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start",}}>Subset of</th>
                                            <th    style={{width:"19%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start",}}>Total Controls</th>
                                            <th    style={{width:"19%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}></th>
                                        </tr>
                                </thead>

                                <tbody  style={{width:"100%"}}>
                                    {
                                        currentItems.map((obj, index) => {
                                                return(
                                                    <tr key={index}  style={{width:"100%"}}>
                                                        <td      style={{width:"5%" , borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}></td>
                                                        <td      style={{width:"19%", borderBottom:"1px solid #ddd", textAlign:"start"}}>
                                                            {
                                                                screen_width === 'default-1536px'   ?
                                                                                                        obj.file_name.length > 18       ?   <div  style={{display:"flex"}}>
                                                                                                                                                <p>{obj.file_name.slice(0,16)}</p>
                                                                                                                                                <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                            </div>  
                                                                                                                                        :   obj.file_name
                                                                                                    :
                                                                                                        obj.file_name.length > 14       ?   <div  style={{display:"flex"}}>
                                                                                                                                                <p>{obj.file_name.slice(0,14)}</p>
                                                                                                                                                <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                            </div>  
                                                                                                                                        :   obj.file_name
                                                            }
                                                        </td>
                                                        
                                                        <td      style={{width:"19%", borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button    onClick={()=>{setDescription_Popup(true);  setSelectedDescription(obj.description)}}    style={{color:"blue"}}>description</button>    </td>

                                                        <td      style={{width:"19%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                            {
                                                                obj.subsetOf === null || obj.subsetOf === ""    ?    "NA" 
                                                                                                                :    screen_width === 'default-1536px'  ?
                                                                                                                                                            obj.subsetOf.length > 20       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                                    <p>{obj.subsetOf.slice(0,18)}</p>
                                                                                                                                                                                                    <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subsetOf)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                                                </div>  
                                                                                                                                                                                            :   obj.subsetOf
                                                                                                                                                        :
                                                                                                                                                            obj.subsetOf.length > 16       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                                    <p>{obj.subsetOf.slice(0,15)}</p>
                                                                                                                                                                                                    <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subsetOf)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                                                </div>  
                                                                                                                                                                                            :   obj.subsetOf
                                                            }
                                                        </td>

                                                        <td      style={{width:"19%" , borderBottom:"1px solid #ddd", textAlign:"start"}}>
                                                            {
                                                                obj.Non_Test_Geos
                                                            }
                                                        </td>
                                                        
                            

                                                        <td      style={{width:"19%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                            {
                                                                (selectedObjID === obj.objId   &&   lift1.length>0)
                                                                ?   
                                                                    <button      onClick={()=>{     set_show_Placebo_Results(true)    }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >
                                                                        See Results
                                                                    </button>
                                                                :
                                                                    <button      onClick={()=>{     set_selected_Dataset_for_Placebo(obj.file_data);    set_selected_objID_for_Placebo(obj.objId);    set_total_controls_for_Selected_Dataset(obj.Non_Test_Geos);    set_placebo_Details_popup(true)    }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >
                                                                        Run Placebo
                                                                    </button>
                                                            }
                                                        </td>
                                                    
                                                    </tr>
                                                );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>


                        <div    style={{ width:"100%",      height:screen_width === 'default-1536px' ? "12%" : "14%",      backgroundColor: "white", display: "flex", justifyContent: "flex-end", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                            <div style={{ display: "flex", gap: "2rem",      paddingRight:screen_width === 'default-1536px' ? "2rem" : "1.75rem" }}>
                                
                                <p style={{ display: "flex", alignItems: "center",    fontSize:screen_width === 'default-1536px' ? "16px" : "14px" }}>
                                    {`${startItem}-${endItem} of ${fileObjs.length}`}
                                </p>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <button onClick={handlePrevPage}  disabled={currentPage === 1}            style={{opacity : currentPage===1           ?  "0.3" : "1"}}>         <GoChevronLeft  fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                    <button onClick={handleNextPage}  disabled={currentPage === totalPages}   style={{opacity : currentPage===totalPages  ?  "0.3" : "1"}}>         <GoChevronRight fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            :
                <div  style={{width:"100%", flex:"1", marginTop:screen_width === 'default-1536px' ? "1.4rem" : "1.2rem",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                    {
            
                        chart1.length>0  &&  
                            <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                                
                                
                                <div  style={{width:"calc(100% - 2.5rem)", height:"14%", display:"flex", justifyContent:"space-between", alignItems:"center", borderBottom:"1px solid #E5E5E5"}}>
                                    
                                    <div  style={{display:"flex", alignItems:"center"}}>
                                        <button  onClick={()=>{    set_Controls('');    set_Iterations('');    set_error_Controls(false);    set_error_Iterations(false);    set_show_Placebo_Results(false);    }}    style={{display:"flex",      gap:screen_width === 'default-1536px' ? "6px" : "5px",      alignItems:"center"}}>
                                            <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                                            <p  style={{color:"#0000EE", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "18px" : "15.5px"}}>{exp_Name} </p>
                                        </button>

                                        {
                                            selectedObjID!==null  &&  <p  className="fontF"  style={{fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "18px" : "15.5px",      color:"#171717"}}>  &nbsp; / {fileObjs.find(obj => obj.objId === selectedObjID).file_name}</p>
                                        }
                                        
                                    </div>


                                    <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "24px" : "20px"}}>

                                        <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                            <button className={`toggle-container ${showGraph ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "41.8px" : "34.8px",           height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle}>
                                                <div className="toggle-button"                                        style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                            </button>
                                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Graph</p>
                                        </div>

                                        <div style={{ display: "flex", gap: "1px" }}>
                                            <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                            <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                        </div>

                                        <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                            <button className={`toggle-container ${showData ? 'toggled' : ''}`}      style={{width:screen_width === 'default-1536px' ? "42.3px" : "35px",         height:screen_width === 'default-1536px' ? "21px" : "17.5px"}}      onClick={handleToggle2}>
                                                <div className="toggle-button"                                       style={{width:screen_width === 'default-1536px' ? "18.7px" : "15.58px",      height:screen_width === 'default-1536px' ? "18.7px" : "15.58px"}}></div>
                                            </button>
                                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#171717"}}>Show Only Data</p>
                                        </div>

                                    </div>
                                </div>
                                

                                <div  style={{width:"100%", height:"86%", display:"flex", position:"relative"}}>
            
                
                                        <div  style={{  width: (!showData && showGraph)    ?    "100%" 
                                                                                        :    (showData && !showGraph)    ?    "0%"
                                                                                                                            :    (!showData && !showGraph) && "75%", 
                                                        display: (showData && !showGraph)  ?    "none"
                                                                                        :    (!showData && !showGraph) && "flex", 

                                                        height:"100%", display:"flex", justifyContent:"center", alignItems:"center", transition: "width 0.2s", position:"absolute", top:"0", left:"0"}}>
                                            <div  className="line-chart-responsiveness"  style={{borderRadius:"8px"}}>
                                                <div style={{width: '96%', height:"92%"}}>
                                                    <Line data={dataChart} options={optionChart}/>
                                                </div>
                                            </div>
                                        </div>    
                
                                        {
                                        (lift1)  &&
                                            <div  style={{  width: (!showData && showGraph)    ?    "0%" 
                                                                                            :    (showData && !showGraph)    ?    "100%" 
                                                                                                                                :    (!showData && !showGraph) && "25%", 
                                                            display: (!showData && showGraph)  ?    "none"
                                                                                            :    (!showData && !showGraph) && "flex", 
                                                            height:"100%",  justifyContent:"flex-start", alignItems:"center", transition: "width 0.2s", paddingLeft:"1.1rem", position:"absolute", top:"0", right:"0"}}>
                
                
                                                <table style={{ width:"80%",      fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>
                                                    <thead>
                                                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                            <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Metric</th>
                                                            <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px" }}>Value</th>
                                                        </tr>
                                                    </thead>
                    
                                                    <tbody>
                                                        <tr>
                                                            <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>Lift Value</td>
                                                            <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "8px" : "6.66px"}}>{lift1[0]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                
                                            
                                            </div>  
                                        }
                                        
            
                                </div>

                                
                            </div>
                    }
                </div>
        }



        {
            
            file_name_Popup  &&  ( 
                <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>File Name</p>
                        <button  onClick={()=>{setFile_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedFilename}    </p>    
              
            
                </div>
            )
                                    
        }

        {
            file_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }


        {
            subset_of_Popup  &&  ( 
                <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Subset Of</p>
                        <button  onClick={()=>{setSubset_of_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedSubsetof}    </p>    
            
                </div>
            )
                                    
        }

        {
            subset_of_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }


        {
            description_Popup  &&  ( 
                <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Description</p>
                        <button  onClick={()=>{setDescription_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedDescription}    </p>    
            
            
                </div>
            )
                                    
        }

        {
            description_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }



        {                                          
            placebo_Details_popup  &&  ( 
                <div   className={` modal-delete-rows`}  style={{width:screen_width === 'default-1536px' ? "420px" : "360px",    display:"flex",      gap:screen_width === 'default-1536px' ? "1rem" : "0.8rem",      padding:screen_width === 'default-1536px' ? "20px 25px" : "16px 20px"}}>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "18.5px",      fontWeight:"600"}}>Placebo Analysis</p>

                    <p>Total Control Cities : {total_controls_for_Selected_Dataset}</p>

                    <div>
                        {
                            error_Controls    ?    <p  className="fontF"  style={{color:"#DC2626", fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}> Input must be a numeric value between 5 and {total_controls_for_Selected_Dataset} </p>
                                              :    error_Iterations    ?    <p  className="fontF"  style={{color:"#DC2626", fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}> Input must be a numeric value between 10 and 500 </p>
                                                                       :    <p  className="fontF"  style={{color:"#DC2626", fontSize:screen_width === 'default-1536px' ? "15px" : "13px"}}>   &nbsp;</p>
                        }
                    </div>
                    
                    <div  style={{width:"100%", position:"relative", marginTop:"1rem"}}>
                        <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1", fontSize:screen_width === 'default-1536px' ? "13px" : "12px", top:screen_width === 'default-1536px' ? "-9px" : "-8px", left:"10px", padding:"0 4px", color:"#404040"}}>Number of Sample Cities</h2>
                        <input    type="text"  value={controls}    onChange={(e) => {handleChange(e.target.value)}}      className="input-field"   placeholder="Controls"        disabled={placebo_Loader}/>
                    </div>

                    <div  style={{width:"100%", position:"relative", marginTop:"1rem"}}>
                        <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1", fontSize:screen_width === 'default-1536px' ? "13px" : "12px", top:screen_width === 'default-1536px' ? "-9px" : "-8px", left:"10px", padding:"0 4px", color:"#404040"}}>Number of Iterations</h2>
                        <input    type="text"  value={iterations}    onChange={(e) => {handleChange2(e.target.value)}}      className="input-field"   placeholder="Iterations"    disabled={placebo_Loader}/>
                    </div>

                    

                    <div    className="discard-business-buttons-container"    style={{display:"flex", justifyContent:"flex-start"}}>
                        <button  onClick={() => {    run_placebo_R()    }}         className="discard-business-button"        style={{width:"50%",  backgroundColor:"#1A7AAF", borderRadius:"5px", color:"white",    opacity:(placebo_Loader  ||  controls===''  ||  iterations===''  ||  error_Controls  ||  error_Iterations) ? "0.6" : "1"}}      disabled={placebo_Loader  ||  controls===''  ||  iterations==='' || error_Controls  ||  error_Iterations}>     {  placebo_Loader ? "Running..." : "Run Placebo"  }      </button>
                        <button  onClick={() => {    set_Controls('');    set_Iterations('');    set_error_Controls(false);    set_error_Iterations(false);    set_placebo_Details_popup(false) }}       className="discard-business-button"        style={{width:"50%",  backgroundColor:"gray", borderRadius:"5px", color:"white"}}        disabled={placebo_Loader}>Cancel</button>
                    </div>                      
            
                </div>
            )                                    
        }

        {
            placebo_Details_popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }



    </div>
    );
  }
  
  export default PlaceboAnalysis;
