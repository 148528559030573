
import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';



function SelectIdPage() {




    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const AdsIds = queryParams.get('AdsIds');
    const AnalyticsIds = queryParams.get('AnalyticsIds');
    const fbAdsIds = queryParams.get('fbAdsIds');
    const fbAdNames = queryParams.get('fbAdsNames');



    // For Google Ads Ids : Split the accountId into an array
    const googleAdsIds_cust = AdsIds ? AdsIds.split(',') : [];
    // Further split each string to get only the number component
    const googleAdsIds  = googleAdsIds_cust.map(account => {
        const parts = account.split('/');
        return parts[1]; // Get the number part
    });



    //For Google Analytics Property Ids : Split the accountId into an array
    const googleAnalyticsIds = AnalyticsIds ? AnalyticsIds.split(',') : [];



    //For FB Ads Ids  : Split the accountId into an array
    const facebookAdsIds_cust = fbAdsIds ? fbAdsIds.split(',') : [];
    





    const [gadsSelectedIds, setGadsSelectedIds] = useState([]); // For G Ads
    const [ganalyticsSelectedIds, setGanalyticsSelectedIds] = useState([]); // For G Analytics
    const [fbSelectedIds, setfbSelectedIds] = useState([]); // For FB Ads
    
    function handleGadsIds(id) {
        if (gadsSelectedIds.includes(id)) {
            setGadsSelectedIds(gadsSelectedIds.filter((selectedId) => selectedId !== id));
        } 
        else {
            setGadsSelectedIds([...gadsSelectedIds, id]);
        }
    }

    function handleGanalyticsIds(id) {
        if (ganalyticsSelectedIds.includes(id)) {
            setGanalyticsSelectedIds(ganalyticsSelectedIds.filter((selectedId) => selectedId !== id));
        } 
        else {
            setGanalyticsSelectedIds([...ganalyticsSelectedIds, id]);
        }
    }

    function handlefbIds(id) {
        if (fbSelectedIds.includes(id)) {
            setfbSelectedIds(fbSelectedIds.filter((fbselectedId) => fbselectedId !== id));
        } 
        else {
            setfbSelectedIds([...fbSelectedIds, id]);
        }
    }
    



    function handleFinishOnClick() {
        let NextUrl = `https://integrations.truelift.ai/selection-status?GADsclient_id=${gadsSelectedIds}&GAnalyticsclient_id=${ganalyticsSelectedIds}`;

        if(fbAdsIds)   NextUrl = `https://integrations.truelift.ai/selection-status?FBAdsclient_id=${fbSelectedIds}`;
        
        window.location.href = NextUrl;
        console.log("Google Ads Selected Ids", gadsSelectedIds);
        console.log("Google Analytics Selected Ids", ganalyticsSelectedIds);
        console.log("Facebook Ads : Selected Ids", fbSelectedIds);
    }






    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';






    return (
        <div  style={{width:"100%",  height:"100%", display: "flex", flexDirection: "column", justifyContent:"space-between",      gap:screen_width === 'default-1536px' ? "22.4px" : "18.56px"}}>

                <div  style={{ width: "100%", height: "9%", background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px",      padding:screen_width === 'default-1536px' ? "16px 24px" : "13.3px 20px",      boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p className="fontF" style={{ fontSize:screen_width === 'default-1536px' ? "24px" : "20px",      fontWeight: "600", lineHeight: "36px" }}>Data Integrations</p>
                    <div className="fontF">DP(Name and Role)</div>
                </div>



                <div  style={{width:"100%",      height:screen_width === 'default-1536px' ? "521px" : "408px",      padding:screen_width === 'default-1536px' ? "1.6rem" : "1.4rem",       borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", flexDirection:"column", justifyContent:"flex-start",      gap:screen_width === 'default-1536px' ? "1.6rem" : "1.3rem"}}>
                    
                    <div  style={{display:"flex", flexDirection:"column",      gap:screen_width === 'default-1536px' ? "4px" : "3.33px"}}>
                        <p  className="fontF"  style={{fontWeight:"600",      fontSize:screen_width === 'default-1536px' ? "20px" : "18px",      color:"#171717"}}>Choose Account IDs</p>
                        <p  className="fontF"  style={{fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px",      color:"#404040"}}>Please select  your account IDs  using the checkboxes you want  to work  with to proceed further</p>
                    </div>
                    
                    
                    <div  style={{width:"100%", flex:"1", overflowY:"auto"}}>
                        <ul  style={{display:"flex", flexDirection:"column", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "16px" : "14.5px",      color:"#171717"}}    className="fontF">
                            {
                            googleAnalyticsIds.map((id, index) => (
                                <li   key={index}   style={{display:"flex",      gap:screen_width === 'default-1536px' ? "10px" : "8.3px",      padding:screen_width === 'default-1536px' ? "20px 18px 20px 0" : "18px 15px 18px 0",      alignItems:"center", borderBottom:"1px solid #D1D5DB"}}>
                                    <input    type="checkbox"     id={`ganalyticsaccount${index+1}`}    onChange={()=>handleGanalyticsIds(id)}        className="rounded border-blue-300 h-3 w-3 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>
                                    <label  style={{fontWeight:"500"}}>{id}</label>
                                </li>
                            ))
                            }
                        </ul>

                        <ul  style={{display:"flex", flexDirection:"column", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "16px" : "14.5px",      color:"#171717"}}    className="fontF">
                            {
                            googleAdsIds.map((id, index) => (
                                <li   key={index}   style={{display:"flex",      gap:screen_width === 'default-1536px' ? "10px" : "8.3px",      padding:screen_width === 'default-1536px' ? "20px 18px 20px 0" : "18px 15px 18px 0",      alignItems:"center", borderBottom:"1px solid #D1D5DB"}}>
                                    <input    type="checkbox"     id={`gadsaccount${index+1}`}     onChange={()=>handleGadsIds(id)}        className="rounded border-blue-300 h-3 w-3 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>
                                    <label  style={{fontWeight:"500"}}>{id}</label>
                                </li>
                            ))
                            }
                        </ul>

                        <ul  style={{display:"flex", flexDirection:"column", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "16px" : "14.5px",      color:"#171717"}}    className="fontF">
                            {
                            facebookAdsIds_cust.map((id, index) => (
                                <li   key={index}   style={{display:"flex",      gap:screen_width === 'default-1536px' ? "10px" : "8.3px",      padding:screen_width === 'default-1536px' ? "20px 18px 20px 0" : "18px 15px 18px 0",      alignItems:"center", borderBottom:"1px solid #D1D5DB"}}>
                                    <input    type="checkbox"     id={`fbaccount${index+1}`}     onChange={()=>handlefbIds(id)}        className="rounded border-blue-300 h-3 w-3 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>
                                    <label  style={{fontWeight:"500"}}>{id}</label>
                                </li>
                            ))
                            }
                        </ul>
                    </div>

                </div>



                <div  style={{width:"100%", height:"8.2%",      padding:screen_width === 'default-1536px' ? "12px 24px" : "1px 16px",      borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                
                        <div  style={{display:"flex", gap:"1rem"}}>
                            <button      onClick={()=>{  navigate('/data-integrations')  }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4px 16px" : "2.1px 13.3px",      border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}    className="fontF" >Cancel</button>
                            <button      onClick={()=>{  handleFinishOnClick()           }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4px 16px" : "2.1px 13.3px",      backgroundColor:"#1A7AAF", color:"white", fontWeight:"600",  opacity:(gadsSelectedIds.length===0  &&  ganalyticsSelectedIds.length===0  &&  fbSelectedIds.length===0)  ?  "0.6"  :  "1"}}      className="fontF"    disabled={gadsSelectedIds.length===0  &&  ganalyticsSelectedIds.length===0  &&  fbSelectedIds.length===0}>
                                Continue
                            </button>
                        </div>
                </div>
    
        </div>
    );
  }
  
  export default SelectIdPage;