import { useState } from "react";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function PrintDataStep4({      handleActive,    isActiveArr,    states,    salesData_Dates,      uniquePositions_in_Print,    setuniquePositions_in_Print,    afterRegionalMapping_arr_P,    after_Missing_Dates_and_Positions_Treatment,    setAfter_Missing_Dates_and_Positions_Treatment}) {







    // console.log("states :- ", states);
    // console.log("salesData_Dates :- ", salesData_Dates);
    // console.log("afterRegionalMapping_arr_P :- ", afterRegionalMapping_arr_P);







    useEffect(()=>{
        if(afterRegionalMapping_arr_P.length    >    0){
            const uniquePositions = [...new Set(afterRegionalMapping_arr_P.map(item => item.Position))];
            // console.log("uniquePositions :- ", uniquePositions);
            setuniquePositions_in_Print(uniquePositions);
        }
    },[afterRegionalMapping_arr_P]);







    useEffect(()=>{
        if(uniquePositions_in_Print.length    >    0){



            const sortedStates = [...states].sort();
            const sortedPositions = [...uniquePositions_in_Print].sort();         // We are assuming that Dates will be sorted, so we are not sorting them now, if required in future then we will also sort them



            const lookup = new Map();

            afterRegionalMapping_arr_P.forEach(item => {
                const key = `${item.Date}|${item.State}|${item.Position}`;
                lookup.set(key, item.Cost);
            });



            const output = [];

            salesData_Dates.forEach(date => {
                sortedStates.forEach(state => {
                    sortedPositions.forEach(position => {
                        
                        const key = `${date}|${state}|${position}`;
                        output.push({  Date: date,    State: state,    Position: position,    Cost: lookup.get(key) || 0  });
                        
                    });
                });
            });


            console.log("output :- ", output);
            setAfter_Missing_Dates_and_Positions_Treatment(output);

        }
    },[uniquePositions_in_Print]);






    const [headers1, setHeaders1] = useState([]);
    useEffect(()=>{
        if(after_Missing_Dates_and_Positions_Treatment.length > 0){
            const formattedData = Object.keys(after_Missing_Dates_and_Positions_Treatment[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            setHeaders1(formattedData);
        }
    },[after_Missing_Dates_and_Positions_Treatment]);







    const [nextBtnClicked, setNextBtnClicked] = useState(false);
    useEffect(()=>{
        if(!isActiveArr.includes(4)    &&    nextBtnClicked){
            if(!isActiveArr.includes(5))  handleActive(5);
            setNextBtnClicked(false);
        }
    }, [isActiveArr, nextBtnClicked]);







    
    return (
        <div>
                <div>
                    {   
                        after_Missing_Dates_and_Positions_Treatment.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between", gap:"4rem"}}>
                            <CSVLink  data={after_Missing_Dates_and_Positions_Treatment}    headers={headers1}    filename={"P_Step4_after_Missing_Dates_and_Positions_Treatment"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>

                            <button  onClick={()=>{handleActive(4);  setNextBtnClicked(true);}}  style={{backgroundColor:"green", fontSize:"13px", borderRadius:"5px", padding:"0 1rem"}}  >Next</button>
                        </div>
                    }
                </div>
        </div>
    );
}


export default PrintDataStep4;