import React, { useEffect, useState } from "react";
import AccordionTabs from "./AccordionTabs";
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import "../CSS/NavigationCSS.css";



function Navigation({    activeTab,    setActiveTab}) {



    const data = [{    id:1,  TabTitle:"Geo Experiment",      subSection:[{title:"Experiment and Data",  url:"/experiment-and-data"},     {title:"Data Subset",        url:"/data-subset"},        {title:"Placebo Analysis",   url:"/placebo-analysis"},  {title:"Power of Test",     url:"/power-of-test"}]       },
                  {    id:2,  TabTitle:"MMM",                 subSection:[{title:"Experiment",           url:"/mmm-experiment"},          {title:"Data",               url:"/mmm-data"},           {title:"Model",              url:"/model"},             {title:"Validation",        url:"/validation"}]    },
                  {    id:3,  TabTitle:"Attribution",         subSection:[{title:"Product A",            url:"/product-a"},               {title:"Product B",          url:"/product-b"},          {title:"Product C",          url:"/product-c"}]    },
                  {    id:4,  TabTitle:"Client Onboarding",   subSection:[{title:"User Management",      url:"/user-management"},         {title:"Data Integrations",  url:"/data-integrations"},  {title:"Business Setup",     url:"/business-setup"}]    }]



    const [isActiveArr, setIsActiveArr] = useState(Array(0));              
    const handleActive = (id) => {                        
        
      if (!isActiveArr.includes(id)    &&    isActiveArr.length>0){   // Because of this if statement - We are allowing only 1 collapsable menu at a time
        const arr = []
        arr.push(id);
        setIsActiveArr(arr);
        return;
      }
        
      setIsActiveArr(    !isActiveArr.includes(id)                         
                          ?    isActiveArr.concat([id])                      
                          :    isActiveArr.filter((e) => e !== id)    );
    }





    const [isHovered, setIsHovered] = useState('');




    // const location = useLocation();
    // useEffect(()=>{
    //     const path = location.pathname;
    //     if(path === '/mmm-tv-data-upload'    ||    path === '/mmm-print-data-upload'    ||    path === '/mmm-radio-data-upload'    ||    path === '/mmm-digital-data-upload'    ||    path === '/mmm-sales-data-upload'){
    //         setActiveTab('/mmm-data');
    //     }
    //     else{
    //         setActiveTab(path);
    //     }
        

    //     // The purpose of these 4 if-else lines is - Suppose I am on a specific path and I refresh the application, then because of these if-else that dropdown opens and corresponding title is selected
    //     if(path === "/experiment-and-data"  ||  path === "/data-subset"  ||  path === "/placebo-analysis"  ||  path === "/power-of-test")                                                                                                                                                                                               handleActive(1);
    //     else if(path === "/mmm-experiment"  ||  path === "/mmm-data"  ||  path === "/model"  ||  path === "/validation"  ||  path === "/mmm-tv-data-upload"  ||  path === "/mmm-print-data-upload"  ||  path === "/mmm-radio-data-upload"  ||  path === "/mmm-digital-data-upload"  ||  path === "/mmm-sales-data-upload")              handleActive(2);
    //     else if(path === "/product-a"  ||  path === "/product-b"  ||  path === "/product-c")                                                                                                                                                                                                                                            handleActive(3);
    //     else if(path === "/user-management"  ||  path === "/data-integrations"  ||  path === "/business-setup")                                                                                                                                                                                                                         handleActive(4);                            

    // },[])
    





    return (
        <div  className="gap-between-logo-and-dropdowns"  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>


            <div  className="truelift-svg-size">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789.07 185.06">
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <path style={{fill:"#4db2e0"}} d="M74.56,162.36a53.34,53.34,0,0,1-11.24,1.13c-14.46,0-19.34-5-19.34-19.83V61.82H85.06V41.25H44V6.64H22.41V41.25H0V61.82H22.41v82.33c0,26.49,13.43,39.92,39.91,39.92a85.23,85.23,0,0,0,19.38-2.16l5.1-1.16v-18.5H75.16Z"/>
                            <path style={{fill:"#4db2e0"}} d="M164.9,38.51c-17.62,0-32.2,6.28-42.22,17.73l-.49-15H101.83V52l1,33.45v96.83h21.56v-82c2.78-35.46,25.62-40.76,39-40.76A48.49,48.49,0,0,1,178.61,62l1,.3h11.8V43.45L186.72,42A74.31,74.31,0,0,0,164.9,38.51Z"/>
                            <path style={{fill:"#4db2e0"}} d="M329.3,41.25H307.73v78.32c-1.61,28.81-16.43,44.67-41.75,44.67-12.75,0-23.07-4.27-29.82-12.33-6.35-7.62-9.44-18.87-9.44-34.4V41.25H205.15v77.26c0,20.73,4.45,35.61,14,46.81,10.71,12.73,26.9,19.74,45.58,19.74,18.27,0,34.07-7.13,44.56-19.44l.68,16.7h20.31V171.54l-1-33.46Z"/>
                            <path style={{fill:"#4db2e0"}} d="M488.39,106.06v-.35c-2.15-40.19-29.5-67.2-68-67.2-41.24,0-70,30-70,73,0,43.29,29.1,73.52,70.78,73.52,25.26,0,46.54-11.22,58.39-30.79l.95-1.56V141.09H462.32l-1.93,3c-8.42,13.17-22,20.13-39.3,20.13-26.67,0-45.51-17.48-48.55-44.16H488.39Zm-115.56-5.8c3.11-24.9,21.51-41.18,47.52-41.18,25.23,0,42.61,15.94,45.74,41.18Z"/>
                            <path style={{fill:"#1f1f1f"}} d="M516,150.63V.5h8.47l-.25,148.88c0,14,6.72,20.42,20.66,20.42a42.41,42.41,0,0,0,7.72-.75H556v7a39.65,39.65,0,0,1-11.71,1.5C525.16,177.52,516,168.55,516,150.63Z"/>
                            <path style={{fill:"#1f1f1f"}} d="M737.78,144.15V55.27h-22.4V47.8h22.4V13.2h8.47V47.8h41.08v7.47H746.25v88.39c0,18.42,7.72,26.39,25.89,26.39a59.85,59.85,0,0,0,12.45-1.25h4.48v6.72a78.66,78.66,0,0,1-17.93,2C748,177.52,737.78,167.06,737.78,144.15Z"/>
                            <path style={{fill:"#1f1f1f"}} d="M675.42,0a75.67,75.67,0,0,1,16.43,1.74l-.25,7h-3.49a63.73,63.73,0,0,0-12.2-1.24c-18.67,0-25.39,8.46-25.39,26.89V47.75H691.6v7.47H650.52v122.3h-8.46V55.22H619.65V47.75h22.41V33.36C642.06,10.71,652.26,0,675.42,0Z"/>
                            <path style={{fill:"#1f1f1f"}} d="M585.17,47.8h8.46v128h-8.46Z"/>
                            <polyline style={{fill:"#4db2e0"}} points="568.46 32.89 589.54 0.6 609.3 32.89"/>
                        </g>
                    </g>
                </svg>
            </div>


            <div  style={{width:"100%", color:"rgb(53, 57, 53)", fontWeight:"600"}}>

                <div  style={{width:"100%", display:"flex", justifyContent:"center"}}>
                    <NavLink to="/dashboard"    onClick={()=>{setIsActiveArr([]);    setActiveTab("/dashboard")}}    className="dashboard-title-styling"     style={{  display: 'flex', backgroundColor:"white",  boxShadow:"8px 8px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1",  textAlign: 'center', borderRadius:"10px", alignItems:"center", gap:"8px"}}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 6C10.2239 6 10 5.77614 10 5.5V0.5C10 0.223858 10.2239 0 10.5 0H17.5C17.7761 0 18 0.223858 18 0.5V5.5C18 5.77614 17.7761 6 17.5 6H10.5ZM0.5 10C0.223858 10 0 9.77614 0 9.5V0.5C0 0.223858 0.223858 0 0.5 0H7.5C7.77614 0 8 0.223858 8 0.5V9.5C8 9.77614 7.77614 10 7.5 10H0.5ZM10.5 18C10.2239 18 10 17.7761 10 17.5V8.5C10 8.22386 10.2239 8 10.5 8H17.5C17.7761 8 18 8.22386 18 8.5V17.5C18 17.7761 17.7761 18 17.5 18H10.5ZM0.5 18C0.223858 18 0 17.7761 0 17.5V12.5C0 12.2239 0.223858 12 0.5 12H7.5C7.77614 12 8 12.2239 8 12.5V17.5C8 17.7761 7.77614 18 7.5 18H0.5ZM2 7.5C2 7.77614 2.22386 8 2.5 8H5.5C5.77614 8 6 7.77614 6 7.5V2.5C6 2.22386 5.77614 2 5.5 2H2.5C2.22386 2 2 2.22386 2 2.5V7.5ZM12 15.5C12 15.7761 12.2239 16 12.5 16H15.5C15.7761 16 16 15.7761 16 15.5V10.5C16 10.2239 15.7761 10 15.5 10H12.5C12.2239 10 12 10.2239 12 10.5V15.5ZM12 3.5C12 3.77614 12.2239 4 12.5 4H15.5C15.7761 4 16 3.77614 16 3.5V2.5C16 2.22386 15.7761 2 15.5 2H12.5C12.2239 2 12 2.22386 12 2.5V3.5ZM2 15.5C2 15.7761 2.22386 16 2.5 16H5.5C5.77614 16 6 15.7761 6 15.5V14.5C6 14.2239 5.77614 14 5.5 14H2.5C2.22386 14 2 14.2239 2 14.5V15.5Z" fill="#52525B"/>
                        </svg>
                        <p  className="title-font"  style={{color:"#404040",      fontWeight: activeTab === "/dashboard" ? "600" : "400" }}>Dashboard</p>
                                                                                             
                    </NavLink>     
                </div>



                <div>
                    {
                        data.map((section, index) => (
                            <AccordionTabs    key={index}    section={section}    isActiveArr={isActiveArr}    handleActive={handleActive}    activeTab={activeTab}    setActiveTab={setActiveTab}    isHovered={isHovered}    setIsHovered={setIsHovered}/>
                        ))
                    }
                </div>   

            </div>


        </div>
    );
}

export default Navigation;