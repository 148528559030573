

import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';






const ReadOnlyInput = (props) => (
    <input
      type="text"
      {...props}
      readOnly
      style={{ ...props.style, cursor: 'pointer' }} // Change cursor to pointer to indicate it's clickable
    />
);






function SubsetByDate({fileObjs, selectedObjID_forSubset,  setSubsetbyDate_Array,                    newStartDate,    setNewStartDate,    newEndDate_Pre,    setNewEndDate_Pre,    newStartDate_Camp,    setNewStartDate_Camp,    newEndDate,    setNewEndDate,    newStartDate_fix,    setNewStartDate_fix,    newEndDate_Pre_fix,    setNewEndDate_Pre_fix,    newStartDate_Camp_fix,    setNewStartDate_Camp_fix,    newEndDate_fix,    setNewEndDate_fix}) {






    const entireObj = fileObjs.find(obj => obj.objId === selectedObjID_forSubset);

    let dataChart = {};
    let optionChart = {};
    if(selectedObjID_forSubset !== null){

        dataChart = {
        
        labels: entireObj.datesObj.OriginalDates.map((data) => data),
        datasets: [
            {                                             // Purpose of this dataset - Because of this dataset the Labels on the top are visible, otherwise value max is 1, and this 1.25 balances that out
                type: 'line',
                data: [
                    { x: "", y: 1.5 },
                ],
                pointRadius: 0,                    
                datalabels: {   display: false    },
            },

            {
                type: 'line',
                data: [
                    { x: entireObj.datesObj.Date_Starting_Pre, y: 1 },
                    { x: entireObj.datesObj.Date_middle_Pre, y: 1 },
                    { x: entireObj.datesObj.Date_Ending_Pre, y: 1 },
                ],
                fill: true,
                backgroundColor: '#C2E2F5',
                borderWidth: 0,
                pointRadius: 0,
                
                
                datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 0,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Pre Period";     }   // index 1 represents  -  { x: date_middle_Pre, y: 1 },
                        else {                             return '';               }
                    }
                }
            },

            {
                type: 'line',
                data: [
                    { x: entireObj.datesObj.Date_Starting_Campaign, y: 1 },
                    { x: entireObj.datesObj.Date_middle_Campaign, y: 1 },
                    { x: entireObj.datesObj.Date_Ending_Campaign, y: 1 },
                ],
                fill: true,
                backgroundColor: '#4FB2E1',
                borderWidth: 0,
                pointRadius: 0,
                
                datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 0,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Campaign Period";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                        else {                             return '';                    }
                    }
                }
            }
        ]
        }
        optionChart = {                          
        maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally

        animation: false, // Disable animations

        plugins: {           // With this code I am hiding legend    ->    label: "Users Gained",
            legend: {
                display: false, 
            },
        },


        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                    family: "Toboggan",            // To change font family of X-Axis
                    weight: "bold",
                    size: 12  
                    },
                    callback: function (value, index, values) {

                    const x = this.getLabelForValue(index)

                    if (x===entireObj.datesObj.Date_Starting_Pre  ||  x===entireObj.datesObj.Date_Starting_Campaign  ||  x===entireObj.datesObj.Date_Ending_Campaign) {
                        return x;
                    } else {
                        return null;
                    }
                    },
                }
            },
            y: {
                beginAtZero: true,  // The purpose of this line of code is to make Y-axis start from 0
                display: false      // This line hides the entire Y-axis
            }
        }
        }
    }












    function setNewStartDate_Func(date){    
        const dateX = new Date(date);                              // To convert date format into required date format (The date format which for API)
        const formattedDate = dateX.toISOString().split('T')[0];
        setNewStartDate(formattedDate);              
    }
    function setNewEndDate_Pre_Func(date)  {    
        const dateX = new Date(date);                              // To convert date format into required date format (The date format which for API)
        const formattedDate = dateX.toISOString().split('T')[0];
        setNewEndDate_Pre(formattedDate);                
    }
    function setNewStartDate_Camp_Func(date){
        const dateX = new Date(date);                              // To convert date format into required date format (The date format which for API)
        const formattedDate = dateX.toISOString().split('T')[0];
        setNewStartDate_Camp(formattedDate);       
    }
    function setNewEndDate_Camp_Func(date){
        const dateX = new Date(date);                              // To convert date format into required date format (The date format which for API)
        const formattedDate = dateX.toISOString().split('T')[0];
        setNewEndDate(formattedDate);  
    }



    useEffect(() => {     
        if(!newStartDate    &&    !newEndDate    &&    !newEndDate_Pre    &&    !newStartDate_Camp){
            const date_starting = entireObj.datesObj.Date_Starting_Pre_default;     
            const m = date_starting.slice(0,2);
            const d = date_starting.slice(3,5);
            const y = date_starting.slice(6,10);
            const zx = `${y}-${m}-${d}`;
            setNewStartDate(zx);  
            setNewStartDate_fix(zx);  


            const dateX2 = entireObj.datesObj.Date_Ending_Pre_default;     
            const m3 = dateX2.slice(0,2);
            const d3 = dateX2.slice(3,5);
            const y3 = dateX2.slice(6,10);
            const zx3 = `${y3}-${m3}-${d3}`;
            setNewEndDate_Pre(zx3);   
            setNewEndDate_Pre_fix(zx3);
            

            const dateX3 = entireObj.datesObj.Date_Starting_Campaign_default;     
            const m4 = dateX3.slice(0,2);
            const d4 = dateX3.slice(3,5);
            const y4 = dateX3.slice(6,10);
            const zx4 = `${y4}-${m4}-${d4}`;
            setNewStartDate_Camp(zx4);
            setNewStartDate_Camp_fix(zx4);


            const date_ending = entireObj.datesObj.Date_Ending_Campaign_default;  
            const m2 = date_ending.slice(0,2);
            const d2 = date_ending.slice(3,5);
            const y2 = date_ending.slice(6,10);
            const zx2 = `${y2}-${m2}-${d2}`;
            setNewEndDate(zx2);
            setNewEndDate_fix(zx2);
        }
    }, []);





    function findDateRanges(data) {
        let numberOfRanges = 1;
        let dateRanges = [{ start: data[0].date,         end: data[data.length - 1].date }];
  
  
        for (let i = 1; i < data.length; i++) {
          const currentDate = new Date(data[i].date);
          const previousDate = new Date(data[i - 1].date);
  
  
          if (currentDate.getTime() !== previousDate.getTime() + 86400000) { 
            numberOfRanges = 2;
            dateRanges = [
              { start: data[0].date,    end: data[i - 1].date,              endingIndex:i-1    },
              { start: data[i].date,    end: data[data.length - 1].date,    startingIndex:i    }
            ];
            break; 
          }
        }
  
        return { numberOfRanges, dateRanges };
    }

    const [subset_StartDate, setSubset_StartDate] = useState(null);
    const [subset_MiddleDate, setSubset_MiddleDate] = useState(null);
    const [subset_EndDate, setSubset_EndDate] = useState(null);

    const [pre_Subset_StartDate, set_Pre_Subset_StartDate] = useState(null);
    const [pre_Subset_MiddleDate, set_Pre_Subset_MiddleDate] = useState(null);
    const [pre_Subset_EndDate, set_Pre_Subset_EndDate] = useState(null);
    const [campaign_Subset_StartDate, set_Campaign_Subset_StartDate] = useState(null);
    const [campaign_Subset_MiddleDate, set_Campaign_Subset_MiddleDate] = useState(null);
    const [campaign_Subset_EndDate, set_Campaign_Subset_EndDate] = useState(null);

    const [Canvas, setCanvas] = useState('default');

    useEffect(()=>{
        if(newStartDate !== newStartDate_fix    ||    newEndDate_Pre !== newEndDate_Pre_fix    ||    newStartDate_Camp !== newStartDate_Camp_fix    ||    newEndDate !== newEndDate_fix){
            
            const date_X = newStartDate;   
            const y = date_X.slice(0,4);  
            const m = date_X.slice(5,7);
            const d = date_X.slice(8,10);
            const zx = `${m}-${d}-${y}`;

            const date_X2 = newEndDate_Pre;   
            const y2 = date_X2.slice(0,4);  
            const m2 = date_X2.slice(5,7);
            const d2 = date_X2.slice(8,10);
            const zx2 = `${m2}-${d2}-${y2}`;

            const date_X3 = newStartDate_Camp;   
            const y3 = date_X3.slice(0,4);  
            const m3 = date_X3.slice(5,7);
            const d3 = date_X3.slice(8,10);
            const zx3 = `${m3}-${d3}-${y3}`;
            
            const date_X4 = newEndDate;   
            const y4 = date_X4.slice(0,4);  
            const m4 = date_X4.slice(5,7);
            const d4 = date_X4.slice(8,10);
            const zx4 = `${m4}-${d4}-${y4}`;

            const filtered = entireObj.file_data.filter(obj => {
                const objDate = new Date(obj.date);

                return (    (objDate >= new Date(zx)         &&    objDate <= new Date(zx2))    ||
                            (objDate >= new Date(zx3)    &&    objDate <= new Date(zx4))              );
            });
            // console.log("filtered:- ", filtered);
            setSubsetbyDate_Array(filtered);



            const location = filtered[0].geo;
            const filteredData = filtered.filter(item => item.geo === location);
            // console.log("filteredData :- ", filteredData)


            const arrZ = findDateRanges(filteredData)
            console.log("arrZ :- ", arrZ);





            if(arrZ.numberOfRanges === 1){

                const subset_StartDate = filteredData[0].date.slice(0,5);   
                const middleIndex = Math.floor(filteredData.length / 2);
                const subset_MiddleDate = filteredData.length % 2 === 0   ?   filteredData[middleIndex - 1].date.slice(0,5)   :   filteredData[middleIndex].date.slice(0,5);
                const subset_EndDate = filteredData[filteredData.length - 1].date.slice(0,5);
                setSubset_StartDate(subset_StartDate);
                setSubset_MiddleDate(subset_MiddleDate);
                setSubset_EndDate(subset_EndDate);

                setCanvas('continuous_subset');
            }
            else if(arrZ.numberOfRanges === 2){

                const pre_subset_StartDate_ = filteredData[0].date.slice(0,5);
                const middleIndex_pre = Math.floor((arrZ.dateRanges[0].endingIndex+1) / 2);
                const pre_subset_MiddleDate_ = (arrZ.dateRanges[0].endingIndex+1) % 2 === 0   ?   filteredData[middleIndex_pre - 1].date.slice(0,5)   :   filteredData[middleIndex_pre].date.slice(0,5);
                const pre_subset_EndDate_ = filteredData[arrZ.dateRanges[0].endingIndex].date.slice(0,5);
                set_Pre_Subset_StartDate(pre_subset_StartDate_);
                set_Pre_Subset_MiddleDate(pre_subset_MiddleDate_);
                set_Pre_Subset_EndDate(pre_subset_EndDate_);
        
                const campaign_subset_StartDate_ = filteredData[arrZ.dateRanges[1].startingIndex].date.slice(0,5);
                const middleIndex_campaign = Math.floor((filteredData.length - (arrZ.dateRanges[1].startingIndex)) / 2);
                const campaign_subset_MiddleDate_ = (filteredData.length - (arrZ.dateRanges[1].startingIndex)) % 2 === 0   ?   filteredData[arrZ.dateRanges[1].startingIndex  +  middleIndex_campaign - 1].date.slice(0,5)   :   filteredData[arrZ.dateRanges[1].startingIndex  +  middleIndex_campaign].date.slice(0,5);
                const campaign_subset_EndDate_ = filteredData[filteredData.length-1].date.slice(0,5);
                set_Campaign_Subset_StartDate(campaign_subset_StartDate_);
                set_Campaign_Subset_MiddleDate(campaign_subset_MiddleDate_);
                set_Campaign_Subset_EndDate(campaign_subset_EndDate_);
        
                setCanvas('non_continuous_subset');
            }
        }
        else{
            setCanvas('default')
        }
    },[newStartDate, newEndDate, newEndDate_Pre, newStartDate_Camp]);









    let dataChart2 = {};
    let optionChart2 = {};
    if(Canvas === 'continuous_subset'){

        const createGradient = (ctx) => {
            const gradient = ctx.createLinearGradient(0, 0, 0, 220); 
            gradient.addColorStop(0, '#DEDEDE'); // Bottom color
            gradient.addColorStop(1, 'rgba(26, 122, 175, 0)'); // 0 means strongest color, 1 means lightest color
            return gradient;
        };
        const createGradient2 = (ctx) => {
            const gradient = ctx.createLinearGradient(0, 0, 0, 500); 
            gradient.addColorStop(0, 'gray'); // Bottom color
            gradient.addColorStop(1, 'rgba(26, 122, 175, 0)'); // 0 means strongest color, 1 means lightest color
            return gradient;
        };
          
        dataChart2 = {
            labels: entireObj.datesObj.OriginalDates.map((data) => data),
            datasets: [
                {                                      // 2nd Rectangle from Left 
                    type: 'line',
                    data: [
                        { x: subset_StartDate, y: 0 },
                        { x: subset_StartDate, y: 1 },
                        { x: entireObj.datesObj.Date_Ending_Pre, y: 1 },
                    ],
                    fill: true,
                    backgroundColor: (context) => {
                        const { chart } = context;
                        const { ctx } = chart;
                        return createGradient(ctx); // Use the gradient as the background color
                    },
                    borderWidth: 2,
                    pointRadius: 0,
                    borderColor: 'rgb(26, 122, 175)',
                    
                    datalabels: {   display: false    },
                },

                {                                      // 1st Rectangle from Left
                    type: 'line',
                    data: [
                        { x: entireObj.datesObj.Date_Starting_Pre, y: 1 },
                        { x: entireObj.datesObj.Date_middle_Pre, y: 1 },
                        { x: subset_StartDate, y: 1 },
                    ],
                    fill: true,
                    backgroundColor: '#DEDEDE',
                    borderWidth: 0,
                    pointRadius: 0,
                    
                    datalabels: {
                        anchor: 'center',
                        align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                        offset: 0,
                        formatter: (value, context) => {
                            if (context.dataIndex === 1) {     return "Pre Period";     }   // index 1 represents  -  { x: date_middle_Pre, y: 1 },
                            else {                             return '';               }
                        }
                    }
                },

                {                                      // 3rd rectangle from Left
                    type: 'line',
                    data: [
                        { x: entireObj.datesObj.Date_Ending_Pre, y: 1 },
                        { x: subset_EndDate, y: 1 },
                        { x: subset_EndDate, y: 0 }
                    ],
                    fill: true,
                    backgroundColor: (context) => {
                        const { chart } = context;
                        const { ctx } = chart;
                        return createGradient2(ctx); // Use the gradient as the background color
                    },
                    borderWidth: 2,
                    pointRadius: 0,
                    borderColor: 'rgb(26, 122, 175)',
                    
                    datalabels: {   display: false    },
                },
    
                {                                      // 4th rectangle from Left
                    type: 'line',
                    data: [
                        { x: subset_EndDate, y: 1 },
                        { x: entireObj.datesObj.Date_middle_Campaign, y: 1 },
                        { x: entireObj.datesObj.Date_Ending_Campaign, y: 1 },
                    ],
                    fill: true,
                    backgroundColor: 'gray',
                    borderWidth: 0,
                    pointRadius: 0,
                    
                    datalabels: {
                        anchor: 'center',
                        align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                        offset: 0,
                        formatter: (value, context) => {
                            if (context.dataIndex === 1) {     return "Campaign Period";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                            else {                             return '';                    }
                        }
                    }
                },

                {                                      // This entire dataset is for 1 line below Subset
                    type: 'line',
                    data: [
                        { x: subset_MiddleDate, y: 0 },
                        { x: subset_MiddleDate, y: 1.25 },
                    ],
                    fill: true,
                    backgroundColor: 'black',
                    borderWidth: 2,
                    pointRadius: 0,
                    borderColor: 'rgb(26, 122, 175)',
                    
                    datalabels: {   display: false    },
                },

                {
                    type: 'line',
                    data: [
                        { x: subset_StartDate, y: 1 },
                        { x: subset_MiddleDate, y: 1 },
                        { x: subset_EndDate, y: 1 },
                    ],
                    fill: true,
                    backgroundColor: 'rgb(26, 122, 175)',
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: 'red',
                    
                    datalabels: {
                        anchor: 'center',
                        align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                        offset: 15,
                        formatter: (value, context) => {
                            if (context.dataIndex === 1) {     return "Subset";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                            else {                             return '';                    }
                        }
                    }
                },
                {                                             // Purpose of this dataset - Because of this dataset the Labels on the top are visible, otherwise value max is 1, and this 1.25 balances that out
                type: 'line',
                data: [
                    { x: "", y: 1.5 },
                ],
                pointRadius: 0,                    
                datalabels: {   display: false    },
                },
            ]
        }
        optionChart2 = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            animation: false, // Disable animations


            plugins: {           // With this code I am hiding legend    ->    label: "Users Gained",
                legend: {
                    display: false, 
                }
            },


            scales: {
                x: {
                    grid: {
                    display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: 12  
                        },
                        callback: function (value, index, values) {

                        const x = this.getLabelForValue(index)

                        if (x===entireObj.datesObj.Date_Starting_Pre  ||  x===entireObj.datesObj.Date_Starting_Campaign  ||  x===entireObj.datesObj.Date_Ending_Campaign  ||  x===subset_EndDate  ||  x===subset_StartDate) {
                            return x;
                        } else {
                            return null;
                        }
                        }
                    }
                },
                y: {
                beginAtZero: true,  // The purpose of this line of code is to make Y-axis start from 0
                display: false      // This line hides the entire Y-axis
                }
            }
        }
    }







    let dataChart3 = {};
    let optionChart3 = {};
    if(Canvas === 'non_continuous_subset'){

        const createGradient = (ctx) => {
            const gradient = ctx.createLinearGradient(0, 0, 0, 136); 
            gradient.addColorStop(0, '#DEDEDE'); // Bottom color
            gradient.addColorStop(1, 'rgba(26, 122, 175, 1)'); // 0 means strongest color, 1 means lightest color
            return gradient;
        };
        const createGradient2 = (ctx) => {
            const gradient = ctx.createLinearGradient(0, 0, 0, 300); 
            gradient.addColorStop(0, 'gray'); // Bottom color
            gradient.addColorStop(1, 'rgba(26, 122, 175, 1)'); // 0 means strongest color, 1 means lightest color
            return gradient;
        };

        dataChart3 = {
            labels: entireObj.datesObj.OriginalDates.map((data) => data),
            datasets: [
                {                                      // 2nd Rectangle from Left 
                    type: 'line',
                    data: [
                        { x: pre_Subset_StartDate, y: 0 },
                        { x: pre_Subset_StartDate, y: 1 },
                        { x: pre_Subset_EndDate, y: 1 },
                        { x: pre_Subset_EndDate, y: 0 },
                    ],
                    fill: true,
                    backgroundColor: (context) => {
                        const { chart } = context;
                        const { ctx } = chart;
                        return createGradient(ctx); // Use the gradient as the background color
                    },
                    borderWidth: 2,
                    pointRadius: 0,
                    borderColor: 'rgb(26, 122, 175)',
                    
                    datalabels: {   display: false    },
                },

                {                                      // 1st Rectangle from Left
                    type: 'line',
                    data: [
                        { x: entireObj.datesObj.Date_Starting_Pre, y: 1 },
                        { x: entireObj.datesObj.Date_middle_Pre, y: 1 },
                        { x: pre_Subset_StartDate, y: 1 },
                    ],
                    fill: true,
                    backgroundColor: '#DEDEDE',
                    borderWidth: 0,
                    pointRadius: 0,
                    
                    datalabels: {
                        anchor: 'center',
                        align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                        offset: 0,
                        formatter: (value, context) => {
                            if (context.dataIndex === 1) {     return "Pre Period";     }   // index 1 represents  -  { x: date_middle_Pre, y: 1 },
                            else {                             return '';               }
                        }
                    }
                },

                {                                      // 5th Rectangle from Left 
                type: 'line',
                data: [
                    { x: campaign_Subset_StartDate, y: 0 },
                    { x: campaign_Subset_StartDate, y: 1 },
                    { x: campaign_Subset_EndDate, y: 1 },
                    { x: campaign_Subset_EndDate, y: 0 },
                ],
                fill: true,
                backgroundColor: (context) => {
                    const { chart } = context;
                    const { ctx } = chart;
                    return createGradient2(ctx); // Use the gradient as the background color
                },
                borderWidth: 2,
                pointRadius: 0,
                borderColor: 'rgb(26, 122, 175)',
                
                datalabels: {   display: false    },
                },

                {                                             // Purpose of this dataset - Because of this dataset the Labels on the top are visible, otherwise value max is 1, and this 1.25 balances that out
                type: 'line',
                data: [
                    { x: "", y: 1.5 },
                ],
                pointRadius: 0,                    
                datalabels: {   display: false    },
                },

                {                                      // 3rd rectangle from Left
                    type: 'line',
                    data: [
                        { x: pre_Subset_EndDate, y: 1 },
                        { x: pre_Subset_MiddleDate, y: 1 },
                        { x: entireObj.datesObj.Date_Ending_Pre, y: 1 },
                    ],
                    fill: true,
                    backgroundColor: '#DEDEDE',
                    borderWidth: 0,
                    pointRadius: 0,
                    
                    datalabels: {
                        anchor: 'center',
                        align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                        offset: 18,
                        formatter: (value, context) => {
                            if (context.dataIndex === 1) {     return "Subset";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                            else {                             return '';                    }
                        }
                    }
                },

                {                                      // 4th rectangle from Left
                    type: 'line',
                    data: [
                        { x: entireObj.datesObj.Date_Ending_Pre, y: 1 },
                        { x: entireObj.datesObj.Date_middle_Campaign, y: 1 },
                        { x: campaign_Subset_StartDate, y: 1 },
                    ],
                    fill: true,
                    backgroundColor: 'gray',
                    borderWidth: 0,
                    pointRadius: 0,
                    
                    datalabels: {
                        anchor: 'center',
                        align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                        offset: 0,
                        formatter: (value, context) => {
                            if (context.dataIndex === 1) {     return "Campaign Period";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                            else {                             return '';                    }
                        }
                    }
                },

                {                                      // 6th rectangle from Left
                type: 'line',
                data: [
                    { x: campaign_Subset_EndDate, y: 1 },
                    { x: campaign_Subset_MiddleDate, y: 1 },
                    { x: entireObj.datesObj.Date_Ending_Campaign, y: 1 },
                ],
                fill: true,
                backgroundColor: 'gray',
                borderWidth: 0,
                pointRadius: 0,
                
                datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 18,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Subset";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                        else {                             return '';                    }
                    }
                }
                },



                {                                      // This entire dataset is for 1 line below Pre Period Subset
                type: 'line',
                data: [
                    { x: pre_Subset_MiddleDate, y: 0 },
                    { x: pre_Subset_MiddleDate, y: 1.32 },
                ],
                fill: true,
                backgroundColor: 'black',
                borderWidth: 2,
                pointRadius: 0,
                borderColor: 'rgb(26, 122, 175)',
                
                datalabels: {   display: false    },
                },

                {                                      // This entire dataset is for 1 line below Pre Period Subset
                type: 'line',
                data: [
                    { x: campaign_Subset_MiddleDate, y: 0 },
                    { x: campaign_Subset_MiddleDate, y: 1.32 },
                ],
                fill: true,
                backgroundColor: 'black',
                borderWidth: 2,
                pointRadius: 0,
                borderColor: 'rgb(26, 122, 175)',
                
                datalabels: {   display: false    },
                },
            ]
        }
        optionChart3 = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            animation: false, // Disable animations


            plugins: {           // With this code I am hiding legend    ->    label: "Users Gained",
                legend: {
                    display: false, 
                }
            },


            scales: {
                x: {
                    grid: {
                    display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: 12  
                        },
                        callback: function (value, index, values) {

                        const x = this.getLabelForValue(index)

                        if (x===entireObj.datesObj.Date_Starting_Pre  ||  x===pre_Subset_StartDate  ||  x===pre_Subset_EndDate  ||  x===entireObj.datesObj.Date_Starting_Campaign  ||  x===entireObj.datesObj.Date_Ending_Campaign  ||  x===campaign_Subset_StartDate  ||  x===campaign_Subset_EndDate) {
                            return x;
                        } else {
                            return null;
                        }
                        }
                    }
                },
                y: {
                beginAtZero: true,  // The purpose of this line of code is to make Y-axis start from 0
                display: false      // This line hides the entire Y-axis
                }
            }
        }
    }





    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';




    return (
        <div  style={{width:"100%",  height:"100%"}}>



            <div  style={{width:"100%", height:"35%", display:"flex", justifyContent:"space-between", padding:"0 1.2rem", alignItems:"flex-end"}}>
                <div  style={{width:screen_width === 'default-1536px' ? "40%" : "44%",      height:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                    <div  style={{width:"47%", height:"50%"}}>
                        <div  style={{width:"100%", position:"relative", zIndex:"2"}}>
                            <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1",      fontSize:screen_width === 'default-1536px' ? "12px" : "11px",      top:screen_width === 'default-1536px' ? "-9px" : "-8px",      left:"10px", padding:"0 4px", color:"#404040"}}>Pre Period Start Date</h2>
                            
                            <div  style={{position: "relative"}}>
                                <DatePicker    selected={newStartDate}    onChange={setNewStartDate_Func}    customInput={<ReadOnlyInput/>}    dateFormat="yyyy/MM/dd"    minDate={entireObj.datesObj.Date_Starting_Pre_default}    maxDate={entireObj.datesObj.Date_Ending_Pre_default}    className="input-field  px-8"/>
                                <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"      style={{height:screen_width === 'default-1536px' ? "24px" : "22px",    width:screen_width === 'default-1536px' ? "24px" : "22px",      position: "absolute",  right: "10px",  top: "50%",  transform: "translateY(-50%)",  pointerEvents: "none", zIndex: "2"}}>
                                    <g clipPath="url(#clip0_243_2595)">
                                    <path d="M18.4 4.63636H17.6V3H16V4.63636H8V3H6.4V4.63636H5.6C4.72 4.63636 4 5.37273 4 6.27273V19.3636C4 20.2636 4.72 21 5.6 21H18.4C19.28 21 20 20.2636 20 19.3636V6.27273C20 5.37273 19.28 4.63636 18.4 4.63636ZM18.4 19.3636H5.6V10.3636H18.4V19.3636ZM18.4 8.72727H5.6V6.27273H18.4V8.72727Z" fill="#247CA8"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_243_2595">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                    
                    <div  style={{width:"47%", height:"50%"}}>
                        <div  style={{width:"100%", position:"relative", zIndex:"2"}}>
                            <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1",       fontSize:screen_width === 'default-1536px' ? "12px" : "11px",      top:screen_width === 'default-1536px' ? "-9px" : "-8px",       left:"10px", padding:"0 4px", color:"#404040"}}>Pre Period End Date</h2>

                            <div  style={{position: "relative"}}>
                                <DatePicker    selected={newEndDate_Pre}    onChange={setNewEndDate_Pre_Func}    customInput={<ReadOnlyInput/>}    dateFormat="yyyy/MM/dd"    minDate={entireObj.datesObj.Date_Starting_Pre_default}    maxDate={entireObj.datesObj.Date_Ending_Pre_default}    className="input-field  px-8"/>
                                <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"      style={{height:screen_width === 'default-1536px' ? "24px" : "22px",    width:screen_width === 'default-1536px' ? "24px" : "22px",      position: "absolute",  right: "10px",  top: "50%",  transform: "translateY(-50%)",  pointerEvents: "none", zIndex: "2"}}>
                                    <g clipPath="url(#clip0_243_2595)">
                                    <path d="M18.4 4.63636H17.6V3H16V4.63636H8V3H6.4V4.63636H5.6C4.72 4.63636 4 5.37273 4 6.27273V19.3636C4 20.2636 4.72 21 5.6 21H18.4C19.28 21 20 20.2636 20 19.3636V6.27273C20 5.37273 19.28 4.63636 18.4 4.63636ZM18.4 19.3636H5.6V10.3636H18.4V19.3636ZM18.4 8.72727H5.6V6.27273H18.4V8.72727Z" fill="#247CA8"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_243_2595">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>

                <div  style={{width:screen_width === 'default-1536px' ? "40%" : "44%",      height:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                    <div  style={{width:"47%", height:"50%"}}>
                        <div  style={{width:"100%", position:"relative", zIndex:"2"}}>
                            <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1",       fontSize:screen_width === 'default-1536px' ? "12px" : "11px",      top:screen_width === 'default-1536px' ? "-9px" : "-8px",       left:"10px", padding:"0 4px", color:"#404040"}}>Campaign Period Start Date</h2>

                            <div  style={{position: "relative"}}>
                                <DatePicker    selected={newStartDate_Camp}    onChange={setNewStartDate_Camp_Func}    customInput={<ReadOnlyInput/>}    dateFormat="yyyy/MM/dd"    minDate={entireObj.datesObj.Date_Starting_Campaign_default}    maxDate={entireObj.datesObj.Date_Ending_Campaign_default}    className="input-field  px-8"/>
                                <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"      style={{height:screen_width === 'default-1536px' ? "24px" : "22px",    width:screen_width === 'default-1536px' ? "24px" : "22px",      position: "absolute",  right: "10px",  top: "50%",  transform: "translateY(-50%)",  pointerEvents: "none", zIndex: "2"}}>
                                    <g clipPath="url(#clip0_243_2595)">
                                    <path d="M18.4 4.63636H17.6V3H16V4.63636H8V3H6.4V4.63636H5.6C4.72 4.63636 4 5.37273 4 6.27273V19.3636C4 20.2636 4.72 21 5.6 21H18.4C19.28 21 20 20.2636 20 19.3636V6.27273C20 5.37273 19.28 4.63636 18.4 4.63636ZM18.4 19.3636H5.6V10.3636H18.4V19.3636ZM18.4 8.72727H5.6V6.27273H18.4V8.72727Z" fill="#247CA8"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_243_2595">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                    
                    <div  style={{width:"47%", height:"50%"}}>
                        <div  style={{width:"100%", position:"relative", zIndex:"2"}}>
                            <h2  className="fontF" style={{position:"absolute", backgroundColor:"white", zIndex:"1",       fontSize:screen_width === 'default-1536px' ? "12px" : "11px",      top:screen_width === 'default-1536px' ? "-9px" : "-8px",       left:"10px", padding:"0 4px", color:"#404040"}}>Campaign Period End Date</h2>

                            <div  style={{position: "relative"}}>
                                <DatePicker    selected={newEndDate}    onChange={setNewEndDate_Camp_Func}    customInput={<ReadOnlyInput/>}    dateFormat="yyyy/MM/dd"    minDate={entireObj.datesObj.Date_Starting_Campaign_default}    maxDate={entireObj.datesObj.Date_Ending_Campaign_default}    className="input-field  px-8"/>
                                <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"      style={{height:screen_width === 'default-1536px' ? "24px" : "22px",    width:screen_width === 'default-1536px' ? "24px" : "22px",      position: "absolute",  right: "10px",  top: "50%",  transform: "translateY(-50%)",  pointerEvents: "none", zIndex: "2"}}>
                                    <g clipPath="url(#clip0_243_2595)">
                                    <path d="M18.4 4.63636H17.6V3H16V4.63636H8V3H6.4V4.63636H5.6C4.72 4.63636 4 5.37273 4 6.27273V19.3636C4 20.2636 4.72 21 5.6 21H18.4C19.28 21 20 20.2636 20 19.3636V6.27273C20 5.37273 19.28 4.63636 18.4 4.63636ZM18.4 19.3636H5.6V10.3636H18.4V19.3636ZM18.4 8.72727H5.6V6.27273H18.4V8.72727Z" fill="#247CA8"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_243_2595">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            

            <div  style={{width:"100%", height:"65%"}}>

                {
                    Canvas === 'default'   ?    <div  className="line-chart-responsiveness"      style={{alignItems:screen_width === 'default-1536px' ? "flex-start" : "center"}}>
                                                    <div style={{width: '100%', height:screen_width === 'default-1536px' ? "72%" : "92%"}}>
                                                        <Line data={dataChart} options={optionChart}/>
                                                    </div>
                                                </div>
                                            :   Canvas === 'continuous_subset'     ?    <div  className="line-chart-responsiveness"      style={{alignItems:screen_width === 'default-1536px' ? "flex-start" : "center"}}>
                                                                                            <div style={{width: '100%', height:screen_width === 'default-1536px' ? "72%" : "92%"}}>
                                                                                                <Line data={dataChart2} options={optionChart2}/>
                                                                                            </div>
                                                                                        </div>
                                                                                   :    Canvas === 'non_continuous_subset'    &&    <div  className="line-chart-responsiveness"      style={{alignItems:screen_width === 'default-1536px' ? "flex-start" : "center"}}>
                                                                                                                                        <div style={{width: '100%', height:screen_width === 'default-1536px' ? "72%" : "92%"}}>
                                                                                                                                            <Line data={dataChart3} options={optionChart3}/>
                                                                                                                                        </div>
                                                                                                                                    </div>
                }
            </div>


        </div>
    );
  }
  
  export default SubsetByDate;