import { useState } from "react";
import DataSection from "./ChildComponents/DataSection";
import ExperimentSection from "./ChildComponents/ExperimentSection";
import LazyLoading_UploadDataSection from "./ChildComponents/LazyLoading_UploadDataSection";
import CreateExperimentSection from "./ChildComponents/CreateExperimentSection";
import "../../CSS/ComponentsStyling.css";





function ExperimentAndData({    file_data_table_arr,    uniqueFileIDS_from_Experiments,    count2,    setCount2,    count3,    setCount3,    business_setup_arr,    loading,    fileId_Mapping_With_Exps,    loading2,    setLoading2,    loadingCS,    fileObjs,    setFileObjs,    runR_ForStartingDatasets,    set_runR_ForStartingDatasets,    createExperiment_btn_Clicked,    setCreateExperiment_btn_Clicked,    currentExperimentWindow,    setCurrentExperimentWindow,    exp_Name,    setExp_Name,    experimentnamesArray,        initial_Datasets_fetched,    set_initial_Datasets_fetched,    loadingCE,    setLoadingCE,    setCreateSubset,    selectedObjID_forSubset,    experiment_table_arr_modified,    experiment_table_arr,    currentExperiment_IsLoaded_FromDB,    setCurrentExperiment_IsLoaded_FromDB}) {



    const [experiment_or_data, setExperiment_or_data] = useState('experiment');
    const [uploadData_btn_Clicked, setUploadData_btn_Clicked] = useState(false);
    const displayText = uploadData_btn_Clicked  ? "Data Upload" 
                                                : createExperiment_btn_Clicked  ? "Create Experiment" 
                                                                                : "Experiment and Data";



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';
    



    return (
        <div  style={{width:"100%",  height:"100%", display: "flex", flexDirection: "column", position:"relative"}}>
    

                <div    style={{ width: "100%", height: "9%",     padding: screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p    className="header-title" style={{ fontWeight: "600" }}>{displayText}</p>
                    <div  className="fontF">DP(Name and Role)</div>
                </div>

                {
                    uploadData_btn_Clicked      ?    <LazyLoading_UploadDataSection    setUploadData_btn_Clicked={setUploadData_btn_Clicked}    business_setup_arr={business_setup_arr}    count2={count2}    setCount2={setCount2}    setExperiment_or_data={setExperiment_or_data}/>
                                                            
                                                :    createExperiment_btn_Clicked   ?   <CreateExperimentSection    setCreateExperiment_btn_Clicked={setCreateExperiment_btn_Clicked}    file_data_table_arr={file_data_table_arr}    fileId_Mapping_With_Exps={fileId_Mapping_With_Exps}    setUploadData_btn_Clicked={setUploadData_btn_Clicked}    setExperiment_or_data={setExperiment_or_data}    loading2={loading2}    fileObjs={fileObjs}    setFileObjs={setFileObjs}    runR_ForStartingDatasets={runR_ForStartingDatasets}    set_runR_ForStartingDatasets={set_runR_ForStartingDatasets}    exp_Name={exp_Name}    setExp_Name={setExp_Name}    experimentnamesArray={experimentnamesArray}    initial_Datasets_fetched={initial_Datasets_fetched}    set_initial_Datasets_fetched={set_initial_Datasets_fetched}    loadingCE={loadingCE}    setLoadingCE={setLoadingCE}/>
                                                                                           
                                                                                    :   <>
                                                                                            <div  style={{width:"100%", height:"9%", display:"flex", justifyContent:"flex-start", alignItems:"flex-end"}}>
                                                                                                <button   onClick={()=>{setExperiment_or_data("experiment")}}      className="experiment-data-tabs"    style={{color: experiment_or_data === "experiment" ? "#1A7AAF" : "#171717",    fontWeight: experiment_or_data === "experiment" ? "600" : "400",    borderBottom: experiment_or_data === "experiment" ? "2px solid #1A7AAF" : "2px solid #E5E5E5"}}>Experiment</button>
                                                                                                <button   onClick={()=>{setExperiment_or_data("data")}}            className="experiment-data-tabs"    style={{color: experiment_or_data === "data"       ? "#1A7AAF" : "#171717",    fontWeight: experiment_or_data === "data"       ? "600" : "400",    borderBottom: experiment_or_data === "data"       ? "2px solid #1A7AAF" : "2px solid #E5E5E5"}}>Data</button>
                                                                                                <div  style={{flex:"1", borderBottom:"1px solid #E5E5E5"}}></div>
                                                                                            </div>

                                                                                            <div  style={{width:"100%", height:"82%"}}>
                                                                                                {
                                                                                                    experiment_or_data === "experiment"   ?                                           <ExperimentSection    setCreateExperiment_btn_Clicked={setCreateExperiment_btn_Clicked}    currentExperimentWindow={currentExperimentWindow}    setCurrentExperimentWindow={setCurrentExperimentWindow}    exp_Name={exp_Name}    setExp_Name={setExp_Name}    fileObjs={fileObjs}    setFileObjs={setFileObjs}    loading2={loading2}    setLoading2={setLoading2}    loadingCS={loadingCS}    setCreateSubset={setCreateSubset}    selectedObjID_forSubset={selectedObjID_forSubset}    count2={count2}    setCount2={setCount2}    count3={count3}    setCount3={setCount3}    experiment_table_arr_modified={experiment_table_arr_modified}    experiment_table_arr={experiment_table_arr}    currentExperiment_IsLoaded_FromDB={currentExperiment_IsLoaded_FromDB}    setCurrentExperiment_IsLoaded_FromDB={setCurrentExperiment_IsLoaded_FromDB}/>
                                                                                                                                          :   experiment_or_data === "data"    &&     <DataSection          setUploadData_btn_Clicked={setUploadData_btn_Clicked}    file_data_table_arr={file_data_table_arr}    uniqueFileIDS_from_Experiments={uniqueFileIDS_from_Experiments}    count2={count2}    setCount2={setCount2}    fileId_Mapping_With_Exps={fileId_Mapping_With_Exps}    loading2={loading2}/> 
                                                                                                }
                                                                                            </div>
                                                                                        </>
                }

    
        </div>
    );
  }
  
  export default ExperimentAndData;