import { useEffect, useRef, useCallback } from 'react';

export function useOutsideClick(set_Index_Opened, index_Opened) {
  const drop_Down_Ref = useRef(null);

  const handleCloseDropDown = useCallback((event) => {
      set_Index_Opened(null);
  }, [set_Index_Opened]);

  useEffect(() => {
      if(index_Opened !== null){
        document.addEventListener("mousedown", handleCloseDropDown);
      }

    return () => {
      document.removeEventListener("mousedown", handleCloseDropDown);
    };
  }, [index_Opened, handleCloseDropDown]);

  return { drop_Down_Ref };
}
