import { useState } from "react";
import { GoSearch } from "react-icons/go";
import Loader from "../../../assets/Images/loading.gif";
import { MdOutlineFilterAlt, MdOutlineDelete } from "react-icons/md";
import ExperimentInSession from "./ExperimentInSession";
import { MdClose } from "react-icons/md";
import "../../../CSS/ComponentsStyling.css";
import { FiArrowRight } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
import { fetch_geo_Data_corresponding_to_file_id_func, fetch_R_Data_corresponding_to_file_id_func, delete_csv_file_from_geo_data_table_func, delete_raw_csv_file_func, delete_file_data_func, delete_file_results_func, delete_experiment_geo_entries_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import { FaCircleMinus, FaCirclePlus, FaMinus, FaPlus } from "react-icons/fa6";




function ExperimentSection({setCreateExperiment_btn_Clicked,    currentExperimentWindow,    setCurrentExperimentWindow,    exp_Name,    setExp_Name,    fileObjs,    setFileObjs,    loading2,    setLoading2,    loadingCS,    setCreateSubset,    selectedObjID_forSubset,    count2,    setCount2,    count3,    setCount3,    experiment_table_arr_modified,    experiment_table_arr,    currentExperiment_IsLoaded_FromDB,    setCurrentExperiment_IsLoaded_FromDB}) {


    // console.log("experiment_table_arr_modified:-", experiment_table_arr_modified);
    // console.log("experiment_table_arr:-", experiment_table_arr);



    const [searchText3, setSearchText3] = useState("");
    const filteredKeys = Object.keys(experiment_table_arr_modified).filter(experimentName =>
        experimentName.toLowerCase().includes(searchText3.toLowerCase())
    );
    // console.log("filteredKeys :- ", filteredKeys);


    const [isHovered_on_filter3, setIsHovered_on_filter3] = useState(false);
    const [isHovered_on_del3, setIsHovered_on_del3] = useState(false);
    const [isHovered_on_Create_Experiment, setIsHovered_on_Create_Experiment] = useState(false);



    const [file_name_Popup, setFile_name_Popup] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState('');
    const [subset_of_Popup, setSubset_of_Popup] = useState(false);
    const [selectedSubsetof, setSelectedSubsetof] = useState('');
    const [description_Popup, setDescription_Popup] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');




    const [isHovered_on_del_row, setIsHovered_on_del_row] = useState(-1);






    async function loadExperimentIntoSession(experimentArr){ 
        // console.log("experimentArr :- ", experimentArr);    
    
        setLoading2(true); 



        setExp_Name(experimentArr[0].experiment_name);
        let collectedData = []; // Array to hold all results
    
        for(let i=0; i<experimentArr.length; i++){
          await fetch_geo_Data_corresponding_to_file_id(experimentArr[i], i, collectedData);
        }
    
        setFileObjs(collectedData);
        setCurrentExperimentWindow(true);
        setCurrentExperiment_IsLoaded_FromDB(true);



        setLoading2(false);    
    }

    const fetch_geo_Data_corresponding_to_file_id = async (fileObject, i, collectedData) => {    
       
        console.log("fileObject :- ", fileObject);
          
        try {
            const res = await fetch_geo_Data_corresponding_to_file_id_func(fileObject.file_id);          
            //  console.log("File Data ", res.data.data);    
    
            const R_output = await fetch_R_Data_corresponding_to_file_id_func(fileObject.file_id);          
            //  console.log("R Output ", fileObject.file_name, "    ", R_output.data.data[0].graph_data);    
            
            const data = res.data.data;
            const location = data[0].geo;
            const datesObj = findCampaignDates(data, location);

            const businessTagsObj = {   business:fileObject.business,    category:fileObject.category,    product:fileObject.product   };
            

            collectedData.push({    objId:i,    file_data:res.data.data,    file_name:fileObject.file_name,    result:R_output.data.data[0].graph_data,    description:fileObject.description,    datesObj:datesObj,    presentonDB:true,    businessTagsObj:businessTagsObj,    subsetOf:fileObject.subset_of,    file_id:fileObject.file_id,    Non_Test_Geos:fileObject.num_control_group    });
        } 
        catch (error) {
            console.log("error:-", error);
        }
    
    
    }    
    
    const findCampaignDates = (data, location) => {    // Calculating for only 1 geo, because this file must have passed through validations and thus campaign value for each date will be same across all geos
      
        const filteredData = data.filter(item => item.geo === location && item.campaign === '1');
  
        const Date_Starting_Campaign = filteredData[0].date.slice(0,5);   
        const middleIndex = Math.floor(filteredData.length / 2);
        const Date_middle_Campaign = filteredData.length % 2 === 0   ?   filteredData[middleIndex - 1].date.slice(0,5)   :   filteredData[middleIndex].date.slice(0,5);
        const Date_Ending_Campaign = filteredData[filteredData.length - 1].date.slice(0,5);
  
        const Date_Starting_Campaign_default = filteredData[0].date;
        const Date_Ending_Campaign_default = filteredData[filteredData.length - 1].date;
  
  
  
  
        const filteredData2 = data.filter(item => item.geo === location && item.campaign === '0');
        
        const Date_Starting_Pre = filteredData2[0].date.slice(0,5);
        const middleIndex_ = Math.floor(filteredData2.length / 2);
        const Date_middle_Pre = filteredData2.length % 2 === 0  ?  filteredData2[middleIndex_ - 1].date.slice(0,5)  :  filteredData2[middleIndex_].date.slice(0,5);
        const Date_Ending_Pre = Date_Starting_Campaign;
  
        const Date_Starting_Pre_default = filteredData2[0].date;
        const Date_Ending_Pre_default = filteredData2[filteredData2.length - 1].date;
  
  
  

        const OriginalDates = data.filter(item => item.geo === location).map(item => item.date.slice(0, 5));




        return {
            Date_Starting_Campaign,
            Date_middle_Campaign,
            Date_Ending_Campaign,
            Date_Starting_Campaign_default,
            Date_Ending_Campaign_default,
            Date_Starting_Pre,
            Date_middle_Pre,
            Date_Ending_Pre,
            Date_Starting_Pre_default,
            Date_Ending_Pre_default,
            OriginalDates
        };
    };









    const [deleteExperiment, setDeleteExperiment] = useState(false);
    const [array_DeleteExperiment, setArray_DeleteExperiment] = useState(null);
  
    async function del_exp_func(){
      // console.log("array_DeleteExperiment :-", array_DeleteExperiment);
      
      setLoading2(true);

  
  
      for(let i=0; i<array_DeleteExperiment.length; i++){
        const obj = array_DeleteExperiment[i];
        const currentFileId = obj.file_id;
  
        const count = experiment_table_arr.reduce((acc, item) => {    // Counting occurrences of currentFileId in experiment_table_arr
          return item.file_id === currentFileId ? acc + 1 : acc;      // The only way it can occur more than once is when this subset is used as a Starting file by some other Experiment
        }, 0);
  
        console.log(`File ID ${currentFileId} appears ${count} times.`);
  
        if(count === 1){   // Only then delete file_data from all 3 tables and output from model_result table
          await delete_csv_file_from_geo_data_table(currentFileId);
        }
      }
  
      await delete_entries_from_exp_table(array_DeleteExperiment[0].experiment_id);
  

  
      setLoading2(false);
      setCount2(count2 + 1);    // To fetch the updated list of files from DB
      setCount3(count3 + 1);    // To fetch the updated list of experiments from DB
  
    }
  
  
  
    const delete_csv_file_from_geo_data_table = async (id_DeleteCSV) => {    
      
        const res = await delete_csv_file_from_geo_data_table_func(id_DeleteCSV);         
        
        if(res.data.status === 500){
            console.log("Error while deleting rows from geo_data");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from geo_data`);
            await delete_raw_csv_file(id_DeleteCSV);
        }
  
    }
  
    const delete_raw_csv_file = async (id_DeleteCSV) => {   
  
        const res = await delete_raw_csv_file_func(id_DeleteCSV);    
        
        if(res.data.status === 500){
            console.log("Error while deleting row from raw_csv_file");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from raw_csv_file`);
            await delete_file_data(id_DeleteCSV);
        }
  
    }
  
    const delete_file_data = async (id_DeleteCSV) => {   
  
        const res = await delete_file_data_func(id_DeleteCSV);    
        
        if(res.data.status === 500){
            console.log("Error while deleting row from file_data");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from file_data`);
            await delete_file_results(id_DeleteCSV);
        }
  
    }
  
    const delete_file_results = async (id_DeleteCSV) => {   
  
        const res = await delete_file_results_func(id_DeleteCSV);    
        
        if(res.data.status === 500){
            console.log("Error while deleting row from model_result");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from model_result`);
        }
  
    }
  
    const delete_entries_from_exp_table = async (exp_id) => {   
  
        const res = await delete_experiment_geo_entries_func(exp_id);    
        
        if(res.data.status === 500){
            console.log("Error while deleting entries from experiment_geo");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} entries deleted successfully from experiment_geo`);
        }
    
    }







          //------ For Collapsible row -----------//
    const initial_Row_Count = 2;
    const [is_Collapsed, set_Is_Collapsed] = useState([]);

    function handle_Row_Collapse(experimentName) {
        const isAlreadyOpen = is_Collapsed.includes(experimentName);

        if (isAlreadyOpen) {
        const updatedList = is_Collapsed.filter( (name) => name !== experimentName );
        set_Is_Collapsed(updatedList);
        return;
        }

        set_Is_Collapsed((prev) => [...prev, experimentName]);
    }





    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)  screen_width = '1200_1400';






    if (loading2) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={Loader} width="180" height="180"/>
            </div>
        )
    }





    return (
        <>
        {
            !currentExperimentWindow  
            ?
                <div  style={{width:"100%", height:"100%"}}>


                    <div style={{ width: "100%", height: "14%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>


                        <div      className="search-bar-container"   style={{borderRadius: "30px", backgroundColor: "white", boxShadow: "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", display: "flex", alignItems: "center" }}>
                            <GoSearch   fontSize={screen_width === 'default-1536px' ? 22 : 18.3}   color="#27272A" />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchText3}
                                onChange={(e) => setSearchText3(e.target.value)}
                                style={{ color: "#6E6E6E", border: "none", outline: "none", background: "transparent", flex: "1",    fontSize:screen_width === 'default-1536px' ? "16px" : "13.3px" }}
                            />
                        </div>


                        <div style={{ display: "flex",  gap:screen_width === 'default-1536px' ? "1rem" : "0.8rem" }}>
                            {/* <button style={{ width: "46px", height: "46px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_filter3 ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}      onMouseEnter={() => setIsHovered_on_filter3(true)}     onMouseLeave={() => setIsHovered_on_filter3(false)}>
                                <MdOutlineFilterAlt color="#27272A" fontSize={26} />
                            </button> */}

                            <button    className="delete-icon"     style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_del3    ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out' }}      onMouseEnter={() => setIsHovered_on_del3(true)}        onMouseLeave={() => setIsHovered_on_del3(false)}>
                                    <div  style={{color: "#A1A1AA"}}>
                                        <MdOutlineDelete    fontSize={screen_width === 'default-1536px' ? 26 : 21.6}  />
                                    </div>
                            </button>

                            <div style={{ display: "flex", gap: "1px" }}>
                                <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "46px" : "38px", borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "46px" : "38px", borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                            </div>

                            <button    onClick={()=>{setCreateExperiment_btn_Clicked(true)}}    className="create-exp-button"   style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#97DDFF", color: "#171717", borderRadius: "37px", fontWeight: "500",      boxShadow: isHovered_on_Create_Experiment ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}    onMouseEnter={() => setIsHovered_on_Create_Experiment(true)}     onMouseLeave={() => setIsHovered_on_Create_Experiment(false)}>Create Experiment</button>
                        </div>


                    </div>

                    <div  style={{width:"100%", height:"86%", borderRadius: "8px",  boxShadow: "8px 8px 20px 0px #AEAEC080", padding:"1rem 0"}}>
                        
                        {
                            experiment_table_arr.length === 0
                            ?
                                <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.2rem"}}>
                                    <svg    style={{width:screen_width === 'default-1536px' ? "285px" : "237px"}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                                    </svg>

                                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "27px" : "24px", color:"#111827", fontWeight:"600"}}>No Saved Experiment in the DB</p>

                                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "16px", color:"#4B5563", fontWeight:"400"}}>Create an Experiment to start working</p>
                                    </div>
                                    
                                </div>
                            :                        
                                <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center", overflow:"auto", gap:"2rem", padding:"0 2rem"}}>
                                
                                
                                    {
                                        filteredKeys.map((experimentName, index) => (
                                            <div  key={experimentName}    style={{width:"100%", display:"flex", flexDirection:"column"}}>
                        
                        
                                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"0.6rem 0"}}>
                                                        <p  className="fontF"  style={{fontWeight:"600",      fontSize:screen_width === 'default-1536px' ? "22px" : "18.5px",      color:"#171717"}}>{experimentName}</p>
                                                        
                                                        <div  style={{display:"flex", gap:"1.2rem"}}>
                                                            <button      onClick={()=>{loadExperimentIntoSession(experiment_table_arr_modified[experimentName])}}   style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF"}}    className="footer-button" >Load into session</button>

                                                            <div     onClick={()=> {   setDeleteExperiment(true);    setArray_DeleteExperiment(experiment_table_arr_modified[experimentName])   }}     style={{display:"flex", alignItems:"center", gap:"0.4rem", padding:"0.4rem 0.7rem", borderRadius:"5px", cursor:"pointer", boxShadow: isHovered_on_del_row === index? "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1" : "none", transition: 'box-shadow 0.2s ease-in-out'}}    onMouseEnter={() => setIsHovered_on_del_row(index)}     onMouseLeave={() => setIsHovered_on_del_row(-1)}>
                                                                <div  style={{display:"flex", alignItems:"center"}}>    
                                                                    <MdOutlineDelete color="#DC2626" fontSize={20}/>    
                                                                </div>  
                                                                <p  className="delete-business"  style={{color:"#DC2626"}}>Delete</p>
                                                            </div>
                                                        </div>
                                                    </div>
                        
                        
                                                    <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",    backgroundColor: "white", color:"#171717" }}>
                                                        
                                                        <thead  style={{width:"100%"}}>
                                                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                                                    <th    style={{width:"3%",         padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                                                    <th    style={{width:"23.5%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>File Name</th>
                                                                    <th    style={{width:"50%",        padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Description</th>
                                                                    <th    style={{width:"23.5%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>Subset of</th>
                                                                </tr>
                                                        </thead>
                            
                                                        <tbody  style={{width:"100%"}}>
                                                            {
                                                                experiment_table_arr_modified[experimentName].map((obj, index) => {

                                                                    const is_InvisibleRow = initial_Row_Count <= index;
                                                                    const is_Show = is_Collapsed.includes(experimentName);

                                                                    return(
                                                                        <tr key={index}  style={{width:"100%",    display: !is_Show && is_InvisibleRow  ?  "none"  :  "",    transition: "all 0.3s ease, opacity 0.3s ease",}}>
                                                                            <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}></td>
                                                                            <td      style={{width:"23.5%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>
                                                                                {
                                                                                    screen_width === 'default-1536px'   ?
                                                                                                                            obj.file_name.length > 30       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                    <p>{obj.file_name.slice(0,27)}</p>
                                                                                                                                                                    <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                </div>  
                                                                                                                                                            :   obj.file_name
                                                                                                                        :
                                                                                                                            obj.file_name.length > 26       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                    <p>{obj.file_name.slice(0,25)}</p>
                                                                                                                                                                    <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                </div>  
                                                                                                                                                            :   obj.file_name
                                                                                }
                                                                            </td>

                                                                            <td      style={{width:"50%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>    
                                                                                {
                                                                                    obj.description.length > 60     ?    <div  style={{display:"flex"}}>
                                                                                                                            <p>{obj.description.slice(0,58)}</p>
                                                                                                                            <button    onClick={()=>{setDescription_Popup(true);  setSelectedDescription(obj.description)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                        </div>  
                                                                                                                    :    obj.description
                                                                                }
                                                                                
                                                                            </td>

                                                                            <td      style={{width:"23.5%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                                                {
                                                                                    obj.subset_of === "" ||  obj.subset_of === null  ?    "NA" 
                                                                                                            :    screen_width === 'default-1536px'  ?
                                                                                                                                                        obj.subset_of.length > 25       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                                <p>{obj.subset_of.slice(0,22)}</p>
                                                                                                                                                                                                <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subset_of)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                                            </div>  
                                                                                                                                                                                        :   obj.subset_of
                                                                                                                                                    :
                                                                                                                                                        obj.subset_of.length > 21       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                                <p>{obj.subset_of.slice(0,20)}</p>
                                                                                                                                                                                                <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subset_of)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                                            </div>  
                                                                                                                                                                                        :   obj.subset_of
                                                                                }
                                                                            </td>                                                        
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                            
                                                    </table>


                                                    {
                                                        (initial_Row_Count    <    experiment_table_arr_modified[experimentName]?.length)
                                                        && 
                                                            (
                                                                <button    onClick={() => { handle_Row_Collapse(experimentName) }}    style={{padding: "8px 16px 8px 27px",  color: "black",   borderRight: "1px solid #ddd",  borderBottom: "1px solid #ddd",   borderLeft: "1px solid #ddd",  cursor: "pointer",  display: "flex",  alignItems: "center",  justifyContent: "start"  }}>
                                                                    {
                                                                        is_Collapsed.length  &&  is_Collapsed.includes(experimentName)    ?    (<>    <FaMinus size={"12"}/>   &nbsp;   <span   style={{ fontSize: "13px"  }}> Hide </span>    </>) 
                                                                                                                                          :    (<>    <FaPlus size={"12"}/>    &nbsp;   <span   style={{ fontSize: "13px"  }}> Show All </span>  </>)
                                                                    }
                                                                </button>
                                                    )}

                        
                                            </div>
                                        ))
                                    }
            
            
                                </div>
                        }
 
                    </div>


                </div>
            :
                <ExperimentInSession    exp_Name={exp_Name}    setExp_Name={setExp_Name}    fileObjs={fileObjs}    setFileObjs={setFileObjs}    setCurrentExperimentWindow={setCurrentExperimentWindow}    loading2={loading2}    setLoading2={setLoading2}    loadingCS={loadingCS}    setCreateSubset={setCreateSubset}    selectedObjID_forSubset={selectedObjID_forSubset}    count2={count2}    setCount2={setCount2}    count3={count3}    setCount3={setCount3}    currentExperiment_IsLoaded_FromDB={currentExperiment_IsLoaded_FromDB}    setCurrentExperiment_IsLoaded_FromDB={setCurrentExperiment_IsLoaded_FromDB}/>

        }



       {
            file_name_Popup  &&  ( 
                <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>File Name</p>
                        <button  onClick={()=>{setFile_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedFilename}    </p>    
                
            
                </div>
            )
                                    
        }

        {
            file_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }



        {
            subset_of_Popup  &&  ( 
                <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Subset Of</p>
                        <button  onClick={()=>{setSubset_of_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedSubsetof}    </p>    
            
                </div>
            )
                                    
        }

        {
            subset_of_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }



        {
            description_Popup  &&  ( 
                <div   className={` modal-delete-rows`}   style={{width: screen_width === '1200_1400' && "320px"}}>

                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                        <p  className="fontF"  style={{fontSize: screen_width === 'default-1536px' ? "22px" : "19px", fontWeight:"600"}}>Description</p>
                        <button  onClick={()=>{setDescription_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}       color="#171717"/>                      </button>
                    </div>

                    <p  className="fontF"  style={{fontSize: screen_width === 'default-1536px' ? "16px" : "14px",      margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedDescription}    </p>    
            
            
                </div>
            )
                                    
        }

        {
            description_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
        }









        {
                                        
            deleteExperiment  &&  ( 
                <div   className={` modal-delete-rows`}   style={{width: screen_width === '1200_1400' && "300px"}}>

                    <div style={{display:"flex", flexDirection:"column", gap:"0.5rem"}}>
                        <p  style={{fontSize: screen_width === 'default-1536px' ? "24px" : "20px"}}>     Are you sure?    </p>    
                        <p  style={{fontSize: screen_width === 'default-1536px' ? "15px" : "12px"}}>     The Selected Experiment will be deleted    </p>    
                    </div>

                    <div   className="discard-business-buttons-container"   style={{display:"flex", justifyContent:"flex-start"}}>
                        <button  onClick={() => { setDeleteExperiment(false);    del_exp_func()}}                 className="discard-business-button"     style={{backgroundColor:"red", borderRadius:"5px", color:"white"}}>Delete</button>
                        <button  onClick={() => { setDeleteExperiment(false) }}                                   className="discard-business-button"     style={{backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                    </div>                      
            
                </div>
            )
                                    
        }

        {
            deleteExperiment    &&    (<div   className={` overlay-delete-rows `}></div>)
        }




        </>
    );
  }
  
  export default ExperimentSection;
