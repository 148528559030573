function PowerOfTest() {


    return (
        <div  style={{width:"100%",  height:"100%",  display:"flex", justifyContent:"center", alignItems:"center", fontSize:"24px"}}>
    
            PowerOfTest
    
        </div>
    );
  }
  
  export default PowerOfTest;