
import { useState, useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useSearchParams } from 'react-router-dom';

import TD_Step_1 from "./Transform_Data/TD_Step_1";
import TD_Step_2 from "./Transform_Data/TD_Step_2";
import TD_Step_3 from "../MMM_data_Components/Transform_Data/TD_Step_3";





function MMM_Merged_data({    final_merged_Dataset,    overAll_Variable_Groups,    set_overAll_Variable_Groups,    selected_dummies,    set_selected_dummies,    selected_Transform_Action,    set_selected_Transform_Action,    set_mergedDataset_Card_Selected    }) {




    const navigate = useNavigate(); 













    
    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';



    const [Transform_Data_Step_Number, set_Transform_Data_Step_Number] = useState(1);






    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column"}}>




                <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", backgroundColor:""}}>
                    <div  style={{width:"100%", height:"86%", display:"flex", flexDirection:"column", alignItems:"center", backgroundColor:"",          gap:screen_width === 'default-1536px' ? "1.8rem" : "1rem"}}>
                    

                        <div  style={{width:"100%", height:"40px", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"", position:"relative"}}>



                            <div  style={{width:"100%", display:"flex", alignItems:"center", backgroundColor:"", position:"absolute", top:"", left:""}}>
                                <button    className="back-navigation-business-setup"   onClick={()=>{    set_mergedDataset_Card_Selected('')    }}   style={{color:"#6E6E6E", display:"flex", gap:"0.3rem", backgroundColor:"", alignItems:""}}>
                                    <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                                    <span  style={{color:"#0000EE", fontWeight:"400", fontSize:"17px"}}>Merged Data</span>
                                </button>

                                {/* <p  className="add-new-text"  style={{color:"#6E6E6E", fontSize:"15.5px"}}>  &nbsp; </p> */}
                            </div>


                            
                            <div style={{display:"flex", gap:"8px", alignItems:"center"}}>
                                

                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"38px", height:"38px", borderRadius:"50%", backgroundColor:"#16618E", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:"#FFFFFF", fontSize:"16px", fontWeight:"600"}}>1</span>    </div>
                                    <p    className='fontF'  style={{color:"#3D3D3D", fontSize:"16px", fontWeight:"600"}}>Create Dummies</p>      {/* for text */}
                                </div>                                
                                <div  style={{width:"64px", height:"0", border:Transform_Data_Step_Number === 1 ? "1px dashed gray" : "1px dashed #16618E"}}></div>


                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"38px", height:"38px", border:Transform_Data_Step_Number === 1 ? "2px solid gray" : "none",    borderRadius:"50%", backgroundColor:Transform_Data_Step_Number === 1 ? "#f1f1f1" : "#16618E", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:Transform_Data_Step_Number === 1 ? "#3D3D3D" : "#FFFFFF", fontSize:"16px", fontWeight:"600"}}>2</span>    </div>
                                    <p    className='fontF'  style={{color:"#3D3D3D", fontSize:"16px", fontWeight:"600"}}>Transform Variables</p>      {/* for text */}
                                </div>
                                <div  style={{width:"64px", height:"0", border:Transform_Data_Step_Number === 3 ? "1px dashed #16618E" : "1px dashed gray"}}></div>


                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"38px", height:"38px", border:Transform_Data_Step_Number === 3 ? "none" : "2px solid gray",    borderRadius:"50%", backgroundColor:Transform_Data_Step_Number === 3 ? "#16618E" : "#f1f1f1", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:Transform_Data_Step_Number === 3 ? "#FFFFFF" : "#3D3D3D", fontSize:"16px", fontWeight:"600"}}>3</span>    </div>
                                    <p    className='fontF'  style={{color:"#3D3D3D", fontSize:"16px", fontWeight:"600"}}>Create Adstock</p>      {/* for text */}
                                </div>


                            </div>

                        </div>

                        <div  style={{width:"100%", height:"383px", backgroundColor:""}}>
                            {
                                Transform_Data_Step_Number === 1    ?   <TD_Step_1    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}    selected_dummies={selected_dummies}    set_selected_dummies={set_selected_dummies}/>
                                                                    :   Transform_Data_Step_Number === 2    ?    <TD_Step_2     final_merged_Dataset={final_merged_Dataset}    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}    selected_dummies={selected_dummies}    selected_Transform_Action={selected_Transform_Action}    set_selected_Transform_Action={set_selected_Transform_Action}/>
                                                                                                            :    Transform_Data_Step_Number === 3    &&    <TD_Step_3 />
                            }
                        </div> 

                      
                    </div>



                    <div  style={{width:"100%", height:"10%",    padding:screen_width === 'default-1536px' ? "12px 24px" : "10px 24px",    borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                        <div>
                            {/* { 
                                ASD    ?    <p    className="error-texts"    style={{color:"#DC2626"}}>CSV file contains Errors, Click on "Show Errors" for more details</p>
                                        :    !(showBtn  &&  business_Val  &&  category_Val  &&  product_Val   &&  !duplicateFileNameError  &&  file  &&  uploadData_descriptionX)  &&  <p    className="error-texts"    style={{color:"#DC2626"}}>All fields must be filled in before saving</p>
                            } */}

                            {/* {
                                Transform_Data_Step_Number === 3    &&    <p    className="error-texts"    style={{color:"darkblue", fontWeight:"500"}}>Variable aggregation is an optional step. You can proceed by clicking the Save button.</p>
                            } */}
                            
                        </div>

                        <div  style={{display:"flex", gap:"1rem"}}>
                            {
                                Transform_Data_Step_Number !== 1  &&  <button      onClick={()=>{ set_Transform_Data_Step_Number(Transform_Data_Step_Number-1) }}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}   >Previous</button>
                            }
                            
                            {
                                Transform_Data_Step_Number !== 3  &&  <button      onClick={()=>{ set_Transform_Data_Step_Number(Transform_Data_Step_Number+1) }}    className="footer-button"    style={{borderRadius:"7px",  border:"2px solid #1A7AAF", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600"}}   >Next</button>
                            }
                        </div>
                    </div>
                
                </div>

    
        </div>
    );
  }
  
  export default MMM_Merged_data;