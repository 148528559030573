

import { useState } from "react";
import Select from "react-select"; 
import { sampleData3, arr1, arr, arr2, arrX  } from "./GeoExperiment/ChildComponents/SampleFile3";
import { sampleData4 } from "./GeoExperiment/ChildComponents/SampleFile4";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';   // Because I imported and registered this here, It will be applied to entire Project folder, SO for others I have to set  datalabels: {   display: false    },
ChartJS.register(ChartDataLabels);






const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '43px', 
        borderRadius: '4px',
        border: '2px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '18px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '12px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};

const customStyles2 = {
    control: (provided, state) => ({
        ...provided,
        height: '40px', 
        borderRadius: '4px',
        border: '1px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '10px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};







function Dashboard() {





    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';





    let dataChart = {};
    let optionChart = {};
    if(sampleData3.length > 0){

        const maxActual = Math.max(...sampleData3.map(data => data.actual_metric));
        const maxSynthetic = Math.max(...sampleData3.map(data => data.synthetic_metric));
        const maxYValue = Math.max(maxActual, maxSynthetic);



        dataChart = {
            labels: sampleData3.map((data) => data.date.slice(-5)),
            datasets: [
                {
                    label: "Actual",
                    data: sampleData3.map((data) => data.actual_metric),
                    pointBackgroundColor: "#6e6e6e",
                    backgroundColor:"#6e6e6e",
                    borderColor: "#6e6e6e",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },

                    xAxisId: 'x-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                    yAxisId: 'y-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis

                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph

                },
                {
                    label: "Synthetic",
                    data: sampleData3.map((data) => data.synthetic_metric),
                    pointBackgroundColor: "#2899cf",
                    borderColor: "#2899cf",
                    backgroundColor:"#2899cf",
                    borderWidth: 2,
                    // datalabels: {
                    //   anchor: 'end', 
                    //   align: 'end',
                    //   offset:-1,        // This is the distance between top of bar and Data-Label
                        
                    //   formatter: (value, context) => {
                    //     const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                    //       style: 'decimal',
                    //       minimumFractionDigits: 0, 
                    //       maximumFractionDigits: 2, 
                    //       grouping: true 
                    //     });
                    //     return formatter.format(value);
                    //   },
                    // },
                    datalabels: {   display: false    },
                    // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                    radius:0,                   // The purpose of this line is to specify the dot's radius
                    tension: 0.1,               // The purpose of this line is to add curve between 2 dots
                    // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph
                },
                {
                    type: 'line',
                    label: '',
                    data: [
                        // { x: date_Starting_Campaign, y: 0 },
                        { x: "09-27", y: maxYValue },
                        { x: "10-04", y: maxYValue },
                        { x: "10-11", y: maxYValue },
                        
                        // { x: date_Ending_Campaign, y: 0 }
                    ],
                    fill: true,
                    backgroundColor: '#EEEEEE',
                    borderColor: 'white',
                    tension: 0,
                    borderWidth: 0.3,
                    pointRadius: 0,
                    
                    datalabels: {
                    anchor: 'center',
                    align: 'top',           // I coded this thing in a way that "Campaign Period" will come on top of
                    offset: 0,
                    formatter: (value, context) => {
                        if (context.dataIndex === 1) {     return "Campaign";     }   // index 1 represents  -  { x: date_middle_Campaign, y: maxYValue },
                        else {                             return '';                    }
                    }
                    }
                },

                // {
                //     type: 'line',
                //     label: '',
                //     data: [
                //         { x: lastDatebeforeGap, y: 0 },
                //         { x: lastDatebeforeGap, y: lastBiggestMetricBeforeGap },
                //     ],
                //     fill: true,
                //     borderColor: 'gray', 
                //     tension: 0,
                //     borderWidth: 0.5,
                //     pointRadius: 0,
                    
                //     datalabels: {    display: false    }
                // },
            
                // {
                //     type: 'line',
                //     label: '',
                //     data: [
                //     { x: firstDateAfterGap, y: 0 },
                //     { x: firstDateAfterGap, y: FirstBiggestMetricAfterGap },
                //     ],
                //     fill: true,
                //     borderColor: 'gray',
                //     tension: 0,
                //     borderWidth: 0.5,
                //     pointRadius: 0,
                    
                //     datalabels: {    display: false    }
                // },
            ]
        }
        optionChart = {                          
            maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


            // animation: {
            //   duration: 2000,
            //   easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
            //   animateScales: true,

                // onStart: function(animation) {
                //   console.log('Animation started');
                // },
                // onComplete: function(animation) {
                //   console.log('Animation completed');
                // },
            // },


            plugins: {
                datalabels: {
                    font: {
                        family: "Toboggan",        // To change font family of data labels on charts
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "12px" : "10px"
                    }
                },
                legend: {
                labels: {
                    boxWidth: screen_width === 'default-1536px' ? 12 : 10,       // For making legend square
                    font: {
                        family: "Toboggan",         // To change font family of label(label is present on top of chart)
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "11px" : "10px"
                    }
                }
                }
            
            },


            scales: {
                x: {
                    id: 'x-axis1',
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: "Toboggan",            // To change font family of Y-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "12px" : "10px"  
                        }
                    }
                },
                y: {
                    beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

                    ticks:{
                        callback: function(value){
                            if (value >= 1000) {
                                return "" + (value / 1000).toFixed(1) + "k"; // Dividing by 1000 and appending 'k' with one decimal
                            }
                            return "" + value; // For values less than 1000, show as is
                        },
                        font: {
                            family: "Toboggan",            // To change font family of X-Axis
                            weight: "bold",
                            size: screen_width === 'default-1536px' ? "11px" : "10px"
                        }
                    },

                    id: 'y-axis1',
                    grid: {
                        display: false
                    }
                }
            }
        }
    }




    const [showGraph, setShowGraph] = useState(true);

    const handleToggle = () => {
        if(showData){
            setShowData(prevState => !prevState);
        }
        setShowGraph(prevState => !prevState);
    };

    const [showData, setShowData] = useState(false);

    const handleToggle2 = () => {
        if(showGraph){
            setShowGraph(prevState => !prevState);
        }
        setShowData(prevState => !prevState);
    };










    const data1 = {
        labels: arr1.map((data) => data.channel),
        datasets: [
            {
                label: "Media Share",
                data: arr1.map((data) => data.share),
                backgroundColor:[
                    "#165376",
                    "#1A7AAF",
                    "#2899CF",
                    "#4FB2E1",
                    "#8ECDED",
                    "#C2E2F5"
                ],
                borderColor: "white",
                borderWidth: 3,
                datalabels: {
                    display: false,      
                    anchor: 'center', 
                    align: 'end',  
                    offset: -16,                                               // Positioning the data label
                    formatter: (value, context) => {    return `${value}%` ;    },
                    // formatter: (value, context) => {
                    //     // Get the year associated with the data point
                    //     const channel = context.chart.data.labels[context.dataIndex];
                    //     return `${channel} ${value}`; // Append year to the value
                    // },
                    color: 'white',
                }
            }
        ]
    }

    const options = {
        responsive:true,                   // Extra
        maintainAspectRatio:false,          // Extra
        // layout:{                           // Extra
        //     padding: 100,                  // Extra
        // },
        plugins: {
          legend: {               // To edit the Color indicators
            display: false,      
            position: 'bottom', 
    
            labels: {
              usePointStyle: true, 
              fontColor: 'black',
              fontSize: 14,
              padding: 16,
            }
          },
          title: {
            display: false, // Set to true to display the title
            position: 'bottom',
            text: "Media Mix", // Replace with your desired title text
            fontSize: 20, // Adjust font size as needed
            fontColor: "black", // Set the title text color
            padding: 10, // Add padding around the title
          },
        },
        cutout:"70%"
    };

    const lineDetails = {
        id: "lineDetails",
        afterDraw(chart, args, options) {
            const { ctx, chartArea: { width, height } } = chart;
    
            const lineWidth = 8;
            const layoutPadding = 10;
            chart.getDatasetMeta(0).data.forEach((dataPoint, id) => {
    
                const { x: toolTipX, y: toolTipY, innerRadius, outerRadius } = dataPoint.tooltipPosition();
    
                const halfWidth = width / 2;
                const halfHeight = height / 2;
    
                // Determine the line end position based on which half of the chart the tooltip is in
                const lineEndPosX = toolTipX >= halfWidth + layoutPadding ? toolTipX + lineWidth : toolTipX - lineWidth;
                const lineEndPosY = toolTipY >= halfHeight + layoutPadding ? toolTipY + lineWidth : toolTipY - lineWidth;
    
                const extraLine = toolTipX >= halfWidth + layoutPadding ? 30 : -30;
    
                // Draw the line
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(toolTipX + 0, toolTipY + 0);  // Positioning of line from inside the doughnut's sections
                ctx.lineTo(lineEndPosX, lineEndPosY);
                ctx.lineTo(lineEndPosX + extraLine, lineEndPosY);
                ctx.strokeStyle = "#404040";
                ctx.stroke();
    
                ctx.textBaseline = "middle";
                ctx.font = "13px Inter";
    
                // Get the channel name and share from arr1
                const channelName = chart.data.labels[id];
                const shareValue = chart.data.datasets[0].data[id];
    
                // Format the share value (optional - you can change it to a percentage or any other format)
                const shareText = `${shareValue}%`;
    
                // Combine the channel name and share value
                const displayText = `${channelName} (${shareText})`;
    
                // Calculate text position to ensure it's positioned correctly based on line direction
                const textSize = ctx.measureText(displayText).width;
                const textPositionX = (lineEndPosX + extraLine) + (toolTipX >= halfWidth + layoutPadding ? 3 : -(textSize + 3));
    
                // Render the text (channel name and share percentage)
                ctx.fillText(displayText, textPositionX, lineEndPosY);
            });
        }
    };
    







    const dataX = {
        labels: arrX.map((data) => data.channel),
        datasets: [
            {
                label: "Media Share",
                data: arrX.map((data) => data.share),
                backgroundColor:[
                    "#557763",
                    "#778f51",
                    "#f7e788",
                    "#d83c3c",
                    "#d83c3c"
                ],
                borderColor: "white",
                borderWidth: 3,
                datalabels: {
                    display: false,
                    anchor: 'center', 
                    align: 'end',  
                    offset: -16,                                               // Positioning the data label
                    formatter: (value, context) => {    return `${value}%` ;    },
                    // formatter: (value, context) => {
                    //     // Get the year associated with the data point
                    //     const channel = context.chart.data.labels[context.dataIndex];
                    //     return `${channel} ${value}`; // Append year to the value
                    // },
                    color: 'white',
                }
            }
        ]
    }

    const optionsX = {
        responsive:true,
        maintainAspectRatio:false,
        plugins: {
          legend: {               // To edit the Color indicators
            display: false,      
            position: 'bottom', 
    
            labels: {
              usePointStyle: true, 
              fontColor: 'black',
              fontSize: 14,
              padding: 16,
            }
          },
          title: {
            display: false, // Set to true to display the title
            position: 'bottom',
            text: "Media Mix", // Replace with your desired title text
            fontSize: 20, // Adjust font size as needed
            fontColor: "black", // Set the title text color
            padding: 10, // Add padding around the title
          },
        },
        cutout:"70%"
    };

    const lineDetailsX = {
        id: "lineDetailsX",
        afterDraw(chart, args, options) {
            const { ctx, chartArea: { width, height } } = chart;
    
            const lineWidth = 8;
            const layoutPadding = 10;
            chart.getDatasetMeta(0).data.forEach((dataPoint, id) => {
    
                const { x: toolTipX, y: toolTipY, innerRadius, outerRadius } = dataPoint.tooltipPosition();
    
                const halfWidth = width / 2;
                const halfHeight = height / 2;
    
                // Determine the line end position based on which half of the chart the tooltip is in
                const lineEndPosX = toolTipX >= halfWidth + layoutPadding ? toolTipX + lineWidth : toolTipX - lineWidth;
                const lineEndPosY = toolTipY >= halfHeight + layoutPadding ? toolTipY + lineWidth : toolTipY - lineWidth;
    
                const extraLine = toolTipX >= halfWidth + layoutPadding ? 30 : -30;
    
                // Draw the line
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(toolTipX + 0, toolTipY + 0);  // Positioning of line from inside the doughnut's sections
                ctx.lineTo(lineEndPosX, lineEndPosY);
                ctx.lineTo(lineEndPosX + extraLine, lineEndPosY);
                ctx.strokeStyle = "#404040";
                ctx.stroke();
    
                ctx.textBaseline = "middle";
                ctx.font = "13px Inter";
    
                // Get the channel name and share from arr1
                const channelName = chart.data.labels[id];
                const shareValue = chart.data.datasets[0].data[id];
    
                // Format the share value (optional - you can change it to a percentage or any other format)
                const shareText = `${shareValue}%`;
    
                // Combine the channel name and share value
                const displayText = `${channelName} (${shareText})`;
    
                // Calculate text position to ensure it's positioned correctly based on line direction
                const textSize = ctx.measureText(displayText).width;
                const textPositionX = (lineEndPosX + extraLine) + (toolTipX >= halfWidth + layoutPadding ? 3 : -(textSize + 3));

                // Render the text (channel name and share percentage)
                ctx.fillText(displayText, textPositionX, lineEndPosY);
            });
        }
    };











    const data3 = {
        labels: [''], // No label as there's only one bar
        datasets: [
          {   
            label: "Paid",
            data: [arr2.find(item => item.mode === "paid").share],
            backgroundColor: "#2899CF", // Left segment color
            borderColor: "white",
            borderWidth: 1,
            datalabels: {
              anchor: 'center',
              align: 'center', 
              offset: 0,
              formatter: (value) => `${value}% (Paid)`,
              color: 'white',
            }
          },
          {
            label: "Organic",
            data: [arr2.find(item => item.mode === "organic").share],
            backgroundColor: "#f1f1f1", // Right segment color
            borderColor: "white",
            borderWidth: 1,
            datalabels: {
              anchor: 'center',
              align: 'center', 
              offset: 0,
              formatter: (value) => `${value}% (Organic)`,
              color: 'black',
            }
          }
        ]
    };
    
    const options3 = {
        plugins: {
          legend: {
            display: false, // Hide legend
          },
          title: {
            display: false, // Hide title
          },
          datalabels: {
            display: true, // Display data labels
            font: {
                family: "Toboggan",        // To change font family of data labels on charts
                weight: "bold",
                size: screen_width === 'default-1536px' ? "14px" : "11px"
            }
          }
        },
        scales: {
          x: {
            stacked: true, // Enable stacking of bars
            display: false, // Hide x-axis
          },
          y: {
            stacked: true, // Enable stacking of bars
            display: false, // Hide y-axis
          }
        },
        indexAxis: 'y', // Make the bars horizontal
        responsive: true,
        maintainAspectRatio: false,
        barThickness: 100, // Adjust bar thickness if necessary
    };


    
    
    









    const data2 = {
        labels: arr.map((data) => data.channel),
        datasets: [
            {
                label: "Contribution",
                data: arr.map((data) => data.share),   // VERY IMP : The 4th parameter of rgba is for opacity of color
                backgroundColor:[
                    "#165376",
                    "#1A7AAF",
                    "#2899CF",
                    "#4FB2E1",
                    "#8ECDED",
                    "#C2E2F5"
                ],


              
                borderWidth: 0,

                datalabels: {
                    anchor: 'end', 
                    align: 'end',  
                    offset: -3,                                               // Positioning the data label
                    formatter: (value, context) => {    return `${value}`;    }
                },

                barThickness: 44,             // Self explanatory

                xAxisId: 'x-axis',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                yAxisId: 'y-axis',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
            }
        ]
    }  

    const options2 = {                          
        maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


        animation: {
          duration: 2000,
          easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
          animateScales: true,

            onStart: function(animation) {
              console.log('Animation started');
            },
            onComplete: function(animation) {
              console.log('Animation completed');
            },
        },


        plugins: {
            datalabels: {
                font: {
                    family: "Inter",        // To change font family of data labels on charts
                    weight: "500",
                    size: screen_width === 'default-1536px' ? "13px" : "10px"
                }
            },
            legend: {
                display: false, 
                labels: {
                    boxWidth: screen_width === 'default-1536px' ? 12 : 10,       // For making legend square
                    font: {
                        family: "Toboggan",         // To change font family of label(label is present on top of chart)
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "11px" : "10px"
                    }
                }
            }
        
        },


        scales: {
            x: {
                id: 'x-axis1',
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "Toboggan",            // To change font family of Y-Axis
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "13px" : "10px"  
                    }
                }
            },
            y: {
                beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

                ticks:{
                    callback: function(value){  return value  },
                    font: {
                        family: "Toboggan",            // To change font family of X-Axis
                        weight: "bold",
                        size: screen_width === 'default-1536px' ? "12px" : "10px"
                    }
                },

                id: 'y-axis1',
                grid: {
                    display: false
                }
            }
        }
    }








    const [Model_type_Selection_Options, set_Model_type_Selection_Options] = useState([{ value: 'Sales', label: 'Sales' }, { value: 'Orders', label: 'Orders' } ]);
    const [Model_type_Val, set_Model_type_Val] = useState({ value: 'Sales', label: 'Sales' });
    function set_Model_type_Val_Func(X){
        set_Model_type_Val(X);
    }

    const [Model_type_Selection_Options2, set_Model_type_Selection_Options2] = useState([{ value: 'Last 7 Days', label: 'Last 7 Days' }, { value: 'Last 30 Days', label: 'Last 30 Days' }, { value: 'Last 90 Days', label: 'Last 90 Days' }, { value: 'MTD', label: 'MTD' }, ]);
    const [Model_type_Val2, set_Model_type_Val2] = useState({ value: 'Last 7 Days', label: 'Last 7 Days' });
    function set_Model_type_Val_Func2(X){
        set_Model_type_Val2(X);
    }





 

    const data4 = {
        labels: sampleData4.map((data) => data.date_X),
        datasets: [
            {
                label: "Google",
                data: sampleData4.map((data) => data.google_Spend),
                pointBackgroundColor: "#165376",
                backgroundColor:"#165376",
                borderColor: "#165376",
                borderWidth: 2,
                datalabels: {
                  anchor: 'end', 
                  align: 'end',
                  offset:-1,        // This is the distance between top of bar and Data-Label
                  
                  formatter: (value, context) => {
                    const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                      style: 'decimal',
                      minimumFractionDigits: 0, 
                      maximumFractionDigits: 2, 
                      grouping: true 
                    });
                    return formatter.format(value);
                  },
                },

                xAxisId: 'x-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis
                yAxisId: 'y-axis1',              // Z : The purpose of this snippet of code is to remove background gridlines of X-axis & Y-axis

                // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                radius:2,                   // The purpose of this line is to specify the dot's radius
                tension: 0.2,               // The purpose of this line is to add curve between 2 dots
                // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph

            },
            {
                label: "Meta",
                data: sampleData4.map((data) => data.linkedin_Spend),
                pointBackgroundColor: "#4FB2E1",
                borderColor: "#4FB2E1",
                backgroundColor:"#4FB2E1",
                borderWidth: 2,
                datalabels: {
                  anchor: 'end', 
                  align: 'end',
                  offset:-1,        // This is the distance between top of bar and Data-Label
                  
                  formatter: (value, context) => {
                    const formatter = new Intl.NumberFormat('en-US', {     // To use comma in the data label, eg- I will show 35234 as 35,234
                      style: 'decimal',
                      minimumFractionDigits: 0, 
                      maximumFractionDigits: 2, 
                      grouping: true 
                    });
                    return formatter.format(value);
                  },
                },
                // hitRadius: 50,              // The purpose of this line is - We don't need to hover on exact dot to view details
                radius:2,                   // The purpose of this line is to specify the dot's radius
                tension: 0.2,               // The purpose of this line is to add curve between 2 dots
                // pointRadius: 0              // The purpose of this line is to remove the dots from Line Graph
            }
        ]
    }
    const option4 = {                          
    maintainAspectRatio: false,         // VERY VERY IMP : Because of this line we are able to elongate the chart horizontally


    // animation: {
    //   duration: 2000,
    //   easing: 'easeInOutExpo',               // We have 25+ types of animation effect, so we can choose and apply anyone
    //   animateScales: true,

        // onStart: function(animation) {
        //   console.log('Animation started');
        // },
        // onComplete: function(animation) {
        //   console.log('Animation completed');
        // },
    // },


    plugins: {
        datalabels: {
            font: {
                family: "Toboggan",        // To change font family of data labels on charts
                weight: "bold",
                size: "12px"
            }
        },
        legend: {
        labels: {
            boxWidth: 13,       // For making legend square
            font: {
            family: "Toboggan",         // To change font family of label(label is present on top of chart)
            weight: "bold"
            }
        }
        }
    },


    scales: {
        x: {
            id: 'x-axis1',
            grid: {
                display: false
            },
            ticks: {
                font: {
                family: "Toboggan",            // To change font family of Y-Axis
                weight: "bold",
                size: 12  
                }
            }
        },
        y: {
            beginAtZero: true,          // The purpose of this line of code is to make Y-axis start from 0

            ticks:{
                display:false,
                callback: function(value){
                    return "₹" + value
                },
                font: {
                family: "Toboggan",            // To change font family of X-Axis
                weight: "bold",
                size: 12
                }
            },

            id: 'y-axis1',
            grid: {
                display: false
            }
        }
    }
    }
    







    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column", padding:"0px", gap:"20px"}}>


            <div  style={{width:"100%", height:"6%", display:"flex", justifyContent:"space-between"}}>

                {/* <div  style={{width:"34%", height:"100%", paddingRight:"10px"}}>

                    <div  className="line-chart-responsiveness"  style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:""}}>
                        <div style={{width: '100%', height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Bar data={data3}  options={options3}/>
                        </div>    
                    </div>

                </div> */}
                <div></div>

                <div    style={{display:"flex", gap:"20px"}}>
                    <div  style={{width:"186px", position:"relative", backgroundColor:""}}>
                        {/* <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Model Type</h2> */}
                        <Select  options={Model_type_Selection_Options}  value={Model_type_Val}  onChange={set_Model_type_Val_Func}    styles={screen_width === 'default-1536px' ? customStyles : customStyles2}/>
                    </div>

                    <div  style={{width:"186px", position:"relative", backgroundColor:""}}>
                        {/* <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Model Type</h2> */}
                        <Select  options={Model_type_Selection_Options2}  value={Model_type_Val2}  onChange={set_Model_type_Val_Func2}    styles={screen_width === 'default-1536px' ? customStyles : customStyles2}/>
                    </div>
                </div>
            </div>


            <div  style={{width:"100%", height:"13%", border:"0px solid gray", display:"flex", justifyContent:"space-between", alignItems:"flex-start", gap:"20px"}}>
                
                <div  style={{width:"25%", height:"100%", backgroundColor:"#E4F1FA",     borderRadius:"8px",  boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", padding:"0 20px", display:"flex", flexDirection:"column", justifyContent:"center", gap:"2px" }}>
                    
                    <div  style={{display:"flex", gap:"16px", alignItems:"center", backgroundColor:""}}>
                        <p  className="fontF"  style={{fontSize:"18px", fontWeight:"400", color:"#28231D"}}>Spend</p>
                        <p  className="fontF"  style={{fontSize:"22px", fontWeight:"600", color:"#404040"}}>₹ 1.45 Cr</p>
                    </div>

                    <div  style={{display:"flex", alignItems:"center", justifyContent:"flex-start", backgroundColor:"", color:"green", gap:"4px", fontWeight:"600"}}>
                        <AiOutlineArrowUp  fontSize={18}/>
                        <p> 5.54%</p>
                    </div>

                </div>

                <div  style={{width:"25%", height:"100%", backgroundColor:"#E4F1FA",     borderRadius:"8px",  boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", padding:"0 20px", display:"flex", flexDirection:"column", justifyContent:"center", gap:"2px" }}>
                    
                    <div  style={{display:"flex", gap:"16px", alignItems:"center", backgroundColor:""}}>
                        <p  className="fontF"  style={{fontSize:"18px", fontWeight:"400", color:"#28231D"}}>Sales Lift</p>
                        <p  className="fontF"  style={{fontSize:"22px", fontWeight:"600", color:"#404040"}}>₹ 4.74 Cr</p>
                    </div>

                    <div  style={{display:"flex", alignItems:"center", justifyContent:"flex-start", backgroundColor:"", color:"green", gap:"4px", fontWeight:"600"}}>
                        <AiOutlineArrowUp  fontSize={18}/>
                        <p> 2.94%</p>
                    </div>

                </div>

                <div  style={{width:"25%", height:"100%", backgroundColor:"#E4F1FA",     borderRadius:"8px",  boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", padding:"0 20px", display:"flex", flexDirection:"column", justifyContent:"center", gap:"2px" }}>
                    
                    <div  style={{display:"flex", gap:"16px", alignItems:"center", backgroundColor:""}}>
                        <p  className="fontF"  style={{fontSize:"18px", fontWeight:"400", color:"#28231D"}}>Sales Lift (%)</p>
                        <p  className="fontF"  style={{fontSize:"22px", fontWeight:"600", color:"#404040"}}>23.7%</p>
                    </div>

                    <div  style={{display:"flex", alignItems:"center", justifyContent:"flex-start", backgroundColor:"", color:"red", gap:"4px", fontWeight:"600"}}>
                        <AiOutlineArrowDown  fontSize={18}/>
                        <p> 2.45%</p>
                    </div>

                </div>

                <div  style={{width:"25%", height:"100%", backgroundColor:"#E4F1FA",     borderRadius:"8px",  boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", padding:"0 20px", display:"flex", flexDirection:"column", justifyContent:"center", gap:"2px" }}>
                    
                    <div  style={{display:"flex", gap:"16px", alignItems:"center", backgroundColor:""}}>
                        <p  className="fontF"  style={{fontSize:"18px", fontWeight:"400", color:"#28231D"}}>Incr. ROAS</p>
                        <p  className="fontF"  style={{fontSize:"22px", fontWeight:"600", color:"#404040"}}>₹ 3.27</p>
                    </div>

                    <div  style={{display:"flex", alignItems:"center", justifyContent:"flex-start", backgroundColor:"", color:"green", gap:"4px", fontWeight:"600"}}>
                        <AiOutlineArrowUp  fontSize={18}/>
                        <p> 3.98%</p>
                    </div>

                </div>

            </div>
  
            
            <div  style={{width:"100%", height:"40.5%", backgroundColor:"", display:"flex", gap:"20px",  border:"0px solid gray"}}>

                <div  style={{width:"37.5%", height:"100%", backgroundColor:"", border:"0px solid gray", boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", borderRadius:"8px", display:"flex", alignItems:"center", justifyContent:"center"}}>

                    <div  className="line-chart-responsiveness"  style={{backgroundColor:"", width:"436px", height:"90%", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center"}}>
                        <div style={{width: '100%', height:"80%", backgroundColor:"", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Doughnut data={dataX}  options={optionsX}  plugins={[lineDetailsX]}/>
                            <div  style={{position:"absolute", fontSize:"15px"}}>
                                <p  style={{fontWeight:"600", color:"#404040"}}>Paid Media</p>
                                <p style={{textAlign:"center", color:"#404040", fontSize:"15.5px"}}>19%</p>
                            </div>
                        </div>
                        
                        <div  style={{width:"100%", height:"12%", backgroundColor:"", display:"flex", justifyContent:"center", alignItems:"flex-end", fontSize:"15px", color:"#404040"}}  className="fontF"  >Media Contributions</div>
                    </div>

                </div>

                <div  style={{width:"62.5%", height:"100%", backgroundColor:"", border:"0px solid gray", boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", borderRadius:"8px", display:"flex", alignItems:"center", padding:"0 1rem"}}>

                    <div  className="line-chart-responsiveness"  style={{backgroundColor:"", height:"92%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        
                        <div  style={{width:"100%", height:"12%", backgroundColor:"", display:"flex", justifyContent:"center", alignItems:"flex-end", fontSize:"15px", color:"#404040"}}  className="fontF"  >Media ROAS</div>

                        <div style={{width: '98%', height:"88%", backgroundColor:"", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Bar data={data2}  options={options2}/>
                        </div>
                        
                        
                    </div>

                </div>
            </div>


            <div  style={{width:"100%", height:"40.5%", backgroundColor:"", display:"flex", gap:"20px"}}>
                {/* <div  style={{width:"55%", height:"100%", backgroundColor:"", boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", borderRadius:"8px"}}>
                {
                    sampleData3.length>0  &&  
                        <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                            
                            
                            <div  style={{width:"calc(100% - 2.5rem)", height:"12%", display:"flex", justifyContent:"space-between", alignItems:"center", borderBottom:"1px solid #E5E5E5"}}>
                                
                                <div  style={{display:"flex", alignItems:"center"}}>

                                    <p  style={{color:"#0000EE", fontWeight:"400",      fontSize:screen_width === 'default-1536px' ? "16px" : "13.5px"}}> Geo Experiment </p>
                                    <p  className="fontF"  style={{fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "13.5px", color:"#404040"}}>  &nbsp; / geo.csv</p>
                                    
                                </div>


                                <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "14px" : "10px"}}>

                                    <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                        <button className={`toggle-container2 ${showGraph ? 'toggled2' : ''}`}      style={{width:"34.8px",       height:"17.5px"}}      onClick={handleToggle}>
                                            <div className="toggle-button2"                                        style={{width:"15.58px",      height:"15.58px"}}></div>
                                        </button>
                                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "14px" : "14px",      color:"#171717"}}>Show Only Graph</p>
                                    </div>

                                    <div style={{ display: "flex", gap: "1px" }}>
                                        <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                        <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "24px" : "20px",      borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                    </div>

                                    <div  style={{display:"flex", alignItems:"center",      gap:screen_width === 'default-1536px' ? "8px" : "6.66px"}}>
                                        <button className={`toggle-container2 ${showData ? 'toggled2' : ''}`}      style={{width:"35px",         height:"17.5px"}}      onClick={handleToggle2}>
                                            <div className="toggle-button2"                                       style={{width:"15.58px",      height:"15.58px"}}></div>
                                        </button>
                                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "14px" : "14px",      color:"#171717"}}>Show Only Data</p>
                                    </div>

                                </div>
                            </div>
                            

                            <div  style={{width:"100%", height:"88%", display:"flex", backgroundColor:""}}>


                                    <div  style={{  width: (!showData && showGraph)    ?    "100%" 
                                                                                       :    (showData && !showGraph)    ?    "0%"
                                                                                                                        :    (!showData && !showGraph) && "80%", 
                                                    display: (showData && !showGraph)  ?    "none"
                                                                                       :    (!showData && !showGraph) && "flex", 

                                                    height:"100%", display:"flex", justifyContent:"center", alignItems:"center", transition: "width 0.2s"}}>
                                        <div  className="line-chart-responsiveness"  style={{borderRadius:"8px"}}>
                                            <div style={{width: '98%', height:"97%"}}>
                                                <Line data={dataChart} options={optionChart}/>
                                            </div>
                                        </div>
                                    </div>    

                                
                                    <div  style={{  width: (!showData && showGraph)    ?    "0%" 
                                                                                    :    (showData && !showGraph)    ?    "100%" 
                                                                                                                        :    (!showData && !showGraph) && "20%", 
                                                    display: (!showData && showGraph)  ?    "none"
                                                                                    :    (!showData && !showGraph) && "flex", 
                                                    height:"100%",  justifyContent:"flex-start", alignItems:"center", transition: "width 0.2s", backgroundColor:"", paddingTop:"0rem"}}>


                                        <table style={{ width:"96%",      fontSize:screen_width === 'default-1536px' ? "13.5px" : "13px"}}>
                                            <thead>
                                                <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                    <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px" }}>Metric</th>
                                                    <th    style={{ width: "50%", border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px" }}>Value</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Lift Value</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>1940.28</td>                                                                
                                                </tr>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Lift %</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>12.31%</td>
                                                </tr>
                                                <tr>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>Std dev</td>
                                                    <td   style={{border:"1px solid #ddd", textAlign:"center",    padding: screen_width === 'default-1536px' ? "5px" : "4.66px"}}>1.75%</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    
                                    </div>  
                                    
                            </div>

                            
                        </div>
                }
                </div>


                <div  style={{width:"45%", height:"100%", backgroundColor:"", boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", borderRadius:"8px", padding:"12px", display:"flex", flexDirection:"column", gap:"12px", color:"#404040"}}>
                    
                    <div  style={{width:"100%", height:"20%", fontSize:"18px", color:"#404040"}}>Test Results</div>
                    <div  style={{width:"100%", height:"80%", display:"flex", gap:"12px"}}>
                        <div  style={{width:"33.3%", height:"100%", backgroundColor:"#f8fafb", display:"flex", flexDirection:"column", padding:"6px", gap:"10px", justifyContent:"center"}}>
                            <p  style={{backgroundColor:"", fontSize:"18px", color:"#404040"}}>Sales Lift</p>
                            <p  style={{backgroundColor:"", fontSize:"20px", color:"#404040", fontWeight:"500"}}>2,201</p>
                            
                            <div  style={{width:"100%", height:"31%",  display:"flex", border:"2px solid #e7e9eb", padding:"5px 7px", borderRadius:"5px", justifyContent:"space-between", marginTop:"10px"}}>
                                <div  style={{display:"flex", flexDirection:"column", fontSize:"13.5px", color:"#404040", fontWeight:"500"}}>
                                    <p>Actual</p>
                                    <p  style={{color:"#4a729f"}}>20,766</p>
                                </div>
                                <div  style={{display:"flex", gap:"4px", alignItems:"flex-end"}}>
                                    <div style={{ width: "4px", height:"38px", borderRadius: "2px", backgroundColor: "#4f9c7e", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                    <div style={{ width: "4px", height:"30px", borderRadius: "2px", backgroundColor: "#cdcddb", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                </div>
                                <div  style={{display:"flex", flexDirection:"column", fontSize:"13.5px", color:"#404040", fontWeight:"500"}}>
                                    <p>Predicted</p>
                                    <p  style={{color:"#4a729f"}}>18,565</p>
                                </div>
                            </div>

                        </div>

                        <div  style={{width:"33.3%", height:"100%", backgroundColor:"#f8fafb", display:"flex", flexDirection:"column", padding:"6px", gap:"10px", justifyContent:"center"}}>
                            <p  style={{backgroundColor:"", fontSize:"18px", color:"#404040"}}>Sales Lift (%)</p>
                            <p  style={{backgroundColor:"", fontSize:"20px", color:"#404040", fontWeight:"500"}}>11.85%</p>

                            <div  style={{width:"100%", height:"31%",  display:"flex", border:"2px solid #e7e9eb", padding:"5px 8px 5px 8px", borderRadius:"5px", justifyContent:"flex-start", marginTop:"10px", alignItems:"center", gap:"16px"}}>
                                
                                <div style={{width: "36px", height: "36px", borderRadius: "50%", background: "conic-gradient(#509f7f 0% 84%, #c9cfd6 84% 100%)", position: "relative", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{width: "28px", height: "28px", borderRadius: "50%", backgroundColor: "white", position: "absolute"}}></div>
                                    <span style={{position:"absolute", fontSize: "14.5px", fontWeight: "bold", color: "#509f7f"}}>84</span>
                                </div>

                                <div  style={{display:"flex", flexDirection:"column", backgroundColor:""}}>
                                    <p  style={{fontSize:"13.5px", color:"#404040", fontWeight:"500", lineHeight:"1"}}>Confidence </p>
                                    <p  style={{fontSize:"13.5px", color:"#404040", fontWeight:"500"}}>level </p>
                                </div>

                            </div>

                        </div>

                        <div  style={{width:"33.3%", height:"100%", backgroundColor:"#f8fafb", display:"flex", flexDirection:"column", padding:"6px", gap:"10px", justifyContent:"center"}}>
                            <p  style={{backgroundColor:"", fontSize:"18px", color:"#404040"}}>Incr. ROAS</p>
                            <p  style={{backgroundColor:"", fontSize:"20px", color:"#404040", fontWeight:"500"}}>$23.36</p>
                            
                            <div  style={{width:"100%", height:"31%",  display:"flex", flexDirection:"column", border:"2px solid #e7e9eb", padding:"5px 8px 12px 8px", borderRadius:"5px", gap:"8px", marginTop:"10px"}}>
                                <div  style={{display:"flex", justifyContent:"space-between", fontSize:"13.5px", color:"#404040", fontWeight:"500"}}>
                                    <p>Previous</p>
                                    <p  style={{color:"#4a729f"}}>$12.16</p>
                                </div>

                                <div  style={{display:"flex", justifyContent:"center"}}>
                                    <div style={{ width: "58%", height:"5px", borderRadius: "0px", backgroundColor: "#c8d0d6",  }}></div>
                                    <div style={{ width: "38%", height:"5px", borderRadius: "0px", backgroundColor: "#7d8597",  }}></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div> */}

                <div  style={{width:"37.5%", height:"100%", backgroundColor:"", boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", borderRadius:"8px", display:"flex", alignItems:"center", justifyContent:"center"}}>

                    <div  className="line-chart-responsiveness"  style={{backgroundColor:"", width:"436px", height:"90%", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center"}}>
                        <div style={{width: '100%', height:"80%", backgroundColor:"", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Doughnut data={data1}  options={options}  plugins={[lineDetails]}/>
                            <div  style={{position:"absolute", fontSize:"15px"}}>
                                <p  style={{fontWeight:"600", color:"#404040"}}>Total Spend</p>
                                <p style={{textAlign:"center", color:"#404040", fontSize:"15.5px"}}>₹ 1.45 Cr</p>
                            </div>
                        </div>
                        
                        <div  style={{width:"100%", height:"12%", backgroundColor:"", display:"flex", justifyContent:"center", alignItems:"flex-end", fontSize:"15px", color:"#404040"}}  className="fontF"  >Media Mix</div>
                    </div>

                </div>

                <div  style={{width:"62.5%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", boxShadow:"2px 2px 10px 0px #AEAEC080, -2px -2px 10px 0px #f1f1f1", borderRadius:"8px"}}>
                    <div  className="line-chart-responsiveness"  style={{ borderRadius:"8px"}}>
                        <div style={{width: '96%', height:"92%"}}>
                            <Line data={data4} options={option4}/>
                        </div>
                    </div>
                </div>

            </div>

    
        </div>
    );
  }
  
  export default Dashboard;
