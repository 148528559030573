import { useState, useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { GoSearch } from "react-icons/go";
import { fetch_geo_Data_corresponding_to_file_id_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import Loader from "../../../assets/Images/loading.gif";





function CreateExperimentSection({setCreateExperiment_btn_Clicked,    file_data_table_arr,    fileId_Mapping_With_Exps,    setUploadData_btn_Clicked,    setExperiment_or_data,    loading2,    fileObjs,    setFileObjs,    runR_ForStartingDatasets,    set_runR_ForStartingDatasets,    exp_Name,    setExp_Name,    experimentnamesArray,        initial_Datasets_fetched,    set_initial_Datasets_fetched,    loadingCE,    setLoadingCE}) {





    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';





    const [searchText3, setSearchText3] = useState("");
    const filteredArr = file_data_table_arr.filter(obj => 
        obj.business.toLowerCase().includes(searchText3.toLowerCase()) ||
        obj.category.toLowerCase().includes(searchText3.toLowerCase()) ||
        obj.product.toLowerCase().includes(searchText3.toLowerCase())  ||
        obj.file_name.toLowerCase().includes(searchText3.toLowerCase())
    );



    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredArr.slice(indexOfFirstItem, indexOfLastItem);   // Items to display on current page


    const totalPages = Math.ceil(filteredArr.length / itemsPerPage);


    const startItem = indexOfFirstItem + 1;                             // For displaying 1-8 of 18
    const endItem = Math.min(indexOfLastItem, filteredArr.length);      // For displaying 1-8 of 18



    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };










    const heightX =   screen_width === 'default-1536px'    ?    currentItems.length===1  ?  "40.5%"  :  currentItems.length===2  ?  "60.25%"  :  currentItems.length===3  ?  "80%"  :  "100%"
                                                           :    currentItems.length===1  ?  "41.8%"  :  currentItems.length===2  ?  "61.05%"  :  currentItems.length===3  ?  "80.55%"  :  "100%"











    const [checkedItems, setCheckedItems] = useState([]);
    if(file_data_table_arr.length > 0    &&    checkedItems.length===0){
        setCheckedItems(new Array(file_data_table_arr.length).fill(false));
    }

    const handleCheckboxChange = (index) => {
        const updatedCheckedItems = checkedItems.map((checked, i) =>  i === index   ?   !checked   :   checked);
        setCheckedItems(updatedCheckedItems);
    };

    useEffect(() => {
        if(checkedItems.includes(true)){
            // console.log("checkedItems :- ", checkedItems);
            const res = file_data_table_arr.filter((item, index) => checkedItems[index])
            // console.log("Datasets Selected :- ", res);
        }
    }, [checkedItems]);







    const [file_name_Popup, setFile_name_Popup] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState('');
    const [exp_name_Popup, setExp_name_Popup] = useState(false);
    const [selectedExpname, setSelectedExpname] = useState('');
    const [description_Popup, setDescription_Popup] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');
    const [isHovered_on_Upload_Data2, setIsHovered_on_Upload_Data2] = useState(false);


    const [expName_not_Unique, setExpName_not_Unique] = useState(false);
    useEffect(()=>{
        if(experimentnamesArray.includes(exp_Name)){
            setExpName_not_Unique(true);
        }
        else{
            setExpName_not_Unique(false);
        }
    }, [exp_Name])








    useEffect(()=>{
        if(initial_Datasets_fetched){  
            set_initial_Datasets_fetched(false);
            set_runR_ForStartingDatasets(runR_ForStartingDatasets + 1);
        }
    },[fileObjs]);



    let arrTemp = [];

    async function fetch_datasets_from_DB(){
        const arr = file_data_table_arr.filter((item, index) => checkedItems[index])
        set_initial_Datasets_fetched(true);
        // console.log("Datasets to be fetched :- ", arr);

        setLoadingCE(true);

        for(let i=0;i<arr.length;i++){
            await fetch_geo_Data_corresponding_to_file_id(arr[i]);
        }

        setFileObjs(arrTemp);  // After this, the code flow goes to useEffect dependent on fileObjs (which is just present above)
    }


    const fetch_geo_Data_corresponding_to_file_id = async (fileObject) => {

        try {
          const res = await fetch_geo_Data_corresponding_to_file_id_func(fileObject.file_id);            

          const data = res.data.data;
          const location = data[0].geo;
          const datesObj = findCampaignDates(data, location);
          const businessTagsObj = {   business:fileObject.business,    category:fileObject.category,    product:fileObject.product   };
          
          arrTemp = [...arrTemp,      {    objId: arrTemp.length === 0  ?  0  :  arrTemp[arrTemp.length-1].objId+1,    file_data:res.data.data,    file_name:fileObject.file_name,    result:"",    description:fileObject.description,    datesObj:datesObj,    presentonDB:true,    businessTagsObj:businessTagsObj,    subsetOf:"",    file_id:fileObject.file_id,    Non_Test_Geos:fileObject.num_control_group    }];
        } 
        catch (error) {
          console.log("error:-", error);
        }

    }



    const findCampaignDates = (data, location) => {    // Calculating for only 1 geo, because this file must have passed through validations and thus campaign value for each date will be same across all geos
      
        const filteredData = data.filter(item => item.geo === location && item.campaign === '1');
  
        const Date_Starting_Campaign = filteredData[0].date.slice(0,5);   
        const middleIndex = Math.floor(filteredData.length / 2);
        const Date_middle_Campaign = filteredData.length % 2 === 0   ?   filteredData[middleIndex - 1].date.slice(0,5)   :   filteredData[middleIndex].date.slice(0,5);
        const Date_Ending_Campaign = filteredData[filteredData.length - 1].date.slice(0,5);
  
        const Date_Starting_Campaign_default = filteredData[0].date;
        const Date_Ending_Campaign_default = filteredData[filteredData.length - 1].date;
  
  
  
  
        const filteredData2 = data.filter(item => item.geo === location && item.campaign === '0');
        
        const Date_Starting_Pre = filteredData2[0].date.slice(0,5);
        const middleIndex_ = Math.floor(filteredData2.length / 2);
        const Date_middle_Pre = filteredData2.length % 2 === 0  ?  filteredData2[middleIndex_ - 1].date.slice(0,5)  :  filteredData2[middleIndex_].date.slice(0,5);
        const Date_Ending_Pre = Date_Starting_Campaign;
  
        const Date_Starting_Pre_default = filteredData2[0].date;
        const Date_Ending_Pre_default = filteredData2[filteredData2.length - 1].date;
  
  
  

        const OriginalDates = data.filter(item => item.geo === location).map(item => item.date.slice(0, 5));




        return {
            Date_Starting_Campaign,
            Date_middle_Campaign,
            Date_Ending_Campaign,
            Date_Starting_Campaign_default,
            Date_Ending_Campaign_default,
            Date_Starting_Pre,
            Date_middle_Pre,
            Date_Ending_Pre,
            Date_Starting_Pre_default,
            Date_Ending_Pre_default,
            OriginalDates
        };
    };







    if (loadingCE  || loading2) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={Loader} width="180" height="180"/>
            </div>
        )
    }








    return (
        <>
   
            <div style={{width:"100%", height:"91%"}}>
               
                
                <div  style={{width:"100%", height:"10%", display:"flex", alignItems:"center"}}>
                    <button    className="back-navigation-business-setup"   onClick={()=>{setCreateExperiment_btn_Clicked(false)}}   style={{color:"#6E6E6E", display:"flex"}}>
                        <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                        <span  style={{color:"#0000EE", fontWeight:"400"}}>Experiment</span>
                    </button>

                    <p  className="add-new-text"  style={{color:"#6E6E6E"}}>  &nbsp; / &nbsp; Create Experiment</p>
                </div>
                

                <div  style={{width:"100%", height:"90%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                        
                        <div  style={{width:"100%", height:screen_width === 'default-1536px' ? "86%" : "84.9%", display:"flex", flexDirection:"column", alignItems:"center",    padding:screen_width === 'default-1536px' ? "1.6rem 1.6rem 0 1.6rem" : "1.33rem 1.33rem 0 1.33rem",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>

                            
                            <div  style={{width:"100%", height:"13%" ,borderBottom:"1px solid #E5E5E5"}}>
                                <div  style={{width:"25%", position:"relative"}}>
                                    <h2    className="input-label"    style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Experiment Name</h2>
                                    <input    type="text"  value={exp_Name}    onChange={(e) => {setExp_Name(e.target.value)}}      className="input-field2"   placeholder="Experiment Name"/>
                                </div>
                            </div>


                            <div  style={{width:"100%", height:"17%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                                <div      className="search-bar-container"   style={{ borderRadius: "30px", backgroundColor: "white", boxShadow: "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", display: "flex", alignItems: "center" }}>
                                    <GoSearch fontSize={screen_width === 'default-1536px' ? 22 : 18.3} color="#27272A" />
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchText3}
                                        onChange={(e) => setSearchText3(e.target.value)}
                                        style={{ color: "#6E6E6E",  border: "none", outline: "none", background: "transparent", flex: "1",      fontSize:screen_width === 'default-1536px' ? "16px" : "13.3px" }}
                                    />
                                </div>

                                <button    onClick={()=>{setUploadData_btn_Clicked(true);  setExperiment_or_data("data");  setCreateExperiment_btn_Clicked(false)}}     className="create-exp-button"      style={{ width:screen_width === 'default-1536px' ? "142px" : "116px",    display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#97DDFF", color: "#171717", borderRadius: "37px", fontWeight: "500",      boxShadow: isHovered_on_Upload_Data2 ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}    onMouseEnter={() => setIsHovered_on_Upload_Data2(true)}     onMouseLeave={() => setIsHovered_on_Upload_Data2(false)}>Upload Data</button>

                            </div>

                        
                            <div  style={{ width: "100%", height:"70%", borderRadius: "8px", display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>

                                <div  style={{width:"100%", height:"82%"}}>
                                    <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",    width: "100%",    height: heightX, backgroundColor: "white", color:"#171717" }}>
                                        <thead  style={{width:"100%"}}>
                                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                                    <th    style={{width:"7%",    borderBottom:"1px solid #ddd", textAlign:"center", borderTopLeftRadius:"8px"}}>        </th>
                                                    <th    style={{width:"15.5%", borderBottom:"1px solid #ddd", textAlign:"start",  padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0"}}>File Name</th>
                                                    <th    style={{width:"15.5%", borderBottom:"1px solid #ddd", textAlign:"start",  padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0"}}>Description</th>
                                                    <th    style={{width:"15.5%", borderBottom:"1px solid #ddd", textAlign:"start",  padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0"}}>Experiment</th>
                                                    <th    style={{width:"15.5%", borderBottom:"1px solid #ddd", textAlign:"start",  padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0"}}>Business</th>
                                                    <th    style={{width:"15.5%", borderBottom:"1px solid #ddd", textAlign:"start",  padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0"}}>Category</th>
                                                    <th    style={{width:"15.5%", borderBottom:"1px solid #ddd", textAlign:"start",  padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0", borderTopRightRadius:"8px"}}>Product</th>
                                                </tr>
                                        </thead>
                                        
                                        <tbody  style={{width:"100%"}}>
                                            {
                                                currentItems.map((obj, index) => {
                                                        
                                                        const foundItem = fileId_Mapping_With_Exps.find(item => item.file_id === obj.file_id);     // Find the corresponding object in res
                                                        const firstExpName = foundItem ? foundItem.experiment_name[0] : "Not Mapped";              // Get the first element of ExpName array
                                                        let totalCount=0;
                                                        if(foundItem)  totalCount = foundItem.experiment_name.length;

                                                        return(
                                                            <tr key={index}  style={{width:"100%"}}>
                                                                <td className="cell2XY"   style={{width:"7%", paddingTop:"5px"}}>     <input   type="checkbox"   checked={checkedItems[indexOfFirstItem+index]}     onChange={() => handleCheckboxChange(indexOfFirstItem+index)}            className="rounded border-blue-300 h-3 w-3 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>     </td>
                                                                <td className="cell2"     style={{width:"15.5%"}}>
                                                                    {
                                                                        screen_width === 'default-1536px'   ?
                                                                                                                obj.file_name.length > 20       ?   <div  style={{display:"flex"}}>
                                                                                                                                                        <p>{obj.file_name.slice(0,17)}</p>
                                                                                                                                                        <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                    </div>  
                                                                                                                                                :   obj.file_name
                                                                                                            :
                                                                                                                obj.file_name.length > 16       ?   <div  style={{display:"flex"}}>
                                                                                                                                                        <p>{obj.file_name.slice(0,15)}</p>
                                                                                                                                                        <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                    </div>  
                                                                                                                                                :   obj.file_name
                                                                    }
                                                                </td>
                                                                <td className="cell2"     style={{width:"15.5%"}}>    <button    onClick={()=>{setDescription_Popup(true);  setSelectedDescription(obj.description)}}    style={{color:"blue"}}>description</button>    </td>
                                                                <td className="cell2"     style={{width:"15.5%"}}>
                                                                {
                                                                    totalCount > 1    ?   `${firstExpName} + ${totalCount - 1}` 
                                                                                      :   screen_width === 'default-1536px'     ?
                                                                                                                                    firstExpName.length > 20    ?   <div  style={{display:"flex"}}>
                                                                                                                                                                        <p>{firstExpName.slice(0,17)}</p>
                                                                                                                                                                        <button    onClick={()=>{setExp_name_Popup(true);  setSelectedExpname(firstExpName)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                    </div>  
                                                                                                                                                                :   firstExpName
                                                                                                                                :
                                                                                                                                    firstExpName.length > 16    ?   <div  style={{display:"flex"}}>
                                                                                                                                                                        <p>{firstExpName.slice(0,15)}</p>
                                                                                                                                                                        <button    onClick={()=>{setExp_name_Popup(true);  setSelectedExpname(firstExpName)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                    </div>  
                                                                                                                                                                :   firstExpName
                                                                }
                                                                </td>
                                                                <td className="cell2"     style={{width:"15.5%"}}>{obj.business}</td>
                                                                <td className="cell2"     style={{width:"15.5%"}}>{obj.category}</td>
                                                                <td className="cell2"     style={{width:"15.5%"}}>{obj.product}</td>
                                                            </tr>
                                                        );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ width: "100%", height: "18%", backgroundColor: "white", display: "flex", justifyContent: "flex-end", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                                    <div style={{ display: "flex", gap: "2rem", paddingRight: "2rem" }}>
                                        
                                        <p style={{ display: "flex", alignItems: "center",    fontSize:screen_width === 'default-1536px' ? "16px" : "14px" }}>
                                            {`${startItem}-${endItem} of ${filteredArr.length}`}
                                        </p>

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <button onClick={handlePrevPage}  disabled={currentPage === 1}            style={{opacity : currentPage===1           ?  "0.3" : "1"}}>         <GoChevronLeft  fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                            <button onClick={handleNextPage}  disabled={currentPage === totalPages}   style={{opacity : currentPage===totalPages  ?  "0.3" : "1"}}>         <GoChevronRight fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            

                        </div>



                        <div  style={{width:"100%", height:"10%",    padding:screen_width === 'default-1536px' ? "12px 24px" : "10px 24px",    borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <div>
                                { 
                                    exp_Name.length === 0  ?  <p    className="error-texts"     style={{color:"#DC2626"}}>Please enter experiment name</p>
                                                           :  expName_not_Unique  ?  <p    className="error-texts"     style={{color:"#DC2626"}}>The Experiment with same name exists in the DB, name should be unique</p>
                                                                                  :  !checkedItems.includes(true)  &&  <p    className="error-texts"     style={{color:"#DC2626"}}>Select atleast 1 dataset to create Experiment</p>
                                }
                            </div>

                            <div  style={{display:"flex", gap:"1rem"}}>
                                <button      onClick={()=>{ setCreateExperiment_btn_Clicked(false);    setExp_Name('') }}      className="footer-button"      style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}    >Cancel</button>
                                <button      onClick={()=>{ fetch_datasets_from_DB()               }}                          className="footer-button"      style={{borderRadius:"7px", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600", opacity: (checkedItems.includes(true)  &&  exp_Name.length>0  &&  !expName_not_Unique) ? "1" : "0.6"}}     disabled={  !(checkedItems.includes(true)  &&  exp_Name.length>0  &&  !expName_not_Unique)  }>Create Experiment</button>
                            </div>
                        </div>
                        
                    </div>
            </div>



            {
                
                file_name_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>File Name</p>
                            <button  onClick={()=>{setFile_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedFilename}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                file_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {
                
                exp_name_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Experiment Name</p>
                            <button  onClick={()=>{setExp_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedExpname}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                exp_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {
                
                description_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Description</p>
                            <button  onClick={()=>{setDescription_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",    margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedDescription}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                description_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }
        </>
    );
  }
  
  export default CreateExperimentSection;