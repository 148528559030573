


import { apiConnector } from "./apiConnector";







export async function send_Business_Setup_Data_to_DB(editedArr) {

    let result;

    console.log("editedArr before pushing to DB :- ", editedArr);
  
    try {
      const response = await apiConnector( "POST",  `${process.env.REACT_APP_BASE_URL}/upload-business-setup-data`,  editedArr );
      console.log("RESPONSE :- ", response);
      result = response.data;
    } 
    catch (error) {
      console.log("ERROR RESPONSE :- ", error);
    }
     
    return result;
}








export async function getDataFrom_Business_Setup_Table() {  
  let result = [];

  try {
    const response = await apiConnector( "GET",  `${process.env.REACT_APP_BASE_URL}/get-business-setup-table-data`,  null, null );
    // console.log("RESPONSE from DB for Business Setup Table :- ", response);

    result = response.data.data;
  } 
  catch (error) {
    console.log("ERROR RESPONSE from DB :- ", error);
  }

  return result;                                                            
}








export async function delete_all_rows_from_Business_Setup_table_DB_func() {

  let result;

  try {
    const response = await apiConnector( "DELETE",  `${process.env.REACT_APP_BASE_URL}/delete-all-business-setup-data`,  null, null );
    console.log("RESPONSE :- ", response);
    result = response.data;
  } 
  catch (error) {
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function delete_rows_from_Business_Setup_table_func(arrDel) {
  let result;

  try {
    const response = await apiConnector( "PUT",  `${process.env.REACT_APP_BASE_URL}/delete-rows-of-business-setup-data`,  arrDel );
    console.log("RESPONSE :- ", response);
    result = response.data;
  } 
  catch (error) {
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function insert_file_data_func(obj_file_data) {
  console.log("obj_file_data :- ", obj_file_data);
  let result;

  try {
    const response = await apiConnector( "POST",  `${process.env.REACT_APP_BASE_URL}/upload-file-data`,  obj_file_data );
    //console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    //console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function save_raw_csv_file_func(formData) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/upload-raw-csv-file`, formData, {headers: {'Content-Type': 'multipart/form-data',  },    });  
    //console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    //console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function save_geo_data_func(formData2) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/upload-geo-data`, formData2, {headers: {'Content-Type': 'multipart/form-data',  },    });  
    //console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    //console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function getDataFrom_File_Data_Table() {  
  let result = [];

  try {
    const response = await apiConnector( "GET",  `${process.env.REACT_APP_BASE_URL}/get-file-data`,  null, null );
    // console.log("RESPONSE from DB for File Data Table :- ", response);

    result = response.data.data;
  } 
  catch (error) {
    console.log("ERROR RESPONSE from DB :- ", error);
  }

  return result;                                                            
}

export async function fetch_geo_Data_corresponding_to_file_id_func(file_id) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/fetch-geo-data`,  { file_id: file_id } );  
    // console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}

export const sendDataTo_R_API = async (formData) => {                    
  let result = null;

  try {
     const response = await apiConnector("POST", `https://int.truelift.ai/start_and_fetch`,  formData,  {headers: {'Content-Type': 'multipart/form-data',  },    }  );
    
     console.log("Geolift R code RESPONSE", response);    

    if(response.data.results)  result = response?.data?.results;
  } 
  catch (error) {
    console.log("ERROR RESPONSE FROM BACKEND............", error);
    result = error?.response?.data?.status;
  }

   return result;                                                         
}

export const sendDataTo_R_API_MMM = async (formData) => {                    
  let result = null;

  try {
     const response = await apiConnector("POST", `https://dev.truelift.ai/run_model_and_fetch_result`,  formData,  {headers: {'Content-Type': 'multipart/form-data',  },    }  );  
    
    console.log("MMM R code response :- ", response); 
    if(response.data.results)  result = response?.data?.results;
  } 
  catch (error) {
    console.log("ERROR RESPONSE FROM BACKEND............", error);
    result = error?.response?.data?.status;
  }

   return result;                                                         
}

export const sendDataTo_R_API_Placebo = async ( formData, inputValue, inputValue2) => {                    
  let result = null;

  try {
   const response = await apiConnector("POST", `https://placebo.truelift.ai/start_and_fetch?smpl=${inputValue}&iter=${inputValue2}`,  formData,  {headers: {'Content-Type': 'multipart/form-data',  },    }   );  


    console.log("Placebo R code response :- ", response);
    if(response.data.results)  result = response?.data?.results;
  } 
  catch (error) {
    console.log("ERROR RESPONSE FROM BACKEND............", error);
    result = error?.response?.data?.status;
  }

   return result;                                                         
}

export async function delete_csv_file_from_geo_data_table_func(id_DeleteCSV) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/delete-csv-file`,  
      { id_DeleteCSV: id_DeleteCSV });  
    // console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response;
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function delete_raw_csv_file_func(id_DeleteCSV) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/delete-raw-csv-file`,  { id_DeleteCSV: id_DeleteCSV });  
    // console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response;
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function delete_file_data_func(id_DeleteCSV) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/delete-file-data`,  { id_DeleteCSV: id_DeleteCSV });  
    // console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response;
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function insert_file_data_func2(obj_file_data) {
  console.log("obj_file_data :- ", obj_file_data);
  let result;

  try {
    const response = await apiConnector( "POST",  `${process.env.REACT_APP_BASE_URL}/upload-file-data-via-exp`,  obj_file_data );
    //console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    //console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function generate_exp_id_func() {  
  let result;

  try {
    const response = await apiConnector( "GET",  `${process.env.REACT_APP_BASE_URL}/generate-exp-id`,  null, null );
    // console.log("RESPONSE  :- ", response);

    result = response.data;
  } 
  catch (error) {
    console.log("ERROR RESPONSE from DB :- ", error);
  }

  return result;                                                            
}








export async function experiment_table_entry_func(expId, exp_name, file_id, subsetOf) {
  let result;

  try {
    const response = await apiConnector( "POST",  `${process.env.REACT_APP_BASE_URL}/upload-experiment-table-row`,  { expId: expId,   exp_name: exp_name,  file_id: file_id,  subsetOf:subsetOf } );
    //console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    //console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function results_table_entry_func(file_id, output) {
  let result;

  try {
    const response = await apiConnector( "POST",  `${process.env.REACT_APP_BASE_URL}/upload-model_result-table-row`,  { file_id: file_id,  output:output} );
    //console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    //console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function getDataFrom_Experiment_Table() {  
  let result = [];

  try {
    const response = await apiConnector( "GET",  `${process.env.REACT_APP_BASE_URL}/get-experiment-data`,  null, null );
    // console.log("RESPONSE from DB for File Data Table :- ", response);

    result = response.data.data;
  } 
  catch (error) {
    console.log("ERROR RESPONSE from DB :- ", error);
  }

  return result;                                                            
}








export async function fetch_R_Data_corresponding_to_file_id_func(file_id) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/fetch-r-data`,  { file_id: file_id } );  
    // console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response.data.message;
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function delete_file_results_func(id_DeleteCSV) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/delete-file-results`,  { id_DeleteCSV: id_DeleteCSV });  
    // console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response;
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}








export async function delete_experiment_geo_entries_func(exp_id) {
  let result;

  try {
    const response = await apiConnector("POST", `${process.env.REACT_APP_BASE_URL}/delete-exp-table-entries`,  { exp_id: exp_id });  
    // console.log("RESPONSE :- ", response);
    result = response;
  } 
  catch (error) {
    result = error.response;
    console.log("ERROR RESPONSE :- ", error);
  }
   
  return result;
}
