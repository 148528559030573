import { useEffect, useRef, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import RadioDataStep1 from './RadioDataSteps/RadioDataStep1';
import RadioDataStep2 from './RadioDataSteps/RadioDataStep2';
import RadioDataStep3 from './RadioDataSteps/RadioDataStep3';
import RadioDataStep4 from './RadioDataSteps/RadioDataStep4';




export default function AccordionTabs3({ section, isActiveArr, handleActive,                                      
                                         dimensionsOptions_R,    data3,    states,    salesData_Dates,    checkedItems_RD,    setCheckedItems_RD,    selected_RD,    setSelected_RD,      data_After_Discarding_ExtraData_R,    setdata_After_Discarding_ExtraData_R,    aggregatedData_R,    setAggregatedData_R,    afterRegionalMapping_arr_R,    setAfterRegionalMapping_arr_R,    after_Missing_Dates_and_States_Treatment_R,    setAfter_Missing_Dates_and_States_Treatment_R}) {    



    const contentEl = useRef(null);
    const [active, setActive] = useState(false);           
    const [sectionHeight, setSectionHeight] = useState(0);

    useEffect(() => {                               
        setActive(isActiveArr?.includes(section.id));
    }, [isActiveArr]);                               

    useEffect(() => {                                               
        setSectionHeight(active ? contentEl.current.scrollHeight : 0); 
    }, [active]);                                                   















    return (
        <div style={{width:"100%", border:"2px solid #e1e1e1",  display:"flex", flexDirection:"column"}}>
        
        
            <div>             
                <div   onClick={()=>{handleActive(section.id)}}   className={`bg-opacity-20 transition-[0.3s]`}      style={{backgroundColor:"rgb(34, 50, 84)", color:"white", fontSize:"18px", padding:"18px 24px",    display:"flex", justifyContent:"space-between", alignItems:"flex-start", cursor:"pointer"}}>
                
                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <p>  {section?.TabTitle}  </p>

                        <i  className={`${isActiveArr.includes(section.id)   ?   "rotate-180"   :   "rotate-0"}     transition-all duration-300`}>   
                            <AiOutlineDown />        
                        </i>
                    </div>

                </div>
            </div>




            <div   ref={contentEl}   style={{height: sectionHeight}}   className={`h-0 overflow-hidden bg-richblack-900 transition-[height] duration-[0.35s] ease-[ease]`}>
                <div className="text-textHead  font-semibold"      style={{display:"flex", flexDirection:"column", gap:"1rem", alignItems:"center", padding:"2rem 0"}}>
                    {
                        section.id === 1    ?   <RadioDataStep1      data3={data3}    handleActive={handleActive}    isActiveArr={isActiveArr}    salesData_Dates={salesData_Dates}        data_After_Discarding_ExtraData_R={data_After_Discarding_ExtraData_R}    setdata_After_Discarding_ExtraData_R={setdata_After_Discarding_ExtraData_R}/>
                                            :   section.id === 2    ?    <RadioDataStep2      dimensionsOptions_R={dimensionsOptions_R}    checkedItems_RD={checkedItems_RD}    setCheckedItems_RD={setCheckedItems_RD}    selected_RD={selected_RD}    setSelected_RD={setSelected_RD}    data_After_Discarding_ExtraData_R={data_After_Discarding_ExtraData_R}    handleActive={handleActive}    isActiveArr={isActiveArr}    aggregatedData_R={aggregatedData_R}    setAggregatedData_R={setAggregatedData_R}/>
                                                                    :    section.id === 3    ?    <RadioDataStep3      aggregatedData_R={aggregatedData_R}    handleActive={handleActive}    isActiveArr={isActiveArr}    states={states}     afterRegionalMapping_arr_R={afterRegionalMapping_arr_R}    setAfterRegionalMapping_arr_R={setAfterRegionalMapping_arr_R}/>
                                                                                             :    section.id === 4    &&    <RadioDataStep4      states={states}    salesData_Dates={salesData_Dates}      afterRegionalMapping_arr_R={afterRegionalMapping_arr_R}    after_Missing_Dates_and_States_Treatment_R={after_Missing_Dates_and_States_Treatment_R}    setAfter_Missing_Dates_and_States_Treatment_R={setAfter_Missing_Dates_and_States_Treatment_R}/>
                    }
                </div>
            </div>


        </div>
    )
}