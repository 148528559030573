function Specifications() {


    return (
        <div  style={{width:"100%",  height:"100%",  display:"flex", justifyContent:"center", alignItems:"center", fontSize:"24px", backgroundColor:""}}>

            <div style={{ width: "100%", borderRadius: "8px", height:"100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", boxShadow: "8px 8px 20px 0px #AEAEC080" }}>
                
                
                <div    style={{width:"100%", height:"100%",}}>
                    <table  className="table-font"  style={{width: "100%", height: "100%", backgroundColor: "white", color:"#171717" }}>
                        <thead  style={{width:"100%", height:"4%"}}>
                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%"}}>
                                    <th className="cell2XY"  style={{width:"5%", paddingTop:"0px", borderTopLeftRadius:"8px"}}>    </th>
                                    <th className="cell2X"   style={{width:"35%"}}>Specification</th>
                                    <th className="cell2X"   style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem"}}>Selection</th>
                                    <th                      style={{width:"5%", borderBottom:"1px solid #ddd", borderTopRightRadius:"8px"}}></th>
                                </tr>
                        </thead>

                        <tbody  style={{width:"100%"}}>
                            <tr  style={{width:"100%"}}>
                                <td className="cell2"     style={{width:"5%"}}></td>
                                <td className="cell2"     style={{width:"35%"}}>Model Type</td>
                                <td className="cell2"     style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem"}}>Truelift Model</td>
                                <td                       style={{width:"5%", borderBottom:"1px solid #ddd"}}></td>
                            </tr>
                            <tr  style={{width:"100%"}}>
                                <td className="cell2"     style={{width:"5%"}}></td>
                                <td className="cell2"     style={{width:"35%"}}>Model Period</td>
                                <td className="cell2"     style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem"}}>15-11-2020 &nbsp; to &nbsp;  30-11-2020</td>
                                <td                       style={{width:"5%", borderBottom:"1px solid #ddd"}}></td>
                            </tr>
                            <tr  style={{width:"100%"}}>
                                <td className="cell2"     style={{width:"5%"}}></td>
                                <td className="cell2"     style={{width:"35%"}}>Y Variable</td>
                                <td className="cell2"     style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem"}}>Sales</td>
                                <td                       style={{width:"5%", borderBottom:"1px solid #ddd"}}></td>
                            </tr>
                            <tr  style={{width:"100%"}}>
                                <td className="cell2"     style={{width:"5%"}}></td>
                                <td className="cell2"     style={{width:"35%"}}>X Variables</td>
                                <td className="cell2"     style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem", color:"#0000EE"}}>  <button>Click Here</button>  </td>
                                <td                       style={{width:"5%", borderBottom:"1px solid #ddd"}}></td>
                            </tr>
                            <tr  style={{width:"100%"}}>
                                <td className="cell2"     style={{width:"5%"}}></td>
                                <td className="cell2"     style={{width:"35%"}}>Geo Mapping</td>
                                <td className="cell2"     style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem", color:"#0000EE"}}>  <button>Click Here</button>  </td>
                                <td                       style={{width:"5%", borderBottom:"1px solid #ddd"}}></td>
                            </tr>
                            <tr  style={{width:"100%"}}>
                                <td className="cell2"     style={{width:"5%"}}></td>
                                <td className="cell2"     style={{width:"35%"}}>Dimensions Defined</td>
                                <td className="cell2"     style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem", color:"#0000EE"}}>  <button>Click Here</button>  </td>
                                <td                       style={{width:"5%", borderBottom:"1px solid #ddd"}}></td>
                            </tr>
                            <tr  style={{width:"100%"}}>
                                <td className="cell2"     style={{width:"5%"}}></td>
                                <td className="cell2"     style={{width:"35%"}}>Adstock Details</td>
                                <td className="cell2"     style={{width:"55%", borderLeft:"1px solid #DDD", paddingLeft:"2rem", color:"#0000EE"}}>  <button>Click Here</button>  </td>
                                <td                       style={{width:"5%", borderBottom:"1px solid #ddd"}}></td>
                            </tr>

                        </tbody>
                        
                    </table>
                </div>


                


            </div>

        </div>
    );
  }
  
  export default Specifications;