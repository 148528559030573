import { useState } from "react";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function RadioDataStep4({ states,    salesData_Dates,      afterRegionalMapping_arr_R,    after_Missing_Dates_and_States_Treatment_R,    setAfter_Missing_Dates_and_States_Treatment_R }) {







    // console.log("states :- ", states);
    // console.log("salesData_Dates :- ", salesData_Dates);
    // console.log("afterRegionalMapping_arr_R :- ", afterRegionalMapping_arr_R);















    useEffect(()=>{
        if(afterRegionalMapping_arr_R.length    >    0){



            const sortedStates = [...states].sort();     // We are assuming that Dates will be sorted, so we are not sorting them now, if required in future then we will also sort them



            const lookup = new Map();

            afterRegionalMapping_arr_R.forEach(item => {
                const key = `${item.Date}|${item.State}`;
                lookup.set(key, item.Cost);
            });



            const output = [];

            salesData_Dates.forEach(date => {
                sortedStates.forEach(state => {
                        
                    const key = `${date}|${state}`;
                    output.push({  Date: date,    State: state,    Cost_R: lookup.get(key) || 0  });
                      
                });
            });


            console.log("output :- ", output);
            setAfter_Missing_Dates_and_States_Treatment_R(output);

        }
    },[afterRegionalMapping_arr_R]);






    const [headers1, setHeaders1] = useState([]);
    useEffect(()=>{
        if(after_Missing_Dates_and_States_Treatment_R.length > 0){
            const formattedData = Object.keys(after_Missing_Dates_and_States_Treatment_R[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            setHeaders1(formattedData);
        }
    },[after_Missing_Dates_and_States_Treatment_R]);











    
    return (
        <div>
                <div>
                    {   
                        after_Missing_Dates_and_States_Treatment_R.length > 0  &&
                        
                            <div  style={{display:"flex", justifyContent:"space-between", gap:"4rem"}}>
                                <CSVLink  data={after_Missing_Dates_and_States_Treatment_R}    headers={headers1}    filename={"R_Step4_after_Missing_Dates_and_States_Treatment_R"}>
                                    <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                                </CSVLink>
                                
                                <div></div>
                            </div>
                    }
                </div>
        </div>
    );
}


export default RadioDataStep4;