
import { useState } from "react";
import { GoSearch } from "react-icons/go";
import { MdOutlineDelete } from "react-icons/md";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { NavLink } from 'react-router-dom';


function MMMExperiment({setActiveTab,    set_model_selected_Tab}) {


    const [searchText3, setSearchText3] = useState("");
    const [isHovered_on_del3, setIsHovered_on_del3] = useState(false);
    const [isHovered_on_Create_Experiment, setIsHovered_on_Create_Experiment] = useState(false);
    const experiment_table_arr_mmm = [];



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';



    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column"}}>
    

                <div    style={{ width: "100%", height: "9%",     padding: screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p    className="header-title" style={{ fontWeight: "600" }}>Experiment</p>
                    <div  className="fontF">DP(Name and Role)</div>
                </div>

                
                <div  style={{width:"100%", height:"91%", backgroundColor:""}}>


                    <div style={{ width: "100%", height: "12%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>


                        <div      className="search-bar-container"   style={{borderRadius: "30px", backgroundColor: "white", boxShadow: "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", display: "flex", alignItems: "center" }}>
                            <GoSearch   fontSize={screen_width === 'default-1536px' ? 22 : 18.3}   color="#27272A" />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchText3}
                                onChange={(e) => setSearchText3(e.target.value)}
                                style={{ color: "#6E6E6E", border: "none", outline: "none", background: "transparent", flex: "1",    fontSize:screen_width === 'default-1536px' ? "16px" : "13.3px" }}
                            />
                        </div>


                        <div style={{ display: "flex",  gap:screen_width === 'default-1536px' ? "1rem" : "0.8rem" }}>
                            <button    className="delete-icon"     style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_del3    ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out' }}      onMouseEnter={() => setIsHovered_on_del3(true)}        onMouseLeave={() => setIsHovered_on_del3(false)}>
                                    <div  style={{color: "#A1A1AA"}}>
                                        <MdOutlineDelete    fontSize={screen_width === 'default-1536px' ? 26 : 21.6}  />
                                    </div>
                            </button>

                            <div style={{ display: "flex", gap: "1px" }}>
                                <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "46px" : "38px", borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                <div style={{ width: "1px", height:screen_width === 'default-1536px' ? "46px" : "38px", borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                            </div>

                            <button       className="create-exp-button"   style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#97DDFF", color: "#171717", borderRadius: "37px", fontWeight: "500",      boxShadow: isHovered_on_Create_Experiment ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}    onMouseEnter={() => setIsHovered_on_Create_Experiment(true)}     onMouseLeave={() => setIsHovered_on_Create_Experiment(false)}>Create Experiment</button>
                        </div>


                    </div>

                    <div  style={{width:"100%", height:"88%", borderRadius: "8px",  boxShadow: "8px 8px 20px 0px #AEAEC080", padding:"1rem 0"}}>
                        
                    
                                {/* <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.2rem"}}>
                                    <svg    style={{width:screen_width === 'default-1536px' ? "285px" : "237px"}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                                    </svg>

                                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "27px" : "24px", color:"#111827", fontWeight:"600"}}>No Saved Experiment in the DB</p>

                                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "16px", color:"#4B5563", fontWeight:"400"}}>Create an Experiment to start working</p>
                                    </div>
                                    
                                </div> */}
                                              
                                <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", alignItems:"center", overflow:"auto", gap:"2rem", padding:"0 2rem", backgroundColor:""}}>
                                
                                
                                    
                                            <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", backgroundColor:""}}>
                        
                        
                                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"0.6rem 0", backgroundColor:""}}>
                                                        <p  className="fontF"  style={{fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "21px" : "18.5px",      color:"#171717"}}>Quarterly MMM &nbsp; (<span  style={{color:"#0000EE", fontWeight:"400"}}>Quartely_Data_AllMedia</span>)</p>
                                                        
                                                        <button    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF"}}    className="footer-button" >Compare Outputs</button>  
                                                    </div>
                        
                        
                                                    <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",    backgroundColor: "white", color:"#171717" }}>
                                                        
                                                        <thead  style={{width:"100%"}}>
                                                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                                                    <th    style={{width:"3%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                                                    <th    style={{width:"17%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Iteration Name</th>
                                                                    <th    style={{width:"44%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Description</th>
                                                                    <th    style={{width:"11%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>Specification</th>
                                                                    <th    style={{width:"15%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}></th>
                                                                    <th    style={{width:"10%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Action</th>
                                                                </tr>
                                                        </thead>
                            
                                                        <tbody  style={{width:"100%"}}>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_1_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Log, mean standardized, no ad stocked, all impressions  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td>  
                                                               
                                                                
                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_1.1_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                   Mean standardized, Tv ad stocked 1 week, 3 channel groups  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                
                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>

                                                                
                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_1.2_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                   Mean standardized, Tv ad stocked 1 week, all impressions  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td>    
                                                                
                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_2_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                   Mean standardized, Tv ad stocked 1 week,Print, OOH  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                

                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            
                                                        </tbody>
                            
                                                    </table>




                                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"0.6rem 0", backgroundColor:"", marginTop:"2rem"}}>
                                                        <p  className="fontF"  style={{fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "21px" : "18.5px",      color:"#171717"}}>Half Yearly MMM &nbsp; (<span  style={{color:"#0000EE", fontWeight:"400"}}>Half _early_Data_AllMedia</span>)</p>
                                                        
                                                        <button    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF"}}    className="footer-button" >Compare Outputs</button>  
                                                    </div>
                        
                        
                                                    <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",    backgroundColor: "white", color:"#171717" }}>
                                                        
                                                        <thead  style={{width:"100%"}}>
                                                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                                                    <th    style={{width:"3%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                                                    <th    style={{width:"17%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Iteration Name</th>
                                                                    <th    style={{width:"44%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Description</th>
                                                                    <th    style={{width:"11%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>Specification</th>
                                                                    <th    style={{width:"15%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}></th>
                                                                    <th    style={{width:"10%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Action</th>
                                                                </tr>
                                                        </thead>
                            
                                                        <tbody  style={{width:"100%"}}>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_1_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  TV, Print,OOH, Radio Log log Model  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                
                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_1.1_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  TV, Print,Radio, Sale days, Promotions, discount  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                

                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_1.2_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  TV, Print,OOH, Radio Log log Model, Promotions, Discount  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                

                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"17%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration_2_Jul_Sep    </td>
                                                                <td      style={{width:"44%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  TV, Print,OOH, Radio Log log Model, Day of week, week of month  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"11%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                

                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"15%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            
                                                        </tbody>
                            
                                                    </table>




                                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"0.6rem 0", backgroundColor:"", marginTop:"2rem"}}>
                                                        <p  className="fontF"  style={{fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "21px" : "18.5px",      color:"#171717"}}>Annual MMM &nbsp; (<span  style={{color:"#0000EE", fontWeight:"400"}}>View Dataset</span>)</p>
                                                        
                                                        <button    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF"}}    className="footer-button" >Compare Outputs</button>  
                                                    </div>
                        
                        
                                                    <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",    backgroundColor: "white", color:"#171717" }}>
                                                        
                                                        <thead  style={{width:"100%"}}>
                                                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                                                    <th    style={{width:"3%",      padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                                                    <th    style={{width:"18%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Iteration Name</th>
                                                                    <th    style={{width:"37%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Description</th>
                                                                    <th    style={{width:"15%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}>Specification</th>
                                                                    <th    style={{width:"17%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}></th>
                                                                    <th    style={{width:"10%",     padding:screen_width === 'default-1536px' ? "13px 13px 13px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Action</th>
                                                                </tr>
                                                        </thead>
                            
                                                        <tbody  style={{width:"100%"}}>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"18%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration1    </td>
                                                                <td      style={{width:"37%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  MMM Experiment for year 2020  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td>    
                                                                

                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"18%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration2    </td>
                                                                <td      style={{width:"37%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  MMM Experiment for year 2021  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                
                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>

                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"18%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration3    </td>
                                                                <td      style={{width:"37%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  MMM Experiment for year 2022  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                

                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            <tr style={{width:"100%"}}>
                                                                <td      style={{width:"3%" ,   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}>                        </td>
                                                                <td      style={{width:"18%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  Iteration1    </td>
                                                                <td      style={{width:"37%",   padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10.5px 10.5px 10.5px 0", borderBottom:"1px solid #ddd", textAlign:"start"}}>                                  MMM Experiment for year 2023  </td>
                                                                {/* <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>    <button     style={{color:"#0000EE"}}>Click here</button>    </td>    */}
                                                                <td      style={{width:"15%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start"}}>  <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("specifications");    }}    to={"/model"}    style={{color:"#0000EE"}} >  Click Here    </NavLink>    </td> 
                                                                

                                                                {/* <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <button      onClick={()=>{          }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Model Output</button>
                                                                </td> */}
                                                                <td      style={{width:"17%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                    <NavLink      onClick={()=>{setActiveTab("/model");    set_model_selected_Tab("model_output");    }}    to={"/model"}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "4.5px 12px" : "2.07px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >  Model Output    </NavLink>
                                                                </td>


                                                                {/* <td      style={{width:"10%", padding:screen_width === 'default-1536px' ? "11.5px 11.5px 11.5px 0" : "10px 10px 10px 0",       borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>       </td>    */}
                                                                <td      style={{width:"10%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                               
                                                                    <div    className={`group relative flex cursor-pointer items-center gap-1 text-richblack-25`}      onClick={()=>{}}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", paddingLeft:"0.9rem"}}>
                                                                            <HiOutlineDotsVertical />
                                                                    </div>
                                                                </td>                                              
                                                            </tr>
                                                            
                                                        </tbody>
                            
                                                    </table>

                        
                                            </div>
            
            
                                </div>
                        
 
                    </div>


                </div>
    
        </div>
    );
}
  
export default MMMExperiment;