import { useState } from "react";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function PrintDataStep1({data4,    handleActive,    isActiveArr,    salesData_Dates,        data_After_Discarding_ExtraData,    setdata_After_Discarding_ExtraData}) {







    useEffect(()=>{
        if(salesData_Dates.length > 0){
            const filteredData = data4.filter(item => salesData_Dates.includes(item.Date));
            // console.log("filteredData :- ", filteredData);
            setdata_After_Discarding_ExtraData(filteredData);
        }
    },[salesData_Dates]);



    const [headers1, setHeaders1] = useState([]);
    useEffect(()=>{
        if(data_After_Discarding_ExtraData.length > 0){
            const formattedData = Object.keys(data_After_Discarding_ExtraData[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            setHeaders1(formattedData);
        }
    },[data_After_Discarding_ExtraData]);







    const [nextBtnClicked, setNextBtnClicked] = useState(false);
    useEffect(()=>{
        if(!isActiveArr.includes(1)    &&    nextBtnClicked){
            if(!isActiveArr.includes(2))  handleActive(2);
            setNextBtnClicked(false);
        }
    }, [isActiveArr, nextBtnClicked]);








    return (
        <div>
                <div>
                    {   
                        data_After_Discarding_ExtraData.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between", gap:"4rem"}}>
                            <CSVLink  data={data_After_Discarding_ExtraData}    headers={headers1}    filename={"P_Step1_Dataset"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>

                            <button  onClick={()=>{handleActive(1);  setNextBtnClicked(true);}}  style={{backgroundColor:"green", fontSize:"13px", borderRadius:"5px", padding:"0 1rem"}}  >Next</button>
                        </div>
                    }
                </div>
        </div>
    );
}


export default PrintDataStep1;