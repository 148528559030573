
import { useEffect, useState } from "react";

function TD_Step_1({    overAll_Variable_Groups,    set_overAll_Variable_Groups,    selected_dummies,    set_selected_dummies    }) {








    const handleChange = (event) => {
        const value = event.target.value;

        set_overAll_Variable_Groups((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((item) => item !== value);    // Remove from array if already selected
            } 
            else { 
                return [...prevSelected, value];                         // Add to array if not selected
            }
        });

        set_selected_dummies((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((item) => item !== value);    // Remove from array if already selected
            } 
            else { 
                return [...prevSelected, value];                         // Add to array if not selected
            }
        });

    };

    useEffect(()=>{
        if(overAll_Variable_Groups.length){
            console.log("overAll_Variable_Groups :- ", overAll_Variable_Groups);
        }
    },[overAll_Variable_Groups]);





    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"", gap:"1.6rem"}}>


            <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>Standard Dummies</div>
                
                <div    style={{width:"100%", height:"87%", backgroundColor:"", padding:"1.6rem 2rem 2.2rem 2rem"}}>


                    <div    style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16.2px", color:"#474747", fontWeight:"500"}}>
                        <label  style={{display:"flex", gap:"0.8rem", cursor: "pointer", alignItems:"center"}}>
                            <input    type="checkbox"    value="Day_of_the_Week"              checked={overAll_Variable_Groups.includes("Day_of_the_Week")}       onChange={handleChange}    className="rounded border-blue-300 h-4 w-4 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>
                            <p    style={{fontSize:"19px"}}>Day of the Week</p>
                        </label>

                        <label  style={{display:"flex", gap:"0.8rem", cursor: "pointer", alignItems:"center"}}>
                            <input    type="checkbox"    value="Month_of_the_Year"            checked={overAll_Variable_Groups.includes("Month_of_the_Year")}     onChange={handleChange}    className="rounded border-blue-300 h-4 w-4 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>
                            <p    style={{fontSize:"19px"}}>Month of the Year</p>
                        </label>

                        <label  style={{display:"flex", gap:"0.8rem", cursor: "pointer", alignItems:"center"}}>
                            <input    type="checkbox"    value="Time_Square_(T^2)"           checked={overAll_Variable_Groups.includes("Time_Square_(T^2)")}      onChange={handleChange}    className="rounded border-blue-300 h-4 w-4 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>
                            <p    style={{fontSize:"19px"}}>Time Square (T^2)</p>
                        </label>
                    </div>

                    
                </div>
            </div>


            <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>Custom Dummies</div>

                <div style={{width:"100%", height:"87%", backgroundColor:"", padding:"1.6rem 2rem 2.2rem 2rem"}}>


                    <div    style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", gap:"1rem", fontSize:"16.2px", color:"#474747", fontWeight:"500"}}>
                        <label  style={{display:"flex", gap:"0.8rem", cursor: "pointer", alignItems:"center"}}>
                            <input    type="checkbox"    value="Promotion_Dates"              checked={overAll_Variable_Groups.includes("Promotion_Dates")}       onChange={handleChange}    className="rounded border-blue-300 h-4 w-4 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/>
                            <p    style={{fontSize:"19px"}}>Promotion Dates</p>
                        </label>
                    </div>


                </div>
            </div>
    
        </div>
    );
  }
  
  export default TD_Step_1;