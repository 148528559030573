import Loader from "../../assets/Images/loading.gif";
import BusinessSetupChild from "./ChildComponents/BusinessSetupChild";



function BusinessSetup({   business_setup_arr,    count,    setCount,    loading,    setLoading}) {





    if (loading) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={Loader} width="180" height="180"/>
            </div>
        )
    }



    return (
        <div style={{width:"100%", height:"100%"}}>
            <BusinessSetupChild    business_setup_arr={business_setup_arr}    count={count}    setCount={setCount}    setLoading={setLoading}/>
        </div>
    );
}

export default BusinessSetup;