
import { useState } from "react";
import Select from "react-select"; 




const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '43px', 
        borderRadius: '4px',
        border: '1px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '16px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '12px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};

const customStyles2 = {
    control: (provided, state) => ({
        ...provided,
        height: '40px', 
        borderRadius: '4px',
        border: '1px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '10px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};




function Select_Variables_MMM({set_model_selected_Tab}) {




    const [X_Variable_Selection_Options, set_X_Variable_Selection_Options] = useState([{ value: 'TV Impressions Total', label: 'TV Impressions Total' },  { value: 'TV Channel Group1', label: 'TV Channel Group1' },  { value: 'TV Channel Group2', label: 'TV Channel Group2' },  { value: 'TV Channels', label: 'TV Channels' },  { value: 'TV Genres', label: 'TV Genres' }]);
    const [X_Variable_Val, set_X_Variable_Val] = useState({ value: 'TV Impressions Total', label: 'TV Impressions Total' });
    function set_X_Variable_Val_Func(X){
        set_X_Variable_Val(X);
    }

    const [Y_Variable_Selection_Options, set_Y_Variable_Selection_Options] = useState([{ value: 'Sales', label: 'Sales' },  { value: 'Sessions', label: 'Sessions' },  { value: 'Purchase Conversions', label: 'Purchase Conversions' }  ]);
    const [Y_Variable_Val, set_Y_Variable_Val] = useState({ value: 'Sales', label: 'Sales' });
    function set_Y_Variable_Val_Func(X){
        set_Y_Variable_Val(X);
    }

    const [Model_type_Selection_Options, set_Model_type_Selection_Options] = useState([{ value: 'Truelift Model', label: 'Truelift Model' }, { value: `Robyn (Meta)`, label: `Robyn (Meta)` }, { value: `LightweightMMM (Google)`, label: `LightweightMMM (Google)` }]);
    const [Model_type_Val, set_Model_type_Val] = useState({ value: 'Truelift Model', label: 'Truelift Model' });
    function set_Model_type_Val_Func(X){
        set_Model_type_Val(X);
    }


    const [selected_metrics_TV, set_selected_metrics_TV] = useState([]);

    const handleChange = (event) => {
        const value = event.target.value;

        set_selected_metrics_TV((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((item) => item !== value);    // Remove from array if already selected
            } 
            else { 
                return [...prevSelected, value];                         // Add to array if not selected
            }
        });

    };






    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)  screen_width = '1200_1400';







    return (
        <div  style={{height:"100%", width:"100%", display:"flex", alignItems:"flex-end" }}>
            <div  style={{width:"100%", height:"95.5%", borderRadius:"8px", display:"flex", flexDirection:"column", gap:"1.6rem"}}>
            

                <div    style={{width:"100%", height:"88.9%", gap:"1.6rem", borderRadius:"8px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    

                    <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                        <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}></div>
                        
                        <div    style={{width:"100%", height:"87%", backgroundColor:"", padding:"2rem", display:"flex", flexDirection:"column", gap:"2rem"}}>

                            <div  style={{width:"56%", position:"relative", backgroundColor:""}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Model Type</h2>
                                <Select  options={Model_type_Selection_Options}  value={Model_type_Val}  onChange={set_Model_type_Val_Func}    styles={screen_width === 'default-1536px' ? customStyles : customStyles2}/>
                            </div>

                            <div  style={{width:"56%", position:"relative", backgroundColor:""}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Y Variable</h2>
                                <Select  options={Y_Variable_Selection_Options}  value={Y_Variable_Val}  onChange={set_Y_Variable_Val_Func}    styles={screen_width === 'default-1536px' ? customStyles : customStyles2}/>
                            </div>

                            
                        </div>

                    </div>


                    {/* <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                        <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}></div>

                        <div style={{width:"100%", height:"87%", backgroundColor:"", padding:"2rem"}}>


                            <div  style={{width:"56%", position:"relative", backgroundColor:""}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>X Variable</h2>
                                <Select  options={X_Variable_Selection_Options}  value={X_Variable_Val}  onChange={set_X_Variable_Val_Func}    styles={screen_width === 'default-1536px' ? customStyles : customStyles2}/>
                            </div>                          


                        </div>
                    </div> */}

                    <div    style={{width:"50%", height:"100%", backgroundColor:"",    boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px"}}>
                        <div    style={{width:"100%", height:"13%", backgroundColor:"#E4F1FA", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"20px", fontWeight:"500", color:"#29241E", borderBottom:"1px solid #E5E5E5",  borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>Select Metrics</div>

                        <div style={{width:"100%", height:"87%", backgroundColor:"", padding:"1.6rem 2rem 2.2rem 2rem"}}>
                            

                            <div    style={{width:"100%", height:"100%", overflowY:"scroll",  display:"flex", flexDirection:"column", gap:"0.4rem", fontSize:"16.2px", color:"#474747", fontWeight:"500"}}>
                                <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                                    <input    type="checkbox"    value="TV_Impressions_Total"    checked={selected_metrics_TV.includes("TV_Impressions_Total")}     onChange={handleChange}/>
                                    <p>TV Impressions Total</p>
                                </label>

                                <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                                    <input    type="checkbox"    value="TV_Channel_Group1"            checked={selected_metrics_TV.includes("TV_Channel_Group1")}             onChange={handleChange}/>
                                    <p>TV Channel Group1</p>
                                </label>

                                <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                                    <input    type="checkbox"    value="TV_Channel_Group2"           checked={selected_metrics_TV.includes("TV_Channel_Group2")}            onChange={handleChange}/>
                                    <p>TV Channel Group2</p>
                                </label>

                                <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                                    <input    type="checkbox"    value="TV_Channels"           checked={selected_metrics_TV.includes("TV_Channels")}            onChange={handleChange}/>
                                    <p>TV Channels</p>
                                </label>

                                <label  style={{display:"flex", gap:"0.6rem", cursor: "pointer"}}>
                                    <input    type="checkbox"    value="TV_Genres"           checked={selected_metrics_TV.includes("TV_Genres")}            onChange={handleChange}/>
                                    <p>TV Genres</p>
                                </label>
                            </div>

                        </div>
                    </div>


                </div>

                <div    style={{width:"100%", height:"11.1%", borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between", backgroundColor:"",  padding:"0 2rem"}}>
                    <div></div>
                    
                    <button      onClick={()=>{set_model_selected_Tab("model_output")}}   style={{borderRadius:"7px", backgroundColor:"#1A7AAF", color:"white"}}    className="footer-button" >Run Model</button>
                </div>

            </div>
        </div>
    );
  }
  
  export default Select_Variables_MMM;