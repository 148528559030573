import { useEffect } from "react";
import { useState } from "react";
import { GoSearch } from "react-icons/go";
import { MdOutlineFilterAlt, MdOutlineDelete } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import Loader from "../../../assets/Images/loading.gif";
import "../../../CSS/ComponentsStyling.css";

import { delete_csv_file_from_geo_data_table_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import { delete_raw_csv_file_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import { delete_file_data_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import { delete_file_results_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";





function DataSection({setUploadData_btn_Clicked,    file_data_table_arr,    uniqueFileIDS_from_Experiments,    count2,    setCount2,    fileId_Mapping_With_Exps,    loading2}) {


    const [searchText2, setSearchText2] = useState("");
    const filteredArr = file_data_table_arr.filter(obj => 
        obj.business.toLowerCase().includes(searchText2.toLowerCase()) ||
        obj.category.toLowerCase().includes(searchText2.toLowerCase()) ||
        obj.product.toLowerCase().includes(searchText2.toLowerCase())  ||
        obj.file_name.toLowerCase().includes(searchText2.toLowerCase())
    );



    const [currentPage, setCurrentPage] = useState(1);
    let itemsPerPage = 6;



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400){
        itemsPerPage=5;
        screen_width = '1200_1400';
    }



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredArr.slice(indexOfFirstItem, indexOfLastItem);   // Items to display on current page


    const totalPages = Math.ceil(filteredArr.length / itemsPerPage);


    const startItem = indexOfFirstItem + 1;                             // For displaying 1-8 of 18
    const endItem = Math.min(indexOfLastItem, filteredArr.length);      // For displaying 1-8 of 18


    
    const heightX =   itemsPerPage === 6    ?                                currentItems.length===1  ?  "27.3%"  :  currentItems.length===2  ?  "41.9%"  :  currentItems.length===3  ?  "56.4%"  :  currentItems.length===4  ?  "70.9%"  :  currentItems.length===5  ?  "85.4%"  :  "100%"
                                            :    itemsPerPage === 5    &&    currentItems.length===1  ?  "33%"  :  currentItems.length===2  ?  "49.8%"  :  currentItems.length===3  ?  "66.5%"  :  currentItems.length===4  ?  "83.2%"  :  "100%"



    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };







    const [isHovered_on_filter2, setIsHovered_on_filter2] = useState(false);
    const [isHovered_on_del2, setIsHovered_on_del2] = useState(false);
    const [isHovered_on_Upload_Data, setIsHovered_on_Upload_Data] = useState(false);

    const [file_name_Popup, setFile_name_Popup] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState('');
    const [exp_name_Popup, setExp_name_Popup] = useState(false);
    const [selectedExpname, setSelectedExpname] = useState('');
    const [description_Popup, setDescription_Popup] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');
    useEffect(()=>{
        setCheckedItems(new Array(file_data_table_arr.length).fill(false));
    },[file_data_table_arr])








    const [checkedItems, setCheckedItems] = useState(new Array(file_data_table_arr.length).fill(false));

    const handleCheckboxChange = (index) => {
        const updatedCheckedItems = checkedItems.map((checked, i) =>  i === index   ?   !checked   :   checked);
        setCheckedItems(updatedCheckedItems);
    };

    const handleSelectAll = () => {
        const allChecked = checkedItems.includes(true);        
        
        if(allChecked){
            setCheckedItems(new Array(file_data_table_arr.length).fill(!allChecked));
        }
        else{
            const res = file_data_table_arr.map(item => !uniqueFileIDS_from_Experiments.includes(item.file_id));  // Only selecting those datasets which are not part of any experiment
            setCheckedItems(res);
        }
    };

    

    

    useEffect(() => {
        if(checkedItems.includes(true)){
            console.log("checkedItems :- ", checkedItems);
            const res = file_data_table_arr.filter((item, index) => checkedItems[index])
            console.log("Datasets Selected :- ", res);
        }
    }, [checkedItems]);









    const [deleteCSV, setDeleteCSV] = useState(false);
    const [loadingDD, setLoadingDD] = useState(false);

    async function delete_CSV_func(){
        const arrDel = file_data_table_arr.filter((item, index) => checkedItems[index])
        console.log("Datasets to be deleted :- ", arrDel);

        setDeleteCSV(false);
        setLoadingDD(true);

        for(let i=0;i<arrDel.length;i++){
            await delete_csv_file_from_geo_data_table(arrDel[i].file_id);
        }

        setCount2(count2+1);    // To fetch the updated list of files from DB
        setLoadingDD(false);
        
    }

    const delete_csv_file_from_geo_data_table = async (id_DeleteCSV) => {    
       
        const res = await delete_csv_file_from_geo_data_table_func(id_DeleteCSV);         
        
        if(res.data.status === 500){
            console.log("Error while deleting rows from geo_data");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from geo_data`);
            await delete_raw_csv_file(id_DeleteCSV);
        }

    }

    const delete_raw_csv_file = async (id_DeleteCSV) => {   

        const res = await delete_raw_csv_file_func(id_DeleteCSV);    
        
        if(res.data.status === 500){
            console.log("Error while deleting row from raw_csv_file");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from raw_csv_file`);
            await delete_file_data(id_DeleteCSV);
        }

    }

    const delete_file_data = async (id_DeleteCSV) => {   

        const res = await delete_file_data_func(id_DeleteCSV);    
        
        if(res.data.status === 500){
          console.log("Error while deleting row from file_data");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from file_data`);
            await delete_file_results(id_DeleteCSV);
        }
  
    }

    const delete_file_results = async (id_DeleteCSV) => {   

        const res = await delete_file_results_func(id_DeleteCSV);    
        
        if(res.data.status === 500){
          console.log("Error while deleting row from model_result");
        }
        else if(res.data.status === 200){                
            console.log(`${res.data.data} Rows deleted successfully from model_result`);
        }
    
    }







    if (loadingDD  ||  loading2) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={Loader} width="180" height="180"/>
            </div>
        )
    }










    return (
        <div  style={{width:"100%", height:"100%"}}>


            <div style={{ width: "100%", height: "14%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>


                <div     className="search-bar-container"    style={{ borderRadius: "30px", backgroundColor: "white", boxShadow: "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", display: "flex", alignItems: "center" }}>
                    <GoSearch   fontSize={screen_width === 'default-1536px' ? 22 : 18.3}   color="#27272A" />
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchText2}
                        onChange={(e) => setSearchText2(e.target.value)}
                        style={{ color: "#6E6E6E", border: "none", outline: "none", background: "transparent", flex: "1",  fontSize:screen_width === 'default-1536px' ? "16px" : "13.3px" }}
                    />
                </div>


                <div style={{ display: "flex",  gap:screen_width === 'default-1536px' ? "1rem" : "0.8rem"}}>
                    {/* <button style={{ width: "46px", height: "46px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_filter2 ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}      onMouseEnter={() => setIsHovered_on_filter2(true)}     onMouseLeave={() => setIsHovered_on_filter2(false)}>
                        <MdOutlineFilterAlt color="#27272A" fontSize={26} />
                    </button> */}

                    <button    className="delete-icon"    onClick={()=>{setDeleteCSV(true)}}    style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_del2    ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out' }}      onMouseEnter={() => setIsHovered_on_del2(true)}        onMouseLeave={() => setIsHovered_on_del2(false)}     disabled={!checkedItems.includes(true)}>
                            <div  style={{color: !checkedItems.includes(true) ? "#A1A1AA" : "#DC2626"}}>
                                <MdOutlineDelete    fontSize={screen_width === 'default-1536px' ? 26 : 21.6} />
                            </div>
                    </button>

                    <div style={{ display: "flex", gap: "1px" }}>
                        <div style={{ width: "1px",    height:screen_width === 'default-1536px' ? "46px" : "38px",    borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                        <div style={{ width: "1px",    height:screen_width === 'default-1536px' ? "46px" : "38px",    borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                    </div>

                    <button    onClick={()=>{setUploadData_btn_Clicked(true)}}    className="add-new-button"   style={{width:screen_width === 'default-1536px' ? "146px" : "130px",  display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#97DDFF", color: "#171717", borderRadius: "37px", fontWeight: "500",      boxShadow: isHovered_on_Upload_Data ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}    onMouseEnter={() => setIsHovered_on_Upload_Data(true)}     onMouseLeave={() => setIsHovered_on_Upload_Data(false)}>Upload Data</button>
                </div>


            </div>


            {
                file_data_table_arr.length === 0
                ?
                    <div  style={{width:"100%", height:"86%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.2rem", boxShadow: "8px 8px 20px 0px #AEAEC080", borderRadius: "8px"}}>
                        <svg    style={{width:screen_width === 'default-1536px' ? "285px" : "237px"}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                            <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                            <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                            <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                            <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                        </svg>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "27px" : "24px", color:"#111827", fontWeight:"600"}}>No Saved Dataset in the DB</p>

                            <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "16px", color:"#4B5563", fontWeight:"400"}}>Start Uploading Datasets</p>
                        </div>
                        
                    </div>
                :   
                
                    <div  style={{ width: "100%", height:"86%", borderRadius: "8px", display: "flex", flexDirection: "column", justifyContent: "flex-start", boxShadow: "8px 8px 20px 0px #AEAEC080"}}>

                        <div    style={{width:"100%", height:screen_width === 'default-1536px' ? "86%" : "84%"}}>
                            <table  className="table-font"  style={{ width: "100%", height: heightX, backgroundColor: "white", color:"#171717" }}>
                                <thead  style={{width:"100%"}}>
                                        <tr style={{ backgroundColor: "#E4F1FA", width:"100%"}}>
                                            <th className="cell2XY"  style={{width:"7%", paddingTop:"3px", borderTopLeftRadius:"8px"}}>    <input   type="checkbox"      onChange={() => handleSelectAll()}/>      </th>
                                            <th className="cell2X"   style={{width:"15.5%"}}>File Name</th>
                                            <th className="cell2X"   style={{width:"15.5%"}}>Description</th>
                                            <th className="cell2X"   style={{width:"15.5%"}}>Experiment</th>
                                            <th className="cell2X"   style={{width:"15.5%"}}>Business</th>
                                            <th className="cell2X"   style={{width:"15.5%"}}>Category</th>
                                            <th className="cell2X"   style={{width:"15.5%", borderBottom:"1px solid #ddd", borderTopRightRadius:"8px"}}>Product</th>
                                        </tr>
                                </thead>

                                <tbody  style={{width:"100%"}}>
                                    {
                                        currentItems.map((obj, index) => {
                                                
                                                const foundItem = fileId_Mapping_With_Exps.find(item => item.file_id === obj.file_id);     // Find the corresponding object in res
                                                const firstExpName = foundItem ? foundItem.experiment_name[0] : "Not Mapped";              // Get the first element of ExpName array
                                                let totalCount=0;
                                                if(foundItem)  totalCount = foundItem.experiment_name.length;

                                                return(
                                                    <tr key={index}  style={{width:"100%"}}>
                                                        <td className="cell2XY"   style={{width:"7%", paddingTop:"3px"}}>
                                                            {
                                                                !uniqueFileIDS_from_Experiments.includes(obj.file_id)    &&    <input   type="checkbox"   checked={checkedItems[indexOfFirstItem+index]}     onChange={() => handleCheckboxChange(indexOfFirstItem+index)}/>
                                                            }
                                                        </td>

                                                        <td className="cell2"     style={{width:"15.5%", paddingTop:"3px"}}>
                                                            {
                                                                screen_width === 'default-1536px'   ?
                                                                                                        obj.file_name.length > 20       ?   <div  style={{display:"flex"}}>
                                                                                                                                                <p>{obj.file_name.slice(0,17)}</p>
                                                                                                                                                <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                            </div>  
                                                                                                                                        :   obj.file_name
                                                                                                    :
                                                                                                        obj.file_name.length > 16       ?   <div  style={{display:"flex"}}>
                                                                                                                                                <p>{obj.file_name.slice(0,15)}</p>
                                                                                                                                                <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                            </div>  
                                                                                                                                        :   obj.file_name
                                                            }
                                                        </td>
                                                        <td className="cell2"     style={{width:"15.5%"}}>    <button    onClick={()=>{setDescription_Popup(true);  setSelectedDescription(obj.description)}}    style={{color:"blue"}}>description</button>    </td>
                                                        <td className="cell2"     style={{width:"15.5%"}}>
                                                            {
                                                                totalCount > 1    ?   `${firstExpName} + ${totalCount - 1}` 
                                                                                  :   screen_width === 'default-1536px'     ?
                                                                                                                                firstExpName.length > 20    ?   <div  style={{display:"flex"}}>
                                                                                                                                                                    <p>{firstExpName.slice(0,17)}</p>
                                                                                                                                                                    <button    onClick={()=>{setExp_name_Popup(true);  setSelectedExpname(firstExpName)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                </div>  
                                                                                                                                                            :   firstExpName
                                                                                                                            :
                                                                                                                                firstExpName.length > 16    ?   <div  style={{display:"flex"}}>
                                                                                                                                                                    <p>{firstExpName.slice(0,15)}</p>
                                                                                                                                                                    <button    onClick={()=>{setExp_name_Popup(true);  setSelectedExpname(firstExpName)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                </div>  
                                                                                                                                                            :   firstExpName
                                                                                
                                                            }
                                                        </td>
                                                        <td className="cell2"     style={{width:"15.5%"}}>{obj.business}</td>
                                                        <td className="cell2"     style={{width:"15.5%"}}>{obj.category}</td>
                                                        <td className="cell2"     style={{width:"15.5%"}}>{obj.product}</td>
                                                    </tr>
                                                );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>


                        <div style={{ width: "100%", height:screen_width === 'default-1536px' ? "14%" : "16%", backgroundColor: "white", display: "flex", justifyContent: "flex-end", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                            <div style={{ display: "flex", gap: "2rem", paddingRight: "2rem" }}>
                                
                                <p style={{ display: "flex", alignItems: "center",    fontSize:screen_width === 'default-1536px' ? "16px" : "14px" }}>
                                    {`${startItem}-${endItem} of ${filteredArr.length}`}
                                </p>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <button onClick={handlePrevPage}  disabled={currentPage === 1}            style={{opacity : currentPage===1           ?  "0.3" : "1"}}>         <GoChevronLeft  fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />     </button>
                                    <button onClick={handleNextPage}  disabled={currentPage === totalPages}   style={{opacity : currentPage===totalPages  ?  "0.3" : "1"}}>         <GoChevronRight fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />     </button>
                                </div>

                            </div>
                        </div>

                    </div>
                }



            {
                                          
                deleteCSV  &&  ( 
                    <div   className={` modal-delete-rows`}>

                        <div style={{display:"flex", flexDirection:"column", gap:"0.5rem"}}>
                            <p  style={{fontSize:"24px"}}>     Are you sure?    </p>    
                            <p  style={{fontSize:"15px"}}>     The Selected Datasets will be deleted    </p>    
                        </div>

                        <div  style={{display:"flex", justifyContent:"flex-start", gap:"2rem", padding:"1.6rem 0 0.6rem 0"}}>
                            <button  onClick={() => { delete_CSV_func()}}        style={{padding:"0.4rem 1.2rem", backgroundColor:"red", borderRadius:"5px", color:"white"}}>Delete</button>
                            <button  onClick={() => { setDeleteCSV(false) }}     style={{padding:"0.4rem 1.2rem", backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                deleteCSV    &&    (<div   className={` overlay-delete-rows `}></div>)
            }






            {
                file_name_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>File Name</p>
                            <button  onClick={()=>{setFile_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedFilename}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                file_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {
                
                exp_name_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Experiment Name</p>
                            <button  onClick={()=>{setExp_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedExpname}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                exp_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



            {
                
                description_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Description</p>
                            <button  onClick={()=>{setDescription_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px",    margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedDescription}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                description_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }




        </div>
    );
  }
  
  export default DataSection;