

import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import { useState } from 'react';
import PRData_Step1 from "../Process_Different_Channels_data/PRData_Steps/PRData_Step1";
import PRData_Step2 from "../Process_Different_Channels_data/PRData_Steps/PRData_Step2";
import PRData_Step3 from "../Process_Different_Channels_data/PRData_Steps/PRData_Step3";




function MMM_Process_PR_data({    dimensionsOptions_PR,    data_pr,    Sales_states,    Sales_Dates,    selected_Dimensions_PR,    set_selected_Dimensions_PR,    selected_metrics_PR,    set_selected_metrics_PR,    uniquePositions_in_PR,    set_uniquePositions_in_PR,    data_After_Discarding_ExtraData_PR,    setdata_After_Discarding_ExtraData_PR,    aggregatedData_PR,    setAggregatedData_PR,    afterRegionalMapping_arr_PR,    setAfterRegionalMapping_arr_PR,    after_Missing_Dates_and_Positions_Treatment_PR,    setAfter_Missing_Dates_and_Positions_Treatment_PR,    after_flipping_the_Positions_Horizontally_PR,    setAfter_flipping_the_Positions_Horizontally_PR,    after_merging_the_Positions_PR,    setAfter_merging_the_Positions_PR    }) {

    
    

    const navigate = useNavigate();



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';



    const [PR_Process_Step_Number, set_PR_Process_Step_Number] = useState(1);






    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column"}}>
    
            <div    style={{ width: "100%", height: "9%",     padding: screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p    className="header-title" style={{ fontWeight: "600" }}>Process Print Data</p>
                <div  className="fontF">DP(Name and Role)</div>
            </div>


            <div style={{width:"100%", height:"91%", display:"flex", flexDirection:"column"}}>

                <div  style={{width:"100%", height:"10%", display:"flex", alignItems:"center"}}>
                    <button    className="back-navigation-business-setup"   onClick={()=>{    navigate("/mmm-data")    }}   style={{color:"#6E6E6E", display:"flex"}}>
                        <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                        <span  style={{color:"#0000EE", fontWeight:"400"}}>Process Data</span>
                    </button>

                    <p  className="add-new-text"  style={{color:"#6E6E6E"}}>  &nbsp; / &nbsp; Print</p>
                </div>


                <div  style={{width:"100%", height:"90%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                    <div  style={{width:"100%", height:"86%", display:"flex", flexDirection:"column", alignItems:"center",   padding:screen_width === 'default-1536px' ? "1rem 1.2rem 1.2rem 1.2rem" : "1rem",   boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px",        gap:screen_width === 'default-1536px' ? "1.2rem" : "1rem"}}>
                    

                        <div  style={{width:"100%", height:"9%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:""}}>
                            
                            <div style={{display:"flex", gap:"8px", alignItems:"center"}}>
                                

                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"36px", height:"36px", borderRadius:"50%", backgroundColor:"#16618E", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:"#FFFFFF", fontSize:"14px", fontWeight:"600"}}>1</span>    </div>
                                    <p    className='fontF'  style={{color:"#171717", fontSize:"14px", fontWeight:"600"}}>Select Variables</p>      {/* for text */}
                                </div>                                
                                <div  style={{width:"64px", height:"0", border:PR_Process_Step_Number === 1 ? "1px dashed gray" : "1px dashed #16618E"}}></div>


                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"36px", height:"36px", border:PR_Process_Step_Number === 1 ? "2px solid gray" : "none",    borderRadius:"50%", backgroundColor:PR_Process_Step_Number === 1 ? "#f1f1f1" : "#16618E", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:PR_Process_Step_Number === 1 ? "#171717" : "#FFFFFF", fontSize:"14px", fontWeight:"600"}}>2</span>    </div>
                                    <p    className='fontF'  style={{color:"#171717", fontSize:"14px", fontWeight:"600"}}>Map Geos</p>      {/* for text */}
                                </div>
                                <div  style={{width:"64px", height:"0", border:PR_Process_Step_Number === 3 ? "1px dashed #16618E" : "1px dashed gray"}}></div>


                                <div  style={{display:"flex", gap:"8px", alignItems:"center", justifyContent:"center"}}>
                                    <div  style={{width:"36px", height:"36px", border:PR_Process_Step_Number === 3 ? "none" : "2px solid gray",    borderRadius:"50%", backgroundColor:PR_Process_Step_Number === 3 ? "#16618E" : "#f1f1f1", display:"flex", justifyContent:"center", alignItems:"center"}}>    <span  className='fontF'  style={{color:PR_Process_Step_Number === 3 ? "#FFFFFF" : "#171717", fontSize:"14px", fontWeight:"600"}}>3</span>    </div>
                                    <p    className='fontF'  style={{color:"#171717", fontSize:"14px", fontWeight:"600"}}>Aggregate Variables</p>      {/* for text */}
                                </div>


                            </div>

                        </div>

                        <div  style={{width:"100%", height:"91%", backgroundColor:""}}>
                            {
                                PR_Process_Step_Number === 1    ?   <PRData_Step1       data_pr={data_pr}    Sales_Dates={Sales_Dates}        data_After_Discarding_ExtraData_PR={data_After_Discarding_ExtraData_PR}    setdata_After_Discarding_ExtraData_PR={setdata_After_Discarding_ExtraData_PR}        dimensionsOptions_PR={dimensionsOptions_PR}    selected_Dimensions_PR={selected_Dimensions_PR}    set_selected_Dimensions_PR={set_selected_Dimensions_PR}    selected_metrics_PR={selected_metrics_PR}    set_selected_metrics_PR={set_selected_metrics_PR}        aggregatedData_PR={aggregatedData_PR}    setAggregatedData_PR={setAggregatedData_PR}/>
                                                                :   PR_Process_Step_Number === 2    ?    <PRData_Step2      aggregatedData_PR={aggregatedData_PR}    Sales_states={Sales_states}     afterRegionalMapping_arr_PR={afterRegionalMapping_arr_PR}    setAfterRegionalMapping_arr_PR={setAfterRegionalMapping_arr_PR}       Sales_Dates={Sales_Dates}      uniquePositions_in_PR={uniquePositions_in_PR}    set_uniquePositions_in_PR={set_uniquePositions_in_PR}      after_Missing_Dates_and_Positions_Treatment_PR={after_Missing_Dates_and_Positions_Treatment_PR}    setAfter_Missing_Dates_and_Positions_Treatment_PR={setAfter_Missing_Dates_and_Positions_Treatment_PR}/>
                                                                                                    :    PR_Process_Step_Number === 3    &&    <PRData_Step3      after_Missing_Dates_and_Positions_Treatment_PR={after_Missing_Dates_and_Positions_Treatment_PR}    after_flipping_the_Positions_Horizontally_PR={after_flipping_the_Positions_Horizontally_PR}    setAfter_flipping_the_Positions_Horizontally_PR={setAfter_flipping_the_Positions_Horizontally_PR}    uniquePositions_in_PR={uniquePositions_in_PR}      after_merging_the_Positions_PR={after_merging_the_Positions_PR}    setAfter_merging_the_Positions_PR={setAfter_merging_the_Positions_PR}/>
                            }
                        </div>                  


                    </div>



                    <div  style={{width:"100%", height:"10%",    padding:screen_width === 'default-1536px' ? "12px 24px" : "10px 24px",    borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                        <div>
                            {/* { 
                                ASD    ?    <p    className="error-texts"    style={{color:"#DC2626"}}>CSV file contains Errors, Click on "Show Errors" for more details</p>
                                        :    !(showBtn  &&  business_Val  &&  category_Val  &&  product_Val   &&  !duplicateFileNameError  &&  file  &&  uploadData_descriptionX)  &&  <p    className="error-texts"    style={{color:"#DC2626"}}>All fields must be filled in before saving</p>
                            } */}
                        </div>

                        <div  style={{display:"flex", gap:"1rem"}}>
                            {
                                PR_Process_Step_Number !== 1  &&  <button      onClick={()=>{ set_PR_Process_Step_Number(PR_Process_Step_Number-1) }}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}   >Previous</button>
                            }
                            
                            <button      onClick={()=>{ PR_Process_Step_Number !== 3  &&  set_PR_Process_Step_Number(PR_Process_Step_Number+1) }}    className="footer-button"    style={{borderRadius:"7px",  border:"2px solid #1A7AAF", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600"}}   >{PR_Process_Step_Number === 3 ? "Save" : "Next"}</button>
                        </div>
                    </div>
                
                </div>

            </div>
    
        </div>
    );
  }
  
  export default MMM_Process_PR_data;