import React, { useState, useEffect } from 'react';
import { MdClose } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";



function SubsetByGeo({ fileObjs, selectedObjID_forSubset,        selectedGeos,    setSelectedGeos }) {




    const entireObj = fileObjs.find(obj => obj.objId === selectedObjID_forSubset);
    const uniqueGeos = [...new Set(entireObj.file_data.map(item => item.geo))];


    // Find the first item where testgeo is 1
    const result = entireObj.file_data.find(item => item.testgeo === '1');
   // console.log("test geo from all the geos:- ", result);




    const handleChipClick = (geo) => {
        if(geo === result.geo) return;    // User cannot unselect TestGeo
        setSelectedGeos(prev => prev.includes(geo)  ?  prev.filter(item => item !== geo)  :  [...prev, geo]);
    };

    const handleSelectAll = () => {
        setSelectedGeos(selectedGeos.length === uniqueGeos.length ? [] : uniqueGeos);
    };

    useEffect(() => {
        if (selectedGeos.length > 0) {
            console.log("Geos Selected:", selectedGeos);
        }
    }, [selectedGeos]);





    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';





    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ height: "10%", width: "100%" }}>
                <button    onClick={handleSelectAll}    style={{ fontSize:screen_width === 'default-1536px' ? "16px" : "14px",     paddingLeft:"1rem" }}>
                {
                    selectedGeos.length === uniqueGeos.length  ?  'Deselect All'  :  'Select All'
                }
                </button>
            </div>

            <div style={{ height: "90%", width: "100%" }}>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "0.8rem" }}>
                    {
                    uniqueGeos.map((item, index) => (
                        <div  key={index}    style={{backgroundColor:item === result.geo ? "#A1A1AA" : "", border:item === result.geo ? "1px solid #A1A1AA" : "",    fontSize:screen_width === 'default-1536px' ? "17px" : "15px",      padding:screen_width === 'default-1536px' ? "8px 22px" : "6px 18.5px",      margin:screen_width === 'default-1536px' ? "5px" : "4px"}}   className={`chip  ${selectedGeos.includes(item)  ?  'selected'  :  ''}  `}    onClick={()=>handleChipClick(item)}>
                            <p  style={{paddingBottom:screen_width === 'default-1536px' ? "2.5px" : "2px"}}>{item}</p>
                            {
                                selectedGeos.includes(item)  ?  <span  style={{paddingLeft:screen_width === 'default-1536px' ? "10px" : "8px"}}>  <MdClose  fontSize={12}  color="black"/>  </span>
                                                             :  <span  style={{paddingLeft:screen_width === 'default-1536px' ? "10px" : "8px"}}>  <IoMdAdd  fontSize={12}  color="#171717"/>  </span>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SubsetByGeo;