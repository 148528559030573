
import { useEffect } from "react";
import "../../CSS/ComponentsStyling.css";
import Select_Variables_MMM from "./MMM_Model_Components/Select_Variables_MMM";
import Model_Output_MMM from "./MMM_Model_Components/Model_Output_MMM";
import Specifications from "./MMM_Model_Components/Specifications";


function Model({    model_selected_Tab,    set_model_selected_Tab    }) {



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';



    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column"}}>
    

                <div    style={{ width: "100%", height: "9%",     padding: screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p    className="header-title" style={{ fontWeight: "600" }}>Model</p>
                    <div  className="fontF">DP(Name and Role)</div>
                </div>

                
                <div  style={{width:"100%", height:"10%", display:"flex", justifyContent:"flex-start", alignItems:"flex-end"}}>
                    <button   onClick={()=>{set_model_selected_Tab("select_variables") }}     className="experiment-data-tabs"    style={{color: model_selected_Tab === "select_variables"    ? "#1A7AAF" : "#171717",    fontWeight: model_selected_Tab === "select_variables"    ? "600" : "400",    flex:1,    maxWidth:"175px",    borderBottom: model_selected_Tab === "select_variables"    ? "2px solid #1A7AAF" : "2px solid #E5E5E5",    position:'relative'}}>    Select Variables    <span  style={{  position:'absolute',  top:'12%',  right:'0',  bottom:'12%',  width: '1px', backgroundColor: "#E5E5E5"  }}/>    </button>
                    <button   onClick={()=>{set_model_selected_Tab("model_output")}}          className="experiment-data-tabs"    style={{color: model_selected_Tab === "model_output"   ? "#1A7AAF" : "#171717",         fontWeight: model_selected_Tab === "model_output"   ? "600" : "400",         flex:1,    maxWidth:"175px",    borderBottom: model_selected_Tab === "model_output"   ? "2px solid #1A7AAF" : "2px solid #E5E5E5",         position:'relative'}}>    Model Output        <span  style={{  position:'absolute',  top:'12%',  right:'0',  bottom:'12%',  width: '1px', backgroundColor: "#E5E5E5"  }}/>    </button>
                    <button   onClick={()=>{set_model_selected_Tab("specifications")}}        className="experiment-data-tabs"    style={{color: model_selected_Tab === "specifications" ? "#1A7AAF" : "#171717",         fontWeight: model_selected_Tab === "specifications" ? "600" : "400",         flex:1,    maxWidth:"175px",    borderBottom: model_selected_Tab === "specifications" ? "2px solid #1A7AAF" : "2px solid #E5E5E5"}}>    Model Specs    </button>
                    <div  style={{flex:"1", borderBottom:"1px solid #E5E5E5"}}></div>
                </div>

                <div  style={{width:"100%", height:"81%"}}>
                    {
                        model_selected_Tab === "select_variables"  ?  <Select_Variables_MMM      set_model_selected_Tab={set_model_selected_Tab}  />
                                                              :   model_selected_Tab === "model_output"    ?    <Model_Output_MMM  /> 
                                                                                                           :    model_selected_Tab === "specifications"   &&    <Specifications      /> 
                    }
                </div>
    
        </div>
    );
  }
  
  export default Model;