import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function PrintDataStep6({    uniquePositions_in_Print,    after_flipping_the_Positions_Horizontally,    after_merging_the_Positions,    setAfter_merging_the_Positions}) {






    const [select_Merge_Positions_Popup, setSelect_Merge_Positions_Popup] = useState(false);
    const [mergePositions_Popup, setMergePositions_Popup] = useState(false);
    const [mergedColumn_Name, set_MergedColumn_Name] = useState('');
    const [positionsPicked_Overall, set_positionsPicked_Overall] = useState([]);




    const [checkedItems2, setCheckedItems2] = useState([]);
    if(uniquePositions_in_Print.length > 0    &&    checkedItems2.length===0){
        setCheckedItems2(new Array(uniquePositions_in_Print.length).fill(false));
    }
  

    const handleCheckboxChange2 = (index) => {

        const selectedPosition = uniquePositions_in_Print[index];
        set_positionsPicked_Overall((prevStates) => {
            if (prevStates.includes(selectedPosition)) {    return prevStates.filter(state => state !== selectedPosition);    }
            else {                                          return [...prevStates, selectedPosition];                         }
        });


        const updatedCheckedItems = checkedItems2.map((checked, i) =>  i === index   ?   !checked   :   checked);
        // console.log("updatedCheckedItems:-", updatedCheckedItems);
        setCheckedItems2(updatedCheckedItems);

    };

    useEffect(() => {
        if(checkedItems2.includes(true)){
            const res = uniquePositions_in_Print.filter((item, index) => checkedItems2[index])
            console.log(`Positions selected Currently :- `, res);
        }
    }, [checkedItems2]);









    const [space_Found_in_MC_Name, set_space_Found_in_MC_Name] = useState(false);
    useEffect(()=>{
        if(mergedColumn_Name.length > 0){
            if(mergedColumn_Name.includes(' ')){
                set_space_Found_in_MC_Name(true);
            }
            else{
                set_space_Found_in_MC_Name(false);
            }
        }
    },[mergedColumn_Name])











    const [mappingArr, setMappingArr] = useState([]);
    useEffect(()=>{

        if(!mergePositions_Popup    &&    mergedColumn_Name){
            const res = uniquePositions_in_Print.filter((item, index) => checkedItems2[index]);
            console.log("res :- ", res);
            
            if(res.length === 0){
                const updatedItems = mappingArr.filter(obj => obj.MergedColumnName  !==  mergedColumn_Name);
                setMappingArr(updatedItems);
                set_MergedColumn_Name('');
                return;
            }


            if(    mappingArr.some(obj => obj.MergedColumnName  ===  mergedColumn_Name)    ){                       // If MergedColumnName already exists, then override it with newly selected Positions
                const existingIndex = mappingArr.findIndex(obj => obj.MergedColumnName === mergedColumn_Name);
                const updatedArr = mappingArr.map(    (obj, index) => index === existingIndex ? { ...obj, Positions: res } : obj    );
                setMappingArr(updatedArr)
            }
            else{
                const newEntry = {   MergedColumnName: mergedColumn_Name,    Positions: res   };
                setMappingArr([...mappingArr, newEntry]);
            }


            set_MergedColumn_Name('');
            setCheckedItems2(new Array(uniquePositions_in_Print.length).fill(false));
        }

    },[mergePositions_Popup]); 

    useEffect(()=>{
        if(mappingArr.length>0){
            console.log("mappingArr :- ", mappingArr);
        }
    },[mappingArr]);












    useEffect(()=>{                          // Suppose if Positions have already been selected for new column, but user wants to edit the Positions(Add new Positions and remove previous Positions)
        if(mergedColumn_Name.length > 0){
            
            if(    mappingArr.some(obj => obj.MergedColumnName === mergedColumn_Name)    ){
                const index = mappingArr.findIndex(obj => obj.MergedColumnName === mergedColumn_Name);
                // console.log("mappingArr[index] :- ", mappingArr[index].Positions);

                const filteredArray  =  uniquePositions_in_Print.map(item => mappingArr[index].Positions.includes(item));
                // console.log("filteredArray:-", filteredArray);
                setCheckedItems2(filteredArray);
            }

        }
    },[mergedColumn_Name]);














    useEffect(()=>{

        if(!select_Merge_Positions_Popup    &&    mappingArr.length>0){ 
            

            const position_To_MergedColumn_Map = {};
            mappingArr.forEach(({MergedColumnName, Positions}) => {
                Positions.forEach(position => {
                    const formattedPosition = position.replace(/\s+/g, '_'); // Replace spaces with underscores
                    position_To_MergedColumn_Map[formattedPosition] = MergedColumnName;
                });
            });
            console.log("position_To_MergedColumn_Map :- ", position_To_MergedColumn_Map);





            const output = after_flipping_the_Positions_Horizontally.map(item => {
                const aggregated = {};
                
                
                let stateTotals = mappingArr.reduce((acc, {MergedColumnName}) => {
                    acc[MergedColumnName] = 0;
                    return acc;
                }, {});
                
                for (const key in item) {
                    if (position_To_MergedColumn_Map[key]) {
                        const state = position_To_MergedColumn_Map[key];
                        stateTotals[state] += item[key];
                    } 
                    else {
                        aggregated[key] = item[key];
                    }
                }

                for (const [state, total] of Object.entries(stateTotals)) {
                    aggregated[state] = total;
                }

                return aggregated;
            });
            console.log("output :- ", output);



            setAfter_merging_the_Positions(output);
                      
        }

    },[select_Merge_Positions_Popup]);



    const [headers2, setHeaders2] = useState([]);
    useEffect(()=>{
        if(after_merging_the_Positions.length > 0){
            const formattedData = Object.keys(after_merging_the_Positions[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            setHeaders2(formattedData);
        }
    },[after_merging_the_Positions]);









    return (
        <div>

            <div  style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                <button  onClick={()=>{setSelect_Merge_Positions_Popup(true)}}  style={{backgroundColor:"gray", padding:"5px 20px", borderRadius:"5px", color:"white"}}>Merge Positions</button>

                <div>
                    {   
                        after_merging_the_Positions.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={after_merging_the_Positions}    headers={headers2}    filename={"P_Step6_after_merging_the_Positions"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>
                        </div>
                    }
                </div>
            </div>


            {    
                select_Merge_Positions_Popup  &&  ( 
                    <div   className={`mmm-modal-delete-rows`}  style={{width:"75%", height:"90%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>

                        <div  style={{height:"10%", width:"100%", borderBottom:"1px solid white", paddingBottom:"1rem",   display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p  style={{fontSize:"26px"}}>Merged Positions Table</p>
                            <button  onClick={()=>{setSelect_Merge_Positions_Popup(false)}}>    <RxCross2  fontSize={32}/>    </button>
                        </div>



                        <div  style={{width:"100%", height:"80%"}}>

                            <div style={{width:"100%", height:"9%", backgroundColor: "gray", display:"flex", alignItems:"flex-end"}}>
                                        <p className="mmm-cell3"  style={{width:"300px"}}>  Merged Column Name     </p>
                                        <p className="mmm-cell3"  style={{flex:"1"}}>  Positions Merged         </p>      {/* Because of flex:1, it took remaining width */}
                            </div>

                            <div  style={{width:"100%", height:"76%", overflowY:"scroll",}}>
                               
                                        <table style={{ width:"100%", flex:"1", fontSize:"16.35px"}}>

                                            <tbody>
                                                {
                                                    mappingArr.map((obj, index) => (
                                                            <tr key={index}>
                                                                <td  className="mmm-cell3"  style={{width:"300px"}}>        
                                                                    {obj.MergedColumnName}      
                                                                </td>

                                                                <td  className="mmm-cell3"  style={{flex:"1", display:"flex", flexDirection:"column", alignItems:"flex-start", padding:"1rem 0 1rem 12rem", gap:"0.25rem"}}>        
                                                                    {
                                                                        obj.Positions.map((region, index) => (
                                                                            <div key={index}>{region}</div>
                                                                        ))
                                                                    }

                                                                    <button  onClick={()=>{setSelect_Merge_Positions_Popup(false);    setMergePositions_Popup(true);    set_MergedColumn_Name(obj.MergedColumnName)}}  style={{backgroundColor:"green", padding:"0px 20px", borderRadius:"5px", marginTop:"0.5rem"}}>Edit</button>
                                                                </td>
                                                            </tr>
                                                    ))
                                                }
                                            </tbody>

                                        </table>
                            </div>

                            <div    style={{width:"100%", height:"15%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <button    onClick={()=>{setSelect_Merge_Positions_Popup(false);    setMergePositions_Popup(true)}}    style={{backgroundColor:"red", padding:"5px 20px", borderRadius:"5px", fontSize:"20px"}}>Merge Positions</button>
                            </div>
                        </div>


                        <div  style={{height:"6%", width:"100%",   display:"flex", justifyContent:"space-between"}}>
                            {
                                mappingArr.length > 0 
                                ?
                                    <>
                                        <p></p>
                                        <button  onClick={()=>{setSelect_Merge_Positions_Popup(false)}}  style={{width:"16%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px"}}>Save</button>
                                    </>
                                :
                                    <>
                                        <p  style={{color:"orange", fontSize:"18px"}}>*Merge the Positions in order to save</p>
                                        <button  disabled={true}  style={{opacity:"0.6", width:"16%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px"}}>Save</button>
                                    </>
                            }
                        </div>
                    
                    </div>
                )                     
            }

            {
                select_Merge_Positions_Popup    &&    (<div   className={` mmm-overlay-delete-rows `}></div>)
            }







            {    
                mergePositions_Popup  &&  ( 
                    <div   className={`mmm-modal-delete-rows`}  style={{width:"600px", height:"600px"}}>

                        <div  style={{height:"10%", width:"100%", borderBottom:"1px solid white", paddingBottom:"1rem",   display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p  style={{fontSize:"26px"}}>Merge Positions</p>
                            <button  onClick={()=>{setMergePositions_Popup(false);    setSelect_Merge_Positions_Popup(true);}}>    <RxCross2  fontSize={32}/>    </button>
                        </div>

                        <div style={{width:"100%", height:"10%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                <div  style={{width:"50%", position:"relative"}}>
                                    <input    type="text"    value={mergedColumn_Name}    onChange={(e) => {set_MergedColumn_Name(e.target.value)}}      className="mmm-input-field2"   placeholder="Column Name"/>
                                </div>
                                <div  style={{width:"40%"}}>
                                    {
                                        space_Found_in_MC_Name && <p style={{color:"red"}}>Space not allowed</p>
                                    }
                                </div>
                        </div>


                        <div style={{height:"68%", width:"100%", padding:"2rem", overflowY:"scroll"}}>
                            
                            <ul  style={{display:"flex", flexDirection:"column", gap:"0.2rem"}}>
                                {
                                    checkedItems2.map((item, index) => (
                                        item &&
                                            <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                                <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                <p>{uniquePositions_in_Print[index]}</p>
                                            </li>
                                    ))
                                }

                                {
                                    uniquePositions_in_Print.map((item, index) => (

                                        !positionsPicked_Overall.includes(item)    &&
                                            <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                                <input   type="checkbox"   checked={checkedItems2[index]}     onChange={() => handleCheckboxChange2(index)}/>
                                                <p>{item}</p>
                                            </li>
                                    ))
                                }
                            </ul>

                        </div>


                        <div  style={{height:"6%", width:"100%",   display:"flex", justifyContent:"center"}}>
                            <button  onClick={()=>{setMergePositions_Popup(false);    setSelect_Merge_Positions_Popup(true);}}  style={{width:"100%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px",    opacity:space_Found_in_MC_Name ? "0.6" : "1"}}    disabled={space_Found_in_MC_Name}>Save</button>
                        </div>
                
                    </div>
                )                     
            }

            {
                mergePositions_Popup    &&    (<div   className={` mmm-overlay-delete-rows `}  style={{ backdropFilter:"blur(10px)"}}></div>)  
            }

        </div>
    );
}


export default PrintDataStep6;