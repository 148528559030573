import { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { MdOutlineFilterAlt, MdOutlineDelete } from "react-icons/md";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { delete_rows_from_Business_Setup_table_func } from "../../../Upload_Retrieve_DB_Data/ProfileAPI";
import AddNew from "./AddNew";
import "../../../CSS/ComponentsStyling.css";




function BusinessSetupChild({ business_setup_arr,    count,    setCount,    setLoading }) {










    const [searchText, setSearchText] = useState("");
    const filteredArr = business_setup_arr.filter(obj => 
        obj.business.toLowerCase().includes(searchText.toLowerCase()) ||
        obj.category.toLowerCase().includes(searchText.toLowerCase()) ||
        obj.product.toLowerCase().includes(searchText.toLowerCase())
    );



    const [currentPage, setCurrentPage] = useState(1);
    let itemsPerPage = 8;


    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400){
        itemsPerPage=7;
        screen_width = '1200_1400';
    }

    




    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredArr.slice(indexOfFirstItem, indexOfLastItem);   // Items to display on current page


    const totalPages = Math.ceil(filteredArr.length / itemsPerPage);


    const startItem = indexOfFirstItem + 1;                             // For displaying 1-8 of 18
    const endItem = Math.min(indexOfLastItem, filteredArr.length);      // For displaying 1-8 of 18



    const heightX =   itemsPerPage === 8    ?                                currentItems.length===1  ?  "22.3%"  :  currentItems.length===2  ?  "33.4%"  :  currentItems.length===3  ?  "44.5%"  :  currentItems.length===4  ?  "55.6%"  :  currentItems.length===5  ?  "66.7%"  :  currentItems.length===6  ?  "77.8%"   :  currentItems.length===7  ?  "88.9%"  :  "100%"
                                            :    itemsPerPage === 7    &&    currentItems.length===1  ?  "26.2%"  :  currentItems.length===2  ?  "38.5%"  :  currentItems.length===3  ?  "50.8%"  :  currentItems.length===4  ?  "63.1%"  :  currentItems.length===5  ?  "75.4%"  :  currentItems.length===6  ?  "87.65%"  :  "100%";

    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const [isHovered_on_Add_New, setIsHovered_on_Add_New] = useState(false);
    const [isHovered_on_filter, setIsHovered_on_filter] = useState(false);
    const [isHovered_on_del, setIsHovered_on_del] = useState(false);








    const [addNew_btn_Clicked, setAddNew_btn_Clicked] = useState(false);












    const [checkedItems, setCheckedItems] = useState(new Array(business_setup_arr.length).fill(false));
    const [pageWiseSelectionStatus, setPageWiseSelectionStatus] = useState(new Array(totalPages).fill(false));

    const handleCheckboxChange = (index) => {
        const updatedCheckedItems = checkedItems.map((checked, i) =>  i === index   ?   !checked   :   checked);
        setCheckedItems(updatedCheckedItems);
    };

    const handleSelectAll = (currentCheckedStatus) => {

        // if rows are belong to the page range than only update the current pagewise checked status.
        const updatedCheckedList = checkedItems.map((prevCheckedStatus, index) =>    indexOfFirstItem <= index && indexOfLastItem > index  ? currentCheckedStatus  : prevCheckedStatus);
        setCheckedItems(updatedCheckedList);
        
        setPageWiseSelectionStatus((prev) =>
          prev.map((prevCheckedStatus, id) => (id === currentPage - 1 ? currentCheckedStatus : prevCheckedStatus))
        );
    };

    useEffect(() => {
        if(checkedItems.includes(true)){
            const res = business_setup_arr.filter((item, index) => checkedItems[index])
            console.log("Businesses Selected :- ", res);
        }
    }, [checkedItems]);










    const [delRowConfirmation, setDelRowConfirmation] = useState(false);
    function delete_businesses_func(){
        const arrDel = business_setup_arr.filter((item, index) => checkedItems[index])
        console.log("Businesses to be deleted :- ", arrDel);

        delete_rows_from_Business_Setup_table(arrDel)
    }

    const delete_rows_from_Business_Setup_table = async (arrDel) => {   
        setLoading(true);    
        try {
          const res = await delete_rows_from_Business_Setup_table_func(arrDel);          
          console.log("Response of delete_rows_from_Business_Setup_table_func:- ", res);    
          setCount(count+1);
          setDelRowConfirmation(false);
        } 
        catch (error) {
          console.log("Could not Delete rows from DB");
        }
        setLoading(false);
    }







    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", position:"relative"}}>
            


            {
            !addNew_btn_Clicked  
            ? 

                <>
                    <div    style={{ width: "100%", height: "9%",     padding: screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p    className="header-title" style={{ fontWeight: "600" }}>Business Setup</p>
                        <div  className="fontF">DP(Name and Role)</div>
                    </div>



                    <div style={{ width: "100%", height: "12%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        

                        <div    className="search-bar-container"    style={{ borderRadius: "30px", backgroundColor: "white", boxShadow: "5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", display: "flex", alignItems: "center" }}>
                            <GoSearch  fontSize={screen_width === 'default-1536px' ? 22 : 18.3}  color="#27272A" />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                className="search-placeholder"
                                style={{ color: "#6E6E6E", border: "none", outline: "none", background: "transparent", flex: "1", fontSize:screen_width === 'default-1536px' ? "16px" : "13.3px" }}
                            />
                        </div>


                        <div    style={{ display: "flex",    gap:screen_width === 'default-1536px' ? "1rem" : "0.8rem"}}>
                            {/* <button style={{ width: "46px", height: "46px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_filter ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}      onMouseEnter={() => setIsHovered_on_filter(true)}     onMouseLeave={() => setIsHovered_on_filter(false)}>
                                <MdOutlineFilterAlt color="#27272A" fontSize={26} />
                            </button> */}

                            <button    className="delete-icon"    onClick={()=>{setDelRowConfirmation(true)}}   style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_del    ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out' }}      onMouseEnter={() => setIsHovered_on_del(true)}        onMouseLeave={() => setIsHovered_on_del(false)}     disabled={!checkedItems.includes(true)}>
                                    <div  style={{color: !checkedItems.includes(true) ? "#A1A1AA" : "#DC2626"}}>
                                        <MdOutlineDelete   fontSize={screen_width === 'default-1536px' ? 26 : 21.6} />
                                    </div>
                            </button>

                            <div style={{ display: "flex", gap: "1px" }}>
                                <div  style={{ width: "1px",  height:screen_width === 'default-1536px' ? "46px" : "38px",  borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                                <div  style={{ width: "1px",  height:screen_width === 'default-1536px' ? "46px" : "38px",  borderRadius: "2px", backgroundColor: "E5E5E5", boxShadow: "0.5px 0.75px 1px 0px #AEAEC080, -0.2px -0.5px 1px 0px #FFFFFF" }}></div>
                            </div>

                            <button   onClick={()=>{setAddNew_btn_Clicked(true);}}   className="add-new-button"  style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#97DDFF", color: "#171717",  borderRadius: "37px", fontWeight: "500",      boxShadow: isHovered_on_Add_New ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out'}}    onMouseEnter={() => setIsHovered_on_Add_New(true)}     onMouseLeave={() => setIsHovered_on_Add_New(false)}>Add New</button>
                        </div>


                    </div>


                    {
                        business_setup_arr.length === 0
                        ?
                            <div  style={{width:"100%", flex:"1", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"1.2rem", boxShadow: "8px 8px 20px 0px #AEAEC080", borderRadius: "8px"}}>
                                <svg    style={{width:screen_width === 'default-1536px' ? "285px" : "237px"}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                                    <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                                    <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                                    <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                                    <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                                </svg>

                                <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                                    <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "27px" : "24px", color:"#111827", fontWeight:"600"}}>No Saved Business Tags in the DB</p>

                                    <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "16px", color:"#4B5563", fontWeight:"400"}}>Start Uploading Business Tags</p>
                                </div>
                                
                            </div>
                        :  
                            <div style={{ width: "100%", borderRadius: "8px", flex: "1", display: "flex", flexDirection: "column", justifyContent: "flex-start", boxShadow: "8px 8px 20px 0px #AEAEC080" }}>
                                
                                
                                <div    style={{width:"100%", height:"88%",}}>
                                    <table  className="table-font"  style={{width: "100%", height: heightX, backgroundColor: "white", color:"#171717" }}>
                                        <thead  style={{width:"100%"}}>
                                                <tr style={{ backgroundColor: "#E4F1FA", width:"100%"}}>
                                                    <th className="cell2XY"  style={{width:"8%", paddingTop:"5px", borderTopLeftRadius:"8px"}}>    <input    type="checkbox"      onChange={(e) => {handleSelectAll(e.target.checked) }}       className="rounded border-blue-300 h-3.5 w-3.5 text-blue-500 focus:border-blue-500 focus:ring-blue-500"    checked={pageWiseSelectionStatus[currentPage - 1]}     />{" "}      </th>
                                                    <th className="cell2X"   style={{width:"27.5%"}}>Business</th>
                                                    <th className="cell2X"   style={{width:"27.5%"}}>Category</th>
                                                    <th className="cell2X"   style={{width:"27.5%"}}>Product/Brand</th>
                                                    <th                      style={{width:"9.5%", borderBottom:"1px solid #ddd", borderTopRightRadius:"8px"}}>Action</th>
                                                </tr>
                                        </thead>

                                        <tbody  style={{width:"100%"}}>
                                            {
                                                currentItems.map((obj, index) => (
                                                        <tr key={index}  style={{width:"100%"}}>
                                                            <td className="cell2XY"   style={{width:"8%", paddingTop:"5px"}}><input   type="checkbox"   checked={checkedItems[indexOfFirstItem+index]}     onChange={() => handleCheckboxChange(indexOfFirstItem+index)}        className="rounded border-blue-300 h-3.5 w-3.5 text-blue-500 focus:border-blue-500 focus:ring-blue-500"/></td>
                                                            <td className="cell2"     style={{width:"27.5%"}}>{obj.business}</td>
                                                            <td className="cell2"     style={{width:"27.5%"}}>{obj.category}</td>
                                                            <td className="cell2"     style={{width:"27.5%"}}>{obj.product}</td>
                                                            <td                       style={{width:"9.5%", borderBottom:"1px solid #ddd"}}>
                                                                                            <div  style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}><HiOutlineDotsVertical /></div>
                                                            </td>
                                                        </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>


                                <div style={{ width: "100%", height: "12%", backgroundColor: "white", display: "flex", justifyContent: "flex-end", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                                    <div style={{ display: "flex", gap: "2rem",      paddingRight:screen_width === 'default-1536px' ? "2rem" : "1.7rem" }}>
                                        
                                        <p style={{ display: "flex", alignItems: "center",   fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}>
                                            {`${startItem}-${endItem} of ${filteredArr.length}`}
                                        </p>

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <button onClick={handlePrevPage}  disabled={currentPage === 1}            style={{opacity : currentPage===1           ?  "0.3" : "1"}}>         <GoChevronLeft  fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />     </button>
                                            <button onClick={handleNextPage}  disabled={currentPage === totalPages}   style={{opacity : currentPage===totalPages  ?  "0.3" : "1"}}>         <GoChevronRight fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />     </button>
                                        </div>

                                    </div>
                                </div>


                            </div>
                    }
                </>

            :  <AddNew    setAddNew_btn_Clicked={setAddNew_btn_Clicked}    setIsHovered_on_Add_New={setIsHovered_on_Add_New}    business_setup_arr={business_setup_arr}    count={count}    setCount={setCount}    setLoading={setLoading}/>



            }





            {
                                          
                delRowConfirmation  &&  ( 
                    <div   className={` modal-delete-rows`}   style={{width: screen_width === '1200_1400' && "300px"}}>

                        <div style={{display:"flex", flexDirection:"column", gap:"0.5rem"}}>
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "24px" : "20px"}}>     Are you sure?    </p>    
                            <p  style={{fontSize: screen_width === 'default-1536px' ? "15px" : "12px"}}>     The Selected rows will be deleted    </p>    
                        </div>

                        <div   className="discard-business-buttons-container"   style={{display:"flex", justifyContent:"flex-start"}}>
                            <button  onClick={() => { delete_businesses_func()}}         className="discard-business-button"     style={{backgroundColor:"red", borderRadius:"5px", color:"white"}}>Delete</button>
                            <button  onClick={() => { setDelRowConfirmation(false) }}    className="discard-business-button"     style={{backgroundColor:"gray", borderRadius:"5px", color:"white"}}>Cancel</button>
                        </div>                      
                
                    </div>
                )
                                        
            }

            {
                delRowConfirmation    &&    (<div   className={` overlay-delete-rows `}></div>)
            }



        </div>
    );
}

export default BusinessSetupChild;
