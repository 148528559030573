import React, { useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";    
import { useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { HiOutlineLockClosed } from "react-icons/hi2";
import { toast } from 'react-hot-toast';
import './Login-Signup.css';
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';


function Login({  setIsUserAllowed,    setActiveTab  }) {      
  
  
      // LOGIN
      const [formData, setFormData] = useState(  {username: "", password: "", chk: false}  );
      const [showPassword, setShowPassword] = useState(false);
      const [loading, setLoading] = useState(false);
      const [wrong_Username_Pass, set_wrong_Username_Pass] = useState(false);
      const navigate = useNavigate();
  
  
      function updateRealtime(event) {
        setFormData(prevFormData => {                 // Retaining unchanged values as it is  &  Updating changed value 
          return {  ...prevFormData,   [event.target.name]: event.target.type==="checkbox"  ?  event.target.checked   :   event.target.value   } // "...prevFormData" means that copy values of input other fields as it is  +  Update value in current input field where user is currently typing
        });                                                                                                                                      // "[event.target.name]" represents : "event.target.firstName", "event.target.lastName", "event.target.email" etcccc
      }        
      
      
      async function submitHandler(event) {
            event.preventDefault();
            if(formData.password === 'truelift%123'    &&    formData.username === 'truelift'){
                setIsUserAllowed(true);
                navigate("/dashboard");
                setActiveTab('/dashboard');
            }
            else{
                set_wrong_Username_Pass(true);
            }
          
      }



  return (
    
    <div    className={` modal-delete-rows`}    style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:""}}>

        {/* <Link to="/"> 
            <div      style={{color:"", display:"flex", alignItems:"center", fontSize:"17px", gap:"0.3rem", textDecoration: "underline"}}>
                <IoArrowBack />
                <p>back to home</p>
            </div>
        </Link> */}
            
        <div className='login-page-left-section'>
           


          <form onSubmit={submitHandler} className="form-login  flex flex-col w-full">     

            {wrong_Username_Pass  &&  <p style={{color:"red"}}>Please enter valid username and password</p>}       

              <label className='w-full relative'>              
                  <p className='label-font    text-[1.05rem]  mb-1 leading-[1.375rem] tempX'>  Customer Name <sup className='star'>*</sup>  </p> 
                  <FaUser   fontSize="1.4rem"  className='emailIcon'/>
                  <input    type="text"    onChange={updateRealtime}    name="username"    value = {formData.username}      required      placeholder="Enter your Username"      className='pl-14  rounded-[0.5rem]  w-full p-[12px]  input-border'/>
              </label>                                  {/* To connect label with input : We have 2 ways(one is this one, another is which is done in "9-React-Forms")  */}


              <label className='w-full relative mt-4'>
                  <p className='label-font    text-[1.05rem] mb-1 leading-[1.375rem] tempX'>  Password<sup className='star'>*</sup>  </p>
                  <HiOutlineLockClosed    fontSize="1.72rem"  className='passwordIcon'/>
                  <input    type= {showPassword ? ("text") : ("password")}    onChange={updateRealtime}    name="password"    value = {formData.password}      required       placeholder="Enter your Password"      className='pl-14  rounded-[0.5rem] w-full p-[12px] input-border'/>     {/*  if "showPassword" is true, then show typed password as text, if "showPassword" is false , then show typed password as password(dots)  */}
                                  

                  <span className='absolute right-4 top-[38px] cursor-pointer' onClick={() => setShowPassword((prev) => !prev)}>                  {/*  When user clicks on icon, change value of "showPassword"  */}
                      {showPassword ? (<AiOutlineEyeInvisible fontSize={24} fill='#AFB2BF'/>) : (<AiOutlineEye fontSize={24} fill='#AFB2BF'/>)}   {/*  Show icon based on value of "showPassword"  */}
                  </span>


                  <div className='flex  justify-between  mt-2'>
                      <div className='flex  align-center'>
                          <input    type="checkbox"  onChange={updateRealtime}    checked={formData.chk}     name="chk"   id="zx1"/>  {/*  for type="checkbox", we use "checked={}" instead of "value={}"  */}
                          <label    htmlFor='zx1'  className='RememberMe'>Remember me</label>                                               {/*  id="zx1" is linked with label, so that even if I click on label the checkbox gets checked  */}
                      </div>

                  </div>

              </label>


              <button className='custom-btn   rounded-[8px] px-[12px] py-[10px]'  disabled={loading}>   {loading ? 'Loading...' : 'Login'}  </button>

          </form>
                


        </div>


    </div>
  )
}

export default Login;
