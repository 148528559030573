import { useState } from "react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { FiArrowRight } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
import DataSubset_Creation from "./ChildComponents/DataSubset_Creation";





function DataSubset({exp_Name,    fileObjs,    setFileObjs,    runR_For_CreatedSubset,    set_runR_For_CreatedSubset,    subset_Created,    set_subset_Created,    loadingCS,    setLoadingCS,     createSubset,    setCreateSubset,    filenamesArray,    selectedObjID_forSubset,    setSelectedObjID_forSubset,     activeTab,    setActiveTab}) {




    const [currentPage, setCurrentPage] = useState(1);
    let itemsPerPage = 7;



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400){
        screen_width = '1200_1400';
        itemsPerPage = 6;
    }



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = fileObjs.slice(indexOfFirstItem, indexOfLastItem);   // Items to display on current page


    const totalPages = Math.ceil(fileObjs.length / itemsPerPage);


    const startItem = indexOfFirstItem + 1;                             // For displaying 1-8 of 18
    const endItem = Math.min(indexOfLastItem, fileObjs.length);      // For displaying 1-8 of 18




    const heightX = itemsPerPage === 7    ?    currentItems.length===1 ? "24.3%" : currentItems.length===2 ? "36.95%" : currentItems.length===3 ? "49.55%" : currentItems.length===4 ? "62.15%" : currentItems.length===5 ? "74.8%"  :  currentItems.length===6 ? "87.4%" : "100%"
                                          :    currentItems.length===1 ? "27.54%" : currentItems.length===2 ? "42.025%" : currentItems.length===3 ? "56.5%" :  currentItems.length===4 ? "71.025%" : currentItems.length===5 ? "85.5%"  :  "100%"





    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };







    const [file_name_Popup, setFile_name_Popup] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState('');
    const [subset_of_Popup, setSubset_of_Popup] = useState(false);
    const [selectedSubsetof, setSelectedSubsetof] = useState('');
    const [description_Popup, setDescription_Popup] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');










    if (fileObjs.length === 0) {                          
        return (                          
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>

                <div    style={{ width: "100%", height: "9%",     padding:screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p    className="header-title" style={{ fontWeight: "600" }}>Data Subset</p>
                    <div  className="fontF">DP(Name and Role)</div>
                </div>

                <div  style={{width:"100%", flex:"1",  boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px", marginTop:screen_width === 'default-1536px' ? "1.4rem" : "1.2rem",    display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1.5rem"}}>
                    <svg    style={{width:screen_width === 'default-1536px' ? "290px" : "250px",}}     viewBox="0 0 396 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M76.1453 198.284C117.809 198.284 151.584 193.088 151.584 186.678C151.584 180.268 117.809 175.072 76.1453 175.072C34.4819 175.072 0.707092 180.268 0.707092 186.678C0.707092 193.088 34.4819 198.284 76.1453 198.284Z" fill="#DFDFEB"/>
                        <path d="M199.448 200.605C274.715 200.605 335.731 195.409 335.731 189C335.731 182.59 274.715 177.394 199.448 177.394C124.181 177.394 63.1646 182.59 63.1646 189C63.1646 195.409 124.181 200.605 199.448 200.605Z" fill="#DFDFEB"/>
                        <path d="M247.544 17.2266C251.995 17.2266 256.304 15.6646 259.722 12.8132L269.204 4.90096C272.616 2.05386 276.669 0.528076 280.821 0.528076H327.879C332.149 0.528076 335.611 3.99004 335.611 8.25998V17.2259V178.62C335.611 185.989 329.637 191.963 322.268 191.963H87.69C81.9589 191.963 77.3126 187.316 77.3126 181.585V27.6039C77.3126 21.8728 81.9589 17.2266 87.69 17.2266H247.544Z" fill="#4B5563"/>
                        <path d="M77.3133 182.455C77.3133 182.455 93.2776 152.509 94.8744 149.07L138.505 60.1836C139.602 57.8214 141.969 56.3105 144.573 56.3105H306.707C312.602 56.3105 321.127 53.2206 325.652 49.4441L332.195 43.9849C335.607 41.1378 339.66 39.6121 343.812 39.6121H388.595C393.362 39.6121 396.6 44.4543 394.778 48.8598L340.626 179.833C337.591 187.174 330.43 191.962 322.486 191.962H80.2413C78.6623 191.962 77.2039 190.61 77.2047 189.031L77.3133 182.455Z" fill="#9CA3AF"/>
                        <path d="M143.261 64.7623L79.2388 189.216" stroke="black" strokeWidth="0.71" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </svg>

                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "28px" : "24.5px", color:"#111827", fontWeight:"600"}}>No Experiment in the session</p>

                        <p    className="fontF"    style={{fontSize:screen_width === 'default-1536px' ? "17.5px" : "15.5px", color:"#4B5563", fontWeight:"400"}}>Either Create or Load an Experiment in session to start creating Subsets</p>
                    </div>
                    
                    <NavLink      onClick={()=>{setActiveTab("/experiment-and-data")}}    to={"/experiment-and-data"}    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontSize:screen_width === 'default-1536px' ? "18px" : "15px", display:"flex", alignItems:"center", gap:"0.4rem"}}     className="footer-button" >
                        Go to Experiment Page
                        <FiArrowRight  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}/>
                    </NavLink>

                </div>

            </div>
        )
    }







    return (

        <div  style={{width:"100%",  height:"100%", display: "flex", flexDirection: "column", position:"relative"}}>


            <div    style={{ width: "100%", height: "9%",     padding:screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p    className="header-title" style={{ fontWeight: "600" }}>Data Subset</p>
                <div  className="fontF">DP(Name and Role)</div>
            </div>


            {
                !createSubset 
                ?
                    <div  style={{width:"100%", flex:"1",      padding:screen_width === 'default-1536px' ? "1.6rem 1.6rem 0 1.6rem" : "1.33rem 1.33rem 0 1.33rem",      boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px", marginTop:screen_width === 'default-1536px' ? "1.4rem" : "1.2rem"}}>
                        
                        <div  style={{width:"100%", height:"10%", display:"flex", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <p  className="fontF"  style={{fontWeight:"600",      fontSize:screen_width === 'default-1536px' ? "22px" : "18.5px",      color:"#171717"}}>{exp_Name}</p>
                        </div>



                        
                        <div  style={{ width: "100%", height:"90%", display:"flex", flexDirection: "column", justifyContent: "flex-start"}}>

                            <div    style={{ width:"100%", height:screen_width === 'default-1536px' ? "88%" : "86%"}}>
                                <table  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "15px" : "13px",      width:"100%",  height:heightX,    backgroundColor: "white", color:"#171717" }}>
                                    <thead  style={{width:"100%"}}>
                                            <tr style={{ backgroundColor: "#E4F1FA", width:"100%" }}>
                                                <th    style={{width:"4%",       padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopLeftRadius:"8px"}}></th>
                                                <th    style={{width:"16%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>File Name</th>
                                                <th    style={{width:"44%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start"}}>Description</th>
                                                <th    style={{width:"16%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start",}}>Subset of</th>
                                                <th    style={{width:"20%",      padding:screen_width === 'default-1536px' ? "15px 15px 15px 0" : "12.5px 12.5px 12.5px 0",      borderBottom:"1px solid #ddd", textAlign:"start", borderTopRightRadius:"8px"}}></th>
                                            </tr>
                                    </thead>

                                    <tbody  style={{width:"100%"}}>
                                        {
                                            currentItems.map((obj, index) => {
                                                    return(
                                                        <tr key={index}  style={{width:"100%"}}>
                                                            <td      style={{width:"4%" , borderBottom:"1px solid #ddd", textAlign:"start", borderLeft:"1px solid #ddd"}}></td>
                                                            <td      style={{width:"16%", borderBottom:"1px solid #ddd", textAlign:"start"}}>
                                                                {
                                                                    screen_width === 'default-1536px'   ?
                                                                                                            obj.file_name.length > 18       ?   <div  style={{display:"flex"}}>
                                                                                                                                                    <p>{obj.file_name.slice(0,16)}</p>
                                                                                                                                                    <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                </div>  
                                                                                                                                            :   obj.file_name
                                                                                                        :
                                                                                                            obj.file_name.length > 14       ?   <div  style={{display:"flex"}}>
                                                                                                                                                    <p>{obj.file_name.slice(0,14)}</p>
                                                                                                                                                    <button    onClick={()=>{setFile_name_Popup(true);  setSelectedFilename(obj.file_name)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                </div>  
                                                                                                                                            :   obj.file_name
                                                                }
                                                        </td>
                                                            <td      style={{width:"44%", borderBottom:"1px solid #ddd", textAlign:"start"}}>    
                                                                {
                                                                    obj.description.length > 50     ?    <div  style={{display:"flex"}}>
                                                                                                            <p>{obj.description.slice(0,48)}</p>
                                                                                                            <button    onClick={()=>{setDescription_Popup(true);  setSelectedDescription(obj.description)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                        </div>  
                                                                                                    :    obj.description
                                                                }
                                                                
                                                            </td>
                                                            <td      style={{width:"16%", borderBottom:"1px solid #ddd", textAlign:"start",}}>
                                                                {
                                                                    obj.subsetOf === null  ||  obj.subsetOf === ""    ?    "NA" 
                                                                                            :    screen_width === 'default-1536px'  ?
                                                                                                                                        obj.subsetOf.length > 20       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                <p>{obj.subsetOf.slice(0,18)}</p>
                                                                                                                                                                                <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subsetOf)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                            </div>  
                                                                                                                                                                        :   obj.subsetOf
                                                                                                                                    :
                                                                                                                                        obj.subsetOf.length > 16       ?   <div  style={{display:"flex"}}>
                                                                                                                                                                                <p>{obj.subsetOf.slice(0,15)}</p>
                                                                                                                                                                                <button    onClick={()=>{setSubset_of_Popup(true);  setSelectedSubsetof(obj.subsetOf)}}    style={{color:"blue"}}> &nbsp;...</button>  
                                                                                                                                                                            </div>  
                                                                                                                                                                        :   obj.subsetOf
                                                                }
                                                            </td>
                                                            <td      style={{width:"20%", borderBottom:"1px solid #ddd", textAlign:"start", borderRight:"1px solid #ddd"}}>
                                                                <button      onClick={()=>{    setSelectedObjID_forSubset(obj.objId);    setCreateSubset(true)    }}    style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2.5px 12px" : "2px 10px",      border:"1.5px solid #1A7AAF", color:"#1A7AAF", fontWeight:"500",      fontSize:screen_width === 'default-1536px' ? "16px" : "14px"}}    className="fontF" >Create Subset</button>
                                                            </td>
                                                        
                                                        </tr>
                                                    );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>


                            <div    style={{ width:"100%",      height:screen_width === 'default-1536px' ? "12%" : "14%",      backgroundColor: "white", display: "flex", justifyContent: "flex-end", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
                                <div style={{ display: "flex", gap: "2rem",      paddingRight:screen_width === 'default-1536px' ? "2rem" : "1.75rem" }}>
                                    
                                    <p style={{ display: "flex", alignItems: "center",    fontSize:screen_width === 'default-1536px' ? "16px" : "14px" }}>
                                        {`${startItem}-${endItem} of ${fileObjs.length}`}
                                    </p>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <button onClick={handlePrevPage}  disabled={currentPage === 1}            style={{opacity : currentPage===1           ?  "0.3" : "1"}}>         <GoChevronLeft  fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                        <button onClick={handleNextPage}  disabled={currentPage === totalPages}   style={{opacity : currentPage===totalPages  ?  "0.3" : "1"}}>         <GoChevronRight fontSize={screen_width === 'default-1536px' ? "24px" : "20px"} />    </button>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                :
                    <DataSubset_Creation    selectedObjID_forSubset={selectedObjID_forSubset}    exp_Name={exp_Name}    fileObjs={fileObjs}    setFileObjs={setFileObjs}    setCreateSubset={setCreateSubset}    runR_For_CreatedSubset={runR_For_CreatedSubset}    set_runR_For_CreatedSubset={set_runR_For_CreatedSubset}    subset_Created={subset_Created}    set_subset_Created={set_subset_Created}        loadingCS={loadingCS}    setLoadingCS={setLoadingCS}    filenamesArray={filenamesArray}/>
            }



            {
                
                file_name_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>File Name</p>
                            <button  onClick={()=>{setFile_name_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedFilename}    </p>    
                  
                
                    </div>
                )
                                        
            }

            {
                file_name_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }


            {
                subset_of_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Subset Of</p>
                            <button  onClick={()=>{setSubset_of_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedSubsetof}    </p>    
                
                    </div>
                )
                                        
            }

            {
                subset_of_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }


            {
                description_Popup  &&  ( 
                    <div   className={` modal-delete-rows`}    style={{width:screen_width === 'default-1536px' ? "360px" : "320px"}}>

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "22px" : "19px",    fontWeight:"600"}}>Description</p>
                            <button  onClick={()=>{setDescription_Popup(false)}}>    <MdClose  fontSize={screen_width === 'default-1536px' ? 26 : 22}  color="#171717"/>                      </button>
                        </div>

                        <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "16px" : "14px", margin:"1rem 0", color:"rbg(64,64,64)"}}>     {selectedDescription}    </p>    
                
                
                    </div>
                )
                                        
            }

            {
                description_Popup    &&    (<div   className={` overlay-delete-rows `}></div>)
            }




    
        </div>
    );
}
  
export default DataSubset;