import { useEffect, useRef, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import TVDataStep1 from "./TVDataSteps/TVDataStep1";
import TVDataStep2 from "./TVDataSteps/TVDataStep2";
import TVDataStep3 from "./TVDataSteps/TVDataStep3";




export default function AccordionTabs({ section, isActiveArr, handleActive,
                                        dimensionsOptions,    data2,    checkedItems_TV,    setCheckedItems_TV,    selected_TV,    setSelected_TV,    aggregatedData,    setAggregatedData,    states,    afterRegionalMapping_arr,    setAfterRegionalMapping_arr,    wideFormatData,    setWideFormatData}) {    



    const contentEl = useRef(null);
    const [active, setActive] = useState(false);           
    const [sectionHeight, setSectionHeight] = useState(0);

    useEffect(() => {                               
        setActive(isActiveArr?.includes(section.id));
    }, [isActiveArr]);                               

    useEffect(() => {                                               
        setSectionHeight(active ? contentEl.current.scrollHeight : 0); 
    }, [active]);                                                   















    return (
        <div style={{width:"100%", border:"2px solid #e1e1e1",  display:"flex", flexDirection:"column"}}>
        
        
            <div>             
                <div   onClick={()=>{handleActive(section.id)}}   className={`bg-opacity-20 transition-[0.3s]`}      style={{backgroundColor:"rgb(34, 50, 84)", color:"white", fontSize:"18px", padding:"18px 24px",    display:"flex", justifyContent:"space-between", alignItems:"flex-start", cursor:"pointer"}}>
                
                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <p>  {section?.TabTitle}  </p>

                        <i  className={`${isActiveArr.includes(section.id)   ?   "rotate-180"   :   "rotate-0"}     transition-all duration-300`}>    {/*  STEP 30-57  :  isActiveArr[] contains section 2's id, so icon will rotate  */}
                            <AiOutlineDown />        
                        </i>
                    </div>

                </div>
            </div>




            <div   ref={contentEl}   style={{height: sectionHeight}}   className={`h-0 overflow-hidden bg-richblack-900 transition-[height] duration-[0.35s] ease-[ease]`}>
                <div className="text-textHead  font-semibold"      style={{display:"flex", flexDirection:"column", gap:"1rem", alignItems:"center", padding:"2rem 0"}}>
                    {
                        section.id === 1    ?   <TVDataStep1    dimensionsOptions={dimensionsOptions}    data2={data2}    checkedItems_TV={checkedItems_TV}    setCheckedItems_TV={setCheckedItems_TV}    selected_TV={selected_TV}    setSelected_TV={setSelected_TV}    handleActive={handleActive}    isActiveArr={isActiveArr}    aggregatedData={aggregatedData}    setAggregatedData={setAggregatedData}/>
                                            :   section.id === 2    ?    <TVDataStep2    aggregatedData={aggregatedData}    states={states}    handleActive={handleActive}    isActiveArr={isActiveArr}    afterRegionalMapping_arr={afterRegionalMapping_arr}    setAfterRegionalMapping_arr={setAfterRegionalMapping_arr}/>
                                                                    :    section.id === 3    &&    <TVDataStep3    afterRegionalMapping_arr={afterRegionalMapping_arr}    wideFormatData={wideFormatData}    setWideFormatData={setWideFormatData}/>
                    }
                </div>
            </div>


        </div>
    )
}