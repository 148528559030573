export const sampleData4 = [
{date_X: '01-01', google_Spend: '737', linkedin_Spend: '223'},
{date_X: '01-02', google_Spend: '729', linkedin_Spend: '300'},
{date_X: '01-03', google_Spend: '456', linkedin_Spend: '217'},
{date_X: '01-04', google_Spend: '727', linkedin_Spend: '239'},
{date_X: '01-05', google_Spend: '710', linkedin_Spend: '256'},
{date_X: '01-06', google_Spend: '605', linkedin_Spend: '219'},
{date_X: '01-07', google_Spend: '559', linkedin_Spend: '335'},
{date_X: '01-08', google_Spend: '751', linkedin_Spend: '226'},
{date_X: '01-09', google_Spend: '766', linkedin_Spend: '456'},
// {date_X: '01-10', google_Spend: '446', linkedin_Spend: '717'},
// {date_X: '01-11', google_Spend: '649', linkedin_Spend: '828'},
// {date_X: '01-12', google_Spend: '611', linkedin_Spend: '853'},
// {date_X: '01-13', google_Spend: '823', linkedin_Spend: '523'},
// {date_X: '01-14', google_Spend: '828', linkedin_Spend: '550'}
];
