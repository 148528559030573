import "../../App.css";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { FiUploadCloud } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';



function UploadData({data,    setData,    data2,    setData2,    data3,    setData3,    data4,    setData4,    file,    setFile,    file2,    setFile2,    file3,    setFile3,    file4,    setFile4,      setDimensionsOptions,    setDimensionsOptions_P,    setDimensionsOptions_R,    setStates,    setSalesData_Dates}) {



    const navigate = useNavigate();  




    const [isDragging, setIsDragging] = useState(false); // Track dragging state
    const [uploadTimestamp, setUploadTimestamp] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileSize, setFileSize] = useState(null);


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setFileSize(selectedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp(formattedTime);

        

        if (selectedFile) {
            Papa.parse(selectedFile, {
                header: true,
                complete: (results) => {
                            
                    const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                        const keys = Object.keys(obj);
                        return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                    });         

                    setData(updatedArray);
                },
            });
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        setFileName(droppedFile.name);
        setFileSize(droppedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp(formattedTime);

        if (droppedFile) {
        Papa.parse(droppedFile, {
            header: true,
            complete: (results) => {
               const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                        const keys = Object.keys(obj);
                        return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                    });         

                    setData(updatedArray);
            },
        });
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true); // Set dragging state for visual feedback
    };

    const handleDragLeave = () => {
        setIsDragging(false); // Reset dragging state
    };



    const handleRemoveFile = (e) => {
        e.stopPropagation();                // This 1 line of code is magical - It ensures that when i click on Remove File button, then "handleBrowseClick function is not called, only "handleRemoveFile" is called

        setFile(null);
        setData([]); // Clear data when file is removed
        setFileName(null);
        setFileSize(null);
    };



    const handleBrowseClick = () => {                   // Trigger file input click when anywhere inside the rectangle is clicked
        if(!file){
        document.getElementById("fileInput").click();
        }
    };



    useEffect(() => {
        if(data.length > 0){
             console.log(data);
            // console.log(uploadTimestamp);
            // console.log(fileName);
            // console.log("file sze in kb :- ",fileSize);
        }
    }, [data]);

















    const [isDragging2, setIsDragging2] = useState(false); // Track dragging state
    const [uploadTimestamp2, setUploadTimestamp2] = useState(null);
    const [fileName2, setFileName2] = useState(null);
    const [fileSize2, setFileSize2] = useState(null);


    const handleFileChange2 = (e) => {
        const selectedFile = e.target.files[0];
        setFile2(selectedFile);
        setFileName2(selectedFile.name);
        setFileSize2(selectedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp2(formattedTime);

        

        if (selectedFile) {
        Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         

                setData2(updatedArray);
            },
        });
        }
    };

    const handleDrop2 = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile2(droppedFile);
        setFileName2(droppedFile.name);
        setFileSize2(droppedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp2(formattedTime);

        if (droppedFile) {
        Papa.parse(droppedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         

                setData2(updatedArray);
            },
        });
        }
    };

    const handleDragOver2 = (e) => {
        e.preventDefault();
        setIsDragging2(true); // Set dragging state for visual feedback
    };

    const handleDragLeave2 = () => {
        setIsDragging2(false); // Reset dragging state
    };



    const handleRemoveFile2 = (e) => {
        e.stopPropagation();                // This 1 line of code is magical - It ensures that when i click on Remove File button, then "handleBrowseClick function is not called, only "handleRemoveFile" is called

        setFile2(null);
        setData2([]); // Clear data when file is removed
        setFileName2(null);
        setFileSize2(null);
    };



    const handleBrowseClick2 = () => {                   // Trigger file input click when anywhere inside the rectangle is clicked
        if(!file2){
        document.getElementById("fileInput2").click();
        }
    };



    useEffect(() => {
        if(data2.length > 0){
             console.log(data2);
            // console.log(uploadTimestamp2);
            // console.log(fileName2);
            // console.log("file sze in kb :- ",fileSize2);
        }
    }, [data2]);
















    const [isDragging3, setIsDragging3] = useState(false); // Track dragging state
    const [uploadTimestamp3, setUploadTimestamp3] = useState(null);
    const [fileName3, setFileName3] = useState(null);
    const [fileSize3, setFileSize3] = useState(null);


    const handleFileChange3 = (e) => {
        const selectedFile = e.target.files[0];
        setFile3(selectedFile);
        setFileName3(selectedFile.name);
        setFileSize3(selectedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp3(formattedTime);

        

        if (selectedFile) {
        Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         


                const reorderedData3 = updatedArray.map(item => {  // The purpose of this snippet of code is to make sure that Date comes as a first column
                    const { Date, ...rest } = item;
                    return { Date, ...rest };
                });
                // console.log("reorderedData3 :- ", reorderedData3);


                setData3(reorderedData3);
            },
        });
        }
    };

    const handleDrop3 = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile3(droppedFile);
        setFileName3(droppedFile.name);
        setFileSize3(droppedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp3(formattedTime);

        if (droppedFile) {
        Papa.parse(droppedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         

                
                const reorderedData3 = updatedArray.map(item => {  // The purpose of this snippet of code is to make sure that Date comes as a first column
                    const { Date, ...rest } = item;
                    return { Date, ...rest };
                });
                console.log("reorderedData3 :- ", reorderedData3);


                setData3(reorderedData3);
            },
        });
        }
    };

    const handleDragOver3 = (e) => {
        e.preventDefault();
        setIsDragging3(true); // Set dragging state for visual feedback
    };

    const handleDragLeave3 = () => {
        setIsDragging3(false); // Reset dragging state
    };



    const handleRemoveFile3 = (e) => {
        e.stopPropagation();                // This 1 line of code is magical - It ensures that when i click on Remove File button, then "handleBrowseClick function is not called, only "handleRemoveFile" is called

        setFile3(null);
        setData3([]); // Clear data when file is removed
        setFileName3(null);
        setFileSize3(null);
    };



    const handleBrowseClick3 = () => {                   // Trigger file input click when anywhere inside the rectangle is clicked
        if(!file3){
        document.getElementById("fileInput3").click();
        }
    };



    useEffect(() => {
        if(data3.length > 0){
             console.log(data3);
            // console.log(uploadTimestamp3);
            // console.log(fileName3);
            // console.log("file sze in kb :- ",fileSize3);
        }
    }, [data3]);

















    const [isDragging4, setIsDragging4] = useState(false); // Track dragging state
    const [uploadTimestamp4, setUploadTimestamp4] = useState(null);
    const [fileName4, setFileName4] = useState(null);
    const [fileSize4, setFileSize4] = useState(null);


    const handleFileChange4 = (e) => {
        const selectedFile = e.target.files[0];
        setFile4(selectedFile);
        setFileName4(selectedFile.name);
        setFileSize4(selectedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp4(formattedTime);

        

        if (selectedFile) {
        Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         

                setData4(updatedArray);
            },
        });
        }
    };

    const handleDrop4 = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile4(droppedFile);
        setFileName4(droppedFile.name);
        setFileSize4(droppedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp4(formattedTime);

        if (droppedFile) {
        Papa.parse(droppedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         

                setData4(updatedArray);
            },
        });
        }
    };

    const handleDragOver4 = (e) => {
        e.preventDefault();
        setIsDragging4(true); // Set dragging state for visual feedback
    };

    const handleDragLeave4 = () => {
        setIsDragging4(false); // Reset dragging state
    };



    const handleRemoveFile4 = (e) => {
        e.stopPropagation();                // This 1 line of code is magical - It ensures that when i click on Remove File button, then "handleBrowseClick function is not called, only "handleRemoveFile" is called

        setFile4(null);
        setData4([]); // Clear data when file is removed
        setFileName4(null);
        setFileSize4(null);
    };



    const handleBrowseClick4 = () => {                   // Trigger file input click when anywhere inside the rectangle is clicked
        if(!file4){
        document.getElementById("fileInput4").click();
        }
    };



    useEffect(() => {
        if(data4.length > 0){
             console.log(data4);
            // console.log(uploadTimestamp4);
            // console.log(fileName4);
            // console.log("file sze in kb :- ",fileSize4);
        }
    }, [data4]);















    const [data5, setData5] = useState([]);
    const [file5, setFile5] = useState(null);
    const [isDragging5, setIsDragging5] = useState(false); // Track dragging state
    const [uploadTimestamp5, setUploadTimestamp5] = useState(null);
    const [fileName5, setFileName5] = useState(null);
    const [fileSize5, setFileSize5] = useState(null);


    const handleFileChange5 = (e) => {
        console.log("handleFileChange5 :- ", handleFileChange5)
        const selectedFile = e.target.files[0];
        setFile5(selectedFile);
        setFileName5(selectedFile.name);
        setFileSize5(selectedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp5(formattedTime);

        

        if (selectedFile) {
        Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         

                setData5(updatedArray);
            },
        });
        }
    };

    const handleDrop5 = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile5(droppedFile);
        setFileName5(droppedFile.name);
        setFileSize5(droppedFile.size);

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp5(formattedTime);

        if (droppedFile) {
        Papa.parse(droppedFile, {
            header: true,
            complete: (results) => {
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                    const keys = Object.keys(obj);
                    return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });         

                setData5(updatedArray);
            },
        });
        }
    };

    const handleDragOver5 = (e) => {
        e.preventDefault();
        setIsDragging5(true); // Set dragging state for visual feedback
    };

    const handleDragLeave5 = () => {
        setIsDragging5(false); // Reset dragging state
    };



    const handleRemoveFile5 = (e) => {
        e.stopPropagation();                // This 1 line of code is magical - It ensures that when i click on Remove File button, then "handleBrowseClick function is not called, only "handleRemoveFile" is called

        setFile5(null);
        setData5([]); // Clear data when file is removed
        setFileName5(null);
        setFileSize5(null);
    };



    const handleBrowseClick5 = () => {                   // Trigger file input click when anywhere inside the rectangle is clicked
        if(!file5){
        document.getElementById("fileInput5").click();
        }
    };



    useEffect(() => {
        if(data5.length > 0){
            // console.log(data5);
            // console.log(uploadTimestamp5);
            // console.log(fileName5);
            // console.log("file sze in kb :- ",fileSize5);
        }
    }, [data5]);










    
    useEffect(() => {
        if(data2.length > 0){

            const excludedStrings = ['impressions', 'rat', 'ngrp'];

            const keys = Object.keys(data2[0]);
            const filteredKeys = keys.filter(   key => !excludedStrings.some(excluded => key.toLowerCase().includes(excluded))   );
            // console.log(filteredKeys);
            setDimensionsOptions(filteredKeys);

        }
    }, [data2]);



    useEffect(() => {
        if(data4.length > 0){

            const excludedStrings = ['cost'];

            const keys = Object.keys(data4[0]);
            const filteredKeys = keys.filter(   key => !excludedStrings.some(excluded => key.toLowerCase().includes(excluded))   );
            // console.log(filteredKeys);
            setDimensionsOptions_P(filteredKeys);

        }
    }, [data4]);



    useEffect(() => {
        if(data3.length > 0){

            const excludedStrings = ['cost'];

            const keys = Object.keys(data3[0]);
            const filteredKeys = keys.filter(   key => !excludedStrings.some(excluded => key.toLowerCase().includes(excluded))   );
            // console.log(filteredKeys);
            setDimensionsOptions_R(filteredKeys);

        }
    }, [data3]);



    useEffect(()=>{
        if(data.length > 0){

            const updatedArray = data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                const keys = Object.keys(obj);
                return !(keys.length === 1     &&     index > 0);    // For such 1 empty row, this code parses that row into 1 key only which is "date"
            });                                                      // I wrote logic of index>0 to make sure that atleast 1 proper row exists

            // console.log("updated Sales Array :- ", updatedArray);
              
            const uniqueStates = Array.from(new Set(updatedArray.map(item => item.State)));
            // console.log("uniqueStates :- ", uniqueStates);
            setStates(uniqueStates);



            
            const uniqueDates = [...new Set(data.map(item => item.Date))];
            setSalesData_Dates(uniqueDates);
        }
    },[data]);






    
    return (
        <div style={{width:"100%", height:"100%",    display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div  style={{width:"40%", height:"90%", border:"1px solid gray", display:"flex", flexDirection:"column", gap:"1rem"}}>
                
                
                <div  style={{width:"100%", height:"0%",  display:"flex", flexDirection:"column", alignItems:"center"}}>
                </div>

                <div  style={{width:"100%", height:"15%",  display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p  style={{fontWeight:"bolder"}}>Sales Data</p>
                    <div    className="file-upload"      onDrop={handleDrop}      onDragOver={handleDragOver}      onDragLeave={handleDragLeave}
                            onClick={handleBrowseClick} 
                            style={{width:"60%",  height:"83%",  display: "flex",  flexDirection: "column",  alignItems: "center",  justifyContent: "center",  border: "2px dashed #ccc",  borderRadius: "5px",  backgroundColor: isDragging ? "#f1f1f1" : "white", cursor: isDragging ? "copy" : "pointer"}}
                    >

                        {
                        file  ?  (
                                    <div>
                                        <p>File Selected: {file.name}</p>
                                        <button onClick={handleRemoveFile}  style={{color:"red"}}>Remove File</button>
                                    </div>
                                ) 
                                :  (
                                    <div  style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <input  id="fileInput"  type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange}  style={{display:"none"}}/>
                                    

                                        <div className="grid aspect-square w-12 place-items-center rounded-full"  style={{ backgroundColor: 'rgb(71, 129, 243)'}}>
                                            <FiUploadCloud fontSize={26} color="white"/>
                                        </div>

                                        <p className="max-w-[200px] text-center text-richblack-200"  style={{fontSize:"14px"}}>
                                            Drag and drop the file, or click to{" "}
                                            <span className="font-bold"  style={{ color: 'rgb(71, 129, 243)' }}>Browse</span> a file
                                        </p>

                                    </div>
                                )
                        }

                    </div>
                </div>


                <div  style={{width:"100%", height:"15%",  display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p  style={{fontWeight:"bolder"}}>TV Data</p>
                    <div    className="file-upload"      onDrop={handleDrop2}      onDragOver={handleDragOver2}      onDragLeave={handleDragLeave2}
                            onClick={handleBrowseClick2} 
                            style={{width:"60%",  height:"83%",  display: "flex",  flexDirection: "column",  alignItems: "center",  justifyContent: "center",  border: "2px dashed #ccc",  borderRadius: "5px",  backgroundColor: isDragging2 ? "#f1f1f1" : "white", cursor: isDragging2 ? "copy" : "pointer"}}
                    >

                        {
                        file2  ?  (
                                    <div>
                                        <p>File Selected: {file2.name}</p>
                                        <button onClick={handleRemoveFile2}  style={{color:"red"}}>Remove File</button>
                                    </div>
                                ) 
                                :  (
                                    <div  style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <input  id="fileInput2"  type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange2}  style={{display:"none"}}/>
                                    

                                        <div className="grid aspect-square w-12 place-items-center rounded-full"  style={{ backgroundColor: 'rgb(71, 129, 243)'}}>
                                            <FiUploadCloud fontSize={26} color="white"/>
                                        </div>

                                        <p className="max-w-[200px] text-center text-richblack-200"  style={{fontSize:"14px"}}>
                                            Drag and drop the file, or click to{" "}
                                            <span className="font-bold"  style={{ color: 'rgb(71, 129, 243)' }}>Browse</span> a file
                                        </p>

                                    </div>
                                )
                        }

                    </div>
                </div>


                <div  style={{width:"100%", height:"15%",  display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p  style={{fontWeight:"bolder"}}>Radio Data</p>
                    <div    className="file-upload"      onDrop={handleDrop3}      onDragOver={handleDragOver3}      onDragLeave={handleDragLeave3}
                            onClick={handleBrowseClick3} 
                            style={{width:"60%",  height:"83%",  display: "flex",  flexDirection: "column",  alignItems: "center",  justifyContent: "center",  border: "2px dashed #ccc",  borderRadius: "5px",  backgroundColor: isDragging3 ? "#f1f1f1" : "white", cursor: isDragging3 ? "copy" : "pointer"}}
                    >

                        {
                        file3  ?  (
                                    <div>
                                        <p>File Selected: {file3.name}</p>
                                        <button onClick={handleRemoveFile3}  style={{color:"red"}}>Remove File</button>
                                    </div>
                                ) 
                                :  (
                                    <div  style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <input  id="fileInput3"  type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange3}  style={{display:"none"}}/>
                                    

                                        <div className="grid aspect-square w-12 place-items-center rounded-full"  style={{ backgroundColor: 'rgb(71, 129, 243)'}}>
                                            <FiUploadCloud fontSize={26} color="white"/>
                                        </div>

                                        <p className="max-w-[200px] text-center text-richblack-200"  style={{fontSize:"14px"}}>
                                            Drag and drop the file, or click to{" "}
                                            <span className="font-bold"  style={{ color: 'rgb(71, 129, 243)' }}>Browse</span> a file
                                        </p>

                                    </div>
                                )
                        }

                    </div>
                </div>


                <div  style={{width:"100%", height:"15%",  display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p  style={{fontWeight:"bolder"}}>Print Data</p>
                    <div    className="file-upload"      onDrop={handleDrop4}      onDragOver={handleDragOver4}      onDragLeave={handleDragLeave4}
                            onClick={handleBrowseClick4} 
                            style={{width:"60%",  height:"83%",  display: "flex",  flexDirection: "column",  alignItems: "center",  justifyContent: "center",  border: "2px dashed #ccc",  borderRadius: "5px",  backgroundColor: isDragging4 ? "#f1f1f1" : "white", cursor: isDragging4 ? "copy" : "pointer"}}
                    >

                        {
                        file4  ?  (
                                    <div>
                                        <p>File Selected: {file4.name}</p>
                                        <button onClick={handleRemoveFile4}  style={{color:"red"}}>Remove File</button>
                                    </div>
                                ) 
                                :  (
                                    <div  style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <input  id="fileInput4"  type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange4}  style={{display:"none"}}/>
                                    

                                        <div className="grid aspect-square w-12 place-items-center rounded-full"  style={{ backgroundColor: 'rgb(71, 129, 243)'}}>
                                            <FiUploadCloud fontSize={26} color="white"/>
                                        </div>

                                        <p className="max-w-[200px] text-center text-richblack-200"  style={{fontSize:"14px"}}>
                                            Drag and drop the file, or click to{" "}
                                            <span className="font-bold"  style={{ color: 'rgb(71, 129, 243)' }}>Browse</span> a file
                                        </p>

                                    </div>
                                )
                        }

                    </div>
                </div>


                <div  style={{width:"100%", height:"15%",  display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p  style={{fontWeight:"bolder"}}>Digital Data</p>
                    <div    className="file-upload"      onDrop={handleDrop5}      onDragOver={handleDragOver5}      onDragLeave={handleDragLeave5}
                            onClick={handleBrowseClick5} 
                            style={{width:"60%",  height:"83%",  display: "flex",  flexDirection: "column",  alignItems: "center",  justifyContent: "center",  border: "2px dashed #ccc",  borderRadius: "5px",  backgroundColor: isDragging5 ? "#f1f1f1" : "white", cursor: isDragging5 ? "copy" : "pointer"}}
                    >

                        {
                        file5  ?  (
                                    <div>
                                        <p>File Selected: {file5.name}</p>
                                        <button onClick={handleRemoveFile5}  style={{color:"red"}}>Remove File</button>
                                    </div>
                                ) 
                                :  (
                                    <div  style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <input  id="fileInput5"  type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange5}  style={{display:"none"}}/>
                                    

                                        <div className="grid aspect-square w-12 place-items-center rounded-full"  style={{ backgroundColor: 'rgb(71, 129, 243)'}}>
                                            <FiUploadCloud fontSize={26} color="white"/>
                                        </div>

                                        <p className="max-w-[200px] text-center text-richblack-200"  style={{fontSize:"14px"}}>
                                            Drag and drop the file, or click to{" "}
                                            <span className="font-bold"  style={{ color: 'rgb(71, 129, 243)' }}>Browse</span> a file
                                        </p>

                                    </div>
                                )
                        }

                    </div>
                </div>

                {
                    (data.length>0    &&    (data2.length>0 || data3.length>0  ||  data4.length>0  ||  data5.length>0))  &&

                        <div  style={{display:"flex", justifyContent:"center", paddingTop:"10px"}}>
                            {
                                data2.length>0    ?    <button  onClick={()=>{navigate("/data-conversion-steps-tv");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>
                                                  :    data4.length>0    ?    <button  onClick={()=>{navigate("/data-conversion-steps-print");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>
                                                                         :    data3.length>0    &&    <button  onClick={()=>{navigate("/data-conversion-steps-radio");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>
                            }
                        </div>
                }

            </div>
        </div>
    );
}

export default UploadData;