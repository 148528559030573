

import { useState, useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";
import { RxCross2 } from "react-icons/rx";




function PRData_Step1({     data_pr,    Sales_Dates,        data_After_Discarding_ExtraData_PR,    setdata_After_Discarding_ExtraData_PR,        dimensionsOptions_PR,    selected_Dimensions_PR,    set_selected_Dimensions_PR,    selected_metrics_PR,    set_selected_metrics_PR,        aggregatedData_PR,    setAggregatedData_PR    }) {







    useEffect(()=>{
        if(Sales_Dates.length > 0    &&    data_After_Discarding_ExtraData_PR.length === 0){  // If user deleted the existing Print file and re-uploads another one, for that scenario we have to build some logics
            const filteredData = data_pr.filter(item => Sales_Dates.includes(item.Date));
            console.log("Data after discarding additional dates :- ", filteredData);
            setdata_After_Discarding_ExtraData_PR(filteredData);
        }
    },[Sales_Dates]);







    const [selectDimensionPopup, setSelectDimensionPopup] = useState(false);

    const handleCheckboxChange = (index) => {

        const updated_selected_Dimensions_PR = selected_Dimensions_PR.map((checked, i) =>  i === index   ?   !checked   :   checked);
        // console.log("updated_selected_Dimensions_PR :- ", updated_selected_Dimensions_PR);
        set_selected_Dimensions_PR(updated_selected_Dimensions_PR);
    };

    const handleSelectAll = () => {
        const allChecked = selected_Dimensions_PR.every(checked => checked);
        set_selected_Dimensions_PR(new Array(dimensionsOptions_PR.length).fill(!allChecked));
    };

    useEffect(() => {
        if(selected_Dimensions_PR.includes(true)){
            const res = dimensionsOptions_PR.filter((item, index) => selected_Dimensions_PR[index])
            console.log("Dimensions Selected :- ", res);
        }
    }, [selected_Dimensions_PR]);








    const [selectMetricPopup, setSelectMetricPopup] = useState(false);
    
    const handleChange = (event) => {
      const value = event.target.value;

      set_selected_metrics_PR((prevSelected) => {
        if (prevSelected.includes(value)) {
          return prevSelected.filter((item) => item !== value);    // Remove from array if already selected
        } 
        else { 
          return [...prevSelected, value];                         // Add to array if not selected
        }
      });

    };

    useEffect(() => {
        if(selected_metrics_PR.length>0){
            // console.log("Selected Metrics :- ", selected_metrics_PR);
        }
    }, [selected_metrics_PR]);








    const [dimsCols, setDimsCols] = useState([]);
    useEffect(()=>{
        if(!selectDimensionPopup    &&    selected_Dimensions_PR.includes(true)){
            const res = dimensionsOptions_PR.filter((item, index) => selected_Dimensions_PR[index]);
            
            const filteredData = data_After_Discarding_ExtraData_PR.map(obj => {
                return Object.keys(obj).reduce((acc, key) => {
                if (res.includes(key)) {
                    acc[key] = obj[key];
                }
                return acc;
                }, {});
            });
            
            console.log("filteredData :- ", filteredData);
            setDimsCols(filteredData);
        }
    },[selectDimensionPopup]);     // Basically when i will close the pop-up or press save button, then this useEffect will execute
 

    const [metricsCols, setMetricsCols] = useState([]);
    useEffect(()=>{
        if(!selectMetricPopup    &&    selected_metrics_PR.length>0){

            const filteredData = data_After_Discarding_ExtraData_PR.map(obj => {
                return Object.keys(obj).reduce((acc, key) => {
                    if (selected_metrics_PR.some(substring => key.includes(substring))) {
                        acc[key] = obj[key];
                    }
                    return acc;
                }, {});
            });
            
            console.log("filteredData :- ", filteredData);
            setMetricsCols(filteredData);
        }
    },[selectMetricPopup]);











    

    const [headers, setHeaders] = useState([]);
    useEffect(()=>{

        // console.log("dimsCols :- ", dimsCols);
        // console.log("metricsCols :- ", metricsCols);

        if(selected_Dimensions_PR.includes(true)    &&    selected_metrics_PR.length>0){
            const mergedArray = dimsCols.map((obj, index) => {      return { ...obj, ...metricsCols[index] };      });
            // console.log("mergedArray :- ", mergedArray);


            const out = aggregateData(mergedArray);
            console.log("out :-", out);
            setAggregatedData_PR(out);


            const formattedData = Object.keys(out[0]).map(X => ({label:X,  key:X}));
            // console.log("formattedData :- ", formattedData);
            setHeaders(formattedData);
        }

    },[dimsCols, metricsCols]);


    function aggregateData(data) {
        const resultMap = new Map();
      

        // Iterate over the data array
        data.forEach(item => {

            const key = `${item.Date}-${item.State}-${item.Position}`; // Unique key for grouping

            if (!resultMap.has(key)) {                  // Initialize the aggregation object
                const parsedItem = { ...item };

                for (const [key, value] of Object.entries(parsedItem)) {
                    if (key.includes("Cost")) {
                        parsedItem[key] = parseFloat(value) || 0;
                    }
                }
                resultMap.set(key, parsedItem);
            } 
            else {
                const existing = resultMap.get(key);      // Aggregate the "Cost" values
                for (const [key, value] of Object.entries(item)) {
                    if (key.includes("Cost")) {
                        existing[key] = (parseFloat(existing[key]) || 0) + (parseFloat(value) || 0);
                    }
                }
            }
        });

        console.log("resultMap :- ", resultMap);
      


        
        // Convert the map values to an array
        const res = dimensionsOptions_PR.filter((item, index) => selected_Dimensions_PR[index]);
       

        return Array.from(resultMap.values()).map(item => ({

            ...res.reduce((acc, key) => {
                if (key in item) {
                  acc[key] = item[key];
                }
                return acc;
            }, {}),

            ...Object.fromEntries(
                Object.entries(item).filter(([key]) => key.includes("Cost"))    // As of now hardcoded only for Cost
            )
        }));

    }














    return (
        <div  style={{width:"100%",  height:"100%", position:"relative", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div  style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                <button  onClick={()=>{setSelectDimensionPopup(true)}}  style={{width:"200px", backgroundColor:"gray", padding:"5px", borderRadius:"5px", color:"white"}}>Select Dimensions</button>
                <button  onClick={()=>{setSelectMetricPopup(true)}}     style={{width:"200px", backgroundColor:"gray", padding:"5px", borderRadius:"5px", color:"white"}}>Select Metrics</button>
                
                <div>
                    {   
                        (selected_Dimensions_PR.includes(true)    &&    selected_metrics_PR.length>0)  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={aggregatedData_PR}    headers={headers}    filename={"P_Step2_Aggregated_file"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>
                        </div>
                    }
                </div>

            </div>


            
            {    
                selectDimensionPopup  &&  ( 
                    <div   className={`mmm-modal-delete-rows`}  style={{width:"400px", height:"460px"}}>

                        <div  style={{height:"10%", width:"100%", borderBottom:"1px solid white", paddingBottom:"1rem",   display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p  style={{fontSize:"26px"}}>Select Dimensions</p>
                            <button  onClick={()=>{setSelectDimensionPopup(false)}}>    <RxCross2  fontSize={32}/>    </button>
                        </div>

                        <div style={{height:"78%", width:"100%", padding:"2rem"}}>

                            <button onClick={handleSelectAll}  style={{marginBottom:"1rem", backgroundColor:"rgb(34, 50, 84)", padding:"2px 12px", fontSize:"13.5px", borderRadius:"5px"}}>
                                {selected_Dimensions_PR.every(checked => checked) ? 'Deselect All' : 'Select All'}
                            </button>
                            
                            <ul  style={{display:"flex", flexDirection:"column", gap:"0.1rem"}}>
                                {
                                    dimensionsOptions_PR.map((item, index) => (
                                        <li key={index}  style={{display:"flex", gap:"0.6rem"}}>
                                            <input   type="checkbox"   checked={selected_Dimensions_PR[index]}     onChange={() => handleCheckboxChange(index)}/>
                                            <p>{item}</p>
                                        </li>
                                    ))
                                }
                            </ul>


                        </div>

                        <div  style={{height:"6%", width:"100%",   display:"flex", justifyContent:"center"}}>
                            <button  onClick={()=>{setSelectDimensionPopup(false)}}  style={{width:"100%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px"}}>Save</button>
                        </div>
                                
                
                    </div>
                )                     
            }

            {/* {
                selectDimensionPopup    &&    (<div   className={` mmm-overlay-delete-rows `}></div>)
            } */}









            {    
                selectMetricPopup  &&  ( 
                    <div   className={`mmm-modal-delete-rows`}  style={{width:"300px", height:"300px"}}>

                        <div  style={{height:"20%", width:"100%", borderBottom:"1px solid white", paddingBottom:"1rem",   display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p  style={{fontSize:"26px"}}>Select Metrics</p>
                            <button  onClick={()=>{setSelectMetricPopup(false)}}>    <RxCross2  fontSize={32}/>    </button>
                        </div>

                        <div style={{height:"60%", width:"100%", padding:"2rem",    display:"flex", flexDirection:"column", gap:"0.2rem", fontSize:"18px"}}>
                            
                            <label  style={{display:"flex", gap:"0.6rem"}}>
                                <input    type="checkbox"    value="Cost"    checked={selected_metrics_PR.includes("Cost")}     onChange={handleChange}/>
                                <p>Cost</p>
                            </label>

                        </div>

                        <div  style={{height:"20%", width:"100%",   display:"flex", justifyContent:"center"}}>
                            <button  onClick={()=>{setSelectMetricPopup(false)}}  style={{width:"100%",  backgroundColor:"rgb(71, 129, 243)", borderRadius:"5px"}}>Save</button>
                        </div>
                                
                
                    </div>
                )                           
            }

            {/* {
                selectMetricPopup    &&    (<div   className={` mmm-overlay-delete-rows `}></div>)
            } */}

        </div>
    );
  }
  
  export default PRData_Step1;