

export const sampleData3 = [
    {
      "date": "2019-06-17",
      "actual_metric": 989,
      "synthetic_metric": 922.7365
    },
    {
      "date": "2019-06-18",
      "actual_metric": 962,
      "synthetic_metric": 942.3973
    },
    {
      "date": "2019-06-19",
      "actual_metric": 891,
      "synthetic_metric": 968.5631
    },
    {
      "date": "2019-06-20",
      "actual_metric": 899,
      "synthetic_metric": 953.2918
    },
    {
      "date": "2019-06-21",
      "actual_metric": 964,
      "synthetic_metric": 1048.6035
    },
    {
      "date": "2019-06-22",
      "actual_metric": 951,
      "synthetic_metric": 1050.1666
    },
    {
      "date": "2019-06-23",
      "actual_metric": 850,
      "synthetic_metric": 935.8966
    },
    {
      "date": "2019-06-24",
      "actual_metric": 874,
      "synthetic_metric": 941.4292
    },
    {
      "date": "2019-06-25",
      "actual_metric": 889,
      "synthetic_metric": 961.4223
    },
    {
      "date": "2019-06-26",
      "actual_metric": 957,
      "synthetic_metric": 999.3789
    },
    {
      "date": "2019-06-27",
      "actual_metric": 845,
      "synthetic_metric": 921.57
    },
    {
      "date": "2019-06-28",
      "actual_metric": 4214,
      "synthetic_metric": 4226.0598
    },
    {
      "date": "2019-06-29",
      "actual_metric": 4007,
      "synthetic_metric": 3969.6056
    },
    {
      "date": "2019-06-30",
      "actual_metric": 3675,
      "synthetic_metric": 3698.286
    },
    {
      "date": "2019-07-01",
      "actual_metric": 3250,
      "synthetic_metric": 3288.758
    },
    {
      "date": "2019-07-02",
      "actual_metric": 3140,
      "synthetic_metric": 3189.0663
    },
    {
      "date": "2019-07-03",
      "actual_metric": 1815,
      "synthetic_metric": 1908.6013
    },
    {
      "date": "2019-07-04",
      "actual_metric": 1212,
      "synthetic_metric": 1347.3627
    },
    {
      "date": "2019-07-05",
      "actual_metric": 1162,
      "synthetic_metric": 1259.802
    },
    {
      "date": "2019-07-06",
      "actual_metric": 1163,
      "synthetic_metric": 1140.4551
    },
    {
      "date": "2019-07-07",
      "actual_metric": 1063,
      "synthetic_metric": 1023.5565
    },
    {
      "date": "2019-07-08",
      "actual_metric": 1195,
      "synthetic_metric": 1208.4946
    },
    {
      "date": "2019-07-09",
      "actual_metric": 1112,
      "synthetic_metric": 1118.9098
    },
    {
      "date": "2019-07-10",
      "actual_metric": 1179,
      "synthetic_metric": 1118.8894
    },
    {
      "date": "2019-07-11",
      "actual_metric": 1126,
      "synthetic_metric": 1134.422
    },
    {
      "date": "2019-07-12",
      "actual_metric": 1208,
      "synthetic_metric": 1250.3277
    },
    {
      "date": "2019-07-13",
      "actual_metric": 1239,
      "synthetic_metric": 1240.6675
    },
    {
      "date": "2019-07-14",
      "actual_metric": 2912,
      "synthetic_metric": 2970.4516
    },
    {
      "date": "2019-07-15",
      "actual_metric": 2427,
      "synthetic_metric": 2358.1695
    },
    {
      "date": "2019-07-16",
      "actual_metric": 2563,
      "synthetic_metric": 2459.46
    },
    {
      "date": "2019-07-17",
      "actual_metric": 2422,
      "synthetic_metric": 2339.0792
    },
    {
      "date": "2019-07-18",
      "actual_metric": 1419,
      "synthetic_metric": 1469.1511
    },
    {
      "date": "2019-07-19",
      "actual_metric": 1399,
      "synthetic_metric": 1411.6717
    },
    {
      "date": "2019-07-20",
      "actual_metric": 1425,
      "synthetic_metric": 1380.5192
    },
    {
      "date": "2019-07-21",
      "actual_metric": 1290,
      "synthetic_metric": 1258.6788
    },
    {
      "date": "2019-07-22",
      "actual_metric": 1929,
      "synthetic_metric": 1926.03
    },
    {
      "date": "2019-07-23",
      "actual_metric": 1683,
      "synthetic_metric": 1643.7619
    },
    {
      "date": "2019-07-24",
      "actual_metric": 1642,
      "synthetic_metric": 1673.5408
    },
    {
      "date": "2019-07-25",
      "actual_metric": 1846,
      "synthetic_metric": 1796.8688
    },
    {
      "date": "2019-07-26",
      "actual_metric": 1360,
      "synthetic_metric": 1532.6294
    },
    {
      "date": "2019-07-27",
      "actual_metric": 1427,
      "synthetic_metric": 1279.4996
    },
    {
      "date": "2019-07-28",
      "actual_metric": 1260,
      "synthetic_metric": 1108.2991
    },
    {
      "date": "2019-07-29",
      "actual_metric": 1264,
      "synthetic_metric": 1157.589
    },
    {
      "date": "2019-07-30",
      "actual_metric": 1196,
      "synthetic_metric": 1139.8227
    },
    {
      "date": "2019-07-31",
      "actual_metric": 1172,
      "synthetic_metric": 1151.309
    },
    {
      "date": "2019-08-01",
      "actual_metric": 1101,
      "synthetic_metric": 1108.407
    },
    {
      "date": "2019-08-02",
      "actual_metric": 1273,
      "synthetic_metric": 1228.7423
    },
    {
      "date": "2019-08-03",
      "actual_metric": 1182,
      "synthetic_metric": 1168.6312
    },
    {
      "date": "2019-08-04",
      "actual_metric": 1138,
      "synthetic_metric": 1075.7403
    },
    {
      "date": "2019-08-05",
      "actual_metric": 1054,
      "synthetic_metric": 1044.8919
    },
    {
      "date": "2019-08-06",
      "actual_metric": 286,
      "synthetic_metric": 401.2678
    },
    {
      "date": "2019-08-07",
      "actual_metric": 283,
      "synthetic_metric": 380.5667
    },
    {
      "date": "2019-08-08",
      "actual_metric": 738,
      "synthetic_metric": 731.8734
    },
    {
      "date": "2019-08-09",
      "actual_metric": 935,
      "synthetic_metric": 895.3065
    },
    {
      "date": "2019-08-10",
      "actual_metric": 896,
      "synthetic_metric": 819.1044
    },
    {
      "date": "2019-08-11",
      "actual_metric": 850,
      "synthetic_metric": 828.7183
    },
    {
      "date": "2019-08-12",
      "actual_metric": 887,
      "synthetic_metric": 771.811
    },
    {
      "date": "2019-08-13",
      "actual_metric": 925,
      "synthetic_metric": 811.9338
    },
    {
      "date": "2019-08-14",
      "actual_metric": 904,
      "synthetic_metric": 812.9155
    },
    {
      "date": "2019-08-15",
      "actual_metric": 772,
      "synthetic_metric": 791.6987
    },
    {
      "date": "2019-08-16",
      "actual_metric": 965,
      "synthetic_metric": 913.9748
    },
    {
      "date": "2019-08-17",
      "actual_metric": 934,
      "synthetic_metric": 897.6224
    },
    {
      "date": "2019-08-18",
      "actual_metric": 899,
      "synthetic_metric": 821.7666
    },
    {
      "date": "2019-08-19",
      "actual_metric": 769,
      "synthetic_metric": 792.725
    },
    {
      "date": "2019-08-20",
      "actual_metric": 763,
      "synthetic_metric": 819.7448
    },
    {
      "date": "2019-08-21",
      "actual_metric": 863,
      "synthetic_metric": 865.434
    },
    {
      "date": "2019-08-22",
      "actual_metric": 888,
      "synthetic_metric": 857.4535
    },
    {
      "date": "2019-08-23",
      "actual_metric": 1043,
      "synthetic_metric": 1015.3343
    },
    {
      "date": "2019-08-24",
      "actual_metric": 942,
      "synthetic_metric": 963.8319
    },
    {
      "date": "2019-08-25",
      "actual_metric": 895,
      "synthetic_metric": 890.1092
    },
    {
      "date": "2019-08-26",
      "actual_metric": 929,
      "synthetic_metric": 934.3064
    },
    {
      "date": "2019-08-27",
      "actual_metric": 899,
      "synthetic_metric": 866.6597
    },
    {
      "date": "2019-08-28",
      "actual_metric": 855,
      "synthetic_metric": 837.5178
    },
    {
      "date": "2019-08-29",
      "actual_metric": 817,
      "synthetic_metric": 720.6441
    },
    {
      "date": "2019-08-30",
      "actual_metric": 950,
      "synthetic_metric": 874.9113
    },
    {
      "date": "2019-08-31",
      "actual_metric": 902,
      "synthetic_metric": 880.6523
    },
    {
      "date": "2019-09-01",
      "actual_metric": 603,
      "synthetic_metric": 727.4911
    },
    {
      "date": "2019-09-02",
      "actual_metric": 691,
      "synthetic_metric": 777.7947
    },
    {
      "date": "2019-09-03",
      "actual_metric": 823,
      "synthetic_metric": 811.9854
    },
    {
      "date": "2019-09-04",
      "actual_metric": 897,
      "synthetic_metric": 811.4786
    },
    {
      "date": "2019-09-05",
      "actual_metric": 815,
      "synthetic_metric": 772.3084
    },
    {
      "date": "2019-09-06",
      "actual_metric": 1000,
      "synthetic_metric": 943.1403
    },
    {
      "date": "2019-09-07",
      "actual_metric": 885,
      "synthetic_metric": 848.5156
    },
    {
      "date": "2019-09-08",
      "actual_metric": 513,
      "synthetic_metric": 826.5013
    },
    {
      "date": "2019-09-09",
      "actual_metric": 536,
      "synthetic_metric": 742.2458
    },
    {
      "date": "2019-09-10",
      "actual_metric": 606,
      "synthetic_metric": 810.5415
    },
    {
      "date": "2019-09-11",
      "actual_metric": 647,
      "synthetic_metric": 825.3746
    },
    {
      "date": "2019-09-12",
      "actual_metric": 723,
      "synthetic_metric": 758.3754
    },
    {
      "date": "2019-09-13",
      "actual_metric": 895,
      "synthetic_metric": 934.3484
    },
    {
      "date": "2019-09-14",
      "actual_metric": 924,
      "synthetic_metric": 934.9266
    },
    {
      "date": "2019-09-15",
      "actual_metric": 911,
      "synthetic_metric": 866.2641
    },
    {
      "date": "2019-09-16",
      "actual_metric": 938,
      "synthetic_metric": 869.8634
    },
    {
      "date": "2019-09-17",
      "actual_metric": 969,
      "synthetic_metric": 897.0357
    },
    {
      "date": "2019-09-18",
      "actual_metric": 931,
      "synthetic_metric": 898.7224
    },
    {
      "date": "2019-09-19",
      "actual_metric": 913,
      "synthetic_metric": 850.345
    },
    {
      "date": "2019-09-20",
      "actual_metric": 1017,
      "synthetic_metric": 1013.85
    },
    {
      "date": "2019-09-21",
      "actual_metric": 844,
      "synthetic_metric": 931.3646
    },
    {
      "date": "2019-09-22",
      "actual_metric": 979,
      "synthetic_metric": 890.109
    },
    {
      "date": "2019-09-23",
      "actual_metric": 1014,
      "synthetic_metric": 878.1054
    },
    {
      "date": "2019-09-24",
      "actual_metric": 993,
      "synthetic_metric": 908.8185
    },
    {
      "date": "2019-09-25",
      "actual_metric": 981,
      "synthetic_metric": 923.9044
    },
    {
      "date": "2019-09-26",
      "actual_metric": 981,
      "synthetic_metric": 930.0417
    },
    {
      "date": "2019-09-27",
      "actual_metric": 1006,
      "synthetic_metric": 892.2811
    },
    {
      "date": "2019-09-28",
      "actual_metric": 1032,
      "synthetic_metric": 805.5286
    },
    {
      "date": "2019-09-29",
      "actual_metric": 1087,
      "synthetic_metric": 924.1573
    },
    {
      "date": "2019-09-30",
      "actual_metric": 1100,
      "synthetic_metric": 917.6285
    },
    {
      "date": "2019-10-01",
      "actual_metric": 1113,
      "synthetic_metric": 959.7219
    },
    {
      "date": "2019-10-02",
      "actual_metric": 1125,
      "synthetic_metric": 1021.5133
    },
    {
      "date": "2019-10-03",
      "actual_metric": 1040,
      "synthetic_metric": 927.2368
    },
    {
      "date": "2019-10-04",
      "actual_metric": 1235,
      "synthetic_metric": 1025.5358
    },
    {
      "date": "2019-10-05",
      "actual_metric": 1211,
      "synthetic_metric": 956.4599
    },
    {
      "date": "2019-10-06",
      "actual_metric": 1080,
      "synthetic_metric": 885.7508
    },
    {
      "date": "2019-10-07",
      "actual_metric": 1020,
      "synthetic_metric": 877.8177
    },
    {
      "date": "2019-10-08",
      "actual_metric": 965,
      "synthetic_metric": 896.5502
    },
    {
      "date": "2019-10-09",
      "actual_metric": 844,
      "synthetic_metric": 873.3711
    },
    {
      "date": "2019-10-10",
      "actual_metric": 905,
      "synthetic_metric": 826.7013
    },
    {
      "date": "2019-10-11",
      "actual_metric": 995,
      "synthetic_metric": 925.8338
    }
];





export const arr1 = [ 
    {    id: 1,    channel: "TV",             share: 32  },
    {    id: 2,    channel: "Print",          share: 18  },
    {    id: 3,    channel: "Search",         share: 16  },
    {    id: 5,    channel: "Social",   share: 20  },
    {    id: 4,    channel: "Affiliates",     share: 14  }];

export const arr = [ 
  {    id: 1,    channel: "TV",             share: 4.7  },
  {    id: 2,    channel: "Print",          share: 3.9  },
  {    id: 3,    channel: "Search",         share: 4.5  },
  {    id: 5,    channel: "Social",   share: 6.5  },
  {    id: 4,    channel: "Affiliates",     share: 5.0  }
];

export const arrX = [ 
  {    id: 5,    channel: "Social",   share: 26  },
  {    id: 4,    channel: "Affiliates",     share: 14  },
  {    id: 1,    channel: "TV",             share: 30  },
  {    id: 3,    channel: "Search",         share: 15  },
  {    id: 2,    channel: "Print",          share: 15  },
];

export const arr2 = [ 
    {    id: 1,    mode: "paid",      share: 19    },
    {    id: 2,    mode: "organic",   share: 81    }];
