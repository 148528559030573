import { Suspense, lazy } from 'react';               
import Loader from "../../../assets/Images/loading.gif";             
const UploadDataSection = lazy(() => import('./UploadDataSection'));             // Updated  :  Component associated with specific route will now load lazily, rather than loading all components at once


function LazyLoading({    setUploadData_btn_Clicked,    business_setup_arr,    count2,    setCount2,    setExperiment_or_data}) {


    return (
        <>
           
            <Suspense fallback={        <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>                <img src={Loader} width="180" height="180"/>            </div>           }>    
                <UploadDataSection    setUploadData_btn_Clicked={setUploadData_btn_Clicked}    business_setup_arr={business_setup_arr}    count2={count2}    setCount2={setCount2}    setExperiment_or_data={setExperiment_or_data}/>       
            </Suspense>

        </>
    );
}

export default LazyLoading;