function PRData_Step2({         aggregatedData_PR,    Sales_states,     afterRegionalMapping_arr_PR,    setAfterRegionalMapping_arr_PR,       Sales_Dates,      uniquePositions_in_PR,    set_uniquePositions_in_PR,      after_Missing_Dates_and_Positions_Treatment_PR,    setAfter_Missing_Dates_and_Positions_Treatment_PR    }) {


    return (
        <div  style={{width:"100%",  height:"100%",  display:"flex", justifyContent:"center", alignItems:"center", fontSize:"24px"}}>
    
    PRData_Step2
    
        </div>
    );
  }
  
  export default PRData_Step2;