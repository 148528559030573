import { useState } from "react";
import { useEffect } from "react";
import "../../CSS/ComponentsStyling.css";
import UploadDataMMM from "./MMM_data_Components/UploadDataMMM";
import ProcessDataMMM from "./MMM_data_Components/ProcessDataMMM";
import TransformDataMMM from "./MMM_data_Components/TransformDataMMM";
import MergeDataMMM from "./MMM_data_Components/MergeDataMMM";



function MMMdata({    setActiveTab,     selected_Tab,    set_selected_Tab,           Sales_states,    Sales_Dates,           data_tv,    data_pr,    data_rd,    data_dg,    data_sl,        wide_Format_Data_TV,    formatted_SalesData,    set_formatted_SalesData,    after_Variables_Aggregation,    final_merged_Dataset,    set_final_merged_Dataset,    items_to_be_merged_arr,    set_items_to_be_merged_arr,

                                                        dimensionsOptions_TV,        selected_Dimensions_TV,    set_selected_Dimensions_TV,    selected_metrics_TV,    set_selected_metrics_TV,    dimsCols_TV,    setDimsCols_TV,    metricsCols_TV,    setMetricsCols_TV,    headers_TV,    setHeaders_TV,    statesPicked_Overall_TV,    set_statesPicked_Overall_TV,    All_metrics_Headers_TV,    set_All_metrics_Headers_TV,    mappingArr,    setMappingArr,    headers_TV_Step2,    set_headers_TV_Step2,    unMappedRegions_TV,    set_unMappedRegions_TV,    headers_TV_Step3,    set_headers_TV_Step3,    unique_Channels_in_TV,    set_unique_Channels_in_TV,
                                                        mapping_Arr_TV_Step_3,    set_mapping_Arr_TV_Step_3,    non_Aggregated_Variables_TV,    set_non_Aggregated_Variables_TV,    Overall_Variables_Picked_for_Aggregation_TV,    set_Overall_Variables_Picked_for_Aggregation_TV,    set_after_Variables_Aggregation,    headers_TV_Step3_,    set_headers_TV_Step3_,    setData_tv,    selected_metrics_TV_raw,    set_selected_metrics_TV_raw,    States_Names_Used,    set_States_Names_Used,    aggregated_Data_TV,    set_Aggregated_Data_TV,    after_Regional_Mapping_arr_TV,    set_After_Regional_Mapping_arr_TV,    set_Wide_Format_Data_TV,
                                                        Variable_Aggregation_Record_TV,    set_Variable_Aggregation_Record_TV,    overAll_Variable_Groups,    set_overAll_Variable_Groups,


                                                        business_setup_arr,        file_tv,    setFile_tv,    fileName_tv,    setFileName_tv,        setDimensionsOptions_TV,
                                                        setData_pr,    file_pr,    setFile_pr,    fileName_pr,    setFileName_pr,        setDimensionsOptions_PR,
                                                        setData_rd,    file_rd,    setFile_rd,    fileName_rd,    setFileName_rd,        setDimensionsOptions_RD,
                                                        setData_dg,    file_dg,    setFile_dg,    fileName_dg,    setFileName_dg,
                                                        setData_sl,    file_sl,    setFile_sl,    fileName_sl,    setFileName_sl,        set_Sales_States,    set_Sales_Dates,

                                                        selected_dummies,    set_selected_dummies,        selected_Transform_Action,    set_selected_Transform_Action
    }) {


    
    useEffect(()=>{
        if(data_sl.length > 0    &&    Sales_Dates.length>0    &&    formatted_SalesData.length === 0){
            const sortedStates = [...Sales_states].sort();     // We are assuming that Dates will be sorted, so we are not sorting them now, if required in future then we will also sort them
            

            const lookup = new Map();
            data_sl.forEach(item => {
                const key = `${item.Date}|${item.State}`;
                lookup.set(key, parseFloat(item.Sales) || 0);
            });
            // console.log("lookup :- ", lookup);


            const output = [];
            Sales_Dates.forEach(date => {
                sortedStates.forEach(state => {
                        
                    const key = `${date}|${state}`;
                    output.push({  Date: date,    State: state,    Sales: lookup.get(key) || 0  });
                      
                });
            });


            console.log("formatted SalesData :- ", output);
            set_formatted_SalesData(output);
        }
    },[Sales_Dates]);


    // useEffect(()=>{
    //     if(formatted_SalesData.length > 0){
    //         console.log("formatted_SalesData :- ", formatted_SalesData);
    //     }
    // },[formatted_SalesData]);



    useEffect(()=>{
        set_upload_Card_Selected('');
        set_process_Card_Selected('');
        set_mergedDataset_Card_Selected('');
    },[selected_Tab]);



 
    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';

    const [upload_Card_Selected, set_upload_Card_Selected] = useState('');
    const [process_Card_Selected, set_process_Card_Selected] = useState('');
    const [mergedDataset_Card_Selected, set_mergedDataset_Card_Selected] = useState('');




    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column", position:"relative"}}>
    

                <div    style={{ width: "100%", height: "9%",     padding: screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p    className="header-title" style={{ fontWeight: "600" }}>{upload_Card_Selected !== ''  ? `Upload ${upload_Card_Selected} Data`
                                                                                                               : process_Card_Selected !== '' ? `Process ${process_Card_Selected} Data` 
                                                                                                                                            : mergedDataset_Card_Selected !== '' ? `${mergedDataset_Card_Selected} Merged Data`
                                                                                                                                                                                 : "Data"}
                                                                                                                                        </p>
                    <div  className="fontF">DP(Name and Role)</div>
                </div>

                
                <div  style={{width:"100%", height:"10%", display:"flex", justifyContent:"flex-start", alignItems:"flex-end", backgroundColor:""}}>
                    <button   onClick={()=>{set_selected_Tab("upload_data") }}      className="experiment-data-tabs"    style={{color: selected_Tab === "upload_data"    ? "#1A7AAF" : "#171717",    fontWeight: selected_Tab === "upload_data"    ? "600" : "400",    flex:1,    maxWidth:"165px",    borderBottom: selected_Tab === "upload_data"    ? "2px solid #1A7AAF" : "2px solid #E5E5E5",    position:'relative'}}>    Upload Data    <span  style={{  position:'absolute',  top:'12%',  right:'0',  bottom:'12%',  width: '1px', backgroundColor: "#E5E5E5"  }}/>    </button>
                    <button   onClick={()=>{set_selected_Tab("process_data")}}      className="experiment-data-tabs"    style={{color: selected_Tab === "process_data"   ? "#1A7AAF" : "#171717",    fontWeight: selected_Tab === "process_data"   ? "600" : "400",    flex:1,    maxWidth:"165px",    borderBottom: selected_Tab === "process_data"   ? "2px solid #1A7AAF" : "2px solid #E5E5E5",    position:'relative'}}>    Process Data   <span  style={{  position:'absolute',  top:'12%',  right:'0',  bottom:'12%',  width: '1px', backgroundColor: "#E5E5E5"  }}/>    </button>
                    <button   onClick={()=>{set_selected_Tab("merge_data")}}        className="experiment-data-tabs"    style={{color: selected_Tab === "merge_data"     ? "#1A7AAF" : "#171717",    fontWeight: selected_Tab === "merge_data"     ? "600" : "400",    flex:1,    maxWidth:"165px",    borderBottom: selected_Tab === "merge_data"     ? "2px solid #1A7AAF" : "2px solid #E5E5E5",    position:'relative'}}>    Merge Data     <span  style={{  position:'absolute',  top:'12%',  right:'0',  bottom:'12%',  width: '1px', backgroundColor: "#E5E5E5"  }}/>    </button>
                    <button   onClick={()=>{set_selected_Tab("transform_data")}}    className="experiment-data-tabs"    style={{color: selected_Tab === "transform_data" ? "#1A7AAF" : "#171717",    fontWeight: selected_Tab === "transform_data" ? "600" : "400",    flex:1,    maxWidth:"165px",    borderBottom: selected_Tab === "transform_data" ? "2px solid #1A7AAF" : "2px solid #E5E5E5"}}>    Transform Data    </button>
                    <div  style={{flex:"1", borderBottom:"1px solid #E5E5E5"}}></div>
                </div>

                <div  style={{width:"100%", height:"81%"}}>
                    {
                        selected_Tab === "upload_data"  ?  <UploadDataMMM      setActiveTab={setActiveTab}       data_tv={data_tv}    data_pr={data_pr}    data_rd={data_rd}    data_dg={data_dg}    data_sl={data_sl}
                        
                                                                                business_setup_arr={business_setup_arr}        setData_tv={setData_tv}    file_tv={file_tv}    setFile_tv={setFile_tv}    fileName_tv={fileName_tv}    setFileName_tv={setFileName_tv}        setDimensionsOptions_TV={setDimensionsOptions_TV}
                                                                                setData_pr={setData_pr}    file_pr={file_pr}    setFile_pr={setFile_pr}    fileName_pr={fileName_pr}    setFileName_pr={setFileName_pr}        setDimensionsOptions_PR={setDimensionsOptions_PR}
                                                                                setData_rd={setData_rd}    file_rd={file_rd}    setFile_rd={setFile_rd}    fileName_rd={fileName_rd}    setFileName_rd={setFileName_rd}        setDimensionsOptions_RD={setDimensionsOptions_RD}
                                                                                setData_dg={setData_dg}    file_dg={file_dg}    setFile_dg={setFile_dg}    fileName_dg={fileName_dg}    setFileName_dg={setFileName_dg}
                                                                                setData_sl={setData_sl}    file_sl={file_sl}    setFile_sl={setFile_sl}    fileName_sl={fileName_sl}    setFileName_sl={setFileName_sl}        set_Sales_States={set_Sales_States}    set_Sales_Dates={set_Sales_Dates}
                                                                                upload_Card_Selected={upload_Card_Selected}    set_upload_Card_Selected={set_upload_Card_Selected}/>

                                                        :   selected_Tab === "process_data"    ?    <ProcessDataMMM     setActiveTab={setActiveTab}    set_selected_Tab={set_selected_Tab}           data_tv={data_tv}    data_pr={data_pr}    data_rd={data_rd}    data_dg={data_dg}    data_sl={data_sl}        wide_Format_Data_TV={wide_Format_Data_TV}
                                                        
                                                                                                                        dimensionsOptions_TV={dimensionsOptions_TV}       Sales_states={Sales_states}    Sales_Dates={Sales_Dates}    selected_Dimensions_TV={selected_Dimensions_TV}    set_selected_Dimensions_TV={set_selected_Dimensions_TV}    selected_metrics_TV={selected_metrics_TV}    set_selected_metrics_TV={set_selected_metrics_TV}    dimsCols_TV={dimsCols_TV}    setDimsCols_TV={setDimsCols_TV}    metricsCols_TV={metricsCols_TV}    setMetricsCols_TV={setMetricsCols_TV}    headers_TV={headers_TV}    setHeaders_TV={setHeaders_TV}    statesPicked_Overall_TV={statesPicked_Overall_TV}    set_statesPicked_Overall_TV={set_statesPicked_Overall_TV}    All_metrics_Headers_TV={All_metrics_Headers_TV}    set_All_metrics_Headers_TV={set_All_metrics_Headers_TV}    mappingArr={mappingArr}    setMappingArr={setMappingArr}    headers_TV_Step2={headers_TV_Step2}    set_headers_TV_Step2={set_headers_TV_Step2}    unMappedRegions_TV={unMappedRegions_TV}    set_unMappedRegions_TV={set_unMappedRegions_TV}    headers_TV_Step3={headers_TV_Step3}    set_headers_TV_Step3={set_headers_TV_Step3}    unique_Channels_in_TV={unique_Channels_in_TV}    set_unique_Channels_in_TV={set_unique_Channels_in_TV}     
                                                                                                                        mapping_Arr_TV_Step_3={mapping_Arr_TV_Step_3}    set_mapping_Arr_TV_Step_3={set_mapping_Arr_TV_Step_3}    non_Aggregated_Variables_TV={non_Aggregated_Variables_TV}    set_non_Aggregated_Variables_TV={set_non_Aggregated_Variables_TV}    Overall_Variables_Picked_for_Aggregation_TV={Overall_Variables_Picked_for_Aggregation_TV}    set_Overall_Variables_Picked_for_Aggregation_TV={set_Overall_Variables_Picked_for_Aggregation_TV}    after_Variables_Aggregation={after_Variables_Aggregation}    set_after_Variables_Aggregation={set_after_Variables_Aggregation}    headers_TV_Step3_={headers_TV_Step3_}    set_headers_TV_Step3_={set_headers_TV_Step3_}    setData_tv={setData_tv}    selected_metrics_TV_raw={selected_metrics_TV_raw}    set_selected_metrics_TV_raw={set_selected_metrics_TV_raw}    States_Names_Used={States_Names_Used}    set_States_Names_Used={set_States_Names_Used}    aggregated_Data_TV={aggregated_Data_TV}    set_Aggregated_Data_TV={set_Aggregated_Data_TV}    after_Regional_Mapping_arr_TV={after_Regional_Mapping_arr_TV}    set_After_Regional_Mapping_arr_TV={set_After_Regional_Mapping_arr_TV}        set_Wide_Format_Data_TV={set_Wide_Format_Data_TV}
                                                                                                                        Variable_Aggregation_Record_TV={Variable_Aggregation_Record_TV}    set_Variable_Aggregation_Record_TV={set_Variable_Aggregation_Record_TV}    overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}
                                                                                                                        process_Card_Selected={process_Card_Selected}    set_process_Card_Selected={set_process_Card_Selected}/> 

                                                                                               :    selected_Tab === "merge_data"   ?    <MergeDataMMM      set_selected_Tab={set_selected_Tab}        data_tv={data_tv}    data_pr={data_pr}    data_rd={data_rd}    data_dg={data_dg}    data_sl={data_sl}      setActiveTab={setActiveTab}    after_Variables_Aggregation={after_Variables_Aggregation}    formatted_SalesData={formatted_SalesData}    final_merged_Dataset={final_merged_Dataset}    set_final_merged_Dataset={set_final_merged_Dataset}    items_to_be_merged_arr={items_to_be_merged_arr}    set_items_to_be_merged_arr={set_items_to_be_merged_arr}
                                                                                                                                                            /> 
                                                                                                                                    :    selected_Tab === "transform_data"    &&    <TransformDataMMM       after_Variables_Aggregation={after_Variables_Aggregation}      setActiveTab={setActiveTab}    set_selected_Tab={set_selected_Tab}    Sales_states={Sales_states}    Sales_Dates={Sales_Dates}    data_tv={data_tv}    data_pr={data_pr}    data_rd={data_rd}    data_dg={data_dg}    data_sl={data_sl}    final_merged_Dataset={final_merged_Dataset}
                                                                                                                                                                                                            overAll_Variable_Groups={overAll_Variable_Groups}    set_overAll_Variable_Groups={set_overAll_Variable_Groups}    selected_dummies={selected_dummies}    set_selected_dummies={set_selected_dummies}        selected_Transform_Action={selected_Transform_Action}    set_selected_Transform_Action={set_selected_Transform_Action}
                                                                                                                                                                                                            mergedDataset_Card_Selected={mergedDataset_Card_Selected}    set_mergedDataset_Card_Selected={set_mergedDataset_Card_Selected}/> 
                    }
                </div>
    
        </div>
    );
  }
  
  export default MMMdata;