import { useState } from "react";
import facebookads from "../../assets/Images/facebookads.svg";
import amazon_ads from "../../assets/Images/amazon_ads.svg";




function DataIntegrations() {





    const [selectedIntegration, setSelectedIntegration] = useState('')


    function handleOnclick() {
        let oauthUrl;

        if(selectedIntegration === 'google_analytics'){
            oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=300076787728-2d5i483mhgahakb17ip3l7lprhsdiug8.apps.googleusercontent.com&redirect_uri=https://integration.truelift.ai/auth/google/callback&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/analytics.readonly&access_type=offline&prompt=consent`;
        }
        else if(selectedIntegration === 'google_ads'){
            oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=300076787728-2d5i483mhgahakb17ip3l7lprhsdiug8.apps.googleusercontent.com&redirect_uri=https://integration.truelift.ai/auth/google/callback&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/adwords&access_type=offline&prompt=consent`;
        }
        else if(selectedIntegration === 'facebook_ads'){
            oauthUrl = `https://www.facebook.com/v13.0/dialog/oauth?client_id=509074538514369&redirect_uri=https://integration.truelift.ai/auth/callback&scope=email,ads_read&config_id=527884523083368&response_type=code`;
        }

        window.location.href = oauthUrl;
    }



    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400)   screen_width = '1200_1400';



    return (
        <div  style={{width:"100%",  height:"100%", display: "flex", flexDirection: "column", justifyContent:"space-between", gap:screen_width === 'default-1536px' ? "22.4px" : "18.56px"}}>


                <div    style={{ width: "100%", height: "9%",     padding:screen_width === 'default-1536px' ? "0 24px" : "0 20px",     background: 'linear-gradient(90.22deg, #8DCCEC 0.19%, #E2F6FF 115.02%)', borderRadius: "8px", boxShadow: "8px 8px 12px 1px #E0E0E0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p    className="header-title" style={{ fontWeight: "600" }}>Data Integrations</p>
                    <div  className="fontF">DP(Name and Role)</div>
                </div>



                <div  style={{width:"100%", flex:"1",    padding: screen_width === 'default-1536px' ? "1.6rem" : "1.33rem",    borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", flexDirection:"column", justifyContent:"flex-start", gap: screen_width === 'default-1536px' ? "1.6rem" : "1.33rem"}}>
                    <p  className="fontF"  style={{fontWeight:"600",      fontSize: screen_width === 'default-1536px' ? "18px" : "16px",      color:"#171717"}}>Please select the channel to integrate</p>
                    
                    <div  style={{width:"100%", flex:"1", display:"flex", justifyContent:"flex-start",      gap:screen_width === 'default-1536px' ? "1.8rem" : "1.5rem"}}>

                    
                        <div    onClick={()=>{setSelectedIntegration('google_analytics')}}    style={{width:screen_width === 'default-1536px' ? "254px" : "212px",      height:screen_width === 'default-1536px' ? "88px" : "73px",      backgroundColor:"#f8f8f8", border:selectedIntegration === 'google_analytics' ?  "2px solid #4FB2E1": "2px solid #D1D5DB", borderRadius:"4px",      padding:screen_width === 'default-1536px' ? "24px" : "20px",      display:"flex", alignItems:"center", justifyContent:"flex-start", position:"relative", cursor:"pointer"}}>
                            <div  style={{position:"absolute",    top:screen_width === 'default-1536px' ? "6px" : "5px",    right:screen_width === 'default-1536px' ? "6px" : "5px"}}>
                                
                                {
                                    selectedIntegration === 'google_analytics'
                                    ?
                                    <svg    style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_290_7069)">
                                        <path d="M9.99935 5.83342C7.69935 5.83342 5.83268 7.70008 5.83268 10.0001C5.83268 12.3001 7.69935 14.1667 9.99935 14.1667C12.2993 14.1667 14.166 12.3001 14.166 10.0001C14.166 7.70008 12.2993 5.83342 9.99935 5.83342ZM9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#247CA8"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_290_7069">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    :
                                    <svg    style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_290_7079)">
                                    <path d="M9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#27272A"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_290_7079">
                                    <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                }

                            </div>
                            <div  style={{display:"flex", gap:screen_width === 'default-1536px' ? "10px" : "8.33px",    alignItems:"center", justifyContent:"center"}}>
                                <svg    style={{width:screen_width === 'default-1536px' ? "34px" : "29px",    height:screen_width === 'default-1536px' ? "34px" : "29px"}}    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_290_7075)">
                                    <path d="M40.0008 34.9942C40.0046 35.647 39.866 36.2941 39.593 36.8985C39.32 37.503 38.9178 38.0529 38.4095 38.517C37.9011 38.981 37.2966 39.3501 36.6304 39.6031C35.9642 39.8561 35.2493 39.988 34.5266 39.9915C34.3003 39.9931 34.074 39.9815 33.8492 39.9569C32.4868 39.7748 31.245 39.149 30.3579 38.1973C29.4707 37.2456 28.9995 36.0337 29.0329 34.7899V5.20109C29.0002 3.9562 29.4729 2.74352 30.3618 1.79173C31.2506 0.839928 32.4943 0.214784 33.8582 0.0341696C34.632 -0.0485597 35.4164 0.0182796 36.1594 0.230254C36.9024 0.442228 37.5871 0.794492 38.1679 1.26368C38.7487 1.73287 39.2125 2.30826 39.5284 2.95168C39.8444 3.5951 40.0052 4.29185 40.0004 4.99571L40.0008 34.9942Z" fill="#F9AB00"/>
                                    <path d="M5.48479 30.0907C6.56958 30.0907 7.63001 30.3812 8.53198 30.9257C9.43395 31.4701 10.137 32.2439 10.5521 33.1492C10.9672 34.0546 11.0758 35.0508 10.8642 36.0119C10.6526 36.973 10.1302 37.8558 9.36313 38.5487C8.59607 39.2416 7.61877 39.7135 6.55483 39.9047C5.49088 40.0959 4.38807 39.9977 3.38585 39.6227C2.38364 39.2477 1.52703 38.6127 0.924355 37.7979C0.321678 36.9831 0 36.0252 0 35.0453C0 33.7312 0.577861 32.471 1.60646 31.5418C2.63506 30.6127 4.03014 30.0907 5.48479 30.0907ZM19.9155 15.0737C18.4515 15.1462 17.0762 15.7305 16.0807 16.7028C15.0852 17.6751 14.5477 18.9593 14.5818 20.2833V33.591C14.5818 37.203 16.3416 39.3949 18.9192 39.8603C19.6263 39.9899 20.3548 39.9924 21.0629 39.8676C21.7711 39.7428 22.4452 39.4932 23.0465 39.133C23.6479 38.7728 24.1648 38.3091 24.5677 37.7684C24.9706 37.2278 25.2516 36.6206 25.3947 35.9818C25.4694 35.6528 25.5061 35.3177 25.5042 34.9818V20.0549C25.5056 19.402 25.3647 18.7552 25.0894 18.1516C24.8142 17.5479 24.4099 16.9991 23.8999 16.5365C23.3898 16.074 22.7839 15.7067 22.1167 15.4557C21.4494 15.2047 20.734 15.0749 20.0113 15.0737H19.9155Z" fill="#E37400"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_290_7075">
                                    <rect width="40" height="40" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Google Analytics</p>
                            </div>
                        </div>

                        <div    onClick={()=>{setSelectedIntegration('google_ads')}}    style={{width:screen_width === 'default-1536px' ? "254px" : "212px",      height:screen_width === 'default-1536px' ? "88px" : "73px",     backgroundColor:"#f8f8f8", border:selectedIntegration === 'google_ads' ?  "2px solid #4FB2E1": "2px solid #D1D5DB", borderRadius:"4px", padding:screen_width === 'default-1536px' ? "24px" : "20px", display:"flex", alignItems:"center", justifyContent:"flex-start", position:"relative", cursor:"pointer"}}>
                            <div  style={{position:"absolute",     top:screen_width === 'default-1536px' ? "6px" : "5px",    right:screen_width === 'default-1536px' ? "6px" : "5px"}}>
                                
                                {
                                    selectedIntegration === 'google_ads'
                                    ?
                                    <svg    style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_290_7069)">
                                        <path d="M9.99935 5.83342C7.69935 5.83342 5.83268 7.70008 5.83268 10.0001C5.83268 12.3001 7.69935 14.1667 9.99935 14.1667C12.2993 14.1667 14.166 12.3001 14.166 10.0001C14.166 7.70008 12.2993 5.83342 9.99935 5.83342ZM9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#247CA8"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_290_7069">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    :
                                    <svg    style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_290_7079)">
                                        <path d="M9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#27272A"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_290_7079">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                }

                            </div>
                            <div  style={{display:"flex", gap:screen_width === 'default-1536px' ? "10px" : "8.33px", alignItems:"center", justifyContent:"center"}}>
                                <svg    style={{width:screen_width === 'default-1536px' ? "40px" : "34px",    height:screen_width === 'default-1536px' ? "40px" : "34px"}}    viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M36 30C36 32.8 33.8 35 31 35C29.1 35 27.5 34 26.6 32.4V32.3L15.7 12.5C15.2 11.7 15 10.9 15 10C15 7.2 17.2 5 20 5C21.9 5 23.5 6 24.4 7.6V7.7L35.3 27.6C35.8 28.3 36 29.1 36 30Z" fill="#0284FE"/>
                                <path d="M13.3992 32.2999C13.7992 31.5999 13.9992 30.7999 13.9992 30C13.9992 27.2 11.7992 25 8.99922 25C7.09922 25 5.49922 26 4.69922 27.5L15.5992 7.69995C15.1992 8.39995 14.9992 9.19995 14.9992 9.99995C14.9992 10.9 15.1992 11.7 15.5992 12.4L19.8992 20.3L13.3992 32.2999Z" fill="#FCBD02"/>
                                <path d="M9 35C11.7614 35 14 32.7614 14 30C14 27.2386 11.7614 25 9 25C6.23858 25 4 27.2386 4 30C4 32.7614 6.23858 35 9 35Z" fill="#03A846"/>
                                </svg>
                                <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Google Ads</p>
                            </div>
                        </div>

                        <div    onClick={()=>{setSelectedIntegration('facebook_ads')}}    style={{width:screen_width === 'default-1536px' ? "254px" : "212px",      height:screen_width === 'default-1536px' ? "88px" : "73px", backgroundColor:"#f8f8f8", border:selectedIntegration === 'facebook_ads' ?  "2px solid #4FB2E1": "2px solid #D1D5DB", borderRadius:"4px", padding:screen_width === 'default-1536px' ? "24px" : "20px", display:"flex", alignItems:"center", justifyContent:"flex-start", position:"relative", cursor:"pointer"}}>
                            <div  style={{position:"absolute",     top:screen_width === 'default-1536px' ? "6px" : "5px",    right:screen_width === 'default-1536px' ? "6px" : "5px"}}>
                                {
                                    selectedIntegration === 'facebook_ads'
                                    ?
                                    <svg    style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_290_7069)">
                                        <path d="M9.99935 5.83342C7.69935 5.83342 5.83268 7.70008 5.83268 10.0001C5.83268 12.3001 7.69935 14.1667 9.99935 14.1667C12.2993 14.1667 14.166 12.3001 14.166 10.0001C14.166 7.70008 12.2993 5.83342 9.99935 5.83342ZM9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#247CA8"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_290_7069">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    :
                                    <svg    style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_290_7079)">
                                        <path d="M9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#27272A"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_290_7079">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                }
                            </div>
                            <div  style={{display:"flex", gap:screen_width === 'default-1536px' ? "10px" : "8.33px", alignItems:"center", justifyContent:"center"}}>
                                <img src={facebookads} style={{width:screen_width === 'default-1536px' ? "40px" : "34px",    height:screen_width === 'default-1536px' ? "40px" : "34px"}}/>
                                <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Facebook Ads</p>
                            </div>
                        </div>

                        <div    onClick={()=>{setSelectedIntegration('amazon_ads')}}    style={{width:screen_width === 'default-1536px' ? "254px" : "212px",      height:screen_width === 'default-1536px' ? "88px" : "73px", backgroundColor:"#f8f8f8", border:selectedIntegration === 'amazon_ads' ?  "2px solid #4FB2E1": "2px solid #D1D5DB", borderRadius:"4px", padding:screen_width === 'default-1536px' ? "24px" : "20px", display:"flex", alignItems:"center", justifyContent:"flex-start", position:"relative", cursor:"pointer"}}>
                            <div  style={{position:"absolute",     top:screen_width === 'default-1536px' ? "6px" : "5px",    right:screen_width === 'default-1536px' ? "6px" : "5px"}}>
                                {
                                    selectedIntegration === 'amazon_ads'
                                    ?
                                    <svg    style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_290_7069)">
                                        <path d="M9.99935 5.83342C7.69935 5.83342 5.83268 7.70008 5.83268 10.0001C5.83268 12.3001 7.69935 14.1667 9.99935 14.1667C12.2993 14.1667 14.166 12.3001 14.166 10.0001C14.166 7.70008 12.2993 5.83342 9.99935 5.83342ZM9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#247CA8"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_290_7069">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    :
                                    <svg   style={{width:screen_width === 'default-1536px' ? "20px" : "16.66px",    height:screen_width === 'default-1536px' ? "20px" : "16.66px"}}    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_290_7079)">
                                        <path d="M9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z" fill="#27272A"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_290_7079">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                }
                            </div>
                            <div  style={{display:"flex", gap:screen_width === 'default-1536px' ? "10px" : "8.33px", alignItems:"center", justifyContent:"center"}}>
                                <img src={amazon_ads} style={{width:screen_width === 'default-1536px' ? "40px" : "34px",    height:screen_width === 'default-1536px' ? "40px" : "34px"}}/>
                                <p  className="roboto-medium"  style={{fontSize:screen_width === 'default-1536px' ? "20px" : "16.66px", color:"#1f1f1f", cursor:"default"}}>Amazon Ads</p>
                            </div>
                        </div>

                    </div>

                </div>



                <div  style={{width:"100%", height:"8.2%",      padding:screen_width === 'default-1536px' ? "12px 24px" : "1px 20px",      borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>

                        <button    onClick={()=>{    handleOnclick()    }}    style={{borderRadius:"7px", padding:screen_width === 'default-1536px' ? "4px 16px" : "3px 13px", backgroundColor:"#1A7AAF", color:"white", fontWeight:"500", fontSize:screen_width === 'default-1536px' ? "18px" : "16.5px",    opacity:selectedIntegration === '' ? "0.6" : "1"}}     className="fontF"   disabled={selectedIntegration === ''}>
                            Connect
                        </button>
                
                </div>
    
        </div>
    );
}

export default DataIntegrations;