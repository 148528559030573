import { useState } from "react";
import { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";




function TVDataStep3({afterRegionalMapping_arr,     wideFormatData,    setWideFormatData}) {


    // console.log("afterRegionalMapping_arr :- ", afterRegionalMapping_arr);


    // All the coding done below this is for Geo Stacking
    
    useEffect(()=>{
        if(afterRegionalMapping_arr.length > 0){
            const uniqueCombinations = [...new Set(      afterRegionalMapping_arr.map(item => `${item.Channel.replace(/ /g, '_')}...${item.Genre.replace(/ /g, '_')}`)      )].sort();;
            console.log("uniqueCombinations :- ", uniqueCombinations);    // It should be equal to total number of channels, if it is more, then it means that for some channel we have more than 1 genre




            const resultMap = {};

            afterRegionalMapping_arr.forEach(item => {
                const { Date, Channel, Genre } = item;
                const stateKeys = Object.keys(item).filter(key => ['AP', 'Assam', 'Bihar', 'Delhi', 'Gujarat', 'Jharkhand', 'Karnataka', 'Kerala', 'Maharashtra', 'MP', 'Odisha', 'PH', 'Rajasthan', 'Tamil Nadu',  'UP', 'WB'].includes(key));

                
                stateKeys.forEach(state => {
                    const value = item[state];
                    const resultKey = `${Channel.replace(/ /g, '_')}...${Genre.replace(/ /g, '_')}`;  // we are replacing " " in Channel and Genre with "_", and also we are joining Channel and Genre with "..."
                    // console.log("resultKey:-", resultKey);


                    if (!resultMap[Date]) {
                        resultMap[Date] = {};
                    }

                    if (!resultMap[Date][state]) {
                        resultMap[Date][state] = { Date, State: state };
                    }

                    resultMap[Date][state][resultKey] = value;
                });
            });
            console.log("resultMap :- ", resultMap);




            
            const res = [];

            Object.entries(resultMap).forEach(([date, states]) => {
                Object.entries(states).forEach(([state, data]) => {
                    
                    uniqueCombinations.forEach(combination => {
                        if (!data[combination]) {
                            data[combination] = 0; // Assign 0 if not present
                        }
                    });

                    // Create the final object in the desired order
                    const orderedResult = { Date: data.Date, State: data.State };
                    uniqueCombinations.forEach(combination => {
                        orderedResult[combination] = data[combination];
                    });


                    res.push(orderedResult);
                });
            });


            console.log(res);
            console.log(res[0].State);
            console.log(typeof(res[0]['Star_Suvarna_Plus...Kar_GEC']));
            console.log(typeof(res[0]['Star_Suvarna...Kar_GEC']));
            



            setWideFormatData(res);
        }
    },[afterRegionalMapping_arr]);





    const [headers3, setHeaders3] = useState([]);
    useEffect(()=>{
        if(wideFormatData.length > 0){
            const formattedData = Object.keys(wideFormatData[0]).map(X => ({label:X,  key:X}));
            console.log("formattedData :- ", formattedData);
            setHeaders3(formattedData);
        }
    },[wideFormatData]);










    return (
        <div>

                <div>
                    {   
                        wideFormatData.length > 0  &&
                        
                        <div  style={{display:"flex", justifyContent:"space-between"}}>
                            <CSVLink  data={wideFormatData}    headers={headers3}    filename={"Geo_Stacked_File"}>
                                <MdOutlineFileDownload  color="black" fontSize={26}   style={{transition: 'transform 0.2s ease-in-out' }}     onMouseEnter={(e) => e.target.style.transform = 'scale(1.10)'}     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}/>    
                            </CSVLink>
                        </div>
                    }
                </div>

        </div>
    );
}

export default TVDataStep3;