import React, { useState } from "react";
import AccordionTabs2 from "./AccordionTabs2";
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";




function DataConversion2({dimensionsOptions_P,      data4,    states,    salesData_Dates,    checkedItems_PR,    setCheckedItems_PR,    selected_PR,    setSelected_PR,    uniquePositions_in_Print,    setuniquePositions_in_Print,      data_After_Discarding_ExtraData,    setdata_After_Discarding_ExtraData,    aggregatedData_P,    setAggregatedData_P,    afterRegionalMapping_arr_P,    setAfterRegionalMapping_arr_P,    after_Missing_Dates_and_Positions_Treatment,    setAfter_Missing_Dates_and_Positions_Treatment,    after_flipping_the_Positions_Horizontally,    setAfter_flipping_the_Positions_Horizontally,    after_merging_the_Positions,    setAfter_merging_the_Positions,    data,    data2,    data3}) {


    
    const navigate = useNavigate();  


    const data_ = [{    id:1,  TabTitle:"Step 1 - Dataset after retaining the dates corresponding to Sales data"},
                   {    id:2,  TabTitle:"Step 2 - Selection of  Dimensions to group and metrics to be aggregated"},
                   {    id:3,  TabTitle:"Step 3 - Regional Mapping"           },
                   {    id:4,  TabTitle:"Step 4 - Missing Dates and Positions Treatment" },
                   {    id:5,  TabTitle:"Step 5 - Flipping the Positions horizontally" },
                   {    id:6,  TabTitle:"Step 6 - Merge Positions(Optional)" }];

    const [isActiveArr, setIsActiveArr] = useState(Array(0));              
    const handleActive = (id) => {                                         
    setIsActiveArr(    !isActiveArr.includes(id)                         
                ?    isActiveArr.concat([id])                      
                :    isActiveArr.filter((e) => e !== id)    );
    }



    return (
        <div  style={{width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:"2rem", paddingTop:"5rem", position:"relative"}}>


            <div  style={{width:"65%", fontSize:"24px", fontWeight:"bold", display:"flex", justifyContent:"space-between"}}>
                <button        onClick={()=>{  data2.length>0  ?  navigate("/data-conversion-steps-tv")  :  navigate("/data-upload")  }}        ><IoMdArrowRoundBack /></button>
                <p>Print Data</p>
                <p></p>
            </div>


            <div  style={{width:"65%", backgroundColor:"#f1f1f1"}}>
                {
                data_.map((section, index) => (
                    <AccordionTabs2    key={index}    section={section}    isActiveArr={isActiveArr}    handleActive={handleActive}
                                       dimensionsOptions_P={dimensionsOptions_P}    data4={data4}    states={states}    salesData_Dates={salesData_Dates}    checkedItems_PR={checkedItems_PR}    setCheckedItems_PR={setCheckedItems_PR}    selected_PR={selected_PR}    setSelected_PR={setSelected_PR}          uniquePositions_in_Print={uniquePositions_in_Print}    setuniquePositions_in_Print={setuniquePositions_in_Print}       data_After_Discarding_ExtraData={data_After_Discarding_ExtraData}    setdata_After_Discarding_ExtraData={setdata_After_Discarding_ExtraData}    aggregatedData_P={aggregatedData_P}    setAggregatedData_P={setAggregatedData_P}    afterRegionalMapping_arr_P={afterRegionalMapping_arr_P}    setAfterRegionalMapping_arr_P={setAfterRegionalMapping_arr_P}    after_Missing_Dates_and_Positions_Treatment={after_Missing_Dates_and_Positions_Treatment}    setAfter_Missing_Dates_and_Positions_Treatment={setAfter_Missing_Dates_and_Positions_Treatment}    after_flipping_the_Positions_Horizontally={after_flipping_the_Positions_Horizontally}    setAfter_flipping_the_Positions_Horizontally={setAfter_flipping_the_Positions_Horizontally}    after_merging_the_Positions={after_merging_the_Positions}    setAfter_merging_the_Positions={setAfter_merging_the_Positions}/>
                ))
                }
            </div>  


            {            
                after_flipping_the_Positions_Horizontally.length>0    &&
                (
                    data3.length>0    ?    <button  onClick={()=>{navigate("/data-conversion-steps-radio");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>//Take to Radio data Transformation
                                      :    data.length>0    &&    <button  onClick={()=>{navigate("/data-conversion-steps-merging");}}  style={{color:"white", backgroundColor:"rgb(34, 50, 84)", padding:"0px 20px 2px 20px", borderRadius:"5px"}}>Next</button>
                )                                                                            
            }


        </div>
    );
}


export default DataConversion2;