

import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select"; 
import Papa from "papaparse";
import { IoMdArrowBack } from "react-icons/io";
import { FiUploadCloud } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { CSVLink } from "react-csv";
import { useNavigate } from 'react-router-dom';




const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '48px', 
        borderRadius: '4px',
        border: '1px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '16px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '12px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};



const customStyles2 = {
    control: (provided, state) => ({
        ...provided,
        height: '40px', 
        borderRadius: '4px',
        border: '1px solid #D1D5DB',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
        color: '#171717',
        fontWeight: '500',
        paddingLeft: '10px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2, // Ensure dropdown options are shown on top of Description
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1A7AAF' : state.isFocused ? '#E5F2F7' : 'white',
        color: state.isSelected ? 'white' : '#171717',
        '&:active': {
            backgroundColor: '#1A7AAF', // Option background when clicked
        },
    }),

};





function MMM_SL_data_Upload({ business_setup_arr,    data_sl,    setData_sl,    file_sl,    setFile_sl,      fileName_sl,    setFileName_sl,        set_Sales_States,    set_Sales_Dates,    set_upload_Card_Selected }) {



    



    const navigate = useNavigate(); 




    let screen_width = "default-1536px"
    const width = window.innerWidth;
    if (width >= 1200 && width <= 1400) screen_width = '1200_1400';




    
    const [business_Val_sl, setBusiness_Val_sl] = useState(null);
    const [businessOptions_sl, setBusinessOptions_sl] = useState([]);
    const [category_Val_sl, setCategory_Val_sl] = useState(null);
    const [categoryOptions_sl, setCategoryOptions_sl] = useState([]);
    const [product_Val_sl, setProduct_Val_sl] = useState(null);
    const [productOptions_sl, setProductOptions_sl] = useState([]);

    function setBusiness_Val_Func_sl(X){    
      setBusiness_Val_sl(X);     
      setProductOptions_sl([]);                 
    }

    useEffect(() => {
      const uniqueBusinesses = [...new Set(business_setup_arr.map(item => item.business))];    // We are retreiving unique business options from business column of business_setup_arr[] aray   (We are using set to filter only unique values)

      const formattedBusinessOptions = uniqueBusinesses.map(business => ({
        value: business,
        label: business
      }));
      
      setBusinessOptions_sl(formattedBusinessOptions);
    }, [business_setup_arr]);



    function setCategory_Val_Func_sl(X){    
      setCategory_Val_sl(X);                      
    }

    useEffect(() => {
      if (business_Val_sl) {
        const filteredCategories = business_setup_arr.filter(item => item.business === business_Val_sl.value);

        const uniqueCategories = [...new Set(filteredCategories.map(item => item.category))];

        const formattedCategoryOptions = uniqueCategories.map(category => ({
          value: category,
          label: category
        }));
        
        setCategoryOptions_sl(formattedCategoryOptions);
        setCategory_Val_sl(null);                          
        setProduct_Val_sl(null);
      }
    }, [business_Val_sl]);



    function setProduct_Val_Func_sl(X){    
      setProduct_Val_sl(X);                      
    }

    useEffect(() => {
      if (category_Val_sl) {
        const filteredProducts = business_setup_arr.filter(item => item.business === business_Val_sl.value    &&    item.category === category_Val_sl.value);
        
        const uniqueProducts = [...new Set(filteredProducts.map(item => item.product))];

        const formattedProductOptions = uniqueProducts.map(product => ({
          value: product,
          label: product
        }));

        setProductOptions_sl(formattedProductOptions);
        setProduct_Val_sl(null);                               
      }
    }, [category_Val_sl]);







    const [uploadData_descriptionX_sl, setUploadData_descriptionX_sl] = useState('');
    function description_ChangeX_sl(event) {   
        if(event.target.value.length <= 200) setUploadData_descriptionX_sl(event.target.value); 
    }





    // const headers = [ {label:'date', key:'date'},            // The key should be same with field's name in object
    //                   {label:'geo', key:'geo'},
    //                   {label:'metric', key:'metric'},
    //                   {label:'testgeo', key:'testgeo'},
    //                   {label:'campaign', key:'campaign'} ];




    const arrayToCSV = (arr) => {               // Function to convert array of objects to CSV string
        const csv = Papa.unparse(arr); 
        return csv;
    };
    const csvToBlob = (csv) => {                // Function to convert CSV string to Blob
        const blob = new Blob([csv], { type: 'text/csv' });
        return blob;
    };
        const blobToFile = (blob, fileName) => {    // Function to convert Blob to File
        const file = new File([blob], fileName, { type: 'text/csv' });
        return file;
    };



    
    const [isHovered_on_delFile_sl, setIsHovered_on_delFile_sl] = useState(false);















    const [isDragging_sl, setIsDragging_sl] = useState(false); // Track dragging state
    const [uploadTimestamp_sl, setUploadTimestamp_sl] = useState(null);
    const [fileSize_sl, setFileSize_sl] = useState(null);
    const [duplicateFileNameError_sl, setDuplicateFileNameError_sl] = useState(false);
    const [fileDataIsEdited_sl, setFileDataIsEdited_sl] = useState(false);



    const handleFileChange_sl = (e) => {
        // console.log("e.target :- ", e.target);
        // console.log("e.target.files[0] :- ", e.target.files[0]);

        const selectedFile = e.target.files[0];
        setFileName_sl(selectedFile.name);
        setFileSize_sl(selectedFile.size / 1024);    // by default the size is in bytes, so i am converting it into bytes, if we want to convert it into mb directly from bytes, then do this :- selectedFile.size / (1024*1024)

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp_sl(formattedTime);

        

        if (selectedFile) {
            Papa.parse(selectedFile, {
                header: true,
                complete: (results) => {
                    // console.log("results.data :- ", results.data)
        
        
                    const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                        const keys = Object.keys(obj);
                        return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                    });                                                      // I wrote logic of index>0 to make sure that atleast 1 proper row exists
        
        
                    setData_sl(updatedArray);

                    const csvString = arrayToCSV(updatedArray);
                    const csvBlob = csvToBlob(csvString);
                    const csvFile = blobToFile(csvBlob, fileName_sl);
                    setFile_sl(csvFile);
                },
            });
        }
    };

    const handleDrop_sl = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFileName_sl(droppedFile.name);
        setFileSize_sl(droppedFile.size / 1024);    // by default the size is in bytes, so i am converting it into bytes, if we want to convert it into mb directly from bytes, then do this :- droppedFile.size / (1024*1024)

        const uploadStartTime = Date.now();
        const formattedTime = new Date(uploadStartTime).toLocaleString();
        setUploadTimestamp_sl(formattedTime);

        if (droppedFile) {
            Papa.parse(droppedFile, {
            header: true,
            complete: (results) => {
                // console.log("results.data :- ", results.data)
    
    
                const updatedArray = results.data.filter((obj, index) => {   // (Suppose in the end of csv file, user clicks enter 3 times then 3 empty rows will created) - Purpose of these 3 lines is to remove those 3 empty lines
                  const keys = Object.keys(obj);
                  return !(keys.length === 1     &&     index > 0);      // For such 1 empty row, this code parses that row into 1 key only which is "date"
                });                                                      // I wrote logic of index>0 to make sure that atleast 1 proper row exists
    
    
                setData_sl(updatedArray);

                const csvString = arrayToCSV(updatedArray);
                const csvBlob = csvToBlob(csvString);
                const csvFile = blobToFile(csvBlob, fileName_sl);
                setFile_sl(csvFile);
              },
            });
        }
    };

    const handleDragOver_sl = (e) => {
        e.preventDefault();
        setIsDragging_sl(true); // Set dragging state for visual feedback
    };

    const handleDragLeave_sl = () => {
        setIsDragging_sl(false); // Reset dragging state
    };

    const handleRemoveFile_sl = (e) => {
        setFile_sl(null);
        setData_sl([]); // Clear data when file is removed
        setFileName_sl(null);
        setFileSize_sl(null);
        setDuplicateFileNameError_sl(false);
        setFileDataIsEdited_sl(false);
    };

    const handleBrowseClick_sl = () => {                   // Trigger file input click when anywhere inside the rectangle is clicked
        if(!file_sl){
            document.getElementById("fileInput").click();
        }
    };

    useEffect(() => {
    if(data_sl.length > 0){
        console.log(data_sl);
        console.log(uploadTimestamp_sl);
        console.log(fileName_sl);
        console.log("file sze in kb :- ",fileSize_sl);
    }
    }, [data_sl]);








    useEffect(()=>{
        if(data_sl.length > 0){
              
            const uniqueStates = Array.from(new Set(data_sl.map(item => item.State)));
            // console.log("uniqueStates :- ", uniqueStates);
            set_Sales_States(uniqueStates);


            const uniqueDates = [...new Set(data_sl.map(item => item.Date))];
            // console.log("uniqueDates :- ", uniqueDates);
            set_Sales_Dates(uniqueDates);
        }
    },[data_sl]);



    






    return (
        <div  style={{width:"100%",  height:"100%", display:"flex", flexDirection:"column"}}>
    
           

            <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>

                <div  style={{width:"100%", height:"9%", display:"flex", alignItems:"center"}}>
                    <button    className="back-navigation-business-setup"   onClick={()=>{    set_upload_Card_Selected('')    }}   style={{color:"#6E6E6E", display:"flex"}}>
                        <IoMdArrowBack  fontSize={screen_width === 'default-1536px' ? 22 : 18.5}  color="#0000EE"/>
                        <span  style={{color:"#0000EE", fontWeight:"400", fontSize:"17px"}}>Sales</span>
                    </button>

                    {/* <p  className="add-new-text"  style={{color:"#6E6E6E"}}>  &nbsp; / &nbsp; Sales</p> */}
                </div>


                <div  style={{width:"100%", height:"91%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                    <div  style={{width:"100%", height:"86%", display:"flex", flexDirection:"column", alignItems:"center",   padding:screen_width === 'default-1536px' ? "1.2rem" : "1.2rem",   boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", borderRadius:"8px",        gap:screen_width === 'default-1536px' ? "1.2rem" : "1.2rem"}}>
                    

                        <div  style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                            <div  style={{width:"31%", position:"relative"}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Business</h2>
                                <Select  options={businessOptions_sl}  value={business_Val_sl}  onChange={setBusiness_Val_Func_sl}    styles={screen_width === 'default-1536px' ? customStyles2 : customStyles2}/>
                            </div>


                            <div  style={{width:"31%", position:"relative"}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Category</h2>
                                <Select  options={categoryOptions_sl}  value={category_Val_sl}  onChange={setCategory_Val_Func_sl}    styles={screen_width === 'default-1536px' ? customStyles2 : customStyles2}/>
                            </div>


                            <div  style={{width:"31%", position:"relative"}}>
                                <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Product</h2>
                                <Select  options={productOptions_sl}  value={product_Val_sl}  onChange={setProduct_Val_Func_sl}    styles={screen_width === 'default-1536px' ? customStyles2 : customStyles2}/>
                            </div>
                        </div>


                        <div style={{width:"100%", height:"18%", position:"relative", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                            <h2  className="input-label" style={{position:"absolute", backgroundColor:"white", zIndex:"1", top:"-9px", left:"10px", color:"#404040"}}>Description</h2>
                            <textarea   onChange={description_ChangeX_sl}  value={uploadData_descriptionX_sl} 
                                        className="textarea-description" style={{width:"100%",  height:"80%",  fontWeight:"500",    border:'1px solid #D1D5DB',    borderRadius:"4px", color:"#171717", outline:"none", fontSize:"14px"}}/>
                            <div  style={{width:"100%", height:"18%", display:"flex", justifyContent:"flex-end"}}>
                                <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "12px" : "10px"}}>{uploadData_descriptionX_sl.length}/200</p>
                            </div>
                        </div>


                        <div  style={{width:"100%", flex:"1", backgroundColor:"#F8F8F8", borderRadius:"4px", padding:"0.9rem 1.4rem 0.8rem 1.4rem"}}>
                            
                            {
                                file_sl    ?   (
                                
                                                <div  style={{width:"100%", height:"84%", display:"flex"}}>
                                                    
                                                    <div  style={{width:"62%", height:"100%"}}>
                                                        <div  style={{width:"100%", height:"100%"}}>
                                                            <div  className="fontF"  style={{width:"100%", height:"10%", color:"#4B5563",    fontSize:screen_width === 'default-1536px' ? "16.5px" : "15.5px",    fontWeight:"600"}}>File Upload</div>
                                                            <div  style={{width:"100%", height:"7%"}}></div>                                           
                                                            <div  style={{width:"100%", height:"83%", backgroundColor:"white", borderRadius:"4px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1"}}>
                                                                <div  style={{width:"100%", height:"60%", display:"flex"}}>
                                                                    
                                                                    <div  style={{ width:screen_width === 'default-1536px' ? "23%" : "24%", height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-end", gap:"0.6rem"}}>
                                                                        <div className="grid aspect-square place-items-center rounded-full"  style={{ width:screen_width === 'default-1536px' ? "48px" : "42px",    backgroundColor: "#1A7AAF" }}>
                                                                            <FiUploadCloud fontSize={ screen_width === 'default-1536px' ? 26 : 22 } color="white"/>
                                                                        </div>
                                                                        <div  className="browse-text"  style={{color:"#1A7AAF", fontWeight:"500"}}>Browse...</div>
                                                                    </div>
                    
                                                                    <div    className="browse-text"    style={{width:screen_width === 'default-1536px' ? "10%" : "6.5%",    height:"100%", fontWeight:"500",  display:"flex", alignItems:"center", justifyContent:"center"}}>or</div>
                    
                                                                    <div  style={{width:screen_width === 'default-1536px' ? "67%" : "69.5%", height:"100%", display:"flex", flexDirection:"column", gap:"4px", justifyContent:"center", alignItems:"flex-start"}}>
                                                                        <div  className="browse-text"  style={{color:"#171717",   fontWeight:"500"}}>Drag and Drop file here</div>
                                                                        <div  className="browse-text"  style={{color:"#6E6E6E",   fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"400"}}>Supported File - CSV (Recommended size: 200Kb)</div>
                                                                    </div>
                                                                    
                                                                </div>
                    
                    
                                                                <div  style={{width:"100%", height:"40%",    padding:screen_width === 'default-1536px' ? "0 2rem 1.4rem 2rem" : "0 1.6rem 1.1rem 1.6rem"}}>
                                                                    <div  style={{width:"100%", height:"100%", display:"flex"}}>
                                                                        
                    
                                                                        <div  style={{width:"90%", height:"100%", borderRight:"1px solid #D1D5DB", display:"flex", paddingRight:"1.6rem"}}>
                                                                            <div  style={{width:"7%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                                                                                <svg    style={{width:screen_width === 'default-1536px' ? "34px" : "29px",   height:screen_width === 'default-1536px' ? "34px" : "29px"}}    viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M4.56616 2.25274C4.56616 1.51301 5.16584 0.91333 5.90558 0.91333H19.6346L24.6574 5.93613V26.3622C24.6574 27.1019 24.0577 27.7016 23.3179 27.7016H5.90558C5.16584 27.7016 4.56616 27.1019 4.56616 26.3622V2.25274Z" fill="#048556"/>
                                                                                    <path d="M18.2646 0.91333L22.8308 5.47951H19.4823C18.8098 5.47951 18.2646 4.93435 18.2646 4.26186V0.91333Z" fill="#85CCAA"/>
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.84937 3.5007C6.84937 3.33258 6.98566 3.19629 7.15378 3.19629H8.82804C8.99616 3.19629 9.13246 3.33258 9.13246 3.5007V4.33783H10.7306C10.8987 4.33783 11.035 4.47413 11.035 4.64225V6.69703C11.035 6.86515 10.8987 7.00144 10.7306 7.00144H7.15378C6.98566 7.00144 6.84937 6.86515 6.84937 6.69703V4.33783V3.5007ZM7.41253 4.33783H8.56929C8.67016 4.33783 8.75194 4.25606 8.75194 4.15519V3.75945C8.75194 3.65858 8.67016 3.5768 8.56929 3.5768H7.41253C7.31166 3.5768 7.22988 3.65858 7.22988 3.75945V4.15519C7.22988 4.25606 7.31166 4.33783 7.41253 4.33783ZM7.41253 4.71835C7.31166 4.71835 7.22988 4.80013 7.22988 4.901V5.29673C7.22988 5.3976 7.31166 5.47938 7.41253 5.47938H8.56929C8.67016 5.47938 8.75194 5.3976 8.75194 5.29673V4.901C8.75194 4.80013 8.67016 4.71835 8.56929 4.71835H7.41253ZM9.13246 4.901C9.13246 4.80013 9.21423 4.71835 9.3151 4.71835H10.4719C10.5727 4.71835 10.6545 4.80013 10.6545 4.901V5.29673C10.6545 5.3976 10.5727 5.47938 10.4719 5.47938H9.3151C9.21423 5.47938 9.13246 5.3976 9.13246 5.29673V4.901ZM7.41253 5.8599C7.31166 5.8599 7.22988 5.94167 7.22988 6.04254V6.43828C7.22988 6.53915 7.31166 6.62093 7.41253 6.62093H8.56929C8.67016 6.62093 8.75194 6.53915 8.75194 6.43828V6.04254C8.75194 5.94167 8.67016 5.8599 8.56929 5.8599H7.41253ZM9.13246 6.04254C9.13246 5.94167 9.21423 5.8599 9.3151 5.8599H10.4719C10.5727 5.8599 10.6545 5.94167 10.6545 6.04254V6.43828C10.6545 6.53915 10.5727 6.62093 10.4719 6.62093H9.3151C9.21423 6.62093 9.13246 6.53915 9.13246 6.43828V6.04254Z" fill="white"/>
                                                                                    <path d="M8.30516 20.0546L8.8358 18.9755H9.72986L8.82242 20.5852L9.75438 22.2217H8.85141L8.30516 21.1226L7.75891 22.2217H6.85815L7.78789 20.5852L6.88269 18.9755H7.77452L8.30516 20.0546ZM10.7934 21.6197H12.1556V22.2217H10.0108V18.9755H10.7934V21.6197ZM14.16 21.3589C14.16 21.2444 14.1192 21.1552 14.0374 21.0914C13.9571 21.0275 13.8152 20.9612 13.6116 20.8929C13.408 20.8245 13.2414 20.7584 13.1121 20.6944C12.6915 20.4878 12.4811 20.204 12.4811 19.8428C12.4811 19.663 12.5332 19.5047 12.6372 19.3678C12.7428 19.2296 12.8915 19.1226 13.0831 19.0468C13.2749 18.9695 13.4904 18.9309 13.7297 18.9309C13.9631 18.9309 14.1719 18.9724 14.3563 19.0557C14.542 19.139 14.6863 19.2578 14.7888 19.4125C14.8913 19.5655 14.9426 19.7409 14.9426 19.9386H14.1623C14.1623 19.8064 14.1214 19.7037 14.0397 19.6309C13.9593 19.5581 13.8501 19.5217 13.7119 19.5217C13.5721 19.5217 13.4614 19.5529 13.3797 19.6154C13.2994 19.6762 13.2593 19.7543 13.2593 19.8495C13.2593 19.9327 13.3039 20.0085 13.393 20.0768C13.4823 20.1438 13.6391 20.2136 13.8635 20.2864C14.0879 20.3578 14.2723 20.4351 14.4164 20.5184C14.7672 20.7205 14.9426 20.9992 14.9426 21.3544C14.9426 21.6384 14.8356 21.8613 14.6216 22.0232C14.4076 22.1853 14.114 22.2663 13.7409 22.2663C13.4778 22.2663 13.2392 22.2195 13.0252 22.1259C12.8127 22.0307 12.6521 21.9014 12.5436 21.738C12.4365 21.573 12.3831 21.3834 12.3831 21.1693H13.1679C13.1679 21.3433 13.2124 21.4719 13.3017 21.5551C13.3923 21.6369 13.5388 21.6777 13.7409 21.6777C13.8702 21.6777 13.972 21.6502 14.0463 21.5952C14.1221 21.5387 14.16 21.46 14.16 21.3589Z" fill="white"/>
                                                                                </svg>
                                                                            </div>
                
                                                                            
                                                                            <div  style={{
                                                                                // width: ASD ? "63%" : "93%",
                                                                                width: "93%", height:"100%", display:"flex", flexDirection:"column",    padding:screen_width === 'default-1536px' ? "6px 0" : "3.4px 0"}}>
                                                                                <div style={{width:"100%", height:"60%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                                                                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "14px" : "12px",   fontWeight:"400"}}>{fileName_sl.length > 34 ? fileName_sl.slice(0, 34) + '...' : fileName_sl}</p>
                                                                                    <p  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "14px" : "12px",   fontWeight:"400"}}>Completed</p>
                                                                                </div>
                                                                                <div style={{width:"100%", height:"40%", display:"flex", alignItems:"flex-end"}}>
                                                                                    <div  style={{width:"100%",    height:screen_width === 'default-1536px' ? "5px" : "3.8px",    backgroundColor:"#4D7C0F", borderRadius:"17px"}}></div>
                                                                                </div>
                                                                            </div>

                                                                            {/* {
                                                                                (ASD)  &&  
                                                                                    <div  style={{width:"30%", display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                                                                                        <button      onClick={()=>{setASD(false)}}      style={{borderRadius:"7px",      padding:screen_width === 'default-1536px' ? "2px 16px" : "1px 13px",      backgroundColor:"#DC2626", color:"white", fontWeight:"600"}}    className="browse-text" >Show Errors</button>
                                                                                    </div>
                                                                            } */}
                                                                        </div>
                    
                                                                        
                                                                        <div  style={{width:"10%", height:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                                                                            <button   onClick={handleRemoveFile_sl}   style={{ width:screen_width === 'default-1536px' ? "40px" : "32px",     height:screen_width === 'default-1536px' ? "40px" : "32px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F3", borderRadius: "50%",     boxShadow: isHovered_on_delFile_sl ? "5px 5px 4px 0px #AEAEC080" : "5px 5px 12px 0px #AEAEC080, -5px -5px 20px 0px #FFFFFF", transition: 'box-shadow 0.2s ease-in-out' }}      onMouseEnter={() => setIsHovered_on_delFile_sl(true)}     onMouseLeave={() => setIsHovered_on_delFile_sl(false)}>
                                                                                    <div  style={{color: isHovered_on_delFile_sl  ?  "gray"  :  "#A1A1AA"}}>
                                                                                        <MdOutlineDelete fontSize={screen_width === 'default-1536px' ? 24 : 20} />
                                                                                    </div>
                                                                            </button>
                                                                        </div>
                                                                        
                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div  style={{width:"2%", height:"100%"}}></div>

                                                                                                                    
                                                    <div  style={{width:"17%", height:"100%"}}>
                                                        <div  style={{width:"100%", height:"100%"}}>
                                                            <div  className="fontF"  style={{width:"100%", height:"10%", color:"#4B5563", fontSize:screen_width === 'default-1536px' ? "16.5px" : "15.5px", fontWeight:"600"}}>View or Edit File</div>
                                                            <div  style={{width:"100%", height:"7%"}}></div>
                                                            <div  onClick={()=>{  
                                                                // setOpen_ViewEdit_Data_Page(true)
                                                                  }}  style={{width:"100%", height:"83%", backgroundColor:"white", borderRadius:"4px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", flexDirection:"column", alignItems:"center",    gap:screen_width === 'default-1536px' ? "1rem" : "0.5rem",    justifyContent:"center", cursor:"pointer"}}>
                                                                <svg    style={{width:screen_width === 'default-1536px' ? "48px" : "42px",   height:screen_width === 'default-1536px' ? "48px" : "42px"}}    viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7.5 3.7C7.5 2.48498 8.48498 1.5 9.7 1.5H32.25L40.5 9.75V43.3C40.5 44.5151 39.5151 45.5 38.3 45.5H9.7C8.48498 45.5 7.5 44.5151 7.5 43.3V3.7Z" fill="#1A7AAF"/>
                                                                    <path d="M30 1.5L37.5 9H32C30.8954 9 30 8.10456 30 7V1.5Z" fill="lavender"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.25 5.75C11.25 5.47386 11.4739 5.25 11.75 5.25H14.5C14.7761 5.25 15 5.47386 15 5.75V7.125H17.625C17.9011 7.125 18.125 7.34886 18.125 7.625V11C18.125 11.2761 17.9011 11.5 17.625 11.5H11.75C11.4739 11.5 11.25 11.2761 11.25 11V7.125V5.75ZM12.175 7.125H14.075C14.2407 7.125 14.375 6.99068 14.375 6.825V6.175C14.375 6.00932 14.2407 5.875 14.075 5.875H12.175C12.0093 5.875 11.875 6.00932 11.875 6.175V6.825C11.875 6.99068 12.0093 7.125 12.175 7.125ZM12.175 7.75C12.0093 7.75 11.875 7.88432 11.875 8.05V8.7C11.875 8.86568 12.0093 9 12.175 9H14.075C14.2407 9 14.375 8.86568 14.375 8.7V8.05C14.375 7.88432 14.2407 7.75 14.075 7.75H12.175ZM15 8.05C15 7.88432 15.1343 7.75 15.3 7.75H17.2C17.3657 7.75 17.5 7.88432 17.5 8.05V8.7C17.5 8.86568 17.3657 9 17.2 9H15.3C15.1343 9 15 8.86568 15 8.7V8.05ZM12.175 9.625C12.0093 9.625 11.875 9.75932 11.875 9.925V10.575C11.875 10.7407 12.0093 10.875 12.175 10.875H14.075C14.2407 10.875 14.375 10.7407 14.375 10.575V9.925C14.375 9.75932 14.2407 9.625 14.075 9.625H12.175ZM15 9.925C15 9.75932 15.1343 9.625 15.3 9.625H17.2C17.3657 9.625 17.5 9.75932 17.5 9.925V10.575C17.5 10.7407 17.3657 10.875 17.2 10.875H15.3C15.1343 10.875 15 10.7407 15 10.575V9.925Z" fill="white"/>
                                                                    <path d="M13.6414 32.9398L14.5129 31.1674H15.9814L14.491 33.8114L16.0217 36.4994H14.5386L13.6414 34.694L12.7441 36.4994H11.2646L12.7917 33.8114L11.3049 31.1674H12.7698L13.6414 32.9398ZM17.7283 35.5106H19.9658V36.4994H16.4429V31.1674H17.7283V35.5106ZM23.258 35.0822C23.258 34.8942 23.191 34.7476 23.0566 34.6428C22.9248 34.5378 22.6916 34.429 22.3572 34.3168C22.0228 34.2044 21.7492 34.0958 21.5368 33.9908C20.846 33.6514 20.5004 33.1852 20.5004 32.592C20.5004 32.2966 20.586 32.0366 20.7568 31.8118C20.9302 31.5848 21.1744 31.409 21.4892 31.2846C21.8042 31.1576 22.1582 31.0942 22.5512 31.0942C22.9346 31.0942 23.2776 31.1624 23.5804 31.2992C23.8854 31.436 24.1224 31.6312 24.2908 31.8852C24.4592 32.1366 24.5434 32.4246 24.5434 32.7494H23.2618C23.2618 32.5322 23.1946 32.3636 23.0604 32.244C22.9284 32.1244 22.749 32.0646 22.522 32.0646C22.2924 32.0646 22.1106 32.1158 21.9764 32.2184C21.8444 32.3184 21.7786 32.4466 21.7786 32.603C21.7786 32.7396 21.8518 32.8642 21.9982 32.9764C22.1448 33.0864 22.4024 33.201 22.771 33.3206C23.1396 33.4378 23.4424 33.5648 23.6792 33.7016C24.2554 34.0336 24.5434 34.4914 24.5434 35.0748C24.5434 35.5412 24.3676 35.9074 24.0162 36.1734C23.6646 36.4396 23.1824 36.5726 22.5696 36.5726C22.1374 36.5726 21.7456 36.4958 21.394 36.342C21.045 36.1856 20.7812 35.9732 20.603 35.7048C20.4272 35.4338 20.3394 35.1224 20.3394 34.7708H21.6284C21.6284 35.0566 21.7016 35.2678 21.8482 35.4044C21.997 35.5388 22.2376 35.6058 22.5696 35.6058C22.782 35.6058 22.9492 35.5606 23.0712 35.4704C23.1958 35.3776 23.258 35.2482 23.258 35.0822Z" fill="white"/>
                                                                </svg>

                                                                <div  style={{display:"flex", flexDirection:"column",    gap:screen_width === 'default-1536px' ? "6px" : "4px",    alignItems:"center"}}>
                                                                    <div  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"500"}}>{fileName_sl.length > 20 ? fileName_sl.slice(0, 20) + '...' : fileName_sl}</div>
                                                                    <div  style={{color:"gray", fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"500"}}>{Math.floor(fileSize_sl / 1000)} Kb</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div  style={{width:"2%", height:"100%"}}></div>

                                                                                                                    
                                                    <div  style={{width:"17%", height:"100%"}}>
                                                        <div  style={{width:"100%", height:"100%"}}>
                                                            <div  className="fontF"  style={{width:"100%", height:"10%", color:"#4B5563", fontSize:screen_width === 'default-1536px' ? "16.5px" : "15.5px", fontWeight:"600"}}>Sample File</div>
                                                            <div  style={{width:"100%", height:"7%"}}></div>
                                                            {/* <CSVLink    */}
                                                            <div     
                                                            // data={sampleData}    headers={headers}    filename={"geo_data"}    
                                                            style={{width:"100%", height:"83%", backgroundColor:"white", borderRadius:"4px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", flexDirection:"column", alignItems:"center",    gap:screen_width === 'default-1536px' ? "1rem" : "0.5rem",    justifyContent:"center"}}>
                                                                <svg    style={{width:screen_width === 'default-1536px' ? "48px" : "42px",   height:screen_width === 'default-1536px' ? "48px" : "42px"}}    viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7.5 3.7C7.5 2.48498 8.48498 1.5 9.7 1.5H32.25L40.5 9.75V43.3C40.5 44.5151 39.5151 45.5 38.3 45.5H9.7C8.48498 45.5 7.5 44.5151 7.5 43.3V3.7Z" fill="#048556"/>
                                                                    <path d="M30 1.5L37.5 9H32C30.8954 9 30 8.10456 30 7V1.5Z" fill="#85CCAA"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.25 5.75C11.25 5.47386 11.4739 5.25 11.75 5.25H14.5C14.7761 5.25 15 5.47386 15 5.75V7.125H17.625C17.9011 7.125 18.125 7.34886 18.125 7.625V11C18.125 11.2761 17.9011 11.5 17.625 11.5H11.75C11.4739 11.5 11.25 11.2761 11.25 11V7.125V5.75ZM12.175 7.125H14.075C14.2407 7.125 14.375 6.99068 14.375 6.825V6.175C14.375 6.00932 14.2407 5.875 14.075 5.875H12.175C12.0093 5.875 11.875 6.00932 11.875 6.175V6.825C11.875 6.99068 12.0093 7.125 12.175 7.125ZM12.175 7.75C12.0093 7.75 11.875 7.88432 11.875 8.05V8.7C11.875 8.86568 12.0093 9 12.175 9H14.075C14.2407 9 14.375 8.86568 14.375 8.7V8.05C14.375 7.88432 14.2407 7.75 14.075 7.75H12.175ZM15 8.05C15 7.88432 15.1343 7.75 15.3 7.75H17.2C17.3657 7.75 17.5 7.88432 17.5 8.05V8.7C17.5 8.86568 17.3657 9 17.2 9H15.3C15.1343 9 15 8.86568 15 8.7V8.05ZM12.175 9.625C12.0093 9.625 11.875 9.75932 11.875 9.925V10.575C11.875 10.7407 12.0093 10.875 12.175 10.875H14.075C14.2407 10.875 14.375 10.7407 14.375 10.575V9.925C14.375 9.75932 14.2407 9.625 14.075 9.625H12.175ZM15 9.925C15 9.75932 15.1343 9.625 15.3 9.625H17.2C17.3657 9.625 17.5 9.75932 17.5 9.925V10.575C17.5 10.7407 17.3657 10.875 17.2 10.875H15.3C15.1343 10.875 15 10.7407 15 10.575V9.925Z" fill="white"/>
                                                                    <path d="M13.6414 32.9398L14.5129 31.1674H15.9814L14.491 33.8114L16.0217 36.4994H14.5386L13.6414 34.694L12.7441 36.4994H11.2646L12.7917 33.8114L11.3049 31.1674H12.7698L13.6414 32.9398ZM17.7283 35.5106H19.9658V36.4994H16.4429V31.1674H17.7283V35.5106ZM23.258 35.0822C23.258 34.8942 23.191 34.7476 23.0566 34.6428C22.9248 34.5378 22.6916 34.429 22.3572 34.3168C22.0228 34.2044 21.7492 34.0958 21.5368 33.9908C20.846 33.6514 20.5004 33.1852 20.5004 32.592C20.5004 32.2966 20.586 32.0366 20.7568 31.8118C20.9302 31.5848 21.1744 31.409 21.4892 31.2846C21.8042 31.1576 22.1582 31.0942 22.5512 31.0942C22.9346 31.0942 23.2776 31.1624 23.5804 31.2992C23.8854 31.436 24.1224 31.6312 24.2908 31.8852C24.4592 32.1366 24.5434 32.4246 24.5434 32.7494H23.2618C23.2618 32.5322 23.1946 32.3636 23.0604 32.244C22.9284 32.1244 22.749 32.0646 22.522 32.0646C22.2924 32.0646 22.1106 32.1158 21.9764 32.2184C21.8444 32.3184 21.7786 32.4466 21.7786 32.603C21.7786 32.7396 21.8518 32.8642 21.9982 32.9764C22.1448 33.0864 22.4024 33.201 22.771 33.3206C23.1396 33.4378 23.4424 33.5648 23.6792 33.7016C24.2554 34.0336 24.5434 34.4914 24.5434 35.0748C24.5434 35.5412 24.3676 35.9074 24.0162 36.1734C23.6646 36.4396 23.1824 36.5726 22.5696 36.5726C22.1374 36.5726 21.7456 36.4958 21.394 36.342C21.045 36.1856 20.7812 35.9732 20.603 35.7048C20.4272 35.4338 20.3394 35.1224 20.3394 34.7708H21.6284C21.6284 35.0566 21.7016 35.2678 21.8482 35.4044C21.997 35.5388 22.2376 35.6058 22.5696 35.6058C22.782 35.6058 22.9492 35.5606 23.0712 35.4704C23.1958 35.3776 23.258 35.2482 23.258 35.0822Z" fill="white"/>
                                                                </svg>

                                                                <div  style={{display:"flex", flexDirection:"column",    gap:screen_width === 'default-1536px' ? "6px" : "4px",    alignItems:"center"}}>
                                                                    <div  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"500"}}>sales_data.csv</div>
                                                                    <div  style={{color:"gray",    fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"500"}}>56Kb</div>
                                                                </div>
                                                            </div>

                                                            {/* </CSVLink> */}
                                                        </div>
                                                    </div>

                                                </div>

                                            ) 
                                        :   
                                            (

                                                <div  style={{width:"100%", height:"84%", display:"flex"}}>
                                                                                
                                                    <div  style={{width:"70%", height:"100%"}}>
                                                        <div  style={{width:"100%", height:"100%"}}>
                                                            <div  className="fontF"  style={{width:"100%", height:"10%", color:"#4B5563",    fontSize:screen_width === 'default-1536px' ? "16.5px" : "15.5px",    fontWeight:"600"}}>File Upload</div>
                                                            <div  style={{width:"100%", height:"7%"}}></div>
                                                            <div  style={{width:"100%", height:"83%", backgroundColor:"white", borderRadius:"4px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1",  cursor: isDragging_sl ? "copy" : "pointer"}}     onDrop={handleDrop_sl}      onDragOver={handleDragOver_sl}      onDragLeave={handleDragLeave_sl}      onClick={handleBrowseClick_sl}>
                                                                <input  id="fileInput"  type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange_sl}  style={{display:"none"}}/>
                                                                <div  style={{width:"100%", height:"100%", display:"flex", justifyContent:"center"}}>
                                                                    
                                                                    <div  style={{ width:"28%", height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-end", gap:"0.6rem"}}>
                                                                        <div className="grid aspect-square place-items-center rounded-full"      style={{width:screen_width === 'default-1536px' ? "48px" : "42px",      backgroundColor: "#1A7AAF" }}>
                                                                            <FiUploadCloud fontSize={ screen_width === 'default-1536px' ? 26 : 22 } color="white"/>
                                                                        </div>
                                                                        <div    className="browse-text"    style={{color:"#1A7AAF", fontWeight:"500"}}>Browse...</div>
                                                                    </div>

                                                                    <div    className="browse-text"    style={{width:"10%", height:"100%", fontWeight:"500",  display:"flex", alignItems:"center", justifyContent:"center"}}>or</div>

                                                                    <div  style={{width:"62%", height:"100%", display:"flex", flexDirection:"column", gap:"4px", justifyContent:"center", alignItems:"flex-start"}}>
                                                                        <div  className="browse-text"  style={{color:"#171717",   fontWeight:"500"}}>Drag and Drop file here</div>
                                                                        <div  className="browse-text"  style={{color:"#6E6E6E",   fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"400"}}>Supported File - CSV (Recommended size: 200Kb)</div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div  style={{width:"2%", height:"100%"}}></div>

                                                                                                                    
                                                    <div  style={{width:"28%", height:"100%"}}>
                                                        <div  style={{width:"100%", height:"100%"}}>
                                                            <div  className="fontF"  style={{width:"100%", height:"10%", color:"#4B5563",     fontSize:screen_width === 'default-1536px' ? "16.5px" : "15px",    fontWeight:"600"}}>Sample File</div>
                                                            <div  style={{width:"100%", height:"7%"}}></div>
                                                            {/* <CSVLink         */}
                                                            <div
                                                            // data={sampleData}    headers={headers}    filename={"geo_data"}    
                                                            style={{width:"100%", height:"83%", backgroundColor:"white", borderRadius:"4px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", flexDirection:"column", alignItems:"center",      gap:screen_width === 'default-1536px' ? "1rem" : "0.5rem",      justifyContent:"center"}}>
                                                                <svg    style={{width:screen_width === 'default-1536px' ? "48px" : "42px",   height:screen_width === 'default-1536px' ? "48px" : "42px"}}    viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7.5 3.7C7.5 2.48498 8.48498 1.5 9.7 1.5H32.25L40.5 9.75V43.3C40.5 44.5151 39.5151 45.5 38.3 45.5H9.7C8.48498 45.5 7.5 44.5151 7.5 43.3V3.7Z" fill="#048556"/>
                                                                    <path d="M30 1.5L37.5 9H32C30.8954 9 30 8.10456 30 7V1.5Z" fill="#85CCAA"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.25 5.75C11.25 5.47386 11.4739 5.25 11.75 5.25H14.5C14.7761 5.25 15 5.47386 15 5.75V7.125H17.625C17.9011 7.125 18.125 7.34886 18.125 7.625V11C18.125 11.2761 17.9011 11.5 17.625 11.5H11.75C11.4739 11.5 11.25 11.2761 11.25 11V7.125V5.75ZM12.175 7.125H14.075C14.2407 7.125 14.375 6.99068 14.375 6.825V6.175C14.375 6.00932 14.2407 5.875 14.075 5.875H12.175C12.0093 5.875 11.875 6.00932 11.875 6.175V6.825C11.875 6.99068 12.0093 7.125 12.175 7.125ZM12.175 7.75C12.0093 7.75 11.875 7.88432 11.875 8.05V8.7C11.875 8.86568 12.0093 9 12.175 9H14.075C14.2407 9 14.375 8.86568 14.375 8.7V8.05C14.375 7.88432 14.2407 7.75 14.075 7.75H12.175ZM15 8.05C15 7.88432 15.1343 7.75 15.3 7.75H17.2C17.3657 7.75 17.5 7.88432 17.5 8.05V8.7C17.5 8.86568 17.3657 9 17.2 9H15.3C15.1343 9 15 8.86568 15 8.7V8.05ZM12.175 9.625C12.0093 9.625 11.875 9.75932 11.875 9.925V10.575C11.875 10.7407 12.0093 10.875 12.175 10.875H14.075C14.2407 10.875 14.375 10.7407 14.375 10.575V9.925C14.375 9.75932 14.2407 9.625 14.075 9.625H12.175ZM15 9.925C15 9.75932 15.1343 9.625 15.3 9.625H17.2C17.3657 9.625 17.5 9.75932 17.5 9.925V10.575C17.5 10.7407 17.3657 10.875 17.2 10.875H15.3C15.1343 10.875 15 10.7407 15 10.575V9.925Z" fill="white"/>
                                                                    <path d="M13.6414 32.9398L14.5129 31.1674H15.9814L14.491 33.8114L16.0217 36.4994H14.5386L13.6414 34.694L12.7441 36.4994H11.2646L12.7917 33.8114L11.3049 31.1674H12.7698L13.6414 32.9398ZM17.7283 35.5106H19.9658V36.4994H16.4429V31.1674H17.7283V35.5106ZM23.258 35.0822C23.258 34.8942 23.191 34.7476 23.0566 34.6428C22.9248 34.5378 22.6916 34.429 22.3572 34.3168C22.0228 34.2044 21.7492 34.0958 21.5368 33.9908C20.846 33.6514 20.5004 33.1852 20.5004 32.592C20.5004 32.2966 20.586 32.0366 20.7568 31.8118C20.9302 31.5848 21.1744 31.409 21.4892 31.2846C21.8042 31.1576 22.1582 31.0942 22.5512 31.0942C22.9346 31.0942 23.2776 31.1624 23.5804 31.2992C23.8854 31.436 24.1224 31.6312 24.2908 31.8852C24.4592 32.1366 24.5434 32.4246 24.5434 32.7494H23.2618C23.2618 32.5322 23.1946 32.3636 23.0604 32.244C22.9284 32.1244 22.749 32.0646 22.522 32.0646C22.2924 32.0646 22.1106 32.1158 21.9764 32.2184C21.8444 32.3184 21.7786 32.4466 21.7786 32.603C21.7786 32.7396 21.8518 32.8642 21.9982 32.9764C22.1448 33.0864 22.4024 33.201 22.771 33.3206C23.1396 33.4378 23.4424 33.5648 23.6792 33.7016C24.2554 34.0336 24.5434 34.4914 24.5434 35.0748C24.5434 35.5412 24.3676 35.9074 24.0162 36.1734C23.6646 36.4396 23.1824 36.5726 22.5696 36.5726C22.1374 36.5726 21.7456 36.4958 21.394 36.342C21.045 36.1856 20.7812 35.9732 20.603 35.7048C20.4272 35.4338 20.3394 35.1224 20.3394 34.7708H21.6284C21.6284 35.0566 21.7016 35.2678 21.8482 35.4044C21.997 35.5388 22.2376 35.6058 22.5696 35.6058C22.782 35.6058 22.9492 35.5606 23.0712 35.4704C23.1958 35.3776 23.258 35.2482 23.258 35.0822Z" fill="white"/>
                                                                </svg>

                                                                <div  style={{display:"flex", flexDirection:"column",      gap:screen_width === 'default-1536px' ? "6px" : "4px",      alignItems:"center"}}>
                                                                    <div  className="fontF"  style={{fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"500"}}>sales_data.csv</div>
                                                                    <div  style={{color:"gray", fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"500"}}>56Kb</div>
                                                                </div>
                                                            </div>

                                                            {/* </CSVLink> */}
                                                        </div>
                                                    </div>

                                                </div>

                                            )
                            }


                                <div  style={{width:"100%", height:"4%"}}></div>


                                <div  style={{width:"100%", height:"12%", display:"flex", alignItems:"center", gap:"0.2rem"}}>
                                    <svg   style={{width:screen_width === 'default-1536px' ? "24px" : "20px",   height:screen_width === 'default-1536px' ? "24px" : "20px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#4B5563"/>
                                        <path d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z" fill="#4B5563"/>
                                        <path d="M12 10C11.7348 10 11.4804 10.1054 11.2929 10.2929C11.1054 10.4804 11 10.7348 11 11V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V11C13 10.7348 12.8946 10.4804 12.7071 10.2929C12.5196 10.1054 12.2652 10 12 10Z" fill="#4B5563"/>
                                    </svg>
                                    <p      className="browse-text"    style={{fontSize:screen_width === 'default-1536px' ? "14px" : "12px",    fontWeight:"500", color:"#4B5563"}}>Your file must meet certain criteria to be uploaded. <span  style={{color:"#005FE3"}}>See all Criteria</span></p>
                                </div>
                        </div>


                    </div>



                    <div  style={{width:"100%", height:"10%",    padding:screen_width === 'default-1536px' ? "12px 24px" : "10px 24px",    borderRadius:"8px", boxShadow:"5px 5px 20px 0px #AEAEC080, -5px -5px 20px 0px #f1f1f1", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                        <div>
                            {/* { 
                                ASD    ?    <p    className="error-texts"    style={{color:"#DC2626"}}>CSV file contains Errors, Click on "Show Errors" for more details</p>
                                        :    !(showBtn  &&  business_Val  &&  category_Val  &&  product_Val   &&  !duplicateFileNameError  &&  file  &&  uploadData_descriptionX)  &&  <p    className="error-texts"    style={{color:"#DC2626"}}>All fields must be filled in before saving</p>
                            } */}
                        </div>

                        <div  style={{display:"flex", gap:"1rem"}}>
                            <button      onClick={()=>{  
                                // setUploadData_btn_Clicked(false) 
                                 }}    className="footer-button"    style={{borderRadius:"7px", border:"2px solid #1A7AAF", color:"#1A7AAF", fontWeight:"600"}}   >Cancel</button>
                            <button      onClick={()=>{  
                                // formSubmit()                      
                                }}    className="footer-button"    style={{borderRadius:"7px", backgroundColor:"#1A7AAF", color:"white", fontWeight:"600",        opacity : (
                                    //showBtn  &&  
                                    business_Val_sl  &&  category_Val_sl  &&  product_Val_sl   &&  !duplicateFileNameError_sl  &&  file_sl  &&  uploadData_descriptionX_sl)  ?  "1"  :  "0.5"}}    disabled={!(
                                        //showBtn  &&  
                                        business_Val_sl  &&  category_Val_sl  &&  product_Val_sl   &&  !duplicateFileNameError_sl  &&  file_sl  &&  uploadData_descriptionX_sl)}  >Save</button>
                        </div>
                    </div>
                
                </div>

            </div>
    
        </div>
    );
}
  
export default MMM_SL_data_Upload;