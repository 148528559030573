import { useEffect, useRef, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import PrintDataStep1 from './PrintDataSteps/PrintDataStep1';
import PrintDataStep2 from './PrintDataSteps/PrintDataStep2';
import PrintDataStep3 from './PrintDataSteps/PrintDataStep3';
import PrintDataStep4 from './PrintDataSteps/PrintDataStep4';
import PrintDataStep5 from './PrintDataSteps/PrintDataStep5';
import PrintDataStep6 from './PrintDataSteps/PrintDataStep6';




export default function AccordionTabs2({ section, isActiveArr, handleActive,
                                         dimensionsOptions_P,    data4,    states,    salesData_Dates,    checkedItems_PR,    setCheckedItems_PR,    selected_PR,    setSelected_PR,      uniquePositions_in_Print,    setuniquePositions_in_Print,       data_After_Discarding_ExtraData,    setdata_After_Discarding_ExtraData,    aggregatedData_P,    setAggregatedData_P,    afterRegionalMapping_arr_P,    setAfterRegionalMapping_arr_P,    after_Missing_Dates_and_Positions_Treatment,    setAfter_Missing_Dates_and_Positions_Treatment,    after_flipping_the_Positions_Horizontally,    setAfter_flipping_the_Positions_Horizontally,    after_merging_the_Positions,    setAfter_merging_the_Positions}) {    



    const contentEl = useRef(null);
    const [active, setActive] = useState(false);           
    const [sectionHeight, setSectionHeight] = useState(0);

    useEffect(() => {                               
        setActive(isActiveArr?.includes(section.id));
    }, [isActiveArr]);                               

    useEffect(() => {                                               
        setSectionHeight(active ? contentEl.current.scrollHeight : 0); 
    }, [active]);                                                   















    return (
        <div style={{width:"100%", border:"2px solid #e1e1e1",  display:"flex", flexDirection:"column"}}>
        
        
            <div>             
                <div   onClick={()=>{handleActive(section.id)}}   className={`bg-opacity-20 transition-[0.3s]`}      style={{backgroundColor:"rgb(34, 50, 84)", color:"white", fontSize:"18px", padding:"18px 24px",    display:"flex", justifyContent:"space-between", alignItems:"flex-start", cursor:"pointer"}}>
                
                    <div  style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <p>  {section?.TabTitle}  </p>

                        <i  className={`${isActiveArr.includes(section.id)   ?   "rotate-180"   :   "rotate-0"}     transition-all duration-300`}>    {/*  STEP 30-57  :  isActiveArr[] contains section 2's id, so icon will rotate  */}
                            <AiOutlineDown />        
                        </i>
                    </div>

                </div>
            </div>




            <div   ref={contentEl}   style={{height: sectionHeight}}   className={`h-0 overflow-hidden bg-richblack-900 transition-[height] duration-[0.35s] ease-[ease]`}>
                <div className="text-textHead  font-semibold"      style={{display:"flex", flexDirection:"column", gap:"1rem", alignItems:"center", padding:"2rem 0"}}>
                    {
                        section.id === 1    ?   <PrintDataStep1      data4={data4}    handleActive={handleActive}    isActiveArr={isActiveArr}    salesData_Dates={salesData_Dates}        data_After_Discarding_ExtraData={data_After_Discarding_ExtraData}    setdata_After_Discarding_ExtraData={setdata_After_Discarding_ExtraData}/>
                                            :   section.id === 2    ?    <PrintDataStep2      dimensionsOptions_P={dimensionsOptions_P}    checkedItems_PR={checkedItems_PR}    setCheckedItems_PR={setCheckedItems_PR}    selected_PR={selected_PR}    setSelected_PR={setSelected_PR}    data_After_Discarding_ExtraData={data_After_Discarding_ExtraData}    handleActive={handleActive}    isActiveArr={isActiveArr}    aggregatedData_P={aggregatedData_P}    setAggregatedData_P={setAggregatedData_P}/>
                                                                    :    section.id === 3    ?    <PrintDataStep3      aggregatedData_P={aggregatedData_P}    handleActive={handleActive}    isActiveArr={isActiveArr}    states={states}     afterRegionalMapping_arr_P={afterRegionalMapping_arr_P}    setAfterRegionalMapping_arr_P={setAfterRegionalMapping_arr_P}/>
                                                                                             :    section.id === 4    ?    <PrintDataStep4      handleActive={handleActive}    isActiveArr={isActiveArr}    states={states}    salesData_Dates={salesData_Dates}      uniquePositions_in_Print={uniquePositions_in_Print}    setuniquePositions_in_Print={setuniquePositions_in_Print}      afterRegionalMapping_arr_P={afterRegionalMapping_arr_P}    after_Missing_Dates_and_Positions_Treatment={after_Missing_Dates_and_Positions_Treatment}    setAfter_Missing_Dates_and_Positions_Treatment={setAfter_Missing_Dates_and_Positions_Treatment}/>
                                                                                                                      :    section.id === 5    ?    <PrintDataStep5      handleActive={handleActive}    isActiveArr={isActiveArr}    after_Missing_Dates_and_Positions_Treatment={after_Missing_Dates_and_Positions_Treatment}    after_flipping_the_Positions_Horizontally={after_flipping_the_Positions_Horizontally}    setAfter_flipping_the_Positions_Horizontally={setAfter_flipping_the_Positions_Horizontally}/>
                                                                                                                                               :    section.id === 6    &&    <PrintDataStep6    uniquePositions_in_Print={uniquePositions_in_Print}      after_flipping_the_Positions_Horizontally={after_flipping_the_Positions_Horizontally}    after_merging_the_Positions={after_merging_the_Positions}    setAfter_merging_the_Positions={setAfter_merging_the_Positions}/>
                    }
                </div>
            </div>


        </div>
    )
}